import translations from './translations'

translations.add('en', {
  all: 'All',
  invited: 'Invited',
  labels: {
    email: 'Email',
    firstName: 'First name',
    lastName: 'Last name',
    role: 'Role',
    roleTitlesStr: 'Roles',
    lastLoginDate: 'Last Login',
    invitationDate: 'Invitation Date',
    status: 'Status',
    authMethod: 'Auth Method'
  },
  errors: {
    email: 'email is required',
    emailInvalid: 'email is invalid',
    firstName: 'first name is required',
    lastName: 'last name is required'
  },
  actions: 'Actions',
  menu: {
    editUser: 'Edit user/access',
    editSecurity: 'Edit security settings',
    delete: 'Delete',
    resend: 'Resend'
  },
  deleteAlert: {
    title: 'Delete Teammate',
    text: 'Are you sure you want to delete this teammate? This action cannot be undone.',
    okText: 'Confirm'
  },
  titles: {
    invite: 'Invite Teammate',
    edit: 'Edit Teammate'
  },
  buttons: {
    add: 'Invite Teammate',
    cancel: 'Cancel',
    invite: 'Invite',
    save: 'Save'
  },
  permissions: {
    chargebacks: 'Chargebacks',
    merchants: 'Merchants',
    'merchants.processes': 'BPM Processes',
    'onboarding.analytics': 'Onboarding Analytics',
    'onboarding.processes': 'Onboarding Applications',
    'onboarding.processes.details': 'Onboarding Application Details',
    'onboarding.processes.fast_track': 'FMPS FastTrack',
    'onboarding.processes.v2': 'Onboarding Applications V2',
    portal_access: 'Merchant Portal Access',
    partners: 'Partner Management',
    'partners.teammates': 'Partner Team Management',
    settlements: 'Settlements',
    'settlements.download': 'Settlements Download',
    'settlements.payments': 'ClearBank Payments',
    'settlements.payments.approve': 'Approve ClearBank Payments',
    'settlements.payments.send_for_approval': 'Send for Approval ClearBank Payments',
    'settlements.per_processed_date': 'Settlements Per Banking Date',
    'settlements.per_banking_date': 'Settlements Per Banking Date',
    'settlements.test': 'Settlements (Test)',
    'settlements.test.download': 'Settlements Download (Test)',
    'settlements.test.per_banking_date': 'Settlements Per Banking Date (Test)',
    'settlements.v2': 'Settlements V2',
    'settlements.v2.download': 'Settlements V2 Download',
    teammates: 'Team Management',
    'transactions.channels': 'Transactions Channels',
    'transactions.dna_ecom_analytics': 'DNA ECOM Analytics',
    'transactions.dna_ecom_statement': 'DNA ECOM Monitoring',
    'transactions.dna_pos_analytics': 'DNA POS Analytics',
    'transactions.dna_pos_statement': 'DNA POS Monitoring',
    'transactions.dna_pos_statement.test': 'DNA POS Monitoring (Test)',
    'transactions.optomany_ecom_analytics': 'Optomany ECOM Analytics',
    'transactions.optomany_ecom_statement': 'Optomany ECOM Monitoring',
    invoices: 'Invoices',
    'invoices.download': 'Invoices Download',
    'invoices.read': 'Invoices Read',
    'netsuite_invoices.download': 'NetSuite Invoices Download'
  },
  permissionSelects: {
    admin: 'Invite as Admin',
    restricted: 'Invite as Restricted Access'
  },
  permissionKeys: {
    read: 'Read',
    update: 'Update',
    create: 'Create',
    delete: 'Delete'
  },
  permissionSelectsText: {
    admin: 'Admins will have full access including managing teammates and new features.',
    restricted: `Customize your teammate's access or select a preset.`
  },
  subtitles: {
    userAccess: 'User access',
    presets: 'Access Presets'
  },
  specificPermissions: 'Specific Permissions',
  totalPagination: (range: number[], total: number) => `${range[0]}-${range[1]} of ${total}`
})
