export const PermissionMap = {
  transactions: {
    dna_total_analytics: {
      read: 'transactions.dna_total_analytics.read'
    },
    dna_pos_analytics: {
      read: 'transactions.dna_pos_analytics.read'
    },
    dna_pos_statement: {
      v2: {
        read: 'transactions.dna_pos_statement.v2.read'
      },
      test: {
        read: 'transactions.dna_pos_statement.test.read'
      }
    },
    dna_pos_amex_statement: {
      read: 'transactions.dna_pos_amex_statement.read'
    },
    dna_ecom_analytics: {
      read: 'transactions.dna_ecom_analytics.read'
    },
    dna_ecom_statement: {
      read: 'transactions.dna_ecom_statement.read'
    },
    optomany_ecom_analytics: {
      read: 'transactions.optomany_ecom_analytics.read'
    },
    optomany_ecom_statement: {
      read: 'transactions.optomany_ecom_statement.read'
    },
    channels: {
      read: 'transactions.channels.read',
      update: 'transactions.channels.update'
    },
    dna_ecom_realtime: {
      read: 'transactions.dna_ecom_realtime.read'
    },
    dna_pos_realtime: {
      read: 'transactions.dna_pos_realtime.read'
    }
  },
  settlements: {
    read: 'settlements.read',
    download: 'settlements.download',
    payments: {
      read: 'settlements.payments.read',
      send_for_approval: 'settlements.payments.send_for_approval',
      approve: 'settlements.payments.approve'
    },
    per_banking_date: {
      read: 'settlements.per_banking_date.read'
    },
    test: {
      read: 'settlements.test.read',
      download: 'settlements.test.download',
      per_banking_date: {
        read: 'settlements.test.per_banking_date.read'
      }
    }
  },
  merchants: {
    read: 'merchants.read',
    update: 'merchants.update',
    processes: {
      read: 'merchants.processes.read',
      update: 'merchants.processes.update',
      moto: {
        read: 'merchants.processes.moto.read'
      },
      bank: {
        read: 'merchants.processes.bank.read'
      },
      risk_limits: {
        read: 'merchants.processes.risk_limits.read'
      },
      merchant_close: {
        read: 'merchants.processes.merchant_close.read'
      },
      terminal_close: {
        read: 'merchants.processes.terminal_close.read'
      },
      settlement_period: {
        read: 'merchants.processes.settlement_period.read'
      },
      additional_terminal: {
        read: 'merchants.processes.additional_terminal.read'
      },
      additional_store: {
        read: 'merchants.processes.additional_store.read'
      },
      settlement_type: {
        read: 'merchants.processes.settlement_type.read'
      },
      hold_settlement: {
        read: 'merchants.processes.hold_settlement.read'
      },
      tariff: {
        read: 'merchants.processes.tariff.read'
      },
      tipjar: {
        read: 'merchants.processes.tipjar.read'
      },
      adjustment: {
        read: 'merchants.processes.adjustment.read'
      }
    },
    section: {
      terminal_routings: {
        read: 'merchants.section.terminal_routings.read'
      }
    }
  },
  onboarding: {
    analytics: {
      read: 'onboarding.analytics.read'
    },
    processes: {
      read: 'onboarding.processes.read',
      create: 'onboarding.processes.create',
      update: 'onboarding.processes.update',
      details: {
        read: 'onboarding.processes.details.read'
      }
    },
    processesV2: {
      create: 'onboarding.processes.v2.create'
    }
  },
  teammates: {
    create: 'teammates.create',
    read: 'teammates.read',
    update: 'teammates.update',
    delete: 'teammates.delete'
  },
  portalAccess: {
    create: 'portal_access.create',
    read: 'portal_access.read',
    update: 'portal_access.update'
  },
  partners: {
    create: 'partners.create',
    read: 'partners.read',
    update: 'partners.update',
    delete: 'partners.delete',

    teammates: {
      create: 'partners.teammates.create',
      read: 'partners.teammates.read',
      update: 'partners.teammates.update',
      delete: 'partners.teammates.delete'
    }
  },
  debug: 'debug',
  chargebacks: {
    read: 'chargebacks.read'
  },
  invoices: {
    read: 'invoices.read',
    download: 'invoices.download'
  },

  netSuiteInvoices: {
    download: 'netsuite_invoices.download'
  },

  processes: {
    tasklist: {
      read: 'processes.tasklist.read'
    }
  },
  partner_commissions: {
    read: 'partner_commissions.read'
  },
  notifications: {
    read: 'notifications.read',
    create: 'notifications.create',
    update: 'notifications.update',
    delete: 'notifications.delete',

    templates: {
      read: 'notifications.templates.read',
      create: 'notifications.templates.create',
      update: 'notifications.templates.update',
      delete: 'notifications.templates.delete'
    }
  }
}
