import { inject, injectable } from 'inversify'
import { makeObservable, reaction, computed } from 'mobx'
import { v4 as uuid } from 'uuid'
import { Modal, notification } from 'antd'
import { TableFilterStore } from '../../TableFilterStore'
import { TableFetchParams } from '~/code/models'
import { IPartnerAccessTableStore } from '~/code/pages/PartnerAccess/components/PartnerAccessTable'

import { PartnerLoginType, PartnerAccessMenuType } from '~/code/stores/PartnerAccessStore/types'
import { fetchPartnerLogins, fetchPartnersByLogin } from '~/code/stores/PartnerAccessStore/services/fetchers'
import { deleteTeammate } from '~/code/stores/TeammatesStore/services'
import { IPartnerAccessStore, PartnerAccessStoreSymbol } from '~/code/pages/PartnerAccess'

import translations from './translations'

@injectable()
export class PartnerAccessTableStore implements IPartnerAccessTableStore {
  partnerAccessStore: IPartnerAccessStore
  loginsTableStore: TableFilterStore<PartnerLoginType>
  partnersTableStore: TableFilterStore<PartnerLoginType>
  emptyRequest = {
    data: [],
    total: 0
  }

  constructor(@inject(PartnerAccessStoreSymbol) partnerAccessStore: IPartnerAccessStore) {
    this.partnerAccessStore = partnerAccessStore

    makeObservable(this, {
      tableStore: computed
    })

    this.partnersTableStore = new TableFilterStore(this.fetchPartnersByLogin.bind(this))
    this.loginsTableStore = new TableFilterStore(this.fetchLoginsByPartner.bind(this))

    reaction(
      () => this.partnerAccessStore.activeTab,
      () => {
        this.partnerAccessStore.resetTabs()
        this.reload()
      }
    )
  }

  get isLoading() {
    return this.tableStore.loadingStatus === 'loading'
  }

  get tableStore() {
    return this.partnerAccessStore.isPartnerTabActive ? this.loginsTableStore : this.partnersTableStore
  }

  fetchLoginsByPartner = async ({ currentPage, pageSize }: TableFetchParams) => {
    if (!this.partnerAccessStore.selectedPartner) {
      return this.emptyRequest
    }

    const { status, result, error } = await fetchPartnerLogins({
      partnerKey: this.partnerAccessStore?.selectedPartner?.clientId,
      page: currentPage,
      size: pageSize
    })

    if (error || !result || !result.logins) {
      return this.emptyRequest
    }

    const { logins, totalCount: total } = result

    return {
      data: logins?.map(item => ({
        ...item,
        rowKey: uuid()
      })),
      total
    }
  }

  fetchPartnersByLogin = async () => {
    if (!this.partnerAccessStore.selectedEmail) {
      return this.emptyRequest
    }

    const { result, error } = await fetchPartnersByLogin(this.partnerAccessStore.selectedEmail)

    if (error || !result) {
      return this.emptyRequest
    }

    const partnersArray = !!result && [result]

    return {
      data: partnersArray.map(item => ({
        ...item,
        rowKey: uuid()
      })),
      total: partnersArray.length
    }
  }

  onActionSelect = (key: PartnerAccessMenuType, item: PartnerLoginType) => {
    const { activeTab, setSelectedTableItem, onLoginDrawerOpen, onSecurityDrawerOpen, onPartnerLoginAccessDrawerOpen } =
      this.partnerAccessStore

    const actionsMap: { [key in PartnerAccessMenuType]: () => void } = {
      edit: () => {
        setSelectedTableItem(item)
        onLoginDrawerOpen()
      },
      'edit-access': () => {
        setSelectedTableItem(item)
        onPartnerLoginAccessDrawerOpen()
      },
      'edit-security': () => {
        setSelectedTableItem(item)
        onSecurityDrawerOpen()
      },
      delete: () => {
        Modal.confirm({
          title: translations().deleteAlert[activeTab].title,
          content: translations().deleteAlert[activeTab].text,
          okText: translations().deleteAlert.okText,
          onOk: () => this.onDelete(item)
        })
      }
    }

    const action = actionsMap[key]

    if (action) {
      action()
    }
  }

  onDelete = async (item: PartnerLoginType) => {
    try {
      const { status, error } = await deleteTeammate(item.id)

      if (error) {
        notification.error({ message: error.message || translations().loginDeleteFailure })
        return
      }

      if (status === 200) {
        notification.success({ message: translations().loginSuccessfulDelete })
      }

      this.reload()
    } catch (error) {
      notification.error({ message: error?.message || translations().loginDeleteFailure })
    }
  }

  reload() {
    this.partnerAccessStore.isPartnerTabActive ? this.loginsTableStore.loadData() : this.partnersTableStore.loadData()
  }
}
