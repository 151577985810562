import React, { useEffect, useState } from 'react'
import { Form, Row, Col, Radio, Alert, Modal, Typography, Collapse, Tooltip } from 'antd'
import { CheckCircleFilled } from '@ant-design/icons'
import { observer } from 'mobx-react-lite'
import { isEmpty } from 'dna-common'
import { CustomButton, CustomModal, CustomVerificationInfo, DNALoader, Input, ReloadButton } from '~/code/components'
import { AccountType } from '../../models'
import { BankAccountsPopover, BelowActionsWithSave, FormSubgroupTitle } from '../shared'
import { BankInfo } from '../../models/BankInfo'
import { parseSortCode } from '../../services'
import { ECOSPEND_STATUSES } from '../../services/constants'
import { OpenBankingQuestionnaire } from '../OpenBankingQuestionnaire'
import translations from './translations'
import { BankAccountInfoProps } from './props'
import styles from './BankAccountInfo.scss'

const { Link } = Typography

export const BankAccountInfo = observer(
  ({ companyType, verifyBtnDisabled = false, store, title, disabled, settings, onCancel }: BankAccountInfoProps) => {
    const { selectedBankAccount } = store
    const [showModal, setShowModal] = useState(false)
    const [showUpdateModal, setShowUpdateModal] = useState(false)
    const [isOwnerEmailEqualToCompany, setIsOwnerEmailEqualToCompany] = useState(true)
    const [ownerEmail, setOwnerEmail] = useState('')
    const showVerifyBtn = !store.checkBankAccResult.sortCode || store.checkBankAccResult.verified
    const [showDocModal, setShowDocModal] = useState(false)
    const [isExistingAcc, setIsExistingAcc] = useState(false)

    const [questionsForm] = Form.useForm()

    const isOBSuccess = [ECOSPEND_STATUSES.VERIFIED, ECOSPEND_STATUSES.MATCH].includes(store.openBankingResults)

    const isNoMatch = [ECOSPEND_STATUSES.NOMATCH].includes(store.openBankingResults)

    const isSent = [ECOSPEND_STATUSES.SENT].includes(store.openBankingResults)

    const uploadDisabled = store.openBankingRequestSent
      ? store.sortCodeFoundInBase && store.isSME && isSent
      : store.sortCodeFoundInBase && store.isSME

    useEffect(() => {
      store.verifyBankAccountChangeHandler(form)
    }, [store.verifyBankAccountResult])

    useEffect(() => {
      if (selectedBankAccount.validationResults) {
        store.parseValidationResult(selectedBankAccount.validationResults)
      }
      if (selectedBankAccount.creditSafeVerificationResults) {
        store.setCheckBankAccResult(selectedBankAccount.creditSafeVerificationResults)
      }
      if (selectedBankAccount.openBankingRequestSent) {
        store.setOpenBankingRequestSent(true)
      }
      if (selectedBankAccount.openBankVerificationResult?.length > 0) {
        store.setOpenBankingResults(selectedBankAccount.openBankVerificationResult)
      }
      if (selectedBankAccount.fromApi) {
        setIsExistingAcc(true)
      }
    }, [selectedBankAccount])

    useEffect(() => {
      store.openBankingRequestSent &&
        store.fetchOpenBankingResults(
          form.getFieldValue('bankAccountNumber')?.replace(/\s/g, ''),
          form.getFieldValue('sortCode')?.replace(/\s/g, '')
        )
    }, [store.openBankingRequestSent])

    const [form] = Form.useForm()

    const isReadOnly = !isEmpty(store.checkBankAccResult.sortCode)

    const onBankAccountChange = () => {
      store.verifyBankAccountResult?.type !== 'invalid' &&
        store.setVerifyBankAccountResult({ type: 'invalid', message: '' })
    }

    const onSelectAccount = (account: BankInfo) => {
      form.setFieldsValue({
        bankAccountType: account.bankAccountType?.toLocaleLowerCase(),
        bankAccountNumber: account.bankAccountNumber?.replace(/(\d{4})(\d{4})/, '$1 $2'),
        sortCode: account.sortCode?.replace(/-/g, ' - '),
        bankName: account.bankName,
        accountName: store.companyProfile?.companyName
      })

      if (account.fromApi) {
        setIsExistingAcc(true)
      }

      store.setVerifyBankAccountResult({
        type: 'invalid',
        message: ''
      })
    }

    return (
      <div className={styles.fullWidth}>
        {store.isOpenBankingResulstLoading && <DNALoader />}
        <Form
          layout='vertical'
          form={form}
          initialValues={store.initialDataForSelectedBankAccount}
          disabled={!isEmpty(store.checkBankAccResult.sortCode)}
          className={styles.formWrapper}
          onValuesChange={() => store.setErrorMessage('')}
        >
          <div className={styles.header}>
            <FormSubgroupTitle>{title || translations().bankAccountInfo}</FormSubgroupTitle>
            {!disabled && <BankAccountsPopover onSelect={account => onSelectAccount(account)} />}
          </div>
          {settings?.bankDetailScheme !== 'UK' ? (
            <Form.Item name={'bankAccountNumber'} label={translations().bankAccountNumberLabel}>
              <Input />
            </Form.Item>
          ) : (
            <>
              {companyType === 'sole-trader' && (
                <Form.Item
                  name='bankAccountType'
                  label={translations().accountType}
                  rules={[{ required: true, message: translations().accountTypeRequired }]}
                  validateTrigger={['onChange', 'onBlur']}
                >
                  <Radio.Group>
                    <Radio value={AccountType.Business}>{translations().accountTypes.business}</Radio>
                    <Radio value={AccountType.Personal}>{translations().accountTypes.personal}</Radio>
                  </Radio.Group>
                </Form.Item>
              )}
              <Row gutter={16}>
                <Col xs={24} md={10}>
                  <Form.Item
                    name='bankAccountNumber'
                    label={translations().bankAccountNumberLabel}
                    rules={[
                      {
                        required: true,
                        message: translations().bankAccountNumberRequired
                      },
                      {
                        pattern: /^(\d){4}(\s)(\d){4}$/,
                        message: translations().bankAccountNumberInvalid
                      }
                    ]}
                    validateTrigger='onBlur'
                  >
                    <Input
                      disabled={disabled}
                      type={'masked'}
                      mask={'9999 9999'}
                      placeholder={'1234 5678'}
                      onChange={onBankAccountChange}
                      readOnly={isReadOnly}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} md={8}>
                  <Form.Item
                    name='sortCode'
                    label={translations().sortCodeLabel}
                    rules={[
                      { required: true, message: translations().sortCodeRequired },
                      {
                        pattern: /^(\d){2}(\s)-(\s)(\d){2}(\s)-(\s)(\d){2}$/,
                        message: translations().sortCodeInvalid
                      }
                    ]}
                    validateTrigger='onBlur'
                  >
                    <Input
                      disabled={disabled}
                      type={'masked'}
                      mask={'99 - 99 - 99'}
                      placeholder={'12 - 34 - 56'}
                      onChange={onBankAccountChange}
                      readOnly={isReadOnly}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} md={6}>
                  <Form.Item label={' '}>
                    <CustomButton
                      disabled={disabled || verifyBtnDisabled}
                      className={styles.verifyButton}
                      loading={store.isVerifyBankLoading}
                      onClick={() => store.verifyBankAccount(form, companyType === 'company')}
                      icon={
                        store.verifyBankAccountResult?.type === 'valid' ? (
                          <CheckCircleFilled className={styles.styledCircle} />
                        ) : null
                      }
                    >
                      {translations().validate}
                    </CustomButton>
                  </Form.Item>
                </Col>

                {store.verifyBankAccountResult?.type !== 'valid' && store.verifyBankAccountResult?.message.length > 0 && (
                  <Col xs={24}>
                    {(store.verifyBankAccountResult.message as string[]).map(errMsg => (
                      <div key={errMsg} className={styles.errMsgVerifyBankAccount}>
                        {errMsg}
                      </div>
                    ))}
                  </Col>
                )}
              </Row>

              <Form.Item
                name='accountName'
                label={translations().accountNameLabel}
                tooltip={translations().accountNameTooltip}
                rules={[
                  {
                    required: true,
                    message: translations().accountNameRequired
                  }
                ]}
              >
                <Input disabled placeholder={translations().accountNamePlaceholder} />
              </Form.Item>

              {store.verifyBankAccountResult?.type === 'valid' && (
                <Form.Item name='bankName' label={translations().bankNameLabel} required>
                  <Input disabled />
                </Form.Item>
              )}

              {store.checkBankAccResult.sortCode && store.checkBankAccResult.verified === true && (
                <div>
                  <b>{translations().automatedVerification}</b>
                  <Alert
                    className={styles.resultsHeader}
                    message={
                      <>
                        {translations().verifResult('VERIFIED')}
                        <p>{translations().verifiedDetails}</p>
                      </>
                    }
                    type='warning'
                  />
                </div>
              )}

              {store.checkBankAccResult.sortCode && store.checkBankAccResult.verified === false && (
                <div>
                  <b>{translations().automatedVerification}</b>
                  <Alert
                    className={styles.resultsHeader}
                    message={
                      <>
                        {translations().verifResult('FAILED')}
                        {store.sortCodeFoundInBase
                          ? isOBSuccess
                            ? translations().successOBNote
                            : store.isSME
                            ? translations().SMENote
                            : translations().notVerifiedDetails
                          : translations().obtainAndUpload}
                      </>
                    }
                    type='warning'
                  />
                  {!store.openBankingRequestSent && !store.isSME && (
                    <div className={styles.verifDetails}>
                      <p>
                        <b>{translations().whatsNext}</b>
                      </p>
                      {store.sortCodeFoundInBase ? translations().twoOptions : translations().optionTwo}
                      {store.sortCodeFoundInBase && (
                        <ol>
                          <li>{translations().optionOne}</li>
                          <b>OR</b>
                          <li>{translations().optionTwo}</li>
                        </ol>
                      )}
                    </div>
                  )}

                  {!store.openBankingRequestSent && store.isSME && (
                    <div className={styles.verifDetails}>
                      <p>
                        <b>{translations().whatsNext}</b>
                      </p>
                      {translations().nowYouHave}
                      {store.sortCodeFoundInBase ? (
                        <ul>
                          <li>{translations().optionOne}</li>
                        </ul>
                      ) : (
                        <ul>
                          <li>{translations().SMENote2}</li>
                        </ul>
                      )}
                    </div>
                  )}
                </div>
              )}

              {store.openBankingRequestSent == true && (
                <>
                  <div className={styles.refresHeader}>
                    <b className={styles.resultsHeader}>{translations().obRequestStatus}</b>
                    {store.openBankingResults?.toUpperCase() !== ECOSPEND_STATUSES.VERIFIED &&
                      store.openBankingResults?.toUpperCase() !== ECOSPEND_STATUSES.MATCH && (
                        <div className={styles.refreshSection}>
                          <Link
                            onClick={() =>
                              store.fetchOpenBankingResults(
                                form.getFieldValue('bankAccountNumber')?.replace(/\s/g, ''),
                                form.getFieldValue('sortCode')?.replace(/\s/g, '')
                              )
                            }
                          >
                            {translations().refreshStatus}
                          </Link>
                          <ReloadButton
                            onClick={() =>
                              store.fetchOpenBankingResults(
                                form.getFieldValue('bankAccountNumber')?.replace(/\s/g, ''),
                                form.getFieldValue('sortCode')?.replace(/\s/g, '')
                              )
                            }
                          />
                        </div>
                      )}
                  </div>
                  <Alert
                    message={
                      <>
                        {translations().verifResult(store.openBankingResults, store.isCanceledByLink)}
                        {store.openBankingResults === ECOSPEND_STATUSES.SENT && (
                          <>
                            <p>{translations().obRequestWaiting}</p>
                            <p>{translations().note}</p>
                            <p>{translations().note2}</p>
                            <p>{translations().note3}</p>
                            <p className={styles.red}>{translations().ifLinkNotOpened}</p>
                          </>
                        )}

                        {store.openBankingResults === ECOSPEND_STATUSES.MATCH && (
                          <>
                            <p>{translations().obrRequestAuthorised}</p>
                            <br />
                            {!isEmpty(store.responseOpenbankingDetails) && (
                              <>
                                <FormSubgroupTitle>{translations().responseData}</FormSubgroupTitle>
                                <Collapse defaultActiveKey={1}>
                                  <Collapse.Panel
                                    header={`${
                                      translations().account
                                    } ${store.responseOpenbankingDetails?.accountIdentification?.substring(6)}`}
                                    key={1}
                                  >
                                    {translations().obrRequestDetailsInfo(
                                      parseSortCode(
                                        store.responseOpenbankingDetails?.accountIdentification?.substring(0, 6)
                                      ),
                                      store.responseOpenbankingDetails?.accountIdentification?.substring(6),
                                      store.responseOpenbankingDetails?.calculatedOwnerName,
                                      store.overallScore
                                    )}
                                  </Collapse.Panel>
                                </Collapse>
                                <br />

                                {!isEmpty(
                                  store.openBankingResultsFull?.result[0]?.responseData?.customVerification?.results
                                ) && (
                                  <CustomVerificationInfo
                                    data={
                                      store.openBankingResultsFull?.result[0]?.responseData?.customVerification?.results
                                    }
                                  />
                                )}
                              </>
                            )}
                          </>
                        )}

                        {store.openBankingResults === ECOSPEND_STATUSES.NOMATCH && (
                          <>
                            {!isEmpty(store.responseOpenbankingDetails) && (
                              <>
                                <FormSubgroupTitle>{translations().responseData}</FormSubgroupTitle>
                                <Collapse defaultActiveKey={1}>
                                  <Collapse.Panel
                                    header={`${
                                      translations().account
                                    } ${store.responseOpenbankingDetails?.accountIdentification?.substring(6)}`}
                                    key={1}
                                  >
                                    {translations().obrRequestDetailsInfo(
                                      parseSortCode(
                                        store.responseOpenbankingDetails?.accountIdentification?.substring(0, 6)
                                      ),
                                      store.responseOpenbankingDetails?.accountIdentification?.substring(6),
                                      store.responseOpenbankingDetails?.calculatedOwnerName,
                                      store.overallScore
                                    )}
                                  </Collapse.Panel>
                                </Collapse>

                                {!isEmpty(
                                  store.openBankingResultsFull?.result[0]?.responseData?.customVerification?.results
                                ) && (
                                  <CustomVerificationInfo
                                    data={
                                      store.openBankingResultsFull?.result[0]?.responseData?.customVerification?.results
                                    }
                                  />
                                )}
                              </>
                            )}

                            <p>
                              {store.isCanceledByLink
                                ? translations().proceedWithBankStatement
                                : translations().obrProceedWithDocs}
                            </p>
                          </>
                        )}
                      </>
                    }
                    type='warning'
                  />
                </>
              )}
            </>
          )}
        </Form>

        {store.openBankingRequestSent &&
          store.openBankingResults === ECOSPEND_STATUSES.SENT &&
          !store.uploadDocManually && (
            <div className={styles.resendSection}>
              <Alert
                message={
                  <Row gutter={10}>
                    <Col span={18}>{translations().resendText}</Col>
                    <Col span={6}>
                      <CustomButton
                        disabled={!store.isResendTimeoutDone}
                        loading={store.isOBResendLoading}
                        onClick={() => store.resendOpenBankingEmail()}
                      >
                        {translations().resend}
                      </CustomButton>
                    </Col>
                  </Row>
                }
              />
            </div>
          )}

        {store.errorMessage !== '' && (
          <Col xs={24}>
            <div className={styles.errMsg}>{store.errorMessage}</div>
          </Col>
        )}

        {store.checkBankAccResult.sortCode &&
          !store.checkBankAccResult.verified &&
          !store.sortCodeFoundInBase &&
          !store.openBankingRequestSent && (
            <Col xs={24}>
              <div className={styles.resendSection}>
                <Alert message={<p className={styles.errMsg}>{translations().doesntSupportOB}</p>} type='warning' />
              </div>
            </Col>
          )}

        {store.checkBankAccResult.sortCode && !store.checkBankAccResult.verified && !store.isDocusignSent && (
          <div className={styles.marginTop}>
            <Row gutter={[8, 16]}>
              <Col span={8}>
                <CustomButton
                  className={styles.fullWidth}
                  disabled={
                    (store.openBankingRequestSent && store.openBankingResults !== '') || !store.sortCodeFoundInBase
                  }
                  type='primary'
                  onClick={() => store.setShowOpenBankingModal(true)}
                >
                  {translations().openBankingVerification}
                </CustomButton>
              </Col>

              <Col span={8}>
                <Tooltip title={uploadDisabled ? translations().SMETooltip : null}>
                  <CustomButton
                    disabled={isOBSuccess || uploadDisabled}
                    className={styles.fullWidth}
                    type='primary'
                    onClick={() => {
                      if (
                        !isNoMatch &&
                        !store.isFoodHub &&
                        !store.isOmniPay &&
                        store.sortCodeFoundInBase &&
                        !store.isSME
                      ) {
                        store.setShowQuestionsModal(true)
                        return
                      }
                      if (store.openBankingResults === ECOSPEND_STATUSES.SENT) {
                        setShowDocModal(true)
                        return
                      }
                      store.setUploadDocManually(true)
                      store.saveAccount(form, true)
                    }}
                  >
                    {translations().uploadBankStatement}
                  </CustomButton>
                </Tooltip>
              </Col>
              <Col span={8} />

              <Col span={8}>
                <CustomButton className={styles.fullWidth} onClick={() => setShowModal(true)}>
                  {translations().detailEdit}
                </CustomButton>
              </Col>
              <Col span={8}>
                <CustomButton
                  className={styles.fullWidth}
                  onClick={() =>
                    store.selectedBankAccount?.id &&
                    store.selectedBankAccount?.openBankVerificationResult === store.openBankingResults &&
                    store.openBankingRequestSent === store.selectedBankAccount.openBankingRequestSent
                      ? onCancel(null)
                      : store.saveAccount(form, true)
                  }
                >
                  {store.selectedBankAccount.id &&
                  store.selectedBankAccount.openBankVerificationResult === store.openBankingResults &&
                  store.openBankingRequestSent === store.selectedBankAccount.openBankingRequestSent
                    ? translations().cancel
                    : store?.openBankingResults === ECOSPEND_STATUSES.MATCH
                    ? translations().save
                    : translations().saveForLater}
                </CustomButton>
              </Col>
              <Col span={8} />
            </Row>
          </div>
        )}

        {showVerifyBtn && !store.isDocusignSent && (
          <div className={styles.marginTop}>
            <Row gutter={10}>
              <Col>
                <CustomButton
                  type='primary'
                  disabled={
                    !!store.selectedBankAccount?.id && !!store.checkBankAccResult.sortCode && !store.checksReseted
                  }
                  onClick={() =>
                    settings?.bankDetailScheme !== 'UK'
                      ? store.saveAccount(form, true)
                      : store.checkBankAccResult.sortCode
                      ? store.saveAccount(form, true)
                      : store.setShowVerifyModal(true, form, isExistingAcc)
                  }
                >
                  {settings?.bankDetailScheme !== 'UK'
                    ? translations().save
                    : store.checkBankAccResult.sortCode
                    ? translations().save
                    : isExistingAcc
                    ? translations().save
                    : translations().verify}
                </CustomButton>
              </Col>
              <Col>
                <CustomButton
                  disabled={!store.selectedBankAccount?.id && !!store.checkBankAccResult.sortCode}
                  onClick={() => onCancel(null)}
                >
                  {translations().cancel}
                </CustomButton>
              </Col>
              {store.checkBankAccResult.sortCode && !store.checkBankAccResult.verified && (
                <Col>
                  <CustomButton
                    disabled={!store.selectedBankAccount?.id && !!store.checkBankAccResult.sortCode}
                    onClick={() => setShowModal(true)}
                  >
                    {translations().detailEdit}
                  </CustomButton>
                </Col>
              )}
            </Row>
          </div>
        )}

        <Modal
          title={translations().verificationSurvey}
          open={store.showQuestionsModal}
          onOk={() => {
            store.setShowQuestionsModal(false)
          }}
          onCancel={() => store.setShowQuestionsModal(false)}
          footer={
            <BelowActionsWithSave
              isLoading={store.isChecksLoading}
              onSave={async () => {
                await questionsForm.validateFields()
                store.setOpenBankingQuestionnaireResults(questionsForm.getFieldsValue())
                store.setUploadDocManually(true)
                store.saveAccount(form, true)
                store.setShowQuestionsModal(false)
              }}
              title={translations().save}
            >
              <CustomButton onClick={() => store.setShowQuestionsModal(false)}>{translations().cancel}</CustomButton>
            </BelowActionsWithSave>
          }
        >
          <OpenBankingQuestionnaire form={questionsForm} />
        </Modal>

        <CustomModal
          title={translations().detailEdit}
          open={showModal}
          onOk={() => {
            store.resetChecks()
            setShowModal(false)
          }}
          onCancel={() => setShowModal(false)}
        >
          {translations().resetChecks}
        </CustomModal>

        <CustomModal
          title={translations().updateAndSave}
          open={showUpdateModal}
          onOk={() => {
            store.updateOpenBankingDetailsAndSave(form)
            setShowUpdateModal(false)
          }}
          onCancel={() => setShowUpdateModal(false)}
        >
          {translations().confirmUpdate}
        </CustomModal>

        <CustomModal
          title={translations().areYouSure}
          open={showDocModal}
          onOk={() => {
            store.setUploadDocManually(true)
            store.saveAccount(form, true)
            setShowDocModal(false)
          }}
          onCancel={() => setShowDocModal(false)}
        >
          {translations().docModalText}
        </CustomModal>

        <CustomModal
          title={
            store.checkBankAccResult.sortCode ? translations().verificationResults : translations().confirmBankDetails
          }
          open={store.showVerifyModal}
          onOk={() => store.verificationCheckBankAccount(form)}
          onCancel={() => store.setShowVerifyModal(false)}
          footer={
            <BelowActionsWithSave
              isLoading={store.isChecksLoading}
              onSave={() =>
                store.checkBankAccResult.sortCode
                  ? store.setShowVerifyModal(false)
                  : store.verificationCheckBankAccount(form)
              }
              title={
                store.checkBankAccResult.sortCode
                  ? store.checkBankAccResult.verified
                    ? translations().close
                    : translations().backToForm
                  : translations().detailsCorrect
              }
            >
              {!store.checkBankAccResult.sortCode && (
                <CustomButton onClick={() => store.setShowVerifyModal(false)}>{translations().detailEdit}</CustomButton>
              )}
            </BelowActionsWithSave>
          }
        >
          {!store.checkBankAccResult.sortCode && (
            <>
              <p>{translations().info1}</p>
              <p>{translations().info2}</p>
              <p>
                <b>{translations().info3}</b>
              </p>
            </>
          )}
          <div className={styles.styledBody}>
            {translations().accountDetailsInfo(
              store.companyProfile.companyName,
              form.getFieldValue('bankAccountNumber')?.replace(/\s/g, ''),
              form.getFieldValue('sortCode')?.replace(/\s/g, '')
            )}
          </div>

          {store.checkBankAccResult.sortCode && store.checkBankAccResult.verified === true && (
            <div className={styles.verifResults}>
              <p className={styles.verified}>{translations().verified}</p>
              <p>{translations().verifiedDetails}</p>
            </div>
          )}

          {store.checkBankAccResult.sortCode && store.checkBankAccResult.verified === false && (
            <div className={styles.verifResults}>
              <p className={styles.notVerified}>{translations().notVerified}</p>
              <p>{translations().notVerifiedDetails}</p>
            </div>
          )}

          {store.isChecksLoading && <DNALoader />}
        </CustomModal>

        <CustomModal
          title={translations().openBankingRequest}
          open={store.showOpenBankingModal}
          onCancel={() => store.setShowOpenBankingModal(false)}
          footer={
            <>
              <Row gutter={10}>
                {!store.openBankingRequestSent && (
                  <Col>
                    <CustomButton
                      disabled={!isOwnerEmailEqualToCompany && ownerEmail?.length === 0}
                      type='primary'
                      loading={store.isChecksLoading}
                      onClick={() =>
                        store.openBankingCheck(
                          isOwnerEmailEqualToCompany ? store.companyProfile.emailAddress : ownerEmail,
                          form
                        )
                      }
                    >
                      {translations().sendOBRequest}
                    </CustomButton>
                  </Col>
                )}
                <Col>
                  <CustomButton onClick={() => store.setShowOpenBankingModal(false)}>
                    {store.openBankingRequestSent ? translations().close : translations().editDetails}
                  </CustomButton>
                </Col>
              </Row>
            </>
          }
        >
          {!store.openBankingRequestSent && (
            <>
              <p>{translations().startOBRequestInfo}</p>
              <p>{translations().startOBRequestInfo2}</p>
              <p>
                <b>{translations().info3}</b>
              </p>
              <div className={styles.styledBody}>
                {translations().accountDetailsInfo(
                  store.companyProfile.companyName,
                  form.getFieldValue('bankAccountNumber')?.replace(/\s/g, ''),
                  form.getFieldValue('sortCode')?.replace(/\s/g, '')
                )}
                <b>{`${translations().accountOwnerEmail} ${store.companyProfile.emailAddress} ?`}</b>

                <div className={styles.marginTop}>
                  <Radio.Group
                    onChange={e => setIsOwnerEmailEqualToCompany(e.target.value)}
                    value={isOwnerEmailEqualToCompany}
                  >
                    <Radio value={true}>{translations().yes}</Radio>
                    <Radio value={false}>{translations().no}</Radio>
                  </Radio.Group>
                </div>

                {!isOwnerEmailEqualToCompany && (
                  <div className={styles.resultsHeader}>
                    <Input
                      placeholder={'me@company.co.uk'}
                      textTransform={'lowercase'}
                      autoComplete='off'
                      onChange={e => setOwnerEmail(e.target.value)}
                    />
                  </div>
                )}
              </div>
            </>
          )}

          {store.openBankingRequestSent && <p>{translations().obVerificationSent}</p>}

          {store.isChecksLoading && <DNALoader />}
        </CustomModal>
      </div>
    )
  }
)
