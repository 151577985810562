import React from 'react'
import { observer } from 'mobx-react'
import { useInjection } from 'dna-react-ioc'
import { TeammatesContainer, TTeammatesStore } from '~/code/containers/Teammates'
import { PermissionMap } from '~/code/constants/PermissionMap'

export const EmployeeTeammatesStoreSymbol = Symbol('EmployeeTeammatesStore')

export const EmployeeTeammates = observer(() => {
  const store = useInjection<TTeammatesStore>(EmployeeTeammatesStoreSymbol)
  return <TeammatesContainer store={store} type={'employee'} permissionSet={PermissionMap.teammates} />
})
