import React from 'react'
import { observer } from 'mobx-react'
import translations from './translations'
import styles from './BusinessOwners.scss'
import { ListPage } from '../ListPage'
import { BankAccountsProps } from './props'
import { isBankAccountValid, isBankAccountValidEuro } from '../../services'

export const BankAccounts = observer((props: BankAccountsProps) => {
  const { accounts, selectAccount, deleteAccount, isSaveLoading, onSave, isContinueLoading, settings, isDocusignSent } =
    props

  return (
    <ListPage
      title={translations().title}
      text={translations().details}
      list={
        accounts &&
        accounts.map(d => ({
          isRemoveDisabled: isDocusignSent,
          name: d.accountName || d.bankAccountNumber,
          surname: '',
          isCompleted: settings?.bankDetailScheme !== 'UK' ? isBankAccountValidEuro(d) : isBankAccountValid(d),
          onClick: () => selectAccount(d),
          onRemove: () => deleteAccount(d),
          account: d,
          accountVerificationStatus:
            settings?.bankDetailScheme !== 'UK' || d.fromApi
              ? null
              : d.openBankVerificationResult
              ? translations().openBankStatus(d.openBankVerificationResult)
              : d.creditSafeVerificationResults
              ? translations().creditSafeStatus(d.creditSafeVerificationResults?.verified)
              : translations().notVerified,
          manuallyDocs: settings?.bankDetailScheme !== 'UK' ? null : d.uploadDocManually
        }))
      }
      onAdd={() => selectAccount({})}
      isContinueLoading={isContinueLoading}
      isSaveLoading={isSaveLoading}
      onSave={onSave}
      addDisabled={isDocusignSent}
      saveDisabled={isDocusignSent}
    />
  )
})
