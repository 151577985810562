import React from 'react'
import { Badge, Dropdown, TableProps } from 'antd'
import moment from 'moment-timezone'
import { NotificationStatusEnum, NotificationType } from '~/code/stores/NotificationManagementStore'
import { IManageNotificationsStore } from '~/code/pages/Notifications/ManageNotifications/IManageNotificationsStore'
import { EllipsisOutlined } from '@ant-design/icons'
import translations from '../translations'
import { hasPermissions } from '~/code/services/auth'
import { PermissionMap } from '~/code/constants/PermissionMap'

const getStatusColor = (statusId: NotificationStatusEnum) => {
  switch (statusId) {
    case NotificationStatusEnum.ACTIVE:
      return 'success'
    case NotificationStatusEnum.SCHEDULED:
      return 'processing'
    default:
      return 'error'
  }
}

const getMenuItems = (record: { notificationTemplateId: string; id: string; statusId: NotificationStatusEnum }, store: IManageNotificationsStore, deactivate: (id: string) => void) => {
  const { notificationTemplateId, id, statusId } = record;
  const { viewDetails } = store;

  const menuItemsConfig = [
    {
      key: '1',
      label: translations().viewDetails,
      action: () => viewDetails(notificationTemplateId),
      hasPermission: hasPermissions([PermissionMap.notifications.read]),
    },
    {
      key: '2',
      label: translations().deactivate,
      action: () => deactivate(id),
      hasPermission: hasPermissions([PermissionMap.notifications.delete]),
    },
    {
      key: '3',
      label: translations().republish,
      action: () => store.republishNotification(notificationTemplateId),
      hasPermission: hasPermissions([PermissionMap.notifications.update]),
    },
  ];

  const items = menuItemsConfig.map(item => ({
    key: item.key,
    label: (
      <a
        target="_blank"
        rel="noopener noreferrer"
        onClick={(event) => {
          event.stopPropagation()
          item.action()
        }}
      >
        {item.label}
      </a>
    ),
    hasPermission: item.hasPermission,
  }));

  const filteredItems = items
    .filter(item => item.hasPermission)
    .map(({ hasPermission, ...rest }) => rest)

  switch (statusId) {
    case NotificationStatusEnum.ACTIVE:
    case NotificationStatusEnum.SCHEDULED:
      return filteredItems.filter(item => item.key !== '3')
    case NotificationStatusEnum.INACTIVE:
      return filteredItems.filter(item => item.key !== '2')
    default:
      return filteredItems
  }
}

export const getColumns = (store: IManageNotificationsStore, deactivate: (id: string) => void): TableProps<NotificationType>['columns'] => {
  return [
    {
      title: translations().title,
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: translations().system,
      dataIndex: 'system',
      key: 'system',
      width: 130
    },
    {
      title: translations().displayType,
      dataIndex: 'displayType',
      key: 'displayType',
      width: 130
    },
    {
      title: translations().type,
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: translations().status,
      dataIndex: 'status',
      key: 'status',
      render: (status, record) => (
        <Badge
          status={getStatusColor(record.statusId)}
          text={status}
        />
      )
    },
    {
      title: translations().startDate,
      dataIndex: 'startDate',
      key: 'startDate',
      render: (value) => moment(value).format('YYYY-DD-MM HH:mm')
    },
    {
      title: translations().endDate,
      dataIndex: 'endDate',
      key: 'endDate',
      render: (value) => moment(value).format('YYYY-DD-MM HH:mm')
    },
    {
      title: translations().actions,
      dataIndex: 'actions',
      key: 'actions',
      align: 'center',
      width: 100,
      fixed: 'right',
      render: (value, record) => {
        const items = getMenuItems(record, store, deactivate)

        return (
          <Dropdown menu={{ items }}>
            <EllipsisOutlined />
          </Dropdown>
        )
      }
    },
  ]
}