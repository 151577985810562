import React from 'react'
import { observer } from 'mobx-react'
import translations from './translations'
import { ListPage } from '../ListPage'
import { FinancialInfoListProps } from './props'
import { isStoreFinancialInfoValid } from '../../services'

export const FinancialInfoList = observer((props: FinancialInfoListProps) => {
  const { stores, selectStore, isSaveLoading, onSave, isContinueLoading, isDocusignSent } = props

  return (
    <ListPage
      title={translations().title}
      text={translations().details}
      list={
        stores &&
        stores
          .filter(d => d.removable === true)
          .map(d => ({
            name: d.storeName,
            onClick: () => selectStore(d),
            isCompleted: isStoreFinancialInfoValid(d),
            isEditDisabled: !d.removable,
            isRemoveDisabled: !d.removable || isDocusignSent
          }))
      }
      isContinueLoading={isContinueLoading}
      isSaveLoading={isSaveLoading}
      onSave={onSave}
      addTitle={translations().addTitle}
      addDisabled={isDocusignSent}
      saveDisabled={isDocusignSent}
    />
  )
})
