import { Routes } from '~/code/startup/Router/Routes'
export const PLATFORM = 'dashboard'

export const ACCESS_TOKEN_KEY = 'accessToken'

export const SECTION_ROUTES = {
    login: Routes.LOGIN,
    auth: Routes.LOGIN,
    additional_store_processes: Routes.BPM_REPORTS_ONBOARDING_PROCESSES_ADDITIONAL_STORE,
    change_of_risk_limits: Routes.BPM_PROCESSES_SUBMITTED_REQUESTS,
    pos_analytics: Routes.TRANSACTIONS_DNA_ACQUIRING_ANALYTICS_POS,
    closure_of_merchant: Routes.BPM_PROCESSES_CLOSURE_OF_MERCHANT,
    pos_monitoring: Routes.TRANSACTIONS_DNA_ACQUIRING_MONITORING_POS,
    ecom_analytics: Routes.TRANSACTIONS_DNA_ACQUIRING_ANALYTICS,
    statistics: Routes.BPM_REPORTS_STATISTICS,
    merchants: Routes.MERCHANTS,
    ecom_monitoring: Routes.TRANSACTIONS_DNA_ACQUIRING_STATEMENT,
    change_of_bank_details: Routes.BPM_PROCESSES_CHANGE_BANK_DETAILS_DOSSIER_V2,
    ecom_monitoring_v3: Routes.TRANSACTIONS_OPTOMANY_CHECKOUT_STATEMENT,
    payments: Routes.SETTLEMENTS_PAYMENTS,
    statement: Routes.SETTLEMENTS_STATEMENT,
    netsuite_invoices: Routes.NETSUITE_INVOICES,
    ecom_analytics_v3: Routes.TRANSACTIONS_OPTOMANY_CHECKOUT_ANALYTICS,
    total_analytics: Routes.TRANSACTIONS_DNA_ACQUIRING_TOTAL_ANALYTICS,
    statement_test: Routes.SETTLEMENTS_STATEMENT_TEST,
    pos_monitoring_test: Routes.TRANSACTIONS_DNA_ACQUIRING_MONITORING_POS_TEST,
    onboarding_processes: Routes.BPM_REPORTS_ONBOARDING_PROCESSES,
    home: Routes.MAIN,
    transaction_channels: Routes.TRANSACTION_CHANNELS,
    team_management: Routes.TEAMMATES,
    pos_american_express: Routes.TRANSACTIONS_DNA_ACQUIRING_MONITORING_POS_AMEX,
    change_of_settlement_period: Routes.BPM_CHANGE_OF_SETTLEMENT_PERIOD,
    change_of_settlement_type: Routes.BPM_CHANGE_OF_SETTLEMENT_KIND,
    adjustment_requests: Routes.BPM_PROCESSES_ADJUSTMENT_REQUESTS,
    closure_of_terminal: Routes.BPM_PROCESSES_CLOSURE_OF_TERMINAL,
    invoices: Routes.INVOICES,
    exports: Routes.EXPORTS,
    additional_terminal_issue: Routes.BPM_PROCESSES_TERMINAL_ISSUE,
    new_application: Routes.APPLICATIONS_START_NEW_PROCESS,
    incomplete_applications: Routes.APPLICATIONS_SAVED_APPLICATION,
    hold_release_settlements: Routes.BPM_CHANGE_OF_SETTLEMENT_STATUS,
    dna_portal_access: Routes.PORTAL_ACCESS,
    chargebacks: Routes.CHARGEBACKS,
    moto_vt_request: Routes.BPM_PROCESSES_MOTO_VT
}


