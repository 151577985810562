import React, { useState, useEffect } from 'react'
import { Checkbox, Typography } from 'antd'
import type { CheckboxChangeEvent } from 'antd/es/checkbox'
import { SpecificPermissionsProps } from './props'

import translations from './translations'
import styles from './styles.scss'

export const SpecificPermissions: React.FC<SpecificPermissionsProps> = ({
  permissions,
  initialPermissions,
  onPermissionsChange
}) => {
  const [selectedPermissions, setSelectedPermissions] = useState<string[]>([])

  useEffect(() => {
    setSelectedPermissions(initialPermissions.filter(permission => permissions.includes(permission)))
  }, [initialPermissions, permissions])

  useEffect(() => {
    onPermissionsChange(selectedPermissions)
  }, [selectedPermissions, onPermissionsChange])

  useEffect(() => {
    setSelectedPermissions(initialPermissions.filter(permission => permissions.includes(permission)))
  }, [initialPermissions, permissions])

  const handleCheckboxChange = (permission: string) => (e: CheckboxChangeEvent) => {
    setSelectedPermissions(prev => {
      if (e.target.checked) {
        return [...prev, permission]
      } else {
        return prev.filter(p => p !== permission)
      }
    })
  }

  return (
    <div>
      <Typography.Title level={5}>{translations().title}</Typography.Title>

      <hr className={styles.separator} />

      {permissions.map(permission => {
        const checkboxId = `checkbox-${permission}`
        return (
          <div key={permission} className={styles.item}>
            <label htmlFor={checkboxId} className={styles.label}>
              <span className={styles.title}>{translations().permissions[permission] || permission}</span>
              <Checkbox
                id={checkboxId}
                checked={selectedPermissions.includes(permission)}
                onChange={handleCheckboxChange(permission)}
                className={styles.checkbox}
              />
            </label>
          </div>
        )
      })}
    </div>
  )
}
