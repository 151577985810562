import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useInjection } from 'dna-react-ioc'
import { Form, Drawer, Grid, Button } from 'antd'
import { hasPermissions } from '~/code/services/auth'
import { PermissionMap } from '~/code/constants/PermissionMap'
import { PartnerAccessAddEditForm } from '../'
import { IPartnerAccessFormStore } from '~/code/pages/PartnerAccess/components/PartnerAccessDrawer'
import { PartnerAccessDrawerProps } from './props'

import translations from './translations'
import styles from './styles.scss'

const { useBreakpoint } = Grid

export const PartnerAccessFormStoreSymbol = Symbol('PartnerAccessFormStore')

export const PartnerAccessDrawer = observer((props: PartnerAccessDrawerProps) => {
  const { open, ...rest } = props
  const [form] = Form.useForm()
  const { xs } = useBreakpoint()
  const width = xs ? window.innerWidth : 512
  const store = useInjection<IPartnerAccessFormStore>(PartnerAccessFormStoreSymbol)
  const isEditMode = store.partnerAccessStore.isPartnerDrawerEdit

  const permissions = Form.useWatch('permissions', form)
  const specificPermissions = Form.useWatch('specificPermissions', form)
  const isSubmitButtonDisabled =
    !hasPermissions([PermissionMap.partners.create, PermissionMap.partners.update]) ||
    (!permissions?.length && !specificPermissions?.length)

  useEffect(() => form.resetFields(), [open])

  const getTitle = () => {
    if (isEditMode) {
      return translations().edit
    }

    if (!!store.partnerAccessStore.selectedPartner && store.partnerAccessStore.selectedPartner.synced === false) {
      return translations().synced
    }

    return translations().add
  }

  const onClose = () => {
    store.partnerAccessStore.onPartnerDrawerClose()
  }

  const renderFooter = () => (
    <div className={styles.footer}>
      <Button onClick={store.partnerAccessStore.onPartnerDrawerClose}>{translations().cancel}</Button>
      <Button
        htmlType='button'
        type='primary'
        loading={store.isLoading}
        disabled={isSubmitButtonDisabled}
        onClick={() => form.submit()}
      >
        {translations().submit}
      </Button>
    </div>
  )

  return (
    <Drawer
      title={getTitle()}
      open={open}
      onClose={onClose}
      width={width}
      footer={renderFooter()}
      destroyOnClose
      {...rest}
    >
      <PartnerAccessAddEditForm form={form} store={store} />
    </Drawer>
  )
})
