import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
  all: string
  invited: string
  actions: string
  labels: {
    email: string
    firstName: string
    lastName: string
    role: string
    roleTitlesStr: string
    lastLoginDate: string
    invitationDate: string
    status: string
    authMethod: string
  }
  errors: {
    email: string
    emailInvalid: string
    firstName: string
    lastName: string
  }
  menu: {
    editUser: string
    editSecurity: string
    delete: string
    resend: string
  }
  deleteAlert: {
    title: string
    text: string
    okText: string
  }
  titles: {
    invite: string
    edit: string
  }
  buttons: {
    add: string
    cancel: string
    invite: string
    save: string
  }
  permissions: Record<string, string>
  permissionSelects: {
    admin: string
    restricted: string
  }
  permissionKeys: {
    read: string
    update: string
    create: string
    delete: string
  }
  permissionSelectsText: {
    admin: string
    restricted: string
  }
  subtitles: {
    userAccess: string
    presets: string
  }
  specificPermissions: string
  totalPagination: (range: number[], total: number) => string
}>()
