import React from 'react'
import { observer } from 'mobx-react'
import { Col, Row } from 'antd'
import { useInjection } from 'dna-react-ioc'
import {
  RealTimeTransactionDataStoreSymbol,
  RealTimeTransactionDataStoreInterface
} from '~/code/stores/RealTimeTransactionStore/RealTimeTransactionDataStore'
import { RealTimeTransactionMap, LiquidChart } from '~/code/pages/RealTimeTransaction/components'

import styles from './styles.scss'

export const RealTimeTransaction = observer(() => {
  const { tpsData } = useInjection<RealTimeTransactionDataStoreInterface>(RealTimeTransactionDataStoreSymbol)

  return (
    <Row gutter={[24, 24]}>
      <Col xs={24} sm={24} lg={24} xl={18}>
        <RealTimeTransactionMap className={styles.transactionsMap} />
      </Col>

      <Col xs={24} sm={24} lg={24} xl={6}>
        <LiquidChart data={tpsData} />
      </Col>
    </Row>
  )
})
