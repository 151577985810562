import translations from './translations'

translations.add('en', {
  title: 'Permissions settings',
  actionTitle: {
    read: 'Read',
    create: 'Create',
    update: 'Update',
    delete: 'Delete'
  },
  permissions: (isPartner: boolean) => ({
    chargebacks: 'Chargebacks',
    merchants: 'Merchants',
    'merchants.processes': 'BPM Processes',
    'onboarding.analytics': 'Onboarding Analytics',
    'onboarding.processes': 'Onboarding Applications',
    'onboarding.processes.details': 'Onboarding Application Details',
    'onboarding.processes.fast_track': 'FMPS FastTrack',
    'onboarding.processes.v2': 'Onboarding Applications V2',
    portal_access: 'Merchant Portal Access',
    partners: 'Partner Management',
    'partners.teammates': isPartner ? 'Team Management' : 'Partner Team Management',
    settlements: 'Settlements',
    'settlements.download': 'Settlements Download',
    'settlements.payments': 'ClearBank Payments',
    'settlements.payments.approve': 'Approve ClearBank Payments',
    'settlements.payments.send_for_approval': 'Send for Approval ClearBank Payments',
    'settlements.per_processed_date': 'Settlements Per Banking Date',
    'settlements.per_banking_date': 'Settlements Per Banking Date',
    'settlements.test': 'Settlements (Test)',
    'settlements.test.download': 'Settlements Download (Test)',
    'settlements.test.per_banking_date': 'Settlements Per Banking Date (Test)',
    'settlements.v2': 'Settlements V2',
    'settlements.v2.download': 'Settlements V2 Download',
    teammates: 'Team Management',
    'transactions.channels': 'Transactions Channels',
    'transactions.dna_ecom_analytics': 'DNA ECOM Analytics',
    'transactions.dna_ecom_statement': 'DNA ECOM Monitoring',
    'transactions.dna_pos_analytics': 'DNA POS Analytics',
    'transactions.dna_pos_statement': 'DNA POS Monitoring',
    'transactions.dna_pos_statement.test': 'DNA POS Monitoring (Test)',
    'transactions.optomany_ecom_analytics': 'Optomany ECOM Analytics',
    'transactions.optomany_ecom_statement': 'Optomany ECOM Monitoring',
    invoices: 'Invoices',
    'invoices.download': 'Invoices Download',
    'invoices.read': 'Invoices Read',
    'netsuite_invoices.download': 'NetSuite Invoices Download',
    'merchants.processes.merchant_close': 'Closure of Merchant',
    'transactions.dna_pos_amex_statement': 'POS American Express',
    'merchants.processes.additional_store': 'Additional Store Processes',
    'merchants.processes.terminal_close': 'Closure of Terminal',
    'merchants.processes.additional_terminal': 'Additional Terminal Issue',
    'transactions.dna_pos_statement.v2': 'DNA POS Monitoring'
  })
})
