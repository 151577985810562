import translations from './translations'

translations.add('en', {
    transactionDate : 'Transaction Date',
    merchant: 'Merchant',
    amount: 'Amount',
    currency: 'Currency',
    status: 'Status',
    returnCode: 'Return Code',
    message: 'Message',
    transactionId: 'Transaction ID',
    mid: 'MID',
    terminalId: 'Terminal',
    operation: 'Operation',
    transactionType: 'Transaction Type',
    transactionChannel: 'Transaction Channel',
    transactionDetails: 'Transaction Details',
    transactionCountry: 'Transaction Country',
    transactionCity: 'Transaction City',
    cardScheme: 'Card Scheme',
    cardMask: 'Card Mask',
    cardType: 'Card Type',
    issuerRegion: 'Issuer Region',
    isCorporateCard: 'Card Category',
    issuer: 'Issuer',
    captureMethod: 'Capture Method',
    transactionMcc: 'Transaction MCC',
    european: 'European',
    nonEuropean: 'Non-European',
    corporate: 'Corporate',
    personal: 'Personal',
    pos: 'POS',
    'pos-contactless': 'POS Contactless',
    'pos-openbanking': 'Open Banking',
    'pos-alipay': 'Alipay',
    moto: 'MOTO',
    recurring: 'Recurring',
    stored: 'Stored',
    export: 'Export'
})
