import { makeObservable, observable, autorun, action, computed } from 'mobx'
import moment from 'moment-timezone'
import { injectable } from 'inversify'
import { GroupableItem, PeriodType, TableData, TableFetchParams, TransactionModel } from '~/code/models'
import { fetchTransactions } from '~/code/services/fetchers'
import { clearObject, createCheckColumnDate, isDateNull } from '~/code/services/utils'
import { TableFilterStore } from '~/code/stores/TableFilterStore'
import { TransactionStatementFilterModel, TransactionStatementFilterScheme } from './models'
import { IAcquiringFilterStore } from '~/code/pages/Acquiring/components/AcquiringFilter'
import {
  columns,
  columnsOnlyForFullAccess,
  CREATED_DATE_COLUMN_KEY,
  CREATED_DATE_COLUMN_WIDTH,
  getCreatedDateColumn,
  partnerColumnsAccess,
  TransactionColumnType
} from '~/code/pages/Acquiring/pages/Statement/components/TransactionsStatement/columns'
import {
  getAcquiringStorageData,
  setAcquiringStorageData
} from '../../pages/Acquiring/components/AcquiringFilter/services'
import { AcquiringType } from '~/code/pages/Acquiring'
import { getAcquisitionChannel, isPartner } from '~/code/services/auth'
import { ITransactionStatementStore } from '~/code/pages/Acquiring/pages/Statement/models'
import { parseArrayFilter, parseSignifydDecisionFilter } from './services'
import {
  COUNTRIES,
  PAYER_AUTHS,
  PAYMENT_METHODS,
  PROCESSING_TYPES,
  STATUSES,
  TRANSACTION_CHANNELS
} from '~/code/constants/dictionaries'
import { GlobalConfigStore } from '../GlobalConfigStore'
import { Routes } from '~/code/startup/Router/Routes'
import { HandbooksStoreInjectable } from '../HandbooksStoreInjectable'
import storage from '~/code/services/storage'
import { ecomCardSchemes } from '~/code/services/payments/card-scheme'

@injectable()
export class TransactionsStatementStore implements ITransactionStatementStore {
  constructor(
    public acquiringFilterStore: IAcquiringFilterStore,
    public handbooksStore: HandbooksStoreInjectable,
    public configStore: GlobalConfigStore,
    public acquiringType: AcquiringType
  ) {
    this.tableStore = new TableFilterStore(this.fetchData)

    const columnsWidthMap = getAcquiringStorageData(this.acquiringType, 'ecom', 'statement', 'columns')
    columns.forEach(c => (this.columnsWidthMap[c.key as string] = columnsWidthMap[c.key as string] || c.width))
    this.columnsWidthMap[CREATED_DATE_COLUMN_KEY] = this.columnsWidthMap[CREATED_DATE_COLUMN_KEY] =
      columnsWidthMap[CREATED_DATE_COLUMN_KEY] || CREATED_DATE_COLUMN_WIDTH

    makeObservable(this, {
      totalAmount: observable,
      filter: observable,
      isExportModalOpen: observable,
      columnsWidthMap: observable,
      columns: computed,
      acquisitionChannelId: computed,
      currency: computed,
      currencySymbol: computed,
      isSolidGate: computed,
      request: computed,
      isExportButtonDisable: computed,
      setFilter: action,
      openExportModal: action
    })

    autorun(
      () => {
        const {
          dateStore: { startDate, endDate },
          merchantTradeName,
          acquisitionChannelId
        } = this.acquiringFilterStore
        const route =
          this.acquiringType === 'dna'
            ? 'TRANSACTIONS_DNA_ACQUIRING_STATEMENT'
            : 'TRANSACTIONS_OPTOMANY_CHECKOUT_STATEMENT'
        // if user is not on current page, do not make requests to backend on currency change
        if (window.location.pathname !== Routes[route]) return
        if (startDate && endDate && merchantTradeName && this.currency) {
          this.loadData(1)
        }
      },
      { delay: 5 }
    )
  }
  isExportModalOpen = false
  dateColumn = {}
  totalAmount: number = 0
  tableStore: TableFilterStore<GroupableItem<TransactionModel>>
  filter = new TransactionStatementFilterModel()
  prevFilterJSON = ''
  columnsWidthMap: Record<string, number> = {}

  get currency() {
    return this.configStore.currency.type
  }

  get currencySymbol() {
    return this.configStore.currency.symbol
  }

  get hasFullAccess() {
    return !isPartner()
  }

  get filteredColumns() {
    const partner = getAcquisitionChannel()

    return columns.filter(c => {
      const column = c.key as string

      if (this.hasFullAccess) return true
      if (partnerColumnsAccess.get(partner)?.includes(column)) return true
      return columnsOnlyForFullAccess.indexOf(column) < 0
    })
  }

  get createdDateColumn() {
    return getCreatedDateColumn(this.filteredColumns.length + 1)
  }

  get isExportButtonDisable() {
    return !this.tableStore.data.length
  }

  get columns() {
    return [this.createdDateColumn, ...this.filteredColumns].map((c, i) => ({
      ...c,
      width: this.columnsWidthMap[c.key as string],
      onHeaderCell: (column: TransactionColumnType) =>
        ({
          width: column.width,
          onResize: this.handleResize(column.key?.toString())
        } as any)
    }))
  }

  get acquisitionChannelId() {
    if (isPartner() || this.acquiringFilterStore.acquisitionChannelId === 'all') return undefined
    return this.acquiringFilterStore.acquisitionChannelId
  }

  get isSolidGate() {
    return isPartner() && storage.get('acquisition_channel') === 'solidgate'
  }

  handleResize = (key: string) =>
    action((e: any, { size: { width } }) => {
      this.columnsWidthMap[key] = width
      setTimeout(() =>
        setAcquiringStorageData(this.acquiringType, 'ecom', 'statement', 'columns', this.columnsWidthMap)
      )
    })

  openExportModal(value: boolean) {
    this.isExportModalOpen = value
  }

  setFilter(filter: Partial<TransactionStatementFilterModel>, shouldLoad: boolean = true) {
    this.filter = {
      ...this.filter,
      ...filter
    }
    if (shouldLoad) {
      this.loadData(1)
    }
  }

  resetFilter() {
    this.filter = new TransactionStatementFilterModel()
    this.acquiringFilterStore.setPeriod(PeriodType.Day)
  }

  onFilterBlur = () => {
    const isValid = TransactionStatementFilterScheme.isValidSync({ ...this.filter })
    const isChanged = this.prevFilterJSON !== JSON.stringify(this.filter)
    if (isValid && isChanged) {
      this.loadData(1)
    }
  }

  loadData(pageNumber?: number) {
    this.tableStore.loadData({ pageNumber })
  }

  get transactionChannels() {
    return this.handbooksStore.getTransactionChannels(this.acquiringType)
  }

  checkColumnDate = createCheckColumnDate(this.dateColumn)

  get request() {
    const { currentPage, pageSize } = this.tableStore
    const {
      dateStore: { startDate, endDate },
      merchantTradeName
    } = this.acquiringFilterStore
    const {
      status,
      paymentMethod,
      transactionChannel,
      issuerCountry,
      ipCountry,
      payerIp,
      payerEmail,
      cardMask,
      terminal,
      reference,
      mid,
      merchantReference,
      threeDSVersion,
      payerAuthenticationResult,
      transactionId,
      processingType,
      cardScheme,
      signifydDecision,
      amountFrom,
      amountTo
    } = this.filter
    this.prevFilterJSON = JSON.stringify(this.filter)

    return {
      startDate: startDate.format(),
      endDate: endDate.format(),
      merchant: merchantTradeName,
      currency: this.currency,
      acquirer: this.isSolidGate ? 'paynetics_eu' : this.acquiringType,
      status: parseArrayFilter(status, STATUSES.length),
      paymentMethod: parseArrayFilter(paymentMethod, PAYMENT_METHODS.length),
      transactionChannel: parseArrayFilter(transactionChannel, TRANSACTION_CHANNELS.length),
      issuerCountry: parseArrayFilter(issuerCountry, COUNTRIES.length),
      ipCountry: parseArrayFilter(ipCountry, COUNTRIES.length),
      payerIp,
      payerEmail,
      cardMask,
      terminal,
      reference,
      mid,
      merchantReference,
      threeDSVersion: Number(threeDSVersion) || undefined,
      payerAuthenticationResult: parseArrayFilter(payerAuthenticationResult, PAYER_AUTHS.length),
      limit: pageSize,
      offset: (currentPage - 1) * pageSize,
      acquisitionChannel: this.acquisitionChannelId,
      transactionId,
      processingType: parseArrayFilter(processingType, PROCESSING_TYPES.length),
      cardScheme: parseArrayFilter(cardScheme, ecomCardSchemes.length),
      signifydDecision: parseSignifydDecisionFilter(signifydDecision),
      amountFrom: amountFrom ? parseFloat(amountFrom) : undefined,
      amountTo: amountTo ? parseFloat(amountTo) : undefined
    }
  }

  fetchData = async (params: TableFetchParams): Promise<TableData<GroupableItem<TransactionModel>, number>> => {
    const { error, result } = await fetchTransactions(this.request)

    clearObject(this.dateColumn)

    if (error || !result?.data) {
      this.totalAmount = 0
      return {
        data: [],
        total: 0
      }
    }

    this.totalAmount = result.totalAmount
    const data: GroupableItem<TransactionModel>[] = []
    result.data.forEach(item => {
      const date = moment(item.createdDate).format('DD.MM.YYYY')
      if (this.checkColumnDate(date)) {
        data.push({ groupTitle: date })
      }
      item.payoutDate = isDateNull(item.payoutDate) ? null : item.payoutDate
      data.push(item)
    })

    return {
      data,
      total: result.totalCount
    }
  }
}
