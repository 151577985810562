import {injectable} from 'inversify'
import {NotificationsClient} from 'dna-notification-client'
import {action, makeObservable, observable, runInAction} from 'mobx'
import {
  PLATFORM, SECTION_ROUTES
} from './constants'
import {INotificationStore} from './INotificationStore'
import {NotificationArgsType, NotificationStatusEnum} from './types'
import { syncWebSocketNotifications } from '~/code/stores/notification/services/syncWebSocketNotifications'

@injectable()
export class NotificationStore implements INotificationStore {
  notificationClient = null
  sections = {}
  isAppBlocked = false
  constructor() {
    this.notificationClient = new NotificationsClient(__IS_PROD__
        ? __NOTIFICATION_PROD_URL__
        : __NOTIFICATION_TEST_URL__,
      {systemId: 'dashboard'})
    this.notificationClient.subscribe(this)
    makeObservable(this, {
      sections: observable,
      isAppBlocked: observable,
      eventHandler: action.bound
    })
  }

  public eventHandler(args: NotificationArgsType[]) {
    const newSections = {}
    if (!args.length) {
      runInAction(() => {
        this.sections = {}
        this.isAppBlocked = false
        syncWebSocketNotifications([], 'unauth')
      })
      return
    }

    args.map((notification) => {
      const {systemId, notificationContentAreaId, descriptionLine1} = notification
      if (systemId === PLATFORM) {
        newSections[notificationContentAreaId] = {
          description: descriptionLine1,
          route: SECTION_ROUTES[notificationContentAreaId],
          ...notification
        }
      }
    })

    syncWebSocketNotifications(args as [], 'unauth')
    runInAction(() => this.sections = newSections)

    if (
      this.sections['app']
      && this.sections['app'].notificationDisplayTypeId === 'full_block'
      && this.sections['app'].statusId === NotificationStatusEnum.ACTIVE
    ) {
      runInAction(() => this.isAppBlocked = true)
    } else {
      runInAction(() => this.isAppBlocked = false)
    }
  }
}
