import { PortalAccessPermissionsType } from '~/code/pages/PortalAccess/types/PortalAccessPermissionsType'
import { PermissionType } from '~/code/pages/PortalAccess/types/PermissionType'
import { Role } from '~/code/pages/PortalAccess/types/Role'
import { PortalUserAccessType } from '~/code/pages/PortalAccess/types/PortalUserAccessType'
import { AddUpdateLoginFormModel } from '~/code/pages/PortalAccess/types/AddUpdateLoginFormModel'
import { PortalAccessSpecificPermissionsType } from '~/code/pages/PortalAccess/types/PortalAccessSpecificPermissionsType'

export const convertTableItemToFormModel = (item: any): AddUpdateLoginFormModel => {
  return {
    publicId: item.publicId,
    merchantId: item.merchantId,
    email: item.email,
    firstName: item.firstName,
    lastName: item.lastName,
    shops: item.shops,
    inviteAs: item.systemRoles?.includes('admin') ? PortalUserAccessType.admin : PortalUserAccessType.restricted,
    role: item.systemRoles?.includes(Role.finance) ? Role.finance : Role.custom,
    permissions: item.systemRoles?.includes(Role.finance)
      ? undefined
      : convertPermissionsArrayIntoObject(item.permissions),
    specificPermissions: convertSpecificPermissionsArrayIntoObject(item.permissions),
    mostSpecificPermissions: convertMostSpecificPermissions(item.permissions || []),
    twoFAStatus: item.twoFAStatus
  }
}

export const convertMostSpecificPermissions = (permissions: string[]) => {
  const mostSpecific = new Set(
    permissions.filter(permission => {
      if (permission.startsWith('merchant.')) {
        return false
      }

      const [key, value] = permission.split('.')
      return !['read', 'full'].includes(value)
    })
  )
  return Array.from(mostSpecific)
}

export const convertPermissionsArrayIntoObject = (permissions: string[]): PortalAccessPermissionsType => {
  return {
    overview: identifyPermissionLevel('overview', permissions),
    online_payments: identifyPermissionLevel('online_payments', permissions),
    pos_payments: identifyPermissionLevel('pos_payments', permissions),
    pos_amex_payments: identifyPermissionLevel('pos_amex_payments', permissions),
    payment_links: identifyPermissionLevel('payment_links', permissions),
    virtual_terminal: identifyPermissionLevel('virtual_terminal', permissions),
    settlements: identifyPermissionLevel('settlements', permissions),
    reports: identifyPermissionLevel('reports', permissions),
    payment_methods: identifyPermissionLevel('payment_methods', permissions),
    teammates: identifyPermissionLevel('teammates', permissions),
    invoices: identifyPermissionLevel('invoices', permissions),
    chargebacks: identifyPermissionLevel('chargebacks', permissions),
    'merchant.profile': identifyPermissionLevel('merchant.profile', permissions),
    'merchant.bank_accounts': identifyPermissionLevel('merchant.bank_accounts', permissions),
    'merchant.pos_stores': identifyPermissionLevel('merchant.pos_stores', permissions),
    'merchant.ecom_stores': identifyPermissionLevel('merchant.ecom_stores', permissions)
  }
}

export const convertSpecificPermissionsArrayIntoObject = (
  permissions: string[]
): PortalAccessSpecificPermissionsType => {
  return {
    card_issuing: identifyPermissionLevel('card_issuing', permissions)
  }
}

const identifyPermissionLevel = (sectionName: string, permissions: string[]): PermissionType => {
  if (!permissions || permissions.length === 0) {
    return 'no'
  }

  const filteredPermissions = permissions
    .filter(item => item.startsWith(sectionName + '.'))
    .map(item => item.replace(sectionName + '.', ''))

  if (filteredPermissions.includes('full')) {
    return 'full'
  } else if (filteredPermissions.includes('read')) {
    return 'read'
  }

  return 'no'
}

export const generatePermissionsArray = (permissions: PortalAccessPermissionsType) => {
  return Object.keys(permissions || []).reduce((accumulator, current) => {
    const result = []
    if (permissions[current] !== 'no') {
      result.push(`${current}.${permissions[current]}`)
    }
    return [...accumulator, ...result]
  }, [])
}
