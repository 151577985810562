import { message } from 'antd'
import { ColumnType } from 'antd/lib/table'
import moment from 'moment'
import { computed, makeObservable } from 'mobx'
import { ApiResponse } from 'back-connector'
import { injectable } from 'inversify'

import { GroupableItem, TableData, TableFetchParams } from '~/code/models'
import { clearObject } from '~/code/services/utils'
import { TableFilterStore } from '~/code/stores/TableFilterStore'
import { fetchSettlementByBankingDate } from '~/code/pages/settlements/SettlementsStatement/services'
import { ISettlementsTableStore } from '~/code/pages/settlements/SettlementsStatement/components/SettlementsTable/ISettlementsTableStore'
import {
  SettlementModel,
  SettlementModelByBankingDate,
  SettlementParams,
  SettlementResponseModel
} from '~/code/pages/settlements/SettlementsStatement/components/SettlementsTable/models'
import { hasPermissions, isPartner } from '~/code/services/auth'
import { PermissionMap } from '~/code/constants/PermissionMap'
import { BaseSettlementsFiltersStore } from './BaseSettlementsFiltersStore'
import translations from './translations'

// @ts-ignore
@injectable()
export abstract class BaseSettlementsTableStore implements ISettlementsTableStore {
  tableStore: TableFilterStore<GroupableItem<SettlementModel>>
  tableStorePerBankingDate: TableFilterStore<GroupableItem<SettlementModelByBankingDate>>
  dateColumn = {}

  abstract columns: ColumnType<any>[]

  constructor(public settlementsFilterStore: BaseSettlementsFiltersStore) {
    this.tableStore = new TableFilterStore(this.fetchData)
    this.tableStorePerBankingDate = new TableFilterStore(this.fetchDataByBankingDate)

    makeObservable(this, {
      emptyText: computed
    })
  }

  get emptyText(): string {
    if (
      !isPartner() &&
      this.settlementsFilterStore.acquisitionChannelId === 'all' &&
      !this.settlementsFilterStore.merchantId
    ) {
      return translations().selectMerchantOrChannelFirst
    } else if (isPartner() && !this.settlementsFilterStore.merchantId) {
      return translations().selectMerchantFirst
    } else if (!this.settlementsFilterStore.startDate || !this.settlementsFilterStore.endDate) {
      return translations().selectDatesFirst
    }

    return translations().noData
  }

  protected abstract fetchSettlement(
    data: SettlementParams
  ): Promise<ApiResponse<SettlementResponseModel<SettlementModel>>>

  fetchData = async (params: TableFetchParams): Promise<TableData<GroupableItem<SettlementModel>, number>> => {
    const {
      dateStore: { startDate, endDate },
      merchantId,
      acquisitionChannelId,
      storeAndTerminalFilterStore: { requestForServer },
      accountNumber
    } = this.settlementsFilterStore

    if (!acquisitionChannelId && !merchantId) {
      return {
        data: [],
        total: 0
      }
    }

    const { error, result } = await this.fetchSettlement({
      merchantId: merchantId || undefined,
      acquisitionChannel: acquisitionChannelId === 'all' ? undefined : acquisitionChannelId,
      from: startDate.format('YYYY-MM-DD'),
      to: endDate.format('YYYY-MM-DD'),
      page: params.currentPage,
      size: params.pageSize,
      accountNumber: accountNumber ? accountNumber : undefined,
      ...requestForServer
    })

    clearObject(this.dateColumn)

    if (error || !result?.data) {
      message.error(error.message)

      return {
        data: [],
        total: 0
      }
    }

    const data: GroupableItem<SettlementModel>[] = []
    result.data.forEach(item => {
      data.push({
        ...item,
        bankingDate: item.bankingDate ? moment(item.bankingDate).format('DD.MM.YYYY') : null,
        settlementDate: item.settlementDate ? moment(item.settlementDate).format('DD.MM.YYYY') : null,
        transactionDate: item.transactionDate ? moment(item.transactionDate).format('DD.MM.YYYY HH:mm') : null
      })
    })

    return {
      data,
      total: result.totalCount
    }
  }

  fetchDataByBankingDate = async (
    params: TableFetchParams
  ): Promise<TableData<GroupableItem<SettlementModelByBankingDate>, number>> => {
    if (!hasPermissions([PermissionMap.settlements.per_banking_date.read])) {
      return {
        data: [],
        total: 0
      }
    }

    const {
      dateStore: { startDate, endDate },
      merchantId,
      acquisitionChannelId,
      storeAndTerminalFilterStore: { requestForServer },
      accountNumber
    } = this.settlementsFilterStore

    if (!acquisitionChannelId && !merchantId) {
      return {
        data: [],
        total: 0
      }
    }

    const { error, result } = await fetchSettlementByBankingDate({
      merchantId: merchantId || undefined,
      acquisitionChannel: acquisitionChannelId === 'all' ? undefined : acquisitionChannelId,
      from: startDate.format('YYYY-MM-DD'),
      to: endDate.format('YYYY-MM-DD'),
      page: params.currentPage,
      size: params.pageSize,
      accountNumber: accountNumber ? accountNumber : undefined,
      ...requestForServer
    })

    clearObject(this.dateColumn)

    if (error || !result?.data) {
      message.error(error.message)

      return {
        data: [],
        total: 0
      }
    }

    const data: GroupableItem<SettlementModelByBankingDate>[] = []
    result.data.forEach(item => {
      data.push({
        ...item,
        processedDate: item.processedDate ? moment(item.processedDate).format('DD.MM.YYYY') : null,
        settlementDate: item.settlementDate ? moment(item.settlementDate).format('DD.MM.YYYY') : null
      })
    })

    return {
      data,
      total: result.totalCount
    }
  }
}
