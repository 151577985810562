import translations from './translations'

translations.add('en', {
    avgDurationTime: 'Avg. duration',
    avgReviewTime: 'Avg. review',
    target: 'Target KPI',
    performance: 'Performance',
    min: 'min',    
    applications: 'applications',
    hour: 'hour',
    hours: 'hours',
    hourAbbr: 'hr',
    hoursAbbr: 'hrs',
    uwCapacity: 'Underwriting capacity, avr daily',
    uwReviews: 'Underwriting reviews per day',
    appsPerChannel: 'Applications per channel',
    numOfAppsPerChannel: 'Number of applications per channel',
    uniqueAppsFunnel: 'Unique applications funnel',
    currentApps: 'Current application status'
})
