import { message } from 'antd'
import { inject, injectable } from 'inversify'
import { makeObservable, action, observable, computed, runInAction } from 'mobx'
import { AppStore } from '~/code/stores/AppStore'
import { MerchantsManagementStoreSymbol } from '~/code/pages'
import { MerchantsManagementStore } from './MerchantsManagementStore'
import { IStoresDossierV2Store } from '~/code/pages/MerchantsManagement/components/Stores/models'
import { StoresDossierV2StoreSymbol } from '~/code/pages/MerchantsManagement/components/Stores'
import { IChangeSettlementTypeStore } from '~/code/pages/MerchantsManagement/components/ChangeSettlementStatus/IChangeSettlementTypeStore'
import { startProcessChangeSettlementDossierV2 } from './services/fetchers'
import {
  stringType,
  grossSettlement,
  directDebit,
  defaultChargeMethod,
  defaultChangeMethodsOptions,
  dossierDefaultVersion
} from './constants'
import translations from './translations'

@injectable()
export class ChangeSettlementTypeStore implements IChangeSettlementTypeStore {
  storesDossierV2Store: IStoresDossierV2Store
  merchantsManagementStore: MerchantsManagementStore
  isChangeSettlementLoading: boolean = false
  isChangeOfGrossSettlement: boolean = false
  isChangeOfDirectDebit: boolean = false
  selectedChargeMethod: string = defaultChargeMethod
  gross: string = grossSettlement
  dd: string = directDebit
  mandate: string = ''
  changeMethodsOptions: { value: string; label: string }[] = defaultChangeMethodsOptions
  constructor(
    @inject(StoresDossierV2StoreSymbol) storesDossierV2Store: IStoresDossierV2Store,
    @inject(MerchantsManagementStoreSymbol) merchantsManagementStore: MerchantsManagementStore
  ) {
    this.storesDossierV2Store = storesDossierV2Store
    this.merchantsManagementStore = merchantsManagementStore
    makeObservable(this, {
      isChangeSettlementLoading: observable,
      isChangeOfDirectDebit: observable,
      isChangeOfGrossSettlement: observable,
      selectedChargeMethod: observable,
      mandate: observable,
      settlementType: computed,

      changeSettlement: action,
      handleSubmitSettlement: action,
      setIsChangeOfGrossSettlement: action,
      setIsChangeOfDirectDebit: action,
      handleChargeMethod: action,
      reset: action
    })
  }

  reset() {
    this.isChangeSettlementLoading = false
    this.isChangeOfGrossSettlement = false
    this.isChangeOfDirectDebit = false
    this.selectedChargeMethod = defaultChargeMethod
    this.mandate = ''
  }

  get settlementType(): { code: string | null; label: string | null } {
    const activeStores = (this.storesDossierV2Store?.companyFullDossierV2?.processing?.stores || []).filter(
      store => store?.storeInfo?.isActive
    )

    const activeContract = activeStores
      .flatMap(store => store?.contracts || [])
      .find(contract => contract?.contract?.isActive)

    const { settlementType, settlementTypeDisplayValue } = activeContract?.contract || {}
    return { code: settlementType || null, label: settlementTypeDisplayValue || null }
  }

  setIsChangeOfGrossSettlement() {
    this.isChangeOfGrossSettlement = !this.isChangeOfGrossSettlement
    this.isChangeOfDirectDebit = false
    this.mandate = ''
    this.selectedChargeMethod = 'MID'
  }

  setIsChangeOfDirectDebit() {
    this.isChangeOfDirectDebit = !this.isChangeOfDirectDebit
    this.isChangeOfGrossSettlement = false
  }

  handleChargeMethod = (method: string) => {
    this.selectedChargeMethod = method
  }

  handleMandate = (mandate: string) => {
    this.mandate = mandate
  }

  handleSubmitSettlement = () => this.changeSettlement()

  async changeSettlement() {
    try {
      runInAction(() => {
        this.isChangeSettlementLoading = true
      })
      const { status, error, result } = await startProcessChangeSettlementDossierV2({
        variables: {
          dossierVersion: {
            value: dossierDefaultVersion,
            type: stringType
          },
          dossierId: {
            value: this.storesDossierV2Store?.companyFullDossierV2?.mainInfo?.dsrId?.toString(),
            type: stringType
          },
          companyName: {
            value: this.storesDossierV2Store?.companyFullDossierV2?.mainInfo?.companyName,
            type: stringType
          },
          companyNumber: {
            value: this.storesDossierV2Store?.companyFullDossierV2?.mainInfo?.companyNumber,
            type: stringType
          },
          initiatorEmail: {
            value: AppStore.authStore.email,
            type: stringType
          },
          settlementType: {
            value: this.isChangeOfGrossSettlement ? this.gross : this.dd,
            type: stringType
          },
          chargeMerchant: {
            value: this.isChangeOfDirectDebit ? this.selectedChargeMethod : null,
            type: stringType
          },
          ddMandateNumber: {
            value: this.mandate,
            type: stringType
          }
        }
      })

      if (status !== 200 || error) {
        message.error(error.message)
      } else {
        message.success(result.message || translations().applicationSuccessful)
      }
    } catch (error) {
      message.error(error.message)
    } finally {
      runInAction(() => {
        this.reset()
      })
    }
  }
}
