import React, { useEffect, useState } from 'react'
import { Card, Col, Divider, Row, Skeleton } from 'antd'
import moment from 'moment'
import numeral from 'numeral'
import DatePicker from '~/code/components/DatePicker'
import { BarChart } from '~/code/components'
import { YearsCompareProps } from './props'
import translations from './translations'
import styles from './YearsCompare.scss'

export const YearsCompare: React.FC<YearsCompareProps> = props => {
  const { title, data, loading, currencySymbol, subtitle, year1, year2, onYearsChange } = props
  const [isLoading, setLoading] = useState(loading)
  const [firstYear, setFirstYear] = useState(year1)
  const [secondYear, setSecondYear] = useState(year2)

  const onFirstYearSelect = (value: moment.Moment) => {
    if (!value) return
    let secondYearValue = secondYear
    if (value.get('year') >= secondYear.get('year')) {
      secondYearValue = value.clone().add(1, 'years')
    }
    setFirstYear(value)
    setSecondYear(secondYearValue)

    onYearsChange && onYearsChange(value, secondYearValue)
  }

  const onSecondYearSelect = (value: moment.Moment) => {
    if (!value) return
    setSecondYear(value)
    onYearsChange && onYearsChange(firstYear, value)
  }

  useEffect(() => {
    setLoading(loading)
  }, [data, loading])

  if (isLoading) {
    return <Skeleton active />
  }
  return (
    <>
      <Row>
        <Col>{title}</Col>
      </Row>
      <Row>
        <Col>
          <div className={styles.subTitle}>{subtitle}</div>
        </Col>
        <Col xs={24} sm={12} lg={8}>
          <div className={styles.yearsPickerWrapper}>
            <DatePicker
              picker={'year'}
              placement={'bottomRight'}
              disabledDate={(date: moment.Moment) => date.get('year') < 2019}
              value={firstYear}
              onChange={onFirstYearSelect}
            />
            <span className={styles.vs}>{translations().vs}</span>
            <DatePicker
              picker={'year'}
              placement={'bottomRight'}
              disabledDate={(date: moment.Moment) => date.get('year') <= firstYear.get('year')}
              value={secondYear}
              onChange={onSecondYearSelect}
            />
          </div>
        </Col>
      </Row>
      <Divider />
      <Card bordered={false} className={styles.styledCard}>
        <div>
          <BarChart
            data={data}
            yAxisNumberFormat={'0.00a'}
            renderTooltipLabel={(label, value) => {
              const currency = currencySymbol || '£'
              const format = '0,0.00'
              return label + ': ' + currency + numeral(value).format(format)
            }}
          />
        </div>
      </Card>
    </>
  )
}
