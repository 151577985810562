import { message } from 'antd'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { AcquisitionChannelType } from '~/code/models'
import {
  parseCompanyPCSListV2,
  parseCompanyProfileV2,
  parseContactInfoDataV2,
  parseOfficerListV2
} from '../../SavedApplications/components/SavedApplicationsTable/services'
import { fetchApplicationByCompanyID, fetchApplicationByIdV2 } from '../../SavedApplications/services'
import { IContactInfoParentStore } from '../components/ContactInfo/models/IContactInfoParentStore'
import {
  CompanyProfile,
  ContactInfoType,
  OfficerProfile,
  OnboardingRequest,
  PCSProfile,
  ProductItem,
  StoreItem
} from '../models'
import { ApplicationStatusType } from '../models/onboarding/FetchApplicationStatusRes'
import {
  checkEmailAddress,
  checkSalesforceOpportunityID,
  fetchApplicationStatus,
  fetchMerchatAttributes,
  isDateWithinSixMonths,
  loadCompanyAdditionalInfoFromCreditSafe,
  loadCompanyInformation,
  loadCompanyMainInfoFromCreditSafe,
  parseCompanyOfficersAndPcs,
  parseCompanyOfficersAndPcsFromCreditSafe,
  startApplication
} from '../services'
import translations from '../translations'
import { isEmpty, log } from 'dna-common'
import { goToRoute } from '~/code/startup/Router/utils'
import { Routes } from '~/code/startup/Router/Routes'
import { apiCheck, noThrow } from 'back-connector'
import moment from 'moment-timezone'
import { StartTypesType } from '../models/contactInfo/StartTypesType'
import { merchantAttributesMap, merchantCharge, PROCESS_VERSIONS, PRODUCT_VERSIONS } from '../services/constants'

export class ContactInfoStore {
  isLoading: boolean = false
  applicationStatus: ApplicationStatusType = null

  isCheckSalesforceOpportunityIDLoading: boolean = false
  isLoadApplicationByIdLoading: boolean = false
  isStartTypeChoosen: boolean = false

  constructor(private parentStore: IContactInfoParentStore) {
    makeObservable(this, {
      isLoading: observable,
      applicationStatus: observable,
      isCheckSalesforceOpportunityIDLoading: observable,
      isLoadApplicationByIdLoading: observable,
      isStartTypeChoosen: observable,

      dataStore: computed,
      contactInfoData: computed,
      isAcquisitionChannelsLoading: computed,
      acquisitionChannels: computed,
      isMoreFormFieldsVisible: computed,
      isApplicationWaitingForSubmission: computed,
      reset: action.bound,
      setContactInfoData: action.bound,
      onEmailChange: action.bound,
      loadApplicationById: action.bound,
      checkSalesforceOpportunityID: action.bound,
      loadCompanyOfficersAndPcs: action.bound,
      checkForExistingEmail: action.bound,
      startAdditionalStore: action.bound,
      setIsStartTypeChoosen: action.bound,
      handlePreStart: action.bound
    })
  }

  setIsStartTypeChoosen(val: boolean) {
    this.isStartTypeChoosen = val
  }

  reset() {
    this.applicationStatus = null
  }

  get dataStore() {
    return this.parentStore.dataStore
  }

  get contactInfoData(): ContactInfoType {
    return this.dataStore.contactInfoData
  }

  get isAcquisitionChannelsLoading(): boolean {
    return this.parentStore.isAcquisitionChannelsLoading
  }

  get acquisitionChannels(): AcquisitionChannelType[] {
    return this.parentStore.acquisitionChannels
  }

  get isMoreFormFieldsVisible(): boolean {
    return this.applicationStatus === 'pde'
  }

  get isApplicationWaitingForSubmission(): boolean {
    return this.applicationStatus === 'wau'
  }

  onEmailChange(email: string) {
    if (!this.contactInfoData) return

    if (email !== this.contactInfoData.email) {
      this.applicationStatus = null
    }
  }

  async setContactInfoData(values: ContactInfoType): Promise<void> {
    this.dataStore.savePhoneNumberAndEmail(values.phoneNumber, values.email)
    this.dataStore.setContactInfoData(values)
    this.isLoading = true
    this.startOnboardingApplication()
  }

  async getApplicationStatus(email: string) {
    try {
      const { error, status, result } = await fetchApplicationStatus(email)
      this.isLoading = false
      if (error || status !== 200) {
        message.error(error.message || translations().errorStartingApplication)
        return
      }

      this.dataStore.setApplicationId(result.id)
      this.applicationStatus = result.st
      if (this.applicationStatus !== 'pde' && this.applicationStatus !== 'wau') {
        message.error(translations().applicationAlreadySubmitted)
      }
    } catch (error) {
      this.isLoading = false
      message.error(error.message || translations().errorStartingApplication)
    }
  }

  async startOnboardingApplication() {
    try {
      const {
        acquisitionChannel,
        firstName,
        surname,
        phoneNumber,
        salesforceOpportunityID,
        email,
        segmentCategory,
        segmentSubCategory,
        onboardingCountry
      } = this.dataStore.contactInfoData

      const companyProfileInfo = this.dataStore.application?.companyProfile
      const selectedCountry = this.parentStore.obrCountries?.find(c => c.value === onboardingCountry)
      companyProfileInfo.onboardingCountryInfo = {
        digitalCode: selectedCountry.digitalCode,
        ioc: selectedCountry.ioc,
        iso: selectedCountry.iso,
        value: selectedCountry.value,
        institution: this.parentStore.onboardingSettings?.institution
      }

      const { error, result, status } = await startApplication({
        applicantInfo: {
          acquisitionChannel,
          firstName,
          surname,
          telephoneNumber: phoneNumber,
          middleName: '',
          opportunityId: salesforceOpportunityID,
          emailAddress: companyProfileInfo.emailAddress,
          segmentCategory,
          segmentSubCategory
        },
        companyProfile: companyProfileInfo,
        version: PROCESS_VERSIONS.ONBOARDING,
        sellerEmail: email,
        productVersion: PRODUCT_VERSIONS.REDESIGNED,
        refereeUberId: this.parentStore.oppOwnerInfo?.refereeUberId
      })
      this.isLoading = false

      if (error || status !== 200) {
        message.error(error.message || translations().errorStartingApplication)
        return
      }

      this.dataStore.setVersion(PROCESS_VERSIONS.ONBOARDING)
      this.dataStore.setProductVersion(PRODUCT_VERSIONS.REDESIGNED)
      this.dataStore.setApplicationId(result.id)
      this.parentStore.setActiveTabKey('individualDetails')
      window.scrollTo({ top: 0, behavior: 'smooth' })
    } catch (error) {
      this.isLoading = false
      message.error(error.message || translations().errorStartingApplication)
    }
  }

  async loadApplicationById(id?: string) {
    try {
      this.isLoadApplicationByIdLoading = true
      const { error, status, result } = await fetchApplicationByIdV2(this.dataStore.applicationId)

      if (error || status !== 200) {
        this.isLoadApplicationByIdLoading = false
        message.error(error.message || translations().errorLoadingApplication)
        return
      }

      if (!result.version) {
        goToRoute(Routes.APPLICATIONS_START_APPLICATION, { id })
        return
      }

      this.parentStore.reset()

      this.dataStore.setVersion(result.version)

      if (!isEmpty(result.productVersion)) {
        this.dataStore.setProductVersion(result.productVersion)
      }

      this.dataStore.savePhoneNumberAndEmail(
        result?.applicantInfo?.telephoneNumber,
        result?.applicantInfo?.emailAddress
      )

      const contactInfoData: ContactInfoType = parseContactInfoDataV2(result)
      if (
        result.version === PROCESS_VERSIONS.ADDITIONAL_STORE_ISSUE &&
        !this.acquisitionChannels.find(ch => ch.code === contactInfoData.acquisitionChannel)
      ) {
        contactInfoData.acquisitionChannel === 'not_applicable'
      }
      this.dataStore.setContactInfoData(contactInfoData)

      if (result.acquisitionChannel) {
        await this.parentStore.onPartnerSelect(result.acquisitionChannel)
      }
      if (!isEmpty(result.bankAccounts)) {
        this.dataStore.setBankAccounts(result.bankAccounts)
      }

      if (result.docusignProcessStatus) {
        this.dataStore.setDocuSignProcessStatus(result.docusignProcessStatus?.toUpperCase())
      }

      if (result.docusignEnvelopeId) {
        this.dataStore.setEnvelopeId(result.docusignEnvelopeId)
      }

      if (result.sentToDocusign != undefined) {
        this.dataStore.setSentToDocusign(result.sentToDocusign)
      }

      if (result.isManualMSA != undefined) {
        this.dataStore.setIsManualMSA(result.isManualMSA)
      }

      if (result.productTariffs) {
        this.dataStore.setTariffs(result.productTariffs)
      }

      if (result.isFastTrack) {
        this.dataStore.setFastTrack(result.isFastTrack)
      }

      if (result.isMsaSigned) {
        this.dataStore.setMsaSigned(result.isMsaSigned)
      }

      if (result.stores?.length > 0) {
        result.stores.forEach((store: StoreItem) => {
          store.removable = true
          store.products.forEach((prod: ProductItem) => (prod.removable = true))
        })
        this.dataStore.setStores(result.stores)
      }

      const companyProfile: CompanyProfile = parseCompanyProfileV2(result)
      this.dataStore.updateCompanyProfile(companyProfile)

      const officerList: OfficerProfile[] = parseOfficerListV2(result)
      this.dataStore.setOfficerList(officerList)

      const companyPCSList: PCSProfile[] = parseCompanyPCSListV2(result)
      this.dataStore.setCompanyPCSList(companyPCSList)

      //logic for additional stores process
      if (result.version === PROCESS_VERSIONS.ADDITIONAL_STORE_ISSUE && result?.companyProfile?.companyNumber) {
        const {
          error: error2,
          status: status2,
          result: result2
        } = await fetchApplicationByCompanyID(result.companyProfile.companyNumber)

        if (error2 || status2 !== 200) {
          this.isLoadApplicationByIdLoading = false
          message.error(error.message || translations().errorLoadingApplication)
          return
        }

        if (!isEmpty(result2)) {
          if (result2.stores?.length > 0) {
            if (isEmpty(this.dataStore.stores)) {
              const st: StoreItem[] = []
              result2.stores?.forEach((store: StoreItem) => {
                store.removable = false
                if (
                  !isEmpty(result2.companyProfile.dateBoarded) &&
                  isDateWithinSixMonths(new Date(moment(result2.companyProfile.dateBoarded, 'YYYY-MM-DD').format()))
                ) {
                  store.bankInfo.fromApi = true
                  store.directDebitBankInfo.fromApi = true
                }

                store?.products?.forEach((prod: ProductItem) => (prod.removable = false))
                st.push(store)
              })
              this.dataStore.setStores(st)
            } else {
              //compare for existing stores
              this.dataStore?.stores?.forEach((store: StoreItem) => {
                const existingStore = result2.stores?.find(
                  (st: StoreItem) => st.systemDescriptor?.toUpperCase() === store.systemDescriptor?.toUpperCase()
                )
                if (existingStore) {
                  store.removable = false
                } else {
                  store.removable = true
                }

                store.products?.forEach((product: ProductItem) => {
                  if (existingStore?.products?.find((prd: ProductItem) => prd.name === product.name)) {
                    product.removable = false
                  } else {
                    product.removable = true
                  }
                })
              })
            }
          }

          if (officerList.length === 0) {
            const officerList2: OfficerProfile[] = parseOfficerListV2(result2)
            !isEmpty(officerList2) && this.dataStore.setOfficerList(officerList2)
          }

          if (companyPCSList.length === 0) {
            const companyPCSList2: PCSProfile[] = parseCompanyPCSListV2(result2)
            !isEmpty(companyPCSList2) && this.dataStore.setCompanyPCSList(companyPCSList2)
          }

          if (isEmpty(result.productTariffs)) {
            !isEmpty(result2.productTariffs) && this.dataStore.setTariffs(result2.productTariffs)
          }
        }
      }

      if (
        (officerList.length === 0 || companyPCSList.length === 0) &&
        (companyProfile.companyType === 'company' || companyProfile.companyType === 'llp') &&
        this.parentStore.onboardingSettings?.checkCompanyNumberService === 'companiesHouse'
      ) {
        this.loadCompanyOfficersAndShareholdersFromCS(
          companyProfile.companyNumber,
          officerList.length,
          companyPCSList.length
        )
      }

      this.isLoadApplicationByIdLoading = false
      this.continueWithApplication(result)
    } catch (error) {
      this.isLoadApplicationByIdLoading = false
      message.error(translations().errorLoadingApplication)
    }
  }

  async loadCompanyOfficersAndPcs(companyNumber: string) {
    const result = await noThrow(apiCheck(loadCompanyInformation(companyNumber)))
    if (result.error) {
      message.error(result.error.message)
      return {}
    } else if (!result.value) {
      message.error(translations().errorLoadingApplication)
      return {}
    }
    // from Company House tradingAddress & tradingCountriesOutsideEea will not come
    const companyInformation = parseCompanyOfficersAndPcs(result.value)
    return companyInformation
  }

  async loadCompanyOfficersAndShareholdersFromCS(
    companyNumber: string,
    officersLength: number,
    shareholdersLength: number
  ) {
    const result = await noThrow(apiCheck(loadCompanyMainInfoFromCreditSafe(companyNumber)))
    if (result.error) {
      message.error(result.error.message)
      return false
    } else if (!result.value) {
      message.error(translations().errorLoadingApplication)
      return false
    }

    const activeCompanyId = result.value?.companies?.filter(i => i.status === 'Active')?.[0]?.id

    if (activeCompanyId) {
      const additionalInfoResult = await noThrow(
        apiCheck(loadCompanyAdditionalInfoFromCreditSafe(activeCompanyId.trim()))
      )
      if (additionalInfoResult.error) {
        message.error(additionalInfoResult.error.message)
        return false
      } else if (!additionalInfoResult.value) {
        message.error(translations().errorLoadingApplication)
        return false
      }
      const officersAndPcs = parseCompanyOfficersAndPcsFromCreditSafe(
        additionalInfoResult.value?.report?.directors?.currentDirectors,
        additionalInfoResult.value?.report?.shareCapitalStructure?.shareHolders
      )

      officersLength === 0 && this.dataStore.setOfficerList(officersAndPcs.companyOfficerList)
      shareholdersLength === 0 && this.dataStore.setCompanyPCSList(officersAndPcs.companyPCSList)
    }

    return true
  }

  continueWithApplication(result: OnboardingRequest) {
    this.parentStore.setActiveTabKey('individualDetails')
    if (result.version === PROCESS_VERSIONS.ADDITIONAL_STORE_ISSUE) {
      this.parentStore.setActiveTabKey('bankInfo')
    }
    if (result.sentToDocusign) {
      this.parentStore.setActiveTabKey('contract')
    }
  }

  async checkSalesforceOpportunityID(id: string): Promise<string> {
    runInAction(() => {
      this.isCheckSalesforceOpportunityIDLoading = true
    })
    let errorMessage = ''
    try {
      const { status, error } = await checkSalesforceOpportunityID(id)
      if (status !== 200 || error) {
        errorMessage = error?.message || translations().errorCheckingSFOpportunityID
      }
    } catch (error) {
      errorMessage = error?.message || translations().errorCheckingSFOpportunityID
    }
    runInAction(() => {
      this.isCheckSalesforceOpportunityIDLoading = false
    })
    return errorMessage
  }

  async checkForExistingEmail(email: string): Promise<boolean> {
    runInAction(() => {
      this.isLoading = true
    })
    let exist = false
    try {
      const { status, error, result } = await checkEmailAddress(email)
      if (status !== 200 || error) {
        message.error(translations().errorEmailCheck)
      }
      exist = Boolean(result?.find(r => r?.mainInfo?.isActive === true))
    } catch (error) {
      message.error(translations().errorEmailCheck)
    }

    runInAction(() => {
      this.isLoading = false
    })

    return exist
  }

  async startAdditionalStore(id: string, uberID: string) {
    this.isLoadApplicationByIdLoading = true
    const { error, status, result } = await fetchApplicationByCompanyID(id)

    if (error || status !== 200) {
      this.isLoadApplicationByIdLoading = false
      message.error(error.message || translations().errorLoadingApplication)
      return
    }

    if (error || status !== 200) {
      this.isLoadApplicationByIdLoading = false
      message.error(error.message || translations().errorLoadingApplication)
      return
    }

    const comProfile: CompanyProfile = {
      ...result.companyProfile,
      dateOfCreation: result.companyProfile?.dateOfCreation
        ? moment(result.companyProfile?.dateOfCreation, 'YYYY-MM-DD').format('YYYY / MM / DD')
        : '',
      initialBoardingDate: result.companyProfile?.dateBoarded
    }

    const { result: result2 } = await fetchMerchatAttributes(uberID)

    const getPaymentTypeInfo = (code: string, isMID: boolean) => {
      switch (code) {
        case 'Gross':
          return { code: 'monthlyNetSettlements', name: 'Monthly Net' }
        case 'DN':
          return { code: 'dailyNetSettlements', name: 'Daily Net' }
        case 'DD':
          return isMID
            ? { code: 'grossSettlementsByMid', name: 'Gross Settlement per MID' }
            : { code: 'grossSettlementsByUberId', name: 'Gross Settlement per UBERID' }
        default:
          return { code: '', name: '' }
      }
    }

    if (!isEmpty(result2)) {
      const paymentTypeInfo = getPaymentTypeInfo(
        result2.find(r => r.paramId === merchantAttributesMap.setlementType)?.paramValue,
        Boolean(result2.find(r => r.paramId === merchantAttributesMap.settlementPaymentCode && r.paramValue === 'MID'))
      )

      const posPricing = result.productTariffs?.find(p => p.type === 'pos')?.tariffs
      const ecomPricing = result.productTariffs?.find(p => p.type === 'ecom')?.tariffs

      comProfile.settlementInfo = {
        isCompleted: false,
        settlementImmediate: true,
        settlementPayment: result2.find(r => r.paramId === merchantAttributesMap.settlementPayment)?.paramValue,
        settlementType: result2.find(r => r.paramId === merchantAttributesMap.setlementType)?.paramValue,
        settlementName: paymentTypeInfo.name,
        [paymentTypeInfo.code]: true,
        chargeMerchant: merchantCharge(paymentTypeInfo.code),
        [result2.find(r => r.paramId === merchantAttributesMap.settlementPayment)?.paramValue === 'mid'
          ? 'perOutletMid'
          : 'perCompanyUberId']: true,
        pos: posPricing && {
          fasterFundingMonthly: posPricing?.fasterFundingMonthly?.toString() || '',
          fasterFundingFee: posPricing?.fasterFundingFee?.toString() || '',
          settlementFee: posPricing?.settlementFee?.toString() || '',
          settlementPeriod: posPricing?.settlementPeriod?.toString() || '',
          nextDayFundingRequested: posPricing?.settlementPeriod === 1 ? true : false
        },
        ecom: ecomPricing && {
          fasterFundingMonthly: ecomPricing?.fasterFundingMonthly?.toString() || '',
          fasterFundingFee: ecomPricing?.fasterFundingFee?.toString() || '',
          settlementFee: ecomPricing?.settlementFee?.toString() || '',
          settlementPeriod: ecomPricing?.settlementPeriod?.toString() || '',
          nextDayFundingRequested: ecomPricing?.settlementPeriod === 1 ? true : false
        }
      }
    }

    const {
      error: errorStart,
      result: resultStart,
      status: statusStart
    } = await startApplication({
      applicantInfo: result.applicantInfo,
      companyProfile: comProfile,
      version: PROCESS_VERSIONS.ADDITIONAL_STORE_ISSUE,
      sellerEmail: result.sellerEmail,
      productVersion: PRODUCT_VERSIONS.REDESIGNED,
      uberId: uberID && Number(uberID)
    })
    this.isLoading = false

    if (errorStart || statusStart !== 200) {
      message.error(errorStart.message || translations().errorStartingApplication)
      return
    }

    goToRoute(Routes.APPLICATIONS_START_PROCESS, { id: resultStart.id })
  }

  async handlePreStart(value: string) {
    if (!value) {
      message.error(translations().chooseValue)
      return
    }
    if (value === 'existing') {
      goToRoute(Routes.MERCHANTS)
      this.dataStore.setStartType(null)
    } else {
      this.setIsStartTypeChoosen(true)
      this.dataStore.setStartType(value as StartTypesType)
    }
  }
}
