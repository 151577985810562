import { injectable } from 'inversify'
import { v4 as uuid } from 'uuid'
import { message, notification } from 'antd'
import { action, autorun, makeObservable, observable, runInAction, computed } from 'mobx'
import {
  fetchLogins,
  fetchMerchants,
  fetchMerchantsByLogin,
  createLogin,
  fetchStoresByMerchant,
  updateLogin,
  deleteLogin,
  updateTwoFASettings
} from '~/code/stores/PortalAccessStore/services/fetchers'
import { getUserInfoByUsername, updateUserInfo } from '~/code/stores/Profile/services/fetchers'
import { fetchRolesWithPermissions, fetchSpecificPermissions } from '~/code/stores/TeammatesStore/services'
import { MerchantModel } from '~/code/models/handbooks'
import { UserModel, EnforceTwoFAStatusParamsType } from '~/code/stores/Profile/models'
import { TableFilterStore } from '../TableFilterStore'
import { TableFetchParams } from '~/code/models'
import { PortalAccessStoreType } from '~/code/pages/PortalAccess/types/PortalAccessStoreType'
import { PortalAccessTabKeyType } from '~/code/pages/PortalAccess/types/PortalAccessTabType'
import {
  LoginType,
  StoreType,
  CreateMerchantLoginParamsType,
  UpdateLoginParamsType
} from '~/code/stores/PortalAccessStore/types'
import { Role } from '~/code/pages/PortalAccess/types/Role'
import { PortalUserAccessType } from '~/code/pages/PortalAccess/types/PortalUserAccessType'
import { AddUpdateLoginFormModel } from '~/code/pages/PortalAccess/types/AddUpdateLoginFormModel'
import { PortalAccessPermissionsType } from '~/code/pages/PortalAccess/types/PortalAccessPermissionsType'

import {
  generatePermissionsArray,
  convertPermissionsArrayIntoObject
} from '~/code/stores/PortalAccessStore/services/processors'

import translations from './translations'
import { SpecificPermissionsEnum } from '~/code/pages/PortalAccess/types/SpecificPermissionsEnum'

@injectable()
export class PortalAccessStore implements PortalAccessStoreType {
  activeTab: PortalAccessTabKeyType = PortalAccessTabKeyType.merchants
  searchMerchantValue: string = ''
  searchLoginValue: string = ''
  isLoginsSending: boolean = false
  isMerchantsLoading: boolean = false
  merchants: MerchantModel[] = []
  stores: StoreType[] = []
  selectedMerchant: MerchantModel = null
  selectedTableItem: AddUpdateLoginFormModel = null
  loginsTableStore: TableFilterStore<LoginType>
  merchantsTableStore: TableFilterStore<MerchantModel>
  permissions: Record<string, PortalAccessPermissionsType> = null
  specificPermissionsTableData: Record<string, string[]> = null
  mostSpecificPermissionsTableData: { key: string; title: string }[] = []
  selectedMostSpecificPermissions: string[] = []
  permissionsTableData: Record<string, string[]> = null
  roles: Record<string, string> = {}
  isUserAccessDrawerOpen: boolean = false
  isUserEditDrawerOpen: boolean = false
  isSecurityDrawerOpen: boolean = false

  constructor() {
    makeObservable(this, {
      stores: observable,
      activeTab: observable,
      merchants: observable,
      searchMerchantValue: observable,
      searchLoginValue: observable,
      permissions: observable,
      specificPermissionsTableData: observable,
      mostSpecificPermissionsTableData: observable,
      selectedMostSpecificPermissions: observable,
      permissionsTableData: observable,
      roles: observable,
      isLoginsSending: observable,
      selectedMerchant: observable,
      selectedTableItem: observable,
      isMerchantsLoading: observable,
      isUserAccessDrawerOpen: observable,
      isUserEditDrawerOpen: observable,
      isSecurityDrawerOpen: observable,

      onSubmit: action.bound,
      resetTabs: action.bound,
      resetStores: action.bound,
      setStores: action.bound,
      setActiveTab: action.bound,
      loadMerchants: action.bound,
      onMerchantSelect: action.bound,
      setSelectedMerchant: action.bound,
      setSearchMerchantValue: action.bound,
      setSelectedTableItem: action.bound,
      setMostSpecificPermissions: action.bound,
      setIsUserAccessDrawerOpen: action.bound,
      setIsUserEditDrawerOpen: action.bound,
      setIsSecurityDrawerOpen: action.bound,
      closeSecurityDrawer: action.bound,
      closeUserEditDrawer: action.bound,
      closeUserAccessDrawer: action.bound,

      isMerchantsTabActive: computed
    })

    this.merchantsTableStore = new TableFilterStore(this.fetchMerchantsByLogin.bind(this))
    this.loginsTableStore = new TableFilterStore(this.fetchLogins.bind(this))

    autorun(() => {
      this.loadMerchants()
      this.loadPermissions()
    })
  }

  get isMerchantsTabActive(): boolean {
    return this.activeTab === PortalAccessTabKeyType.merchants
  }

  get isLoading() {
    return this.merchantsTableStore.loadingStatus === 'loading' || this.loginsTableStore.loadingStatus === 'loading'
  }

  public filterSpecificPermissions(isNoVirtualTerminalPermission: boolean) {
    if (isNoVirtualTerminalPermission) {
      return this.selectedMostSpecificPermissions.filter(
        permission => permission !== SpecificPermissionsEnum.VIRTUAL_TERMINAL_REFUNDS
      )
    } else return this.selectedMostSpecificPermissions
  }

  public setActiveTab(tab: PortalAccessTabKeyType) {
    this.activeTab = tab
  }

  public setSelectedTableItem(item: AddUpdateLoginFormModel) {
    this.selectedTableItem = item
  }

  public setSelectedMerchant(value: string) {
    this.selectedMerchant = this.merchants.find(({ id }) => id === value) || null
  }

  public setMostSpecificPermissions(permissions: string[]) {
    this.selectedMostSpecificPermissions = permissions && permissions.length ? [...permissions] : []
  }

  public onMerchantSelect(value: string) {
    this.setSelectedMerchant(value)
    this.loadStoresByMerchant(value)

    this.loginsTableStore.loadData({ pageNumber: 1 })
  }

  public setSearchMerchantValue(value: string) {
    this.searchMerchantValue = value
  }

  public setSearchLoginValue(value: string) {
    this.searchLoginValue = value
  }

  public setStores(stores: StoreType[]) {
    this.stores = stores
  }

  public setIsUserAccessDrawerOpen(isOpen: boolean) {
    this.isUserAccessDrawerOpen = isOpen
  }

  public setIsUserEditDrawerOpen(isOpen: boolean) {
    this.isUserEditDrawerOpen = isOpen
  }

  public setIsSecurityDrawerOpen(isOpen: boolean) {
    this.isSecurityDrawerOpen = isOpen
  }

  public closeUserEditDrawer() {
    this.setIsUserEditDrawerOpen(false)
    this.setSelectedTableItem(null)
  }

  public closeSecurityDrawer() {
    this.setIsSecurityDrawerOpen(false)
  }

  public closeUserAccessDrawer() {
    this.setIsUserAccessDrawerOpen(false)
    this.setSelectedTableItem(null)
  }

  public resetSelect() {
    this.setSearchMerchantValue('')
    this.setSelectedMerchant(null)
    this.loadMerchants()
    this.loginsTableStore.loadData()
  }

  public resetTabs() {
    this.setSearchMerchantValue('')
    this.setSearchLoginValue('')
    this.merchantsTableStore.loadData()
    this.resetSelect()
  }

  public resetStores() {
    this.stores = []
  }

  public reloadTable() {
    this.isMerchantsTabActive
      ? this.loginsTableStore.loadData({ pageNumber: 1 })
      : this.merchantsTableStore.loadData({ pageNumber: 1 })
  }

  async fetchLogins({ currentPage, pageSize }: TableFetchParams) {
    if (!this.selectedMerchant?.id) {
      return {
        data: [],
        total: 0
      }
    }

    const { status, result, error } = await fetchLogins({
      merchantId: this.selectedMerchant.id,
      page: currentPage,
      size: pageSize
    })

    return {
      data: result.data.map(item => ({
        ...item,
        rolesStr: item?.systemRoles?.map(role => this.roles[role]).join(', '),
        rowKey: uuid()
      })),
      total: result.totalCount
    }
  }

  async fetchMerchantsByLogin({ currentPage, pageSize }: TableFetchParams) {
    if (!this.searchLoginValue.length) {
      return {
        data: [],
        total: 0
      }
    }

    const { status, result, error } = await fetchMerchantsByLogin({
      value: this.searchLoginValue,
      page: currentPage,
      size: pageSize
    })

    return {
      data: result.data.map(item => ({
        ...item,
        rolesStr: item?.systemRoles?.map(role => this.roles[role]).join(', '),
        rowKey: uuid()
      })),
      total: result.totalCount
    }
  }

  async fetchFullNameByLogin(login: string) {
    const { result, error } = await getUserInfoByUsername(login)

    if (!result || error) {
      return { firstName: '', lastName: '' }
    }

    return {
      firstName: result.firstName || '',
      lastName: result.lastName || ''
    }
  }

  async loadStoresByMerchant(merchantId: string, callback?: (stores: StoreType[]) => void) {
    try {
      const { status, result, error } = await fetchStoresByMerchant(merchantId)

      if (status === 200) {
        runInAction(() => (this.stores = result))

        callback && callback(result)
      } else {
        throw new Error(error.message)
      }
    } catch (error) {
      message.error(error)
    }
  }

  async loadMerchants() {
    runInAction(() => (this.isMerchantsLoading = true))

    const params = {
      value: this.searchMerchantValue,
      page: 1,
      size: 500
    }

    try {
      const { status, result, error } = await fetchMerchants(params)

      if (status === 200) {
        runInAction(() => (this.merchants = result.data))
      } else {
        throw new Error(translations().merchantsUploadError || error.message)
      }
    } catch (error) {
      message.error(error)
    } finally {
      runInAction(() => (this.isMerchantsLoading = false))
    }
  }

  async loadRolesWithPermissions() {
    try {
      const { status, result, error } = await fetchRolesWithPermissions('mp')
      const permissions = result?.reduce(
        (accumulator, { role, permissions }) => ({
          ...accumulator,
          [role]: convertPermissionsArrayIntoObject(permissions)
        }),
        {}
      )

      const adminPermissions = result?.find(item => item.role === 'admin')?.permissions || []

      const permissionsTableData = adminPermissions.reduce((accumulator, item) => {
        const lastDotIndex = item.lastIndexOf('.')
        const key = item.substring(0, lastDotIndex)
        const value = item.substring(lastDotIndex + 1)

        if (!['read', 'full'].includes(value)) {
          return accumulator
        }

        if (Array.isArray(accumulator[key])) {
          accumulator[key].push({ value })
        } else {
          accumulator[key] = [
            { value: 'no' },
            ...(key === 'virtual_terminal' ? [{ value: 'read', disabled: true }] : []),
            { value }
          ]
        }

        return accumulator
      }, {})

      const roles = result?.reduce(
        (accumulator, { role, name }) => ({
          ...accumulator,
          [role]: name
        }),
        { custom: 'Custom' }
      )

      if (status === 200) {
        runInAction(() => {
          this.roles = roles
          this.permissions = permissions
          this.permissionsTableData = permissionsTableData
        })
      } else {
        throw new Error(error.message)
      }
    } catch (error) {
      message.error(error)
    }
  }

  async loadSpecificPermissions() {
    try {
      const { status, result, error } = await fetchSpecificPermissions()

      if (error) throw new Error(error.message)

      const specificPermissionsTableData = result
        .map(item => item.split('.'))
        .reduce((accumulator, item) => {
          const key = item[0]
          const value = item[1]

          if (!['read', 'full'].includes(value)) {
            return accumulator
          }

          if (Array.isArray(accumulator[key])) {
            accumulator[key].push({ value })
          } else {
            accumulator[key] = [{ value: 'no' }, { value }]
          }

          return accumulator
        }, {})

      const mostSpecificPermissionsTableData =
        result
          .filter(permission => {
            const [key, value] = permission.split('.')
            return !['read', 'full'].includes(value)
          })
          .map(permission => ({
            key: permission,
            title: translations().specificPermissions[permission] || permission
          })) || null

      runInAction(() => {
        this.specificPermissionsTableData = specificPermissionsTableData
        this.mostSpecificPermissionsTableData = mostSpecificPermissionsTableData
      })
    } catch (error) {
      message.error(error)
    }
  }

  async loadPermissions() {
    await Promise.all([this.loadRolesWithPermissions(), this.loadSpecificPermissions()])
  }

  async deleteLogin(publicId: string, merchantId: string) {
    try {
      const { status, error } = await deleteLogin(publicId, merchantId)

      if (status === 200) {
        const message = this.isMerchantsTabActive
          ? translations().loginSuccessfulDelete
          : translations().merchantSuccessfulDelete

        this.reloadTable()

        notification.success({ message })
      } else {
        throw new Error(error.message || translations().loginDeleteFailure)
      }
    } catch (error) {
      message.error(translations().loginDeleteFailure)
    }
  }

  async updateLogin(publicId: string, params: UpdateLoginParamsType) {
    try {
      const { status, error } = await updateLogin(publicId, params)

      if (status === 200) {
        this.reloadTable()
        this.closeUserAccessDrawer()
        message.success(translations().loginSuccessfulUpdated)
      } else {
        throw new Error(error.message || translations().loginUpdateFailure)
      }
    } catch (error) {
      message.error(error.message || translations().loginUpdateFailure)
    }
  }

  async createLogin(request: CreateMerchantLoginParamsType) {
    try {
      const { status, error } = await createLogin(request)

      if (status === 200) {
        this.reloadTable()
        this.closeUserAccessDrawer()
        message.success(translations().loginSuccessfulAdded)
      } else {
        throw new Error(error.message || translations().loginUpdateFailure)
      }
    } catch (error) {
      message.error(error.message || translations().loginUpdateFailure)
    }
  }

  async onSubmit(values) {
    const merchantId = this.selectedTableItem?.merchantId
    const publicId = this.selectedTableItem?.publicId
    const isEdit = Boolean(merchantId)

    const shouldSendAllStores = values.shops.length === this.stores.length

    const request: CreateMerchantLoginParamsType = {
      email: values.email,
      firstName: values.firstName || '',
      lastName: values.lastName || '',
      merchantId: values.merchantId || merchantId || this.selectedMerchant.id,

      ...(!!values.reason ? { reason: values.reason } : {}),
      ...(!isEdit
        ? {
            sendWelcomeEmail: values.sendWelcomeEmail,
            isDefault: values.isDefault,
            twoFAStatus: values.twoFAEnabled ? 'ENFORCED' : 'DISABLED'
          }
        : {}),
      ...(shouldSendAllStores ? {} : { shops: values.shops })
    }

    const permissions =
      generatePermissionsArray(values.permissions).filter(
        permission => !Object.values(SpecificPermissionsEnum).includes(permission)
      ) || []

    const specificPermissions =
      [
        ...generatePermissionsArray(values.permissions).filter(permission =>
          Object.values(SpecificPermissionsEnum).includes(permission)
        ),
        ...this.selectedMostSpecificPermissions
      ] || []

    if (values.inviteAs === PortalUserAccessType.admin) {
      request.roles = [PortalUserAccessType.admin]
      request.permissions = specificPermissions
    } else {
      if (values.role === Role.finance) {
        request.roles = [Role.finance]
        if (specificPermissions.length) {
          request.permissions = specificPermissions
        }
      } else {
        request.permissions = [...permissions, ...specificPermissions]
        request.roles = [Role.custom]
      }
    }

    if (isEdit) {
      await this.updateLogin(publicId, request)
    } else {
      await this.createLogin(request)
    }
  }

  async updateUser(updateUser: Partial<UserModel>) {
    try {
      const { status, error } = await updateUserInfo(updateUser)

      if (status === 200) {
        this.reloadTable()
        this.closeUserEditDrawer()
        message.success(translations().loginSuccessfulUpdated)
      } else {
        throw new Error(error.message || translations().loginUpdateFailure)
      }
    } catch (error) {
      message.error(error.message || translations().loginUpdateFailure)
    }
  }

  async updateSecurity({
    twoFAEnabled,
    reason,
    publicId
  }: {
    publicId: string
    twoFAEnabled: boolean
    reason?: string
  }) {
    const twoFASettingsRequest = {
      twoFAStatus: twoFAEnabled ? 'ENFORCED' : 'DISABLED',
      ...(!!reason ? { reason } : {})
    } as EnforceTwoFAStatusParamsType

    try {
      const { status, error } = await updateTwoFASettings(publicId, twoFASettingsRequest)

      if (status === 200) {
        this.reloadTable()
        this.closeSecurityDrawer()
        message.success(translations().securitySettingsSuccessfulUpdated)
      } else {
        throw new Error(error.message || translations().securitySettingsUpdateFailure)
      }
    } catch (error) {
      message.error(error.message || translations().securitySettingsUpdateFailure)
    }
  }
}
