import { Grid } from 'antd'
import { observer } from 'mobx-react'
import React from 'react'
import { PermissionMap } from '~/code/constants/PermissionMap'
import { PageHeader, DesktopRangePicker, MobileRangePicker } from '~/code/components'
import { TabType } from '~/code/models'
import { hasPermissions } from '~/code/services/auth'
import { Routes } from '~/code/startup/Router/Routes'
import { goToRoute } from '~/code/startup/Router/utils'
import { OnboardingTabEnum } from './models'
import { OnboardingProps } from './props'
import translations from './translations'

const { useBreakpoint } = Grid

const tabList: (TabType & { permissions: string[] })[] = [
  {
    key: OnboardingTabEnum.AnalyticsV2,
    label: translations().analyticsV2,
    permissions: [PermissionMap.onboarding.analytics.read]
  },
  {
    key: OnboardingTabEnum.Processes,
    label: translations().processes,
    permissions: [PermissionMap.onboarding.processes.read]
  }
]

export const Onboarding = observer((props: OnboardingProps & { children: React.ReactNode }) => {
  const { activeTab, store, children, showItems = true } = props
  const screens = useBreakpoint()
  const allowedTabList = tabList.filter(t => hasPermissions(t.permissions))

  return (
    <div>
      <PageHeader
        tabActiveKey={activeTab}
        tabList={allowedTabList}
        onTabChange={key => goToRoute(`${Routes.BPM_REPORTS_ONBOARDING}/${key}`)}
        tabBarExtraContent={
          screens.xs ? <MobileRangePicker store={store.dateStore} /> : <DesktopRangePicker store={store.dateStore} />
        }
        showItems={showItems}
      />
      {children}
    </div>
  )
})
