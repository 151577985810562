import { action, autorun, computed, makeObservable, observable, reaction, runInAction } from 'mobx'
import { PeriodType } from '~/code/models'
import { BpmStages } from '~/code/constants/BpmStages'
import { HandbooksStore } from '~/code/stores/HandbooksStore'
import { RangePickerStore } from '~/code/stores/RangePickerStore'
import { BpmAnalyticsItemModel, BpmAnalyticsStageModel } from '../../models'
import {
  fetchBpmAnalytics,
  fetchFunnelCurrentStageDataAnalytics,
  fetchFunnelDataAnalytics,
  fetchPieDataAnalytics,
  fetchTopDataAnalytics,
  fetchUWDataAnalytics
} from '../../services/fetchers'
import { message } from 'antd'
import { TopDataReportType } from '../../models/TopDataReportType'
import { UWDataReportType } from '../../models/UWDataReportType'
import { clearMobx } from '~/code/services'
import { PieDataReportType } from '../../models/PieDataReportType'
import { FunnelDataReportType } from '../../models/FunnelDataReportType'

export class BpmAnalyticsStore {
  isInitiated: boolean = false
  isLoading: boolean = false
  totalApplications: number = 0
  dateStore: RangePickerStore
  data: BpmAnalyticsStageModel[] = []
  acquisitionChannel: string = null
  isTopDataLoading: boolean = false
  topDataResult: TopDataReportType = null
  isUWDataLoading: boolean = false
  uWDataReport: UWDataReportType = null
  gaugeConfig: any = null
  isPieDataLoading: boolean = false
  pieDataReport: PieDataReportType[] = null
  isFunnelDataLoading: boolean = false
  funnelDataReport: FunnelDataReportType[] = null
  isCurrentStageDataLoading: boolean = false
  currentStageDataReport: FunnelDataReportType[] = null

  constructor(public handbooksStore: HandbooksStore, rangePickerStore: RangePickerStore) {
    this.dateStore = rangePickerStore

    makeObservable(this, {
      isLoading: observable,
      data: observable,
      acquisitionChannel: observable,
      isTopDataLoading: observable,
      topDataResult: observable,
      isUWDataLoading: observable,
      gaugeConfig: observable,
      isPieDataLoading: observable,
      pieDataReport: observable,
      isFunnelDataLoading: observable,
      funnelDataReport: observable,
      isCurrentStageDataLoading: observable,
      currentStageDataReport: observable,
      mainData: computed,
      init: action,
      reset: action,
      loadData: action,
      setAcquisitionChannel: action,
      loadTopData: action.bound,
      loadDataForUWGraphs: action.bound,
      setGaugeConfig: action.bound,
      loadDataForPieGraphs: action.bound,
      loadDataForFunnelGraphs: action.bound,
      loadDataForFunnelGraphsCurrentStage: action.bound,
      loadDataForV2: action.bound
    })

    autorun(() => {
      if (this.dateStore.startDate && this.dateStore.endDate) {
        this.loadData()
      }
    })

    reaction(() => this.mainData, this.loadDataForV2, { delay: 5 })
  }

  get mainData() {
    return {
      from: this.dateStore.startDate.clone().utc().format(),
      to: this.dateStore.endDate.clone().utc().format()
    }
  }

  loadDataForV2() {
    this.loadTopData()
    this.loadDataForUWGraphs()
    this.loadDataForPieGraphs()
    this.loadDataForFunnelGraphs()
    this.loadDataForFunnelGraphsCurrentStage()
  }

  setGaugeConfig(data: UWDataReportType) {
    this.gaugeConfig = {
      data: {
        target: data?.uwReviewPerDayGaugeTarget,
        total: data?.uwReviewPerDayGaugeTotal,
        name: 'score',
        thresholds: data?.gaugeThresholdsSettings
      },
      scale: {
        color: {
          range: data?.gaugeThresholdsColorSettings
        }
      },
      style: {
        textContent: (target, total) => `\n\n${(target / total) * 100}%`,
        fontWeight: 500
      }
    }
  }

  setAcquisitionChannel(value: string) {
    this.acquisitionChannel = value
    this.loadData()
  }

  async init() {
    this.isInitiated = true
    this.reset()
    if (this.dateStore.period === null) this.dateStore.setPeriod(PeriodType.Yesterday)
    this.handbooksStore.loadAcquisitionChannels()
  }

  reset() {
    this.totalApplications = 0
    this.data = this.getParseData([])
  }

  async loadData() {
    this.isLoading = true
    this.reset()
    await this.handbooksStore.loadBpmStatuses()
    const { result, error } = await fetchBpmAnalytics({
      from: this.dateStore.startDate.clone().utc().format(),
      to: this.dateStore.endDate.clone().utc().format(),
      acquisitionChannel: this.acquisitionChannel || undefined
    })

    runInAction(() => {
      if (!error) {
        const data: BpmAnalyticsItemModel[] = result.current.data.map((current, index) => {
          const { count, target, stage, status } = current
          const { avgReviewTime } = result.historical.data[index]
          return { count, target, stage, status, avgReviewTime }
        })

        this.totalApplications = result.current.totalApplications
        this.data = this.getParseData(data)
      }

      this.isLoading = false
    })
  }

  getParseData(items: BpmAnalyticsItemModel[]) {
    const stageMap: Record<string, BpmAnalyticsItemModel[]> = {}
    items.forEach(item => {
      const { stage } = item
      if (!stageMap[stage]) {
        stageMap[stage] = []
      }
      stageMap[stage].push(item)
    })

    return BpmStages.map(({ name, gaugeData }) => {
      const subStatuses = stageMap[name] || []
      const description = this.handbooksStore.bpmStatuses.find(s => s.name === name)?.description
      const { count, avgReviewTime, target } = subStatuses.reduce(
        (prev, c) => {
          return {
            count: prev.count + c.count,
            avgReviewTime: prev.avgReviewTime + c.avgReviewTime,
            target: prev.target + c.target
          }
        },
        { count: 0, avgReviewTime: 0, target: 0 }
      )

      return {
        name,
        description,
        gaugeData,
        count,
        target,
        avgReviewTime,
        subStatuses
      }
    })
  }

  async loadTopData() {
    try {
      runInAction(() => {
        this.isTopDataLoading = true
      })

      const { status, result, error } = await fetchTopDataAnalytics(this.mainData.from, this.mainData.to)
      if (error) {
        message.error(error.message)
      }

      if (status === 200) {
        this.topDataResult = result.report?.[0]
      }
    } catch (error) {
      message.error(error)
    } finally {
      runInAction(() => {
        this.isTopDataLoading = false
      })
    }
  }

  async loadDataForUWGraphs() {
    try {
      runInAction(() => {
        this.isUWDataLoading = true
      })

      const { status, result, error } = await fetchUWDataAnalytics(this.mainData.from, this.mainData.to)
      if (error) {
        message.error(error.message)
      }

      if (status === 200) {
        this.uWDataReport = result.report?.[0]
        this.setGaugeConfig(result.report?.[0])
      }
    } catch (error) {
      message.error(error)
    } finally {
      runInAction(() => {
        this.isUWDataLoading = false
      })
    }
  }

  async loadDataForPieGraphs() {
    try {
      runInAction(() => {
        this.isPieDataLoading = true
      })

      const { status, result, error } = await fetchPieDataAnalytics(this.mainData.from, this.mainData.to)
      if (error) {
        message.error(error.message)
      }

      if (status === 200) {
        this.pieDataReport = result?.report
      }
    } catch (error) {
      message.error(error)
    } finally {
      runInAction(() => {
        this.isPieDataLoading = false
      })
    }
  }

  async loadDataForFunnelGraphs() {
    try {
      runInAction(() => {
        this.isFunnelDataLoading = true
      })

      const { status, result, error } = await fetchFunnelDataAnalytics(this.mainData.from, this.mainData.to)
      if (error) {
        message.error(error.message)
      }

      if (status === 200) {
        this.funnelDataReport = result.report
      }
    } catch (error) {
      message.error(error)
    } finally {
      runInAction(() => {
        this.isFunnelDataLoading = false
      })
    }
  }

  async loadDataForFunnelGraphsCurrentStage() {
    try {
      runInAction(() => {
        this.isCurrentStageDataLoading = true
      })

      const { status, result, error } = await fetchFunnelCurrentStageDataAnalytics(this.mainData.from, this.mainData.to)
      if (error) {
        message.error(error.message)
      }

      if (status === 200) {
        this.currentStageDataReport = result.report
      }
    } catch (error) {
      message.error(error)
    } finally {
      runInAction(() => {
        this.isCurrentStageDataLoading = false
      })
    }
  }
}
