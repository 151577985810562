import React from 'react'
import { Badge, Tag, Tooltip } from 'antd'
import moment from 'moment'
import { ColumnType } from 'antd/lib/table'
import {
    GroupableItem,
    PaymentMethodType,
    StatusType,
    TransactionChannelType,
    TransactionModel
} from '~/code/models'
import { formatAmount, getPaymentMethodTypeText, getSignifydColor, getStatusColor, getTransactionChannelText, onCell } from '~/code/services/utils'
import { getCardSchemeLabel } from '~/code/services/payments/card-scheme'
import translations from './translations'
import styles from './TransactionsStatement.scss'


export type TransactionColumnType = ColumnType<GroupableItem<TransactionModel>>

export const CREATED_DATE_COLUMN_KEY = 'createdDate'
export const CREATED_DATE_COLUMN_WIDTH = 110

// Map<partner, columns[]>
export const partnerColumnsAccess: Map<string, string[]> = new Map([
  ['affairs_group', ['payerName', 'payerEmail']]
])

export const columnsOnlyForFullAccess = [
  'transactionChannel', 'terminal', 'sourceClient', 'payerName', 'payerEmail', 'signifydDetails'
]

export const getCreatedDateColumn = (columnCount: number): TransactionColumnType => ({
  title: translations().createdDate,
  dataIndex: 'createdDate',
  colSpan: 1,
  key: CREATED_DATE_COLUMN_KEY,
  width: CREATED_DATE_COLUMN_WIDTH,
  onCell: (row) => Boolean(row.groupTitle) && ({ colSpan: columnCount, className: styles.rowGroup }),
  render: (date: string, row) => {
    if (row.groupTitle) {
      return row.groupTitle
    }
    return date ? <span className={styles.grey}>{moment(date).format('HH:mm:ss')}</span> : null
  }
})

export const columns: (TransactionColumnType[]) = [
  {
    title: translations().merchant,
    dataIndex: 'merchant',
    key: 'merchant',
    width: 216,
    onCell
  },
  {
    title: translations().amount,
    dataIndex: 'amount',
    key: 'amount',
    width: 100,
    align: 'right',
    onCell,
    render: (amount: number) => formatAmount(amount)
  },
  {
    title: translations().currency,
    dataIndex: 'currency',
    key: 'currency',
    width: 80,
    onCell
  },
  {
    title: translations().status,
    dataIndex: 'status',
    key: 'status',
    width: 130,
    onCell,
    render: (status: StatusType, row) => (
      status ? 
        <Badge
          status={getStatusColor(status)}
          text={status}
        />
      : ''
    )
  },
  {
    title: translations().message,
    dataIndex: 'message',
    key: 'message',
    width: 316,
    onCell,
    render: (text: string) => <Tooltip title={text} className={styles.cell}>{text}</Tooltip>
  },
  {
    title: translations().signifydResult,
    dataIndex: 'signifydId',
    key: 'signifydId',
    width: 160,
    onCell,
    render: (text: string, row) => text && (
      <Tooltip
        title={<div>
          <div>{translations().id}: {text}</div>
          <div>{translations().reason}: {row.signifydReason}</div>
        </div>}
        className={styles.cell}
      >
        <Badge
          status={getSignifydColor(row.signifydDecision)}
          text={`${row.signifydDecision} ${row.signifydScore}`}
        />
      </Tooltip>
    )
  },
  {
    title: translations().confirmationDate,
    dataIndex: 'payoutDate',
    key: 'payoutDate',
    width: 170,
    onCell,
    render: (date: string) => date ? moment(date).format('DD.MM.YYYY HH:mm:ss') : ''
  },
  {
    title: translations().confirmedAmount,
    dataIndex: 'payoutAmount',
    key: 'payoutAmount',
    width: 150,
    align: 'right',
    onCell,
    render: (amount: number) => formatAmount(amount)
  },
  {
    title: translations().transactionChannel,
    dataIndex: 'transactionChannel',
    key: 'transactionChannel',
    width: 160,
    onCell,
    render: (channel: TransactionChannelType) => getTransactionChannelText(channel)
  },
  {
    title: translations().transactionType,
    dataIndex: 'transactionType',
    key: 'transactionType',
    width: 140,
    onCell,
    render: (value: string) => (value ? (value === 'PAYBYLINK' ? 'Pay By Link' : value) : '')
  },
  {
    title: translations().paymentMethod,
    dataIndex: 'paymentMethod',
    key: 'paymentMethod',
    width: 130,
    onCell,
    render: (method: PaymentMethodType) => getPaymentMethodTypeText(method)
  },
  {
    title: translations().processingType,
    dataIndex: 'processingType',
    key: 'processingType',
    width: 166,
    onCell
  },
  {
    title: translations().mid,
    dataIndex: 'mid',
    key: 'mid',
    width: 140,
    onCell
  },
  {
    title: translations().terminal,
    dataIndex: 'terminal',
    key: 'terminal',
    width: 110,
    onCell
  },
  {
    title: translations().merchantReference,
    dataIndex: 'merchantReference',
    key: 'merchantReference',
    width: 320,
    onCell
  },
  {
    title: translations().rrn,
    dataIndex: 'reference',
    key: 'reference',
    width: 320,
    onCell
  },
  {
    title: translations().sourceClient,
    dataIndex: 'sourceClient',
    key: 'sourceClient',
    width: 258,
    onCell,
    render: (value: string, row) => {
      if (value === 'optomany_ecom_integration') {
        return 'Optomany to DNA Integration'
      }
      if (value === 'smarttradeapp') {
        return 'Smart Trade app Integration'
      }
      if (value) {
        value = value.replace('_', ' ')
        value = value.charAt(0).toUpperCase() + value.slice(1)  
        return value.replace(/(?: ^|\s)\S/g, (a) => a.toUpperCase())
      }
      return ''
    }
  },
  {
    title: translations().threeDSVersion,
    dataIndex: 'threeDSVersion',
    key: 'threeDSVersion',
    width: 100,
    onCell
  },
  {
    title: translations().avsHouseNumberResult,
    dataIndex: 'avsHouseNumberResult',
    key: 'avsHouseNumberResult',
    width: 190,
    onCell
  },
  {
    title: translations().avsPostcodeResult,
    dataIndex: 'avsPostcodeResult',
    key: 'avsPostcodeResult',
    width: 170,
    onCell
  },
  {
    title: translations().cscResult,
    dataIndex: 'cscResult',
    key: 'cscResult',
    width: 130,
    onCell
  },
  {
    title: <div style={{width: 150, overflow: 'hidden', textOverflow: 'ellipsis'}}>{translations().payerAuthenticationResult}</div>,
    dataIndex: 'payerAuthenticationResult',
    key: 'payerAuthenticationResult',
    width: 166,
    onCell,
    render: (value: string, row) => {
      const color = (value === 'Y/Y' || value === 'Y/A') ? '#006400' : '#FFA500'
      return value ? <Tag color={color}>{value}</Tag> : ''
    }
  },
  {
    title: translations().responseCode,
    dataIndex: 'responseCode',
    key: 'responseCode',
    width: 120,
    onCell,
    render: (value: string, row) => {
      const color = (value === '00' || value === '85') ? '#006400' : '#FFA500'
      return value ? <Tag color={color}>{value}</Tag> : ''
    }
  },
  {
    title: translations().cardType,
    dataIndex: 'cardType',
    key: 'cardType',
    width: 120,
    onCell,
    render: (value) => getCardSchemeLabel(value)
  },
  {
    title: translations().cardMask,
    dataIndex: 'cardMask',
    key: 'cardMask',
    width: 125,
    onCell
  },
  {
    title: translations().issuer,
    dataIndex: 'issuer',
    key: 'issuer',
    width: 166,
    onCell
  },
  {
    title: translations().issuerBankCountry,
    dataIndex: 'issuerBankCountry',
    key: 'issuerBankCountry',
    width: 130,
    onCell
  },
  {
    title: translations().payerName,
    dataIndex: 'payerName',
    key: 'payerName',
    width: 130,
    onCell
  },
  {
    title: translations().payerEmail,
    dataIndex: 'payerEmail',
    key: 'payerEmail',
    width: 220,
    onCell
  },
  {
    title: translations().payerIp,
    dataIndex: 'payerIp',
    key: 'payerIp',
    width: 140,
    onCell
  },
  {
    title: translations().ipCountry,
    dataIndex: 'ipCountry',
    key: 'ipCountry',
    width: 166,
    onCell
  },
  {
    title: translations().ipCity,
    dataIndex: 'ipCity',
    key: 'ipCity',
    width: 166,
    onCell
  },
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: 340,
    onCell
  }
]
