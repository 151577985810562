import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Checkbox, Form, Input } from 'antd'
import { PartnerAccessAddEditLoginFormProps } from './props'
import { UserSecurity } from '~/code/components'

import translations from './translations'

export const PartnerAccessAddEditLoginForm = observer(({ form, store }: PartnerAccessAddEditLoginFormProps) => {
  const { partnerAccessStore, loginFormInitialValues, onLoginFormSubmit } = store
  const { isEditMode } = partnerAccessStore

  useEffect(() => {
    form.setFieldsValue(loginFormInitialValues)
  }, [loginFormInitialValues])

  return (
    <Form
      form={form}
      requiredMark={false}
      labelAlign='left'
      labelCol={{ span: 6 }}
      onFinish={onLoginFormSubmit}
    >
      <Form.Item
        label={translations().formFields.email}
        name='login'
        rules={[
          { type: 'email', message: translations().formErrors.enterValidEmail },
          { required: true, message: translations().formErrors.emailRequired }
        ]}
      >
        <Input disabled={isEditMode} />
      </Form.Item>

      <Form.Item label={translations().formFields.firstName} name='firstName'>
        <Input />
      </Form.Item>

      <Form.Item label={translations().formFields.lastName} name='lastName'>
        <Input />
      </Form.Item>

      {!isEditMode && (
        <Form.Item name='sendEmail' valuePropName='checked'>
          <Checkbox>{translations().formFields.sendWelcomeEmail}</Checkbox>
        </Form.Item>
      )}

      {!isEditMode && <UserSecurity form={form} />}
    </Form>
  )
})
