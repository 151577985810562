import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
  edit: string
  cancel: string
  submit: string
  formFields: {
    email: string
    firstName: string
    lastName: string
  }
  formErrors: {
    emailRequired: string
    emailInvalid: string
    firstNameRequired: string
    lastNameRequired: string
  }
  permissionSelects: {
    admin: string
    restricted: string
  }
  permissionSelectsText: {
    admin: string
    restricted: string
  }
  subtitles: {
    userAccess: string
  }
}>()
