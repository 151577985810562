import { CardSchemeType } from '~/code/models/payments'

export const cardTypeBackgroundColors = {
    [CardSchemeType.Visa]: 'rgba(24, 143, 255, 0.8)',
    [CardSchemeType.Mastercard]: 'rgba(250, 173, 20, 0.8)',
    [CardSchemeType.Amex]: 'rgba(19, 194, 194, 0.8)',
    [CardSchemeType.UnionPay]: 'rgba(240, 72, 100, 0.8)',
    [CardSchemeType.Diners]: 'rgba(47, 194, 91, 0.8)',
    [CardSchemeType.Other]: 'rgba(114, 46, 209, 0.8)'
}

export const restCardTypeBackgroundColors = [
    'rgba(250, 219, 20, 0.8)',
    'rgba(240, 72, 100, 0.8)',
    'rgba(47, 84, 235, 0.8)',
    'rgba(235, 47, 150, 0.8)',
    'rgba(160, 217, 17, 0.8)'
]

export const backgroundColors = [
    'rgba(24, 143, 255, 0.8)',
    'rgba(47, 194, 91, 0.8)',
    'rgba(250, 219, 20, 0.8)',
    'rgba(240, 72, 100, 0.8)',
    'rgba(114, 46, 209, 0.8)',
    'rgba(19, 194, 194, 0.8)',
    'rgba(235, 47, 150, 0.8)',
    'rgba(250, 173, 20, 0.8)',
    'rgba(47, 84, 235, 0.8)',
    'rgba(160, 217, 17, 0.8)'
]
