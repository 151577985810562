import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
    totalApp: string
    approved: string
    avrIssueTime: string
    fastestIssueTime: string
    avrReviewTime: string
}>()

