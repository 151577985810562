import React from 'react'
import { Form, Switch } from 'antd'
import { NumberFormItem } from '~/code/components/NumberFormItem'
import { IcTariffType } from '../../Pricing/models'
import translations from '../translations'

export const childrenColumns = [
  {
    dataIndex: 'disable',
    key: 'disable',
    width: '5%',
    align: 'center' as 'center'
  },
  {
    dataIndex: 'name',
    key: 'name',
    width: 120
  }
]

export const childrenColumnsWithoutDisable = [
  {
    dataIndex: 'name',
    key: 'name',
    width: 150
  },
  {
    dataIndex: 'value',
    key: 'value',
    width: 140,
    align: 'center' as 'center'
  },

  {
    dataIndex: 'description',
    key: 'description',
    width: 140
  }
]

export const settlementPaymentColumns = [
  {
    children: childrenColumns
  }
]

export const settlementFeesColumns = [
  {
    children: [
      {
        children: childrenColumnsWithoutDisable,
        align: 'left' as 'left'
      }
    ]
  }
]

export const feesDataSettlementPayments = (
  perCompanyUberIdAllowed: boolean,
  isAdditionalStore: boolean,
  isDocusignSent: boolean
) => {
  return [
    {
      key: 'perCompanyUberId',
      name: translations().perCompanyUberId,
      disable: (
        <Form.Item shouldUpdate>
          {t => {
            return (
              <Form.Item name={'perCompanyUberId'} valuePropName={'checked'} dependencies={['grossSettlementsByMid']}>
                <Switch
                  disabled={
                    isAdditionalStore ||
                    !perCompanyUberIdAllowed ||
                    t.getFieldValue('grossSettlementsByMid') ||
                    isDocusignSent
                  }
                />
              </Form.Item>
            )
          }}
        </Form.Item>
      )
    },
    {
      key: 'perOutletMid',
      name: translations().perOutlerMid,
      disable: (
        <Form.Item shouldUpdate>
          {t => {
            return (
              <Form.Item name={'perOutletMid'} valuePropName={'checked'} dependencies={['perCompanyUberId']}>
                <Switch disabled={isAdditionalStore || isDocusignSent} />
              </Form.Item>
            )
          }}
        </Form.Item>
      )
    }
  ]
}

export const feesDataSettlementType = (
  grossSettlementsByUberIdAllowed: boolean,
  isOnlyEcom: boolean,
  isDopStore: boolean,
  isDocusignSent: boolean
) => {
  return [
    {
      key: 'dailyNetSettlements',
      name: translations().dailyNetSettlements,
      disable: (
        <Form.Item shouldUpdate>
          {t => {
            return (
              <Form.Item name={'dailyNetSettlements'} valuePropName={'checked'}>
                <Switch disabled={isDopStore || isDocusignSent} />
              </Form.Item>
            )
          }}
        </Form.Item>
      )
    },
    {
      key: 'grossSettlementsByMid',
      name: translations().grossSettlementsByMid,
      disable: (
        <Form.Item shouldUpdate>
          {t => {
            return (
              <Form.Item name={'grossSettlementsByMid'} valuePropName={'checked'} dependencies={['perCompanyUberId']}>
                <Switch disabled={isDopStore || isOnlyEcom || t.getFieldValue('perCompanyUberId') || isDocusignSent} />
              </Form.Item>
            )
          }}
        </Form.Item>
      )
    },
    {
      key: 'grossSettlementsByUberId',
      name: translations().grossSettlementsByUberId,
      disable: (
        <Form.Item shouldUpdate>
          {t => {
            return (
              <Form.Item name={'grossSettlementsByUberId'} valuePropName={'checked'}>
                <Switch disabled={isDopStore || isOnlyEcom || !grossSettlementsByUberIdAllowed || isDocusignSent} />
              </Form.Item>
            )
          }}
        </Form.Item>
      )
    }
  ]
}

export const settlementFeesDataPos = (tariffsList: IcTariffType[], isDocusignSent: boolean) => {
  return [
    {
      key: 'settlementPeriodPos',
      name: translations().settlementPeriod,
      value: (
        <Form.Item shouldUpdate>
          {t => {
            return (
              <NumberFormItem
                min={t.getFieldValue(['pos', 'nextDayFundingRequested']) === true ? 1 : 2}
                max={3}
                name={['pos', 'settlementPeriod']}
                placeholder={translations().zeroPlaceholder}
                disabled={isDocusignSent}
              />
            )
          }}
        </Form.Item>
      ),
      description: translations().feeAppliedForEachSettlement
    },
    {
      key: 'settlementFeePos',
      name: translations().settlementFee,
      value: (
        <NumberFormItem
          name={['pos', 'settlementFee']}
          min={tariffsList?.find(item => item?.interchangeFee?.key === 'settlementFee')?.interchangeFee?.minBaseFee}
          max={tariffsList?.find(item => item?.interchangeFee?.key === 'settlementFee')?.interchangeFee?.maxBaseFee}
          placeholder={translations().zeroPlaceholder}
          disabled={isDocusignSent}
        />
      ),
      description: translations().feeAppliedForEachSettlement
    }
  ]
}

export const feesDataNextDayPos = (isDocusignSent: boolean) => {
  return [
    {
      key: 'nextDayFundingRequestedPos',
      name: translations().nextDayFundingRequested,
      disable: (
        <Form.Item shouldUpdate>
          {t => {
            return (
              <Form.Item name={['pos', 'nextDayFundingRequested']} valuePropName={'checked'}>
                <Switch disabled={isDocusignSent} />
              </Form.Item>
            )
          }}
        </Form.Item>
      )
    }
  ]
}

export const nextDayDataPos = (tariffsList: IcTariffType[], isDocusignSent: boolean) => {
  return [
    {
      key: 'nextDayFundingFeePerSettlementPos',
      name: translations().nextDayFundingFeePerSettlement,
      value: (
        <NumberFormItem
          min={tariffsList?.find(item => item?.interchangeFee?.key === 'fasterFundingFee')?.interchangeFee?.minBaseFee}
          max={tariffsList?.find(item => item?.interchangeFee?.key === 'fasterFundingFee')?.interchangeFee?.maxBaseFee}
          name={['pos', 'fasterFundingFee']}
          placeholder={translations().zeroPlaceholder}
          disabled={isDocusignSent}
        />
      ),
      description: translations().feeAppliedForEachSettlement
    },
    {
      key: 'nextDayFundingFeePerMonthPos',
      name: translations().nextDayFundingFeePerMonth,
      value: (
        <NumberFormItem
          min={
            tariffsList?.find(item => item?.interchangeFee?.key === 'fasterFundingMonthly')?.interchangeFee?.minBaseFee
          }
          max={
            tariffsList?.find(item => item?.interchangeFee?.key === 'fasterFundingMonthly')?.interchangeFee?.maxBaseFee
          }
          name={['pos', 'fasterFundingMonthly']}
          placeholder={translations().zeroPlaceholder}
          disabled={isDocusignSent}
        />
      ),
      description: translations().feeReducing
    }
  ]
}

export const settlementFeesDataEcom = (tariffsList: IcTariffType[], isDocusignSent: boolean) => {
  return [
    {
      key: 'settlementPeriodEcom',
      name: translations().settlementPeriod,
      value: (
        <Form.Item shouldUpdate>
          {t => {
            return (
              <NumberFormItem
                min={t.getFieldValue(['ecom', 'nextDayFundingRequested']) === true ? 1 : 2}
                max={3}
                name={['ecom', 'settlementPeriod']}
                placeholder={translations().zeroPlaceholder}
                disabled={isDocusignSent}
              />
            )
          }}
        </Form.Item>
      ),
      description: translations().feeAppliedForEachSettlement
    },
    {
      key: 'settlementFeeEcom',
      name: translations().settlementFee,
      value: (
        <NumberFormItem
          name={['ecom', 'settlementFee']}
          min={tariffsList?.find(item => item?.interchangeFee?.key === 'settlementFee')?.interchangeFee?.minBaseFee}
          max={tariffsList?.find(item => item?.interchangeFee?.key === 'settlementFee')?.interchangeFee?.maxBaseFee}
          placeholder={translations().zeroPlaceholder}
          disabled={isDocusignSent}
        />
      ),
      description: translations().feeAppliedForEachSettlement
    }
  ]
}

export const feesDataNextDayEcom = (isDocusignSent: boolean) => {
  return [
    {
      key: 'nextDayFundingRequestedEcom',
      name: translations().nextDayFundingRequested,
      disable: (
        <Form.Item shouldUpdate>
          {t => {
            return (
              <Form.Item name={['ecom', 'nextDayFundingRequested']} valuePropName={'checked'}>
                <Switch disabled={isDocusignSent} />
              </Form.Item>
            )
          }}
        </Form.Item>
      )
    }
  ]
}

export const nextDayDataEcom = (tariffsList: IcTariffType[], isDocusignSent: boolean) => {
  return [
    {
      key: 'nextDayFundingFeePerSettlementEcom',
      name: translations().nextDayFundingFeePerSettlement,
      value: (
        <NumberFormItem
          min={tariffsList?.find(item => item?.interchangeFee?.key === 'fasterFundingFee')?.interchangeFee?.minBaseFee}
          max={tariffsList?.find(item => item?.interchangeFee?.key === 'fasterFundingFee')?.interchangeFee?.maxBaseFee}
          name={['ecom', 'fasterFundingFee']}
          placeholder={translations().zeroPlaceholder}
          disabled={isDocusignSent}
        />
      ),
      description: translations().feeAppliedForEachSettlement,
      type: 'ecom'
    },
    {
      key: 'nextDayFundingFeePerMonthEcom',
      name: translations().nextDayFundingFeePerMonth,
      value: (
        <NumberFormItem
          min={
            tariffsList?.find(item => item?.interchangeFee?.key === 'fasterFundingMonthly')?.interchangeFee?.minBaseFee
          }
          max={
            tariffsList?.find(item => item?.interchangeFee?.key === 'fasterFundingMonthly')?.interchangeFee?.maxBaseFee
          }
          name={['ecom', 'fasterFundingMonthly']}
          placeholder={translations().zeroPlaceholder}
          disabled={isDocusignSent}
        />
      ),
      description: translations().feeReducing
    }
  ]
}
