import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { CategoryType, ItemModel } from '~/code/models/handbooks'
import { AcquisitionChannelModel, AcquisitionChannelType, BpmStatusModel, BpmUserGroupModel, BpmUserModel } from '~/code/models'
import { AcquiringType } from '../pages/Acquiring/models'
import {
  fetchAcquisitionChannels,
  fetchAcquisitionChannelsV2,
  fetchApprovalTypes,
  fetchBpmStatuses,
  fetchBpmUserGroups,
  fetchBpmUsers,
  fetchCategories,
  fetchProductTypes,
  fetchSubCategories
} from '~/code/services/fetchers'
import {
  COUNTRIES,
  PAYMENT_METHODS,
  STATUSES,
  THREE_DS_VERSIONS,
  TRANSACTION_CHANNELS,
  PAYER_AUTHS,
  PROCESSING_TYPES,
  POS_TRANSACTION_CHANNELS,
  SIGNIFYD_DECISIONS
} from '~/code/constants/dictionaries'
import translations from '~/code/translations'
import { ApiResponse } from 'back-connector'
import { message } from 'antd'
import { injectable } from 'inversify'

const nonUK = { value: 'non-uk', label: translations().nonUK }

// TODO refactor this => use generics
// @ts-ignore
@injectable()
export class HandbooksStoreInjectable {
  isAcquisitionChannelsLoading: boolean = false
  isStartApplicationAcquisitionChannelsLoading: boolean = false
  isBpmStatusesLoading: boolean = false
  isBpmUsersLoading: boolean = false
  isBpmUserGroupsLoading: boolean = false
  isApprovalTypesLoading: boolean = false
  isProductTypesLoading: boolean = false
  isStartProcessAcquisitionChannelsLoading: boolean = false

  acquisitionChannels: AcquisitionChannelModel[] = []
  startApplicationAcquisitionChannels: AcquisitionChannelModel[] = []
  bpmStatuses: BpmStatusModel[] = []
  bpmUserGroups: BpmUserGroupModel[] = []
  bpmUsers: BpmUserModel[] = []
  approvalTypes: ItemModel[] = []
  productTypes: ItemModel[] = []
  segmentCategories: CategoryType[] = []
  segmentSubCategories: CategoryType[] = []
  startProcessAcquisitionChannels: AcquisitionChannelType[] = []

  countries: ItemModel[] = [nonUK, ...COUNTRIES.map(({ name, alpha3 }) => ({ value: alpha3, label: name }))]
  ipCountries: ItemModel[] = [nonUK, ...COUNTRIES.map(({ name }) => ({ value: name, label: name }))]
  statuses: string[] = STATUSES.sort()
  paymentMethods = PAYMENT_METHODS.sort((a, b) => a.label.localeCompare(b.label))
  threeDSVersions: ItemModel[] = THREE_DS_VERSIONS
  payerAuths: ItemModel[] = PAYER_AUTHS.map(payerAuth => ({ value: payerAuth, label: payerAuth }))
  processingTypes: ItemModel[] = PROCESSING_TYPES.map(p => ({ value: p, label: p }))
  signifydDecisions: ItemModel[] = SIGNIFYD_DECISIONS.map(d => ({ value: d, label: d }))
  posTransactionChannels: ItemModel[] = POS_TRANSACTION_CHANNELS

  constructor() {
    makeObservable(this, {
      isAcquisitionChannelsLoading: observable,
      isStartApplicationAcquisitionChannelsLoading: observable,
      isBpmStatusesLoading: observable,
      isBpmUserGroupsLoading: observable,
      isBpmUsersLoading: observable,
      approvalTypes: observable,
      productTypes: observable,

      acquisitionChannels: observable,
      startApplicationAcquisitionChannels: observable,
      bpmStatuses: observable,
      bpmUserGroups: observable,
      bpmUsers: observable,
      segmentCategories: observable,
      segmentSubCategories: observable,
      startProcessAcquisitionChannels: observable,

      acquisitionChannelsWithAll: computed,
      startApplicationAcquisitionChannelsWithAll: computed,
      businessCategoriesWithAll: computed,
      businessSubCategoriesWithAll: computed,
      startProcessAcquisitionChannelsWithAll: computed,

      loadAcquisitionChannels: action.bound,
      loadStartApplicationAcquisitionChannels: action.bound,
      loadBpmStatuses: action.bound,
      loadBpmUserGroups: action.bound,
      loadBpmUsers: action.bound,
      loadApprovalTypes: action.bound,
      loadProductTypes: action.bound,
      sortedSubCategoriesByCategory: action.bound,
      sortedAcqChannelsBySubCategory: action.bound
    })
  }

  get acquisitionChannelsWithAll() {
    return [
      {
        name: null,
        description: translations().all
      },
      ...this.acquisitionChannels
    ]
  }

  get startApplicationAcquisitionChannelsWithAll() {
    return [
      {
        name: null,
        description: translations().all
      },
      ...this.startApplicationAcquisitionChannels
    ]
  }

  get startProcessAcquisitionChannelsWithAll() {
    return [
      {
        code: null,
        value: translations().all
      },
      ...this.startProcessAcquisitionChannels
    ]
  }

  get statusList() {
    return this.statuses.map(s => ({ value: s, label: s })) as ItemModel[]
  }

  getTransactionChannels(acquiringType: AcquiringType) {
    return TRANSACTION_CHANNELS.sort((a, b) => a.label.localeCompare(b.label))
  }

  protected async loadHandbook<T>(
    fetchHandbook: () => Promise<ApiResponse<T[]>>,
    handbookName: string,
    handbookLoaderName: string
  ): Promise<T[]> {
    if (this[handbookName]?.length > 0) {
      return this[handbookName]
    }

    this[handbookLoaderName] = true

    const { error, result } = await fetchHandbook()
    if (!error) {
      this[handbookName] = result
    } else {
      message.error(error.message || translations().errors.loadData)
    }

    this[handbookLoaderName] = false

    return this[handbookName]
  }

  async loadStartApplicationAcquisitionChannels() {
    return this.loadHandbook(
      fetchAcquisitionChannelsV2,
      'startProcessAcquisitionChannels',
      'isStartProcessAcquisitionChannelsLoading'
    )
  }

  loadAcquisitionChannels() {
    return this.loadHandbook(fetchAcquisitionChannels, 'acquisitionChannels', 'isAcquisitionChannelsLoading')
  }

  async loadApprovalTypes() {
    if (this.approvalTypes && this.approvalTypes.length > 0) {
      return this.approvalTypes
    }

    runInAction(() => {
      this.isApprovalTypesLoading = true
    })

    const { error, result } = await fetchApprovalTypes()

    const approvalTypesResponse = result.map(type => ({
      value: type.code,
      label: type.name
    }))

    runInAction(() => {
      if (error) {
        return null
      }

      this.approvalTypes = [
        {
          value: null,
          label: translations().all
        },
        ...approvalTypesResponse
      ]

      this.isApprovalTypesLoading = false
    })
    return this.approvalTypes
  }

  async loadProductTypes() {
    if (this.productTypes && this.productTypes.length > 0) {
      return this.productTypes
    }

    runInAction(() => {
      this.isProductTypesLoading = true
    })

    const { error, result } = await fetchProductTypes()

    const productTypesResponse = result.map(type => ({
      value: type.code,
      label: type.name
    }))

    runInAction(() => {
      if (error) {
        return null
      }

      this.productTypes = [
        {
          value: null,
          label: translations().all
        },
        ...productTypesResponse
      ]

      this.isProductTypesLoading = false
    })
    return this.productTypes
  }

  async loadBpmStatuses() {
    if (this.bpmStatuses && this.bpmStatuses.length > 0) {
      return this.bpmStatuses
    }

    this.isBpmStatusesLoading = true
    const { error, result } = await fetchBpmStatuses()

    runInAction(() => {
      if (error) {
        return null
      }

      this.bpmStatuses = [
        {
          name: null,
          description: translations().all,
          processStatuses: []
        },
        ...result
      ]
      this.isBpmStatusesLoading = false
    })

    return this.bpmStatuses
  }

  async loadBpmUserGroups() {
    if (this.bpmUserGroups && this.bpmUserGroups.length > 0) {
      return this.bpmUserGroups
    }

    this.isBpmUserGroupsLoading = true
    const { error, result } = await fetchBpmUserGroups()
    const sortedResult =
      result && result.sort((a, b) => a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase()))

    runInAction(() => {
      if (error) {
        return null
      }
      this.bpmUserGroups = [
        {
          id: null,
          name: translations().all
        },
        ...sortedResult
      ]
      this.isBpmUserGroupsLoading = false
    })

    return this.bpmUserGroups
  }

  async loadBpmUsers() {
    if (this.bpmUsers && this.bpmUsers.length > 0) {
      return this.bpmUsers
    }

    this.isBpmUsersLoading = true
    const { error, result } = await fetchBpmUsers()
    const sortedResult =
      result && result.sort((a, b) => a.fullName.toLocaleLowerCase().localeCompare(b.fullName.toLocaleLowerCase()))

    runInAction(() => {
      if (error) {
        return null
      }
      this.bpmUsers = [
        {
          login: null,
          email: '',
          fullName: translations().all,
          group: null
        },
        ...sortedResult
      ]
      this.isBpmUsersLoading = false
    })

    return this.bpmUsers
  }

  loadBusinessCategories() {
    return this.loadHandbook(
      async () => {
        const { result, error } = await fetchCategories()
        const items = result && !error ? result : []
        return { result: items, error }
      },
      'segmentCategories',
      'isSegmentCategoriesLoading'
    )
  }

  loadBusinessSubCategories() {
    return this.loadHandbook(
      async () => {
        const { result, error } = await fetchSubCategories()
        const items = result && !error ? result : []
        return { result: items, error }
      },
      'segmentSubCategories',
      'isSegmentSubCategoriesLoading'
    )
  }

  get businessCategoriesWithAll() {
    return [
      {
        code: null,
        value: translations().all
      },
      ...this.segmentCategories
    ]
  }

  get businessSubCategoriesWithAll() {
    return [
      {
        code: null,
        value: translations().all
      },
      ...this.segmentSubCategories
    ]
  }

  sortedSubCategoriesByCategory(category: string) {
    const categoryId = this.segmentCategories.find(s => s.code === category)?.id

    const filteredSubCategories =
      categoryId && this.segmentSubCategories.filter(c => c.segmentCategory === categoryId.toString())

    return [
      {
        code: null,
        value: translations().all
      },
      ...filteredSubCategories
    ]
  }

  sortedAcqChannelsBySubCategory(subCategory: string) {
    const subCategoryId = this.segmentSubCategories.find(s => s.code === subCategory)?.id

    const filteredAcqChannels =
      subCategoryId &&
      this.startProcessAcquisitionChannels.filter(c => c.segmentSubCategory === subCategoryId.toString())

    return [
      {
        code: null,
        value: translations().all
      },
      ...filteredAcqChannels
    ]
  }
}
