import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
    bmpReports: string
    onboarding: string
    analytics: string
    processes: string
    analyticsV2: string
}>()

