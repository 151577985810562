import translations from './translations'

translations.add('en', {
  payByLink: 'Payment links (issued by default)',
  virtualTerminal: 'Virtual Terminal',
  websitePayments: 'Website payments',
  zashDigital: 'Zash Digital Ordering',
  amex: 'AmEx [MID]',
  unionPay: 'Union Pay',
  discovery: 'Discovery',
  openBanking: 'Open Banking',
  payByBank: 'Pay By Bank',
  klarna: 'Klarna',
  options: 'Options:',
  storeWebSite: 'Outlet website',
  webSiteRequired: 'Store website required',
  aliPay: 'AliPay (Coming soon)',
  configureEcom: 'Configure Ecommerce product',
  paymentMethods: 'Payment methods',
  cards: 'Cards (Visa, Mastercard, UnionPay, Diners & Discover)',
  productConfiguration: 'Product configuration',
  minTerm: 'Minimum term',
  minTermRequired: 'Minimum term required',
  websiteInvalid: 'Website invalid',
  invalidWebsiteDomain: 'Domains: dnapayments.com;dnapaymentsgroup.com;optomany.com;123send.com forbidden, use another domain',
  ecomWebsitePlaceholder: 'https://www.example.com'
})
