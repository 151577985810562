import React from 'react'
import { observer } from 'mobx-react'
import translations from './translations'
import { StoresListProps } from './props'
import { isStoreMainInfoValid } from '../../services'
import _ from 'lodash'
import { StoresListPage } from './components'

export const StoresList = observer((props: StoresListProps) => {
  const { stores, selectStore, deleteStore, isSaveLoading, onSave, isContinueLoading, posModels, isDocusignSent } =
    props
  const storesOrdered = _.orderBy(stores, 'id', 'asc')

  return (
    <StoresListPage
      title={translations().title}
      text={translations().details}
      list={
        storesOrdered &&
        storesOrdered.map(d => ({
          name: d.storeName,
          isCompleted: isStoreMainInfoValid(d),
          onClick: () => selectStore(d),
          onRemove: () => deleteStore(d),
          address: d.storeAddress,
          account: d.bankInfo,
          products: d.products,
          isRemoveDisabled: !d.removable || isDocusignSent,
          isEditDisabled: !d.removable,
          posModels: posModels
        }))
      }
      onAdd={() => selectStore({ removable: true })}
      isContinueLoading={isContinueLoading}
      isSaveLoading={isSaveLoading}
      onSave={onSave}
      addTitle={translations().addTitle}
      sorting={false}
      addDisabled={isDocusignSent}
      saveDisabled={isDocusignSent}
    />
  )
})
