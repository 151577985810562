export const liquidGraphStyle = {
  outlineBorder: 4,
  outlineDistance: 8,
  waveLength: 128,
  width: 300,
  height: 300
}

export const pieConfig = {
  angleField: 'value',
  colorField: 'type',
  label: {
    text: 'type',
    style: {
      fontWeight: 'bold',
      fontSize: 10
    }
  },
  legend: false
}

export const funnelConfig = {
  legend: {
    position: 'bottom',
    layout: 'horizontal',
    color: {
      cols: 0
    }
  },
  titleFontSize: 16,
  xField: 'stage',
  yField: 'number',
  isTransposed: false,
  label: [
    {
      text: d => d.number,
      position: 'inside',
      fontSize: 14
    },
    {
      text: (d, i, data) => {
        const val = (d.number / data[i - 1]?.number) * 100
        return val !== null && isFinite(val) ? val.toFixed(2) + '%' : ''
      },
      position: 'top-left',
      textAlign: 'middle',
      textBaseline: 'bottom',
      dy: -20
    }
  ]
}
