import React from 'react'
import { Dropdown, MenuProps } from 'antd'
import { SettingOutlined, DownOutlined } from '@ant-design/icons'
import { PermissionMap } from '~/code/constants/PermissionMap'
import { hasPermissions } from '~/code/services/auth'
import { PartnerAccessTabKeyType } from '~/code/pages/PartnerAccess/types'

import translations from '../translations'
import styles from '../styles.scss'

export const getActionItems = (type: PartnerAccessTabKeyType): MenuProps['items'] => {
  let items: MenuProps['items'] = [
    ...(hasPermissions([PermissionMap.partners.teammates.delete])
      ? [
          {
            key: 'delete',
            label: translations().menu['delete']
          }
        ]
      : [])
  ]

  if (type === PartnerAccessTabKeyType.partners && hasPermissions([PermissionMap.partners.teammates.update])) {
    items = [
      ...[
        {
          key: 'edit',
          label: translations().menu['edit']
        },
        {
          key: 'edit-access',
          label: translations().menu['editAccess']
        },

        {
          key: 'edit-security',
          label: translations().menu['editSecurity']
        }
      ],
      ...items
    ]
  }

  return items
}

export const renderActions = (type: PartnerAccessTabKeyType, onSelect) => {
  const items = getActionItems(type)

  return (
    <Dropdown menu={{ items, onClick: ({ key }) => onSelect(key) }}>
      <div className={styles.actions}>
        <SettingOutlined />
        <DownOutlined />
      </div>
    </Dropdown>
  )
}
