import translations from '../../../translations'
import { NumberFormItem } from '~/code/components/NumberFormItem'
import React from 'react'

export const blendedTableColumns = (withoutLimits: boolean, isDocusignSent: boolean) => [
  {
    title: translations().transactionType,
    children: [
      {
        key: 'cardScheme',
        dataIndex: 'cardScheme',
        title: translations().cardScheme
      },
      {
        key: 'serviceArea',
        dataIndex: 'serviceArea',
        title: translations().serviceArea
      },
      {
        key: 'corporateOrConsumer',
        dataIndex: 'corporateOrConsumer',
        title: translations().corporateOrConsumer
      },
      {
        key: 'cardType',
        dataIndex: 'cardType',
        title: translations().cardType
      }
    ]
  },
  {
    title: translations().baseFee,
    render: (_, row) => (
      <NumberFormItem disabled={isDocusignSent} decimalLength={4} name={[row.interchangeFee.key, 'base']} />
    )
  },
  {
    title: translations().percentFee,
    render: (_, row) => {
      return (
        <NumberFormItem
          disabled={isDocusignSent}
          max={!withoutLimits && row.interchangeFee.maxPercentFee}
          min={!withoutLimits && row.interchangeFee.minPercentFee}
          decimalLength={4}
          name={[row.interchangeFee.key, 'percent']}
        />
      )
    }
  }
]
