import { LiquidConfig } from '@ant-design/plots'
import { RealTimeTPSModel } from '~/code/stores/RealTimeTransactionStore/models'

import translations from '../translations'

export const createConfig = (data: RealTimeTPSModel, chartRef: React.MutableRefObject<any>): LiquidConfig => {
  const chartValues = {
    percent: (data?.percentageTps || 0) / 100,
    percentageText: `${data?.percentageTps || 0}%`,
    currentTps: `${data?.currentTps || 0}`,
    maxTpsText: `${translations().max} ${data?.maxTps || 100}`
  }

  return {
    percent: chartValues.percent,
    autoFit: true,
    tooltip: null,
    style: {
      textFill: 'transparent',
      waveLength: 128,
      outlineBorder: 3,
      outlineDistance: 0
    },
    legend: false,
    label: null,
    annotations: [
      {
        type: 'text',
        tooltip: false,
        style: {
          text: chartValues.percentageText,
          x: '51%',
          y: '61%',
          textAlign: 'center',
          fontSize: 12,
          fill: '#00000073'
        }
      },
      {
        type: 'text',
        tooltip: false,
        style: {
          text: chartValues.currentTps,
          x: '50%',
          y: '50%',
          textAlign: 'center',
          fontSize: 24,
          fontWeight: 'bold'
        }
      },
      {
        type: 'text',
        tooltip: false,
        style: {
          text: chartValues.maxTpsText,
          x: '50%',
          y: '-6%',
          textAlign: 'center',
          fontSize: 10,
          fill: '#00000073'
        }
      }
    ],
    onReady: (chart: any) => {
      chartRef.current = chart
    }
  }
}
