import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
  title: string
  actionTitle: {
    read: string
    create: string
    update: string
    delete: string
  }
  permissions: (isPartner: boolean) => Record<string, string>
}>()
