import React from 'react'
import { observer } from 'mobx-react'
import {
  isCompanyOfficerValid,
  isCompanyOfficerValidWithoutChecks,
  isOfficerVerificationResultPassed
} from '~/code/pages/StartProcess/services'
import { OfficerRole } from '~/code/pages/StartProcess/models'
import { ListPage } from '../../../ListPage'
import translations from './translations'
import { BusinessOwnersProps } from './props'

export const BusinessOwners = observer((props: BusinessOwnersProps) => {
  const { owners, selectOfficer, deleteOfficer, isSaveLoading, onSave, settings, isDocusignSent } = props

  return (
    <ListPage
      title={<>{translations().title}</>}
      text={translations().text}
      list={
        owners &&
        owners.map(d => ({
          name: d.nameElements?.forename,
          surname: d.nameElements?.surname,
          isCompleted:
            settings?.addressAndIDCheck === 'none' ? isCompanyOfficerValidWithoutChecks(d) : isCompanyOfficerValid(d),
          onClick: () => selectOfficer(d),
          onRemove: () => deleteOfficer(d),
          agreementSignatory: d.agreementSignatory,
          isRemoveDisabled: d.fromApi || isDocusignSent,
          officerVerificationStatus:
            settings?.addressAndIDCheck === 'none'
              ? null
              : isOfficerVerificationResultPassed(d.officerVerificationResult, d.jumioCheckResults)
              ? translations().verified
              : translations().nonVerified,
          manuallyDocs: d.uploadDocManually
        }))
      }
      onAdd={() => selectOfficer({ officerRole: OfficerRole.businessOwner })}
      isSaveLoading={isSaveLoading}
      onSave={onSave}
      addDisabled={isDocusignSent}
      saveDisabled={isDocusignSent}
    />
  )
})
