import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import { v4 as uuid } from 'uuid'
import { useInjection } from 'dna-react-ioc'
import storage from '~/code/services/storage'
import { VISITOR_ID_KEY } from '~/code/constants/AuthConstants'
import { AppStore } from '~/code/stores/AppStore'
import { PageTitle } from '~/code/components/PageTitle'
import { Router } from '~/code/startup'
import {
  RealTimeTransactionWebsocketStoreSymbol,
  RealTimeTransactionWebsocketStoreInterface
} from '~/code/stores/RealTimeTransactionStore/RealTimeTransactionWebsocketStore'
import {
  RealTimeTPSWebsocketStoreSymbol,
  RealTimeTPSWebsocketStoreInterface
} from '~/code/stores/RealTimeTransactionStore/RealTimeTPSWebsocketStore'

import { hasPermissions } from '~/code/services/auth'
import { PermissionMap } from '~/code/constants/PermissionMap'

import '~/assets/styles/main.scss'

const App = observer(({ store }: { store: typeof AppStore }) => {
  const rttWebsocket = useInjection<RealTimeTransactionWebsocketStoreInterface>(RealTimeTransactionWebsocketStoreSymbol)
  const tpsWebsocket = useInjection<RealTimeTPSWebsocketStoreInterface>(RealTimeTPSWebsocketStoreSymbol)

  Router(store.routerStore)
  const Layout = observer(props => store.currentLayout(props))
  const Page = observer(() => store.currentPage())

  useEffect(() => {
    const hasPermission =
      hasPermissions([PermissionMap.transactions.dna_ecom_realtime.read]) ||
      hasPermissions([PermissionMap.transactions.dna_pos_realtime.read])

    if (store.authStore.isAuthenticated && hasPermission) {
      rttWebsocket.connect()
      tpsWebsocket.connect()
    }
  }, [store.authStore.isAuthenticated])

  useEffect(() => {
    if (!Boolean(storage.get(VISITOR_ID_KEY))) {
      storage.set(VISITOR_ID_KEY, uuid())
    }
  }, [])

  if (!store.routerStore) {
    return undefined
  }

  return (
    <Layout>
      <PageTitle store={store} />
      <Page />
    </Layout>
  )
})

export { App }
