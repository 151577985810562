import React, { useCallback, useMemo, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Form, Input, Typography } from 'antd'
import { ToolOutlined, UnlockOutlined } from '@ant-design/icons'
import { getSpecificPermission } from '~/code/pages/PartnerAccess/components/PermissionsTable/services'
import { Role } from '~/code/pages/PortalAccess/types/Role'
import { TeammateType } from '~/code/containers/Teammates'
import { UserSecurity } from '~/code/components'
import { PermissionsTable, SpecificPermissions } from '~/code/pages/PartnerAccess/components'
import { PermissionCard } from '~/code/containers/Teammates/components/PermissionCard'
import { PartnerAccessAddEditFormProps } from './props'

import translations from './translations'

export const PartnerTeammateAddEditForm = observer(({ form, store, onCancel }: PartnerAccessAddEditFormProps) => {
  const { userPermissions, userSpecificPermissions, inviteOrUpdatePartnerTeammate, selectedUser } = store

  const [selectedRoles, setSelectedRoles] = useState<string[]>(selectedUser ? selectedUser.roles : [Role.admin])
  const [permissions, setPermissions] = useState<string[]>([])
  const [specificPermissions, setSpecificPermissions] = useState<string[]>([])
  const isEdit = !!selectedUser
  const isAdmin = selectedRoles.includes(Role.admin)

  const teammateAllPermissions = useMemo(() => {
    return store.roles.find(r => r.value === Role.admin)?.permissions || []
  }, [store.roles])

  const teammateSpecificPermissions = useMemo(() => {
    return getSpecificPermission(store.roles.find(r => r.value === Role.admin)?.permissions) || []
  }, [store.roles])

  const handlePermissionsChange = useCallback((newPermissions: string[]) => {
    setPermissions(newPermissions)
  }, [])

  const handleSpecificPermissionsChange = useCallback((newSpecificPermissions: string[]) => {
    setSpecificPermissions(newSpecificPermissions)
  }, [])

  const onPartnerTeammateSubmit = async (user: TeammateType & { twoFAEnabled: boolean }) => {
    const userData = {
      ...user,
      id: isEdit ? selectedUser?.id : undefined,
      roles: selectedRoles
    }

    if (isAdmin) {
      delete userData.permissions
    }

    await inviteOrUpdatePartnerTeammate(userData)

    onCancel()
  }

  const onFinish = v => {
    onPartnerTeammateSubmit({
      ...v,
      permissions: [...permissions, ...specificPermissions],
      roles: selectedRoles
    })
  }

  return (
    <Form
      form={form}
      initialValues={!!selectedUser ? selectedUser : {}}
      requiredMark={false}
      labelAlign='left'
      labelCol={{ span: 8 }}
      onFinish={onFinish}
    >
      {!isEdit && (
        <Form.Item
          name='email'
          label={translations().formFields.email}
          rules={[
            { required: true, message: translations().formErrors.emailRequired },
            { type: 'email', message: translations().formErrors.emailInvalid }
          ]}
        >
          <Input placeholder={translations().formFields.email} />
        </Form.Item>
      )}

      <Form.Item
        label={translations().formFields.firstName}
        name='firstName'
        rules={[{ required: true, message: translations().formErrors.firstNameRequired }]}
      >
        <Input placeholder={translations().formFields.firstName} />
      </Form.Item>

      <Form.Item
        label={translations().formFields.lastName}
        name='lastName'
        rules={[{ required: true, message: translations().formErrors.lastNameRequired }]}
      >
        <Input placeholder={translations().formFields.lastName} />
      </Form.Item>

      {!isEdit && <UserSecurity form={form} />}

      <Typography.Title level={5}>{translations().subtitles.userAccess}</Typography.Title>

      <PermissionCard
        title={translations().permissionSelects.admin}
        content={translations().permissionSelectsText.admin}
        icon={<UnlockOutlined />}
        isActive={isAdmin === true}
        onClick={() => setSelectedRoles([Role.admin])}
      />

      <PermissionCard
        title={translations().permissionSelects.restricted}
        content={translations().permissionSelectsText.restricted}
        icon={<ToolOutlined />}
        isActive={isAdmin === false}
        onClick={() => {
          setSelectedRoles([Role.custom])
        }}
      />

      {isAdmin === false && (
        <Form.Item>
          <PermissionsTable
            permissions={teammateAllPermissions}
            initialPermissions={userPermissions}
            onPermissionsChange={handlePermissionsChange}
          />
        </Form.Item>
      )}

      {isAdmin === false && (!!userSpecificPermissions?.length || !!teammateSpecificPermissions?.length) && (
        <Form.Item>
          <SpecificPermissions
            permissions={teammateSpecificPermissions}
            initialPermissions={userSpecificPermissions}
            onPermissionsChange={handleSpecificPermissionsChange}
          />
        </Form.Item>
      )}
    </Form>
  )
})
