import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import {
  BankAccountStoreType,
  CompanyProfile,
  OpenBankingCheckAccDataType,
  OpenBankingQuestionnaireType,
  OpenBankingResultsType,
  VerifyBankAccountResult
} from '~/code/pages/StartProcess/models'
import { VerifyBankAccountReq } from '~/code/pages/StartProcess/models/verification'
import {
  checkBankAccount,
  openBankingResultsCheck,
  openBankingVerificationCheck,
  parseSortCode,
  resendOBEmail,
  sortCodeCheckInBase,
  verifyBankAccount
} from '~/code/pages/StartProcess/services'
import translations from '../translations'
import { CheckBankAccountDataType, CheckBankAccountResultType } from '../models'
import { StartProcessStore } from '../StartProcessStore'
import { FormInstance, message } from 'antd'
import { BankInfo } from '../models/BankInfo'
import { VerifyBankAccountRes } from '../../StartApplication/models/verification'
import { ECOSPEND_STATUSES, PROCESS_VERSIONS, SENT_STATUS } from '../services/constants'

export class BankAccountStore implements BankAccountStoreType {
  isVerifyBankLoading: boolean = false
  verifyBankAccountResult: VerifyBankAccountResult = {
    type: 'invalid',
    message: ''
  }
  isChecksLoading: boolean = false
  verifyBankAccountResultFull: VerifyBankAccountRes = null

  checkBankAccResult: CheckBankAccountResultType = {
    bankAccountNumber: '',
    sortCode: '',
    accountType: '',
    verified: false
  }

  selectedBankAccount: BankInfo = null
  showVerifyModal: boolean = false
  errorMessage: string = ''
  checksReseted: boolean = false
  showOpenBankingModal: boolean = false
  openBankingRequestSent: boolean = false
  openBankingResults: string = ''
  uploadDocManually: boolean = false
  isOpenBankingResulstLoading: boolean = false
  openBankingResultsFull: OpenBankingResultsType = null
  isOBResendLoading: boolean = false
  isResendTimeoutDone: boolean = true
  showQuestionsModal: boolean = false
  openBankingQuestionnaireResults: OpenBankingQuestionnaireType = null
  isSortCodeCheckLoading: boolean = false
  sortCodeFoundInBase: boolean = false

  constructor(private parentStore: StartProcessStore) {
    makeObservable(this, {
      reset: action,
      isVerifyBankLoading: observable,
      verifyBankAccountResult: observable,
      checkBankAccResult: observable,
      selectedBankAccount: observable,
      isChecksLoading: observable,
      showVerifyModal: observable,
      verifyBankAccountResultFull: observable,
      errorMessage: observable,
      checksReseted: observable,
      showOpenBankingModal: observable,
      openBankingRequestSent: observable,
      openBankingResults: observable,
      uploadDocManually: observable,
      isOpenBankingResulstLoading: observable,
      openBankingResultsFull: observable,
      isOBResendLoading: observable,
      isResendTimeoutDone: observable,
      showQuestionsModal: observable,
      openBankingQuestionnaireResults: observable,
      isSortCodeCheckLoading: observable,
      sortCodeFoundInBase: observable,

      companyProfile: computed,
      initialDataForSelectedBankAccount: computed,
      bankAccounts: computed,
      responseOpenbankingDetails: computed,
      isQuestionnaireMember: computed,
      isFoodHub: computed,
      isOmniPay: computed,
      overallScore: computed,
      isSME: computed,
      isCanceledByLink: computed,
      isDocusignSent: computed,

      setVerifyBankAccountResult: action.bound,
      verifyBankAccountDetails: action.bound,
      checkBankAccountDetails: action.bound,
      setCheckBankAccResult: action.bound,
      verifyBankAccountChangeHandler: action,
      verifyBankAccount: action,
      selectBankAccount: action.bound,
      verificationCheckBankAccount: action.bound,
      setShowVerifyModal: action.bound,
      saveAccount: action.bound,
      parseValidationResult: action.bound,
      setErrorMessage: action.bound,
      resetChecks: action.bound,
      setShowOpenBankingModal: action.bound,
      openBankingCheck: action.bound,
      fetchOpenBankingResults: action.bound,
      setOpenBankingRequestSent: action.bound,
      setOpenBankingResults: action.bound,
      setUploadDocManually: action.bound,
      updateOpenBankingDetailsAndSave: action.bound,
      resendOpenBankingEmail: action.bound,
      setShowQuestionsModal: action.bound,
      setOpenBankingQuestionnaireResults: action.bound,
      findSortCodeInBase: action.bound
    })
  }

  setOpenBankingQuestionnaireResults(results: OpenBankingQuestionnaireType) {
    this.openBankingQuestionnaireResults = results
  }

  setShowQuestionsModal(val: boolean) {
    this.showQuestionsModal = val
  }

  setUploadDocManually(val: boolean) {
    this.uploadDocManually = val
  }

  setOpenBankingResults(res: string) {
    this.openBankingResults = res
  }

  setOpenBankingRequestSent(val: boolean) {
    this.openBankingRequestSent = val
  }

  setErrorMessage(msg: string) {
    this.errorMessage = msg
  }

  get isSME() {
    return this.dataStore.contactInfoData?.segmentCategory === 'SME_DIRECT'
  }

  get isFoodHub() {
    return this.dataStore.contactInfoData?.acquisitionChannel?.toLocaleUpperCase()?.includes('FOODHUB')
  }

  get isOmniPay() {
    return this.dataStore.contactInfoData?.acquisitionChannel?.toLocaleUpperCase()?.includes('OMNIPAY')
  }

  get isQuestionnaireMember() {
    return this.parentStore.oBQuestionnaireCredentials?.pilotMember
  }

  get responseOpenbankingDetails() {
    return this.openBankingResultsFull?.result?.[0]?.responseData?.accounts?.find(
      a => a.subType === 'CurrentAccount' && a.accountFormat === 'SortCode'
    )
  }

  get isCanceledByLink() {
    return this.openBankingResultsFull?.result?.[0]?.processStatus?.toLocaleUpperCase() === ECOSPEND_STATUSES.CANCELED
  }

  get overallScore() {
    return this.openBankingResultsFull?.result?.[0]?.responseData?.verification?.data[0]?.overallScore
  }

  get companyProfile(): CompanyProfile {
    return this.dataStore.application.companyProfile
  }

  get initialDataForSelectedBankAccount(): BankInfo {
    return {
      bankAccountType: this.selectedBankAccount.bankAccountType,
      bankAccountNumber: this.selectedBankAccount.bankAccountNumber?.replace(/(\d{4})(\d{4})/, '$1 $2'),
      sortCode: this.selectedBankAccount.sortCode?.replace(/-/g, ' - '),
      bankName: this.selectedBankAccount.bankName,
      accountName: this.dataStore.application?.companyProfile?.companyName
    }
  }

  get isDocusignSent(): boolean {
    return this.dataStore.isDocusignSent
  }

  async setShowVerifyModal(val: boolean, form?: FormInstance, isExistingAcc?: boolean) {
    if (form) {
      await form.validateFields(['bankAccountNumber', 'sortCode', 'accountName'])

      if (this.verifyBankAccountResult?.type === 'invalid') {
        if (this.verifyBankAccountResult?.message.length <= 0) {
          this.setVerifyBankAccountResult({
            type: 'invalid',
            message: [translations().validateBankAccount]
          })
        }
        return
      }
    }

    isExistingAcc ? this.saveAccount(form, true, isExistingAcc) : (this.showVerifyModal = val)
  }

  setShowOpenBankingModal(val: boolean) {
    this.showOpenBankingModal = val
  }

  selectBankAccount(account: BankInfo) {
    this.selectedBankAccount = account
    this.verifyBankAccountResult = {
      type: 'invalid',
      message: ''
    }
    this.checkBankAccResult = {
      bankAccountNumber: '',
      sortCode: '',
      accountType: '',
      verified: false
    }
    this.openBankingResults = ''
    this.openBankingRequestSent = false
    this.openBankingResultsFull = null
    this.uploadDocManually = false
    this.openBankingQuestionnaireResults = null
    this.sortCodeFoundInBase = account?.sortCodeFoundInBase
  }

  async verifyBankAccount(tForm: FormInstance, isCompany: boolean) {
    const validateFields = ['bankAccountNumber', 'sortCode']
    !isCompany && validateFields.push('bankAccountType')
    await tForm.validateFields(validateFields)

    if (
      !this.selectedBankAccount.id &&
      this.bankAccounts.find(
        b =>
          b.sortCode === tForm.getFieldValue('sortCode')?.replace(/\s/g, '') &&
          b.bankAccountNumber === tForm.getFieldValue('bankAccountNumber')?.replace(/\s/g, '')
      )
    ) {
      this.setErrorMessage(translations().accExists)
      return
    }

    await this.verifyBankAccountDetails({
      accountNumber: tForm.getFieldValue('bankAccountNumber')?.replace(/\s/g, ''),
      sortCode: tForm.getFieldValue('sortCode')?.replace(/\s|-/g, '')
    })
  }

  verifyBankAccountChangeHandler(tForm: FormInstance) {
    if (this.verifyBankAccountResult) {
      const { type, message } = this.verifyBankAccountResult
      if (type === 'valid') {
        tForm.setFieldsValue({ bankName: message as string })
      } else {
        tForm.setFieldsValue({ bankName: '' })
      }
    }
  }

  get dataStore() {
    return this.parentStore.dataStore
  }

  setCheckBankAccResult(val: CheckBankAccountResultType) {
    this.checkBankAccResult = val
  }

  setVerifyBankAccountResult(value: VerifyBankAccountResult) {
    this.verifyBankAccountResult = value
  }

  async verifyBankAccountDetails(data: VerifyBankAccountReq) {
    try {
      runInAction(() => {
        this.isVerifyBankLoading = true
      })

      const accExists = this.parentStore.existingBankAccounts?.find(
        exAcc =>
          exAcc.sortCode?.replace(/\s|-/g, '') === data.sortCode && exAcc.bankAccountNumber === data.accountNumber
      )

      if (
        this.dataStore.version === PROCESS_VERSIONS.ADDITIONAL_STORE_ISSUE &&
        this.companyProfile?.settlementInfo?.perCompanyUberId &&
        !Boolean(accExists)
      ) {
        message.error(translations().invoicedByUberID, 10)
        return
      }

      const { status, result, error } = await verifyBankAccount(data)
      this.verifyBankAccountResultFull = result
      if (status !== 200 || error) {
        this.verifyBankAccountResult = {
          type: 'error',
          message: [error.message || translations().errorVerifyBankAccount]
        }
      } else {
        this.parseValidationResult(result)
      }
    } catch (error) {
      this.verifyBankAccountResult = {
        type: 'error',
        message: [translations().errorVerifyBankAccount]
      }
    } finally {
      runInAction(() => {
        this.isVerifyBankLoading = false
      })
    }
  }

  async checkBankAccountDetails(data: CheckBankAccountDataType) {
    try {
      runInAction(() => {
        this.isChecksLoading = true
      })

      const { result } = await checkBankAccount(this.parentStore.dataStore.applicationId, data)
      this.setCheckBankAccResult(result)
    } finally {
      runInAction(() => {
        this.isChecksLoading = false
      })
    }
  }

  async findSortCodeInBase(sortCode: string) {
    try {
      runInAction(() => {
        this.isSortCodeCheckLoading = true
      })
      const { result } = await sortCodeCheckInBase(sortCode)
      this.sortCodeFoundInBase = result?.isExists
    } catch (error) {
      this.sortCodeFoundInBase = false
    } finally {
      runInAction(() => {
        this.isSortCodeCheckLoading = false
      })
    }
  }

  async openBankingCheck(email: string, tForm: FormInstance) {
    try {
      runInAction(() => {
        this.isChecksLoading = true
      })

      const data: OpenBankingCheckAccDataType = {
        emailAddress: email,
        redirectUrl: '',
        bankId: '',
        companyName: this.companyProfile?.companyName,
        applicantName: `${this.dataStore.contactInfoData?.firstName} ${this.dataStore.contactInfoData?.surname}`,
        bankAccountName: this.companyProfile?.companyName,
        bankAccountNumber: tForm.getFieldValue('bankAccountNumber')?.replace(/\s/g, ''),
        sortCode: tForm.getFieldValue('sortCode')?.replace(/\s|-/g, ''),
        customVerificationData: [{ name: translations().tradeName, value: this.companyProfile?.companyTradeName }]
      }

      const { status } = await openBankingVerificationCheck(this.parentStore.dataStore.applicationId, data)

      if (status === 200) {
        this.openBankingRequestSent = true
        this.saveAccount(tForm, false)
      }
    } finally {
      runInAction(() => {
        this.isChecksLoading = false
      })
    }
  }

  parseOpenBankingRequest(result: OpenBankingResultsType): string {
    let res = ''
    if (
      result?.matchResult?.toLocaleUpperCase() === ECOSPEND_STATUSES.NOMATCH &&
      (result?.result === null || result?.result === undefined)
    ) {
      res = SENT_STATUS
    } else {
      res = result.matchResult
    }

    return res
  }

  async fetchOpenBankingResults(accNum: string, sortCode: string) {
    try {
      runInAction(() => {
        this.isOpenBankingResulstLoading = true
      })
      const { status, result } = await openBankingResultsCheck(
        this.parentStore.dataStore.applicationId,
        accNum,
        sortCode
      )
      if (status !== 200) {
        message.error(translations().ecoSpendError, 10)
      }

      this.openBankingResultsFull = result

      this.openBankingResults = this.parseOpenBankingRequest(result)?.toLocaleUpperCase()
    } finally {
      runInAction(() => {
        this.isOpenBankingResulstLoading = false
      })
    }
  }

  async resendOpenBankingEmail() {
    try {
      runInAction(() => {
        this.isOBResendLoading = true
      })

      runInAction(() => {
        this.isResendTimeoutDone = false
      })
      const { status } = await resendOBEmail(this.parentStore.dataStore.applicationId)

      if (status === 200) {
        message.success(translations().resendSuccess, 11)
      }

      runInAction(() => {
        setTimeout(() => {
          this.isResendTimeoutDone = true
        }, 10000)
      })
    } catch (e) {
      message.error(translations().resendError, 10)
    } finally {
      runInAction(() => {
        this.isOBResendLoading = false
      })
    }
  }

  public reset() {
    this.verifyBankAccountResult = {
      type: 'invalid',
      message: ''
    }
    this.checkBankAccResult = {
      bankAccountNumber: '',
      sortCode: '',
      accountType: '',
      verified: false
    }
    this.openBankingResults = ''
    this.openBankingRequestSent = false
    this.openBankingResultsFull = null
    this.uploadDocManually = false
    this.openBankingQuestionnaireResults = null
    this.sortCodeFoundInBase = false
    this.selectedBankAccount = null
  }

  get bankAccounts() {
    return this.dataStore.bankAccounts
  }

  async verificationCheckBankAccount(tForm: FormInstance) {
    await tForm.validateFields(['bankAccountNumber', 'sortCode'])

    const account: CheckBankAccountDataType = {
      businessStructure: this.dataStore?.application?.companyProfile?.companyType,
      companyNumber: this.dataStore?.application?.companyProfile?.companyNumber,
      companyName: this.dataStore?.application?.companyProfile?.companyName,
      bankAccountNumber: tForm.getFieldValue('bankAccountNumber')?.replace(/\s/g, ''),
      bankAccountType: tForm.getFieldValue('bankAccountType') || 'business',
      bankAccountName: tForm.getFieldValue('accountName'),
      sortCode: tForm.getFieldValue('sortCode')?.replace(/\s/g, ''),
      businessOwner: undefined
    }

    if (this.companyProfile?.companyType === 'sole-trader' && tForm.getFieldValue('bankAccountType') === 'personal') {
      const owner = this.dataStore.application?.companyOfficerList[0]
      account.businessOwner = {
        firstName: owner?.nameElements?.forename,
        surname: owner?.nameElements?.surname,
        dateOfBirth: owner?.dateOfBirth,
        gender: owner?.gender,
        residentialAddress: {
          addressLine1: owner?.address?.addressLine1,
          addressLine2: owner?.address?.addressLine2,
          townOrCity: owner?.address?.locality,
          countyOrState: owner?.address?.country,
          postalCode: owner?.address?.postalCode,
          houseName: owner?.address?.houseName,
          houseNumber: owner?.address?.houseNumber
        }
      }
    }

    await this.checkBankAccountDetails(account)
    await this.findSortCodeInBase(account.sortCode)

    this.saveAccount(tForm, false)
  }

  saveAccount(form: FormInstance, resetSelected: boolean, isExistingAcc?: boolean) {
    const acc: BankInfo = {
      id: this.selectedBankAccount?.id || this.getMaxId(this.bankAccounts),
      bankAccountNumber: form.getFieldValue('bankAccountNumber')?.replace(/\s/g, ''),
      sortCode: form.getFieldValue('sortCode')?.replace(/\s/g, ''),
      accountName: form.getFieldValue('accountName'),
      bankName: form.getFieldValue('bankName'),
      bankAccountType:
        this.dataStore.companyType === 'sole-trader' ? form.getFieldValue('bankAccountType') : 'business',
      creditSafeVerificationResults: this.checkBankAccResult,
      validationResults: this.verifyBankAccountResultFull,
      openBankVerificationResult: this.openBankingResults,
      uploadDocManually: this.uploadDocManually,
      openBankingRequestSent: this.openBankingRequestSent,
      openBankingQuestionnaire: this.openBankingQuestionnaireResults,
      sortCodeFoundInBase: this.sortCodeFoundInBase,
      fromApi: isExistingAcc || false
    }

    if (this.selectedBankAccount.id) {
      this.dataStore.setBankAccounts(this.bankAccounts.filter(st => st.id !== this.selectedBankAccount.id))
    } else {
      if (this.bankAccounts.find(b => b.sortCode === acc.sortCode && b.bankAccountNumber === acc.bankAccountNumber)) {
        this.setErrorMessage(translations().accExists)
        return
      }
    }

    this.bankAccounts.push(acc)

    this.dataStore.saveOnBankAccounts(this.bankAccounts, 'isSaveLoading')

    if (resetSelected) {
      this.selectedBankAccount = null
      this.checksReseted = false
    } else {
      this.selectBankAccount(acc)
    }
  }

  closeModalAndSave() {
    this.showVerifyModal = false
    this.dataStore.saveApplication('isSaveLoading')
  }

  parseValidationResult(result: VerifyBankAccountRes) {
    if (result.errors && result.errors.length > 0) {
      this.verifyBankAccountResult = {
        type: 'error',
        message: result.errors
      }
    } else if (result.validations && result.validations.length > 0) {
      this.verifyBankAccountResult = {
        type: 'invalid',
        message: result.validations
      }
    } else if (result.bankDetails && result.bankDetails.bank) {
      this.verifyBankAccountResult = {
        type: 'valid',
        message: result.bankDetails.bank
      }
    }
  }

  getMaxId(array: { id?: number }[]) {
    if (array.length === 0) {
      return array.length + 1
    } else {
      return Math.max(...array.map(item => item.id)) + 1
    }
  }

  resetChecks() {
    this.verifyBankAccountResult = {
      type: 'invalid',
      message: ''
    }

    this.checkBankAccResult = {
      bankAccountNumber: '',
      sortCode: '',
      accountType: '',
      verified: false
    }

    this.openBankingResults = ''
    this.openBankingRequestSent = false
    this.openBankingResultsFull = null

    this.checksReseted = true
  }

  async updateOpenBankingDetailsAndSave(tForm: FormInstance) {
    tForm.setFieldsValue({
      sortCode: parseSortCode(this.responseOpenbankingDetails?.accountIdentification?.substring(0, 6)),
      bankAccountNumber: this.responseOpenbankingDetails?.accountIdentification?.substring(6),
      accountName: this.responseOpenbankingDetails?.calculatedOwnerName
    })

    this.openBankingResults = 'MATCH'

    this.saveAccount(tForm, true)
  }
}
