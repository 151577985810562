import React, { useEffect } from 'react'
import { Col, Divider, Form, Input, Radio, Row, Select } from 'antd'
import { PosDeliveryInfoPropsType } from './props'
import translations from './translations'
import { validatePostalCodeOfUK } from 'dna-common'
import { FormSubgroupTitle, OfficersPopover, PhoneNumberFormItem } from '../../../shared'
import { isCountryUK } from '~/code/pages/StartProcess/services'
import { PostalCode } from '~/code/components'
import { titleGenerator } from '../../../DetailsForm/services/generator'
import styles from './PosDeliveryInfo.scss'
import { Input as CustomInput } from '~/code/components'
import { MOBILE_PHONE_NUMBER_PATTERN, mobilePhonePatternStr } from '~/code/pages/StartProcess/services/patterns'

export const PosDeliveryInfo: React.FC<PosDeliveryInfoPropsType> = props => {
  const {
    form,
    storeForm,
    companyProfile,
    onboardingSettings,
    posDeliveryAddress,
    posDeliveryAddressType,
    posDeliveryInstructions,
    showHeader,
    contactPerson,
    deliveryPhoneNumber,
    saveDeliveryInfo
  } = props

  useEffect(() => {
    posDeliveryAddress && form.setFieldsValue({ posDeliveryAddress: posDeliveryAddress })
    posDeliveryAddressType && form.setFieldsValue({ posDeliveryAddressType: posDeliveryAddressType })
    posDeliveryInstructions && form.setFieldsValue({ posDeliveryInstructions: posDeliveryInstructions })
    deliveryPhoneNumber && form.setFieldsValue({ posDeliveryNumber: deliveryPhoneNumber })
    contactPerson &&
      form.setFieldsValue({
        nameElements: contactPerson.nameElements,
        emailAddress: contactPerson.emailAddress,
        telephoneNumber: contactPerson.telephoneNumber
      })
  }, [])

  return (
    <div className={styles.mw700}>
      <Form.Item noStyle shouldUpdate>
        {t => (
          <>
            {showHeader && <FormSubgroupTitle>{translations().posDeliveryAddress}</FormSubgroupTitle>}
            <Divider />
            <Form.Item name='posDeliveryAddressType'>
              <Radio.Group
                value='manual'
                onChange={e => {
                  t.resetFields(['posDeliveryAddress'])
                  const val = e.target.value
                  if (val === 'sameAsStoreAddress') {
                    t.setFieldsValue({ posDeliveryAddress: storeForm.getFieldValue('storeAddress') })
                  }

                  if (val === 'sameAsCompanyAddress') {
                    t.setFieldsValue({
                      posDeliveryAddress: {
                        country: companyProfile?.companyAddress?.country,
                        postalCode: companyProfile?.companyAddress?.postalCode,
                        addressLine1: companyProfile?.companyAddress?.addressLine1,
                        addressLine2: companyProfile?.companyAddress?.addressLine2,
                        townOrCity:
                          companyProfile?.companyAddress?.townOrCity || companyProfile?.companyAddress?.locality
                      }
                    })
                  }
                }}
              >
                <Radio value={'manual'}>{translations().manual}</Radio>
                {(companyProfile?.companyType === 'company' || companyProfile?.companyType === 'llp') && (
                  <Radio value={'sameAsCompanyAddress'}>{translations().sameAsCompany}</Radio>
                )}
                <Radio value={'sameAsStoreAddress'}>{translations().sameAsStore}</Radio>
              </Radio.Group>
            </Form.Item>

            <Row gutter={[32, 0]}>
              <Col xs={24}>
                <Form.Item
                  name={['posDeliveryAddress', 'country']}
                  label={translations().countryLabel}
                  rules={[
                    {
                      required: true,
                      message: translations().countryRequired
                    }
                  ]}
                  validateTrigger={['onChange', 'onBlur']}
                >
                  <Input disabled />
                </Form.Item>

                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, curValues) =>
                    prevValues.posDeliveryAddress?.country !== curValues.posDeliveryAddress?.country
                  }
                >
                  {() => (
                    <Form.Item
                      name={['posDeliveryAddress', 'postalCode']}
                      label={translations().postalCodeLabel}
                      getValueFromEvent={(e: React.FormEvent<HTMLInputElement>) => {
                        return e.currentTarget.value?.toLocaleUpperCase()
                      }}
                      rules={[
                        {
                          required: true,
                          message: translations().postalCodeRequired
                        },
                        {
                          validator: async (rule, value) => {
                            const isUK = isCountryUK(form.getFieldValue(['posDeliveryAddress', 'country']))
                            if (value && isUK && !validatePostalCodeOfUK(value)) {
                              throw new Error(translations().postalCodeInvalid)
                            }
                          }
                        }
                      ]}
                      validateTrigger={['onChange', 'onBlur']}
                    >
                      <PostalCode
                        disabled={t.getFieldValue('posDeliveryAddressType') !== 'manual'}
                        isUK={isCountryUK(t.getFieldValue(['posDeliveryAddress', 'country']))}
                        onAddressSelect={a => {
                          t.setFieldsValue({
                            posDeliveryAddress: {
                              addressLine1: a.line_1,
                              addressLine2: `${a.line_2} ${a.line_3 ? ', ' + a.line_3 + ',' : ''} ${a.line_4}`,
                              townOrCity: a.town_or_city,
                              region: a.county
                            }
                          })
                        }}
                      />
                    </Form.Item>
                  )}
                </Form.Item>

                <Form.Item
                  name={['posDeliveryAddress', 'addressLine1']}
                  label={translations().addressLine1Label}
                  rules={[
                    {
                      required: true,
                      message: translations().addressLine1Required
                    }
                  ]}
                  validateTrigger={['onChange', 'onBlur']}
                >
                  <Input disabled={t.getFieldValue('posDeliveryAddressType') !== 'manual'} />
                </Form.Item>

                <Form.Item name={['posDeliveryAddress', 'addressLine2']} label={translations().addressLine2Label}>
                  <Input disabled={t.getFieldValue('posDeliveryAddressType') !== 'manual'} />
                </Form.Item>

                <Form.Item name={['posDeliveryAddress', 'townOrCity']} label={translations().cityLabel}>
                  <Input
                    disabled={t.getFieldValue('posDeliveryAddressType') !== 'manual'}
                    onChange={e => t.setFieldsValue({ systemStoreAddress: { townOrCity: e.target.value } })}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Divider />
          </>
        )}
      </Form.Item>

      <Form.Item>
        <Form.Item name='posDeliveryInstructions' label={translations().deliveryInstructions}>
          <Input.TextArea placeholder={translations().deliveryInstructionsPlaceholder} />
        </Form.Item>
      </Form.Item>

      <Divider />

      <div className={styles.header}>
        <FormSubgroupTitle>{translations().contactPerson}</FormSubgroupTitle>
        <OfficersPopover
          onSelect={officer => {
            form.setFieldsValue({
              nameElements: officer.nameElements,
              emailAddress: officer.emailAddress,
              posDeliveryNumber: MOBILE_PHONE_NUMBER_PATTERN.test(officer.telephoneNumber)
                ? officer.telephoneNumber
                : '',
              telephoneNumber: officer.telephoneNumber
            })
            saveDeliveryInfo && saveDeliveryInfo(form)
          }}
        />
      </div>

      <Row gutter={16}>
        <Col xs={24} md={6}>
          <Form.Item
            name={['nameElements', 'title']}
            label={translations().personTitle}
            rules={[{ required: true, message: translations().titleRequired }]}
            validateTrigger={['onChange', 'onBlur']}
          >
            <Select allowClear>{titleGenerator()}</Select>
          </Form.Item>
        </Col>

        <Col xs={24} md={18}>
          <Form.Item
            name={['nameElements', 'forename']}
            label={translations().name}
            rules={[{ required: true, message: translations().nameRequired }]}
            validateTrigger='onBlur'
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item name={['nameElements', 'middleName']} label={<div>{translations().middleName}</div>}>
        <Input />
      </Form.Item>

      <Form.Item
        name={['nameElements', 'surname']}
        label={translations().surname}
        rules={[{ required: true, message: translations().surnameRequired }]}
        validateTrigger='onBlur'
      >
        <Input />
      </Form.Item>

      <Form.Item
        name='emailAddress'
        label={translations().emailAddress}
        rules={[
          { type: 'email', message: translations().emailFormatIncorrect },
          { required: true, message: translations().emailAddressRequired }
        ]}
        validateTrigger='onBlur'
      >
        <CustomInput placeholder={'me@company.co.uk'} textTransform={'lowercase'} autoComplete='off' />
      </Form.Item>

      <PhoneNumberFormItem
        name='posDeliveryNumber'
        label={translations().deliveryContactNumber}
        initialValue={companyProfile?.telephoneNumber}
        pattern={mobilePhonePatternStr}
        prefix={onboardingSettings?.phoneCode}
      />

      <PhoneNumberFormItem
        name='telephoneNumber'
        label={translations().telNumber}
        initialValue={companyProfile?.telephoneNumber}
        pattern={onboardingSettings?.phonePattern}
        prefix={onboardingSettings?.phoneCode}
      />
      <Divider />
    </div>
  )
}
