import { Divider, Form, Select, Row, Col } from 'antd'
import React, { useEffect, useState } from 'react'
import { CustomButton, Input } from '~/code/components'
import { CompanyNameForm, GeneralInformationForm } from '..'
import { ContactInfoProps } from './props'
import translations from './translations'
import { FormSubgroupTitle } from '~/code/pages/StartApplication/components'
import { UK } from '~/code/constants/dictionaries'
import styles from './ContactInfoForm.scss'
import { PartnerFormFields, SMEFormFields } from './components'

export const ContactInfoForm: React.FC<ContactInfoProps> = props => {
  const {
    initialValues,
    isAcquisitionChannelsLoading,
    acquisitionChannels,
    onEmailChange,
    onFinish,
    checkSalesforceOpportunityID,
    segmentCategories,
    isSegmentCategoryLoading,
    segmentSubCategories,
    isSegmentSubCategoryLoading,
    onSelectCategory,
    selectedCategoryID,
    onSelectSubCategory,
    selectedSubCategoryID,
    onSelectPartner,
    obrCountries,
    isObrCountriesLoading,
    onboardingSettings,
    companyProfile,
    setStartType,
    setCountry,
    country,
    getOppOwnerInfo,
    oppOwnerInfo,
    isOwnerInfoLoading,
    taskId,
    onSave,
    updateContactInfo,
    isSaveLoading,
    isViewMode,
    startType,
    onSelectCategoryByValue,
    onSelectSubCategoryByValue,
    isPartnerRequired,
    isDocusignSent
  } = props

  const [subCategoryOpt, setSubCategoryOpt] = useState(segmentSubCategories)

  useEffect(() => {
    if (segmentCategories.length === 1) {
      onSelectCategory(segmentCategories[0]?.code)
    }
    if (segmentSubCategories.length === 1) {
      onSelectSubCategory(segmentSubCategories[0]?.code)
    }
    if (acquisitionChannels.length === 1) {
      onSelectPartner(acquisitionChannels[0]?.code)
    }
  }, [segmentCategories, segmentSubCategories, acquisitionChannels])

  useEffect(() => {
    setSubCategoryOpt(segmentSubCategories)
  }, [selectedCategoryID])

  useEffect(() => {
    country && onSelectPartner(form.getFieldValue('acquisitionChannel'), country)
  }, [country])

  useEffect(() => {
    if (startType === 'sme') {
      oppOwnerInfo && form.setFieldsValue({ email: oppOwnerInfo?.email })
      oppOwnerInfo?.salesChannel && onSelectCategoryByValue(oppOwnerInfo?.salesChannel, form)
      oppOwnerInfo?.salesSubChannel && onSelectSubCategoryByValue(oppOwnerInfo?.salesSubChannel, form)
    }
  }, [oppOwnerInfo])

  useEffect(() => {
    if (startType === 'partners') {
      form.setFieldsValue({ email: __ONBOARDING_PARTNERS_START_EMAIL__ })
    }
  }, [startType])

  const [form] = Form.useForm()
  const [formCompany] = Form.useForm()

  const isSalesforceOpportunityIDVisible = (acquisitionChannel: string) => {
    if (!acquisitionChannel) return true
    return acquisitionChannels.find(a => a.code === acquisitionChannel)?.salesforceOpportunityRequired
  }

  const handleFinish = async values => {
    Promise.all([form.validateFields(), formCompany.validateFields()])
      .then(() => {
        values.email = values?.email?.toLocaleLowerCase()
        if (!values.acquisitionChannel && startType === 'partners') {
          values.acquisitionChannel = 'not_applicable'
        }
        onFinish(values)
      })
      .catch(er => form.scrollToField(er.errorFields[0].name))
  }

  const commonFormFieldProps = {
    checkSalesforceOpportunityID: checkSalesforceOpportunityID,
    form: form,
    getOppOwnerInfo: getOppOwnerInfo,
    isViewMode: isViewMode,
    isSegmentCategoryLoading: isSegmentCategoryLoading,
    onSelectCategory: onSelectCategory,
    onSelectPartner: onSelectPartner,
    segmentCategories: segmentCategories,
    isSegmentSubCategoryLoading: isSegmentSubCategoryLoading,
    onSelectSubCategory: onSelectSubCategory,
    subCategoryOpt: subCategoryOpt,
    selectedSubCategoryID: selectedSubCategoryID,
    acquisitionChannels: acquisitionChannels,
    isAcquisitionChannelsLoading: isAcquisitionChannelsLoading
  }

  return (
    <Form
      form={form}
      layout='vertical'
      initialValues={{
        ...initialValues,
        onboardingCountry: UK,
        segmentCategory: taskId
          ? initialValues?.segmentCategory
          : (segmentCategories.length === 1 && segmentCategories[0]?.code) || '',
        segmentSubCategory: taskId
          ? initialValues?.segmentSubCategory
          : (segmentSubCategories.length === 1 && segmentSubCategories[0]?.code) || '',
        acquisitionChannel: taskId
          ? initialValues?.acquisitionChannel
          : (acquisitionChannels.length === 1 && acquisitionChannels[0]?.code) || ''
      }}
      onValuesChange={() => taskId && updateContactInfo(form.getFieldsValue())}
      className={styles.formStyles}
      disabled={isDocusignSent}
    >
      <FormSubgroupTitle>{translations().applicationDetails}</FormSubgroupTitle>

      {startType === 'sme' && <SMEFormFields {...commonFormFieldProps} isPartnerRequired={isPartnerRequired} />}

      {startType === 'partners' && (
        <PartnerFormFields
          {...commonFormFieldProps}
          isSalesforceOpportunityIDVisible={isSalesforceOpportunityIDVisible}
          selectedCategoryID={selectedCategoryID}
          initialValues={initialValues}
          isPartnerRequired={isPartnerRequired}
        />
      )}

      {form.getFieldValue('salesforceOpportunityID') && oppOwnerInfo?.refereeUberId && (
        <Row className={styles.withMarginBottom}>
          <Col span={24}>
            <p>{translations().refereeUberId}</p>
            <Input disabled className={styles.customBtn} value={oppOwnerInfo?.refereeUberId} />
          </Col>
        </Row>
      )}

      {form.getFieldValue('salesforceOpportunityID') && oppOwnerInfo?.name && (
        <Row className={styles.withMarginBottom}>
          <Col span={24}>
            <p>{translations().opportunityIdOwnerName}</p>
            <Input disabled className={styles.customBtn} value={oppOwnerInfo?.name} />
          </Col>
        </Row>
      )}

      {onboardingSettings.bankDetailScheme !== 'EU' && (
        <Form.Item
          name='email'
          label={<div>{translations().sellerEmail}</div>}
          rules={[
            { type: 'email', message: translations().emailFormatIncorrect },
            { required: true, message: translations().emailRequired }
          ]}
          validateTrigger='onBlur'
        >
          {isOwnerInfoLoading ? (
            <CustomButton className={styles.customBtn} loading />
          ) : (
            <Input
              placeholder={'seller@company.co.uk'}
              textTransform={'lowercase'}
              onBlur={e => onEmailChange(e.target.value)}
              autoComplete='off'
            />
          )}
        </Form.Item>
      )}

      <Form.Item
        name='onboardingCountry'
        label={translations().obrCountry}
        rules={[
          {
            required: true,
            message: translations().requiredField
          }
        ]}
        validateTrigger='onBlur'
      >
        <Select
          loading={isObrCountriesLoading}
          showSearch
          onSelect={(_, t) => {
            setCountry(t.key)
            onSelectPartner(form.getFieldValue('acquisitionChannel'), t.key)
          }}
        >
          {obrCountries.map(option => (
            <Select.Option key={option.iso} value={option.value}>
              {option.value}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Divider />

      <CompanyNameForm form={formCompany} acquisitionChannel={form.getFieldValue('acquisitionChannel') || ''} />

      <GeneralInformationForm
        form={formCompany}
        companyType={companyProfile.companyType}
        obrCountry={form.getFieldValue('onboardingCountry')}
        data={companyProfile}
        onboardingSettings={onboardingSettings}
        obrCountries={obrCountries}
        isObrCountriesLoading={isObrCountriesLoading}
      />

      {taskId && (
        <Row>
          <Col>
            <CustomButton
              loading={isSaveLoading}
              type='primary'
              onClick={() => {
                onSave('isSaveLoading')
              }}
            >
              {translations().save}
            </CustomButton>
          </Col>
        </Row>
      )}

      {!taskId && (
        <Row gutter={10}>
          <Col>
            <CustomButton
              type='primary'
              onClick={() => {
                handleFinish(form.getFieldsValue())
              }}
            >
              {translations().start}
            </CustomButton>
          </Col>
          <Col>
            <CustomButton
              onClick={() => {
                setStartType(false)
              }}
            >
              {translations().cancel}
            </CustomButton>
          </Col>
        </Row>
      )}
    </Form>
  )
}
