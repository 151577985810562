import { message } from 'antd'
import { parseTextResult } from 'back-connector'
import { getWithAuth } from '~/code/services'
import { getAcquisitionChannel } from '~/code/services/auth'
import { BpmAnalyticsResponseModel, BPMApplicationsRequestParams, BPMApplicationsResponse } from '../models'
import { saveAs } from 'file-saver'
import { deleteWithAuth } from '~/code/services'
import { TopDataResponse } from '../models/TopDataResponse'
import { UWDataResponseType } from '../models/UWDataResponseType'
import { PieDataResponseType } from '../models/PieDataResponseType'
import { FunnelDataResponseType } from '../models/FunnelDataResponseType'

export const fetchProcesses = (params: BPMApplicationsRequestParams, additionalStore: boolean) => {
  return getWithAuth<BPMApplicationsResponse>(`/api/processes-api/bpm/applications`, {
    ...params,
    additionalStore
  })
}

export const fetchBpmAnalytics = (params: { from: string; to: string; acquisitionChannel?: string }) =>
  getWithAuth<BpmAnalyticsResponseModel>(`/api/bpm/applications/summary`, params)

export const fetchDocument = async (id: string, path: string, name: string, location?: string) => {
  const response = await getWithAuth(
    `/api/processes-api/v1/onboarding/${id}/documents?objectName=${encodeURIComponent(path)}${
      location ? `&variableLocation=${location}` : ''
    }`,
    undefined,
    [['Content-Type', 'blob']],
    {
      parseResponse: async res => {
        if (res.status !== 200) {
          const error = await parseTextResult(res)
          throw new Error(error.message)
        }
        const blobFile = await res.blob()
        return blobFile
      }
    }
  )
  if (response.error) {
    // TODO Check all of the message.error(response.error?.message)
    message.error(response.error?.message)
  }
  saveAs.saveAs(await response.result, name)
}

export const deleteProcesses = (params: { processIds: string[]; deleteReason: string }) =>
  deleteWithAuth('/api/processes-api/onboarding/applications', params)

export const fetchTopDataAnalytics = (from: string, to: string) =>
  getWithAuth<TopDataResponse>(`/bpm/adw/report/dashboard-applications-top-panel?from=${from}&to=${to}`)

export const fetchUWDataAnalytics = (from: string, to: string) =>
  getWithAuth<UWDataResponseType>(`/bpm/adw/report/dashboard-applications-uw-gauge?from=${from}&to=${to}`)

export const fetchPieDataAnalytics = (from: string, to: string) =>
  getWithAuth<PieDataResponseType>(`/bpm/adw/report/dashboard-applications-segment-pie?from=${from}&to=${to}`)

export const fetchFunnelDataAnalytics = (from: string, to: string) =>
  getWithAuth<FunnelDataResponseType>(`/bpm/adw/report/dashboard-applications-funnel?from=${from}&to=${to}`)

export const fetchFunnelCurrentStageDataAnalytics = (from: string, to: string) =>
  getWithAuth<FunnelDataResponseType>(`/bpm/adw/report/dashboard-applications-current-stage?from=${from}&to=${to}`)
