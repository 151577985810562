import { Form, Col, Select, Input, Divider, Collapse } from 'antd'
import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { NextButton, ProductSelectionForm, SelectPaymentSolutionForm, StartAppContainer, StoresList } from '..'
import { ProductsInformationProps } from './props'
import translations from './translations'
import styles from './ProductsInformation.scss'
import { StoreInfoForm } from '../StoreInfoForm'
import { default as translations2 } from '../ContactInfoForm/translations'
import { UK } from '~/code/constants/dictionaries'
import { convertDataToFormValue } from '../StoreInfoForm/services/utils'
import { cloneObject } from 'dna-common'
import { BackButton, CustomButton } from '~/code/components'
import { PosDeliveryInfo, EcomProductsList, PosProductsList } from '../ProductSelectionForm/components'
import { ProductsTable } from '../ProductsTable'
import { PROCESS_VERSIONS, PRODUCT_VERSIONS } from '../../services/constants'
import classNames from 'classnames'

export const ProductsInformation = observer((props: ProductsInformationProps) => {
  const { store } = props

  const [form] = Form.useForm()
  const [formContact] = Form.useForm()
  const [formProduct] = Form.useForm()
  const [formDelivery] = Form.useForm()

  useEffect(() => {
    if (store.productStore.needRefresh) {
      store.productStore.setNeedRefresh(false)
    }
  }, [store.productStore.needRefresh])

  useEffect(() => {
    if (store.productStore.selectedStore?.industry) {
      store.productStore.onLoadData(form)
    }
    form.resetFields()
    form.setFieldsValue({
      ...store.dataStore.generalInformationViewData,
      ...convertDataToFormValue(cloneObject(store.productStore.selectedStore), ['mcc'], store.dataStore.bankAccounts)
    })
  }, [store.productStore.selectedStore])

  return (
    <>
      {store.productStore.selectedStore ? (
        <>
          <StartAppContainer>
            <Form
              form={form}
              layout='vertical'
              initialValues={{
                ...store.dataStore.generalInformationViewData,
                ...convertDataToFormValue(
                  cloneObject(store.productStore.selectedStore),
                  ['mcc'],
                  store.dataStore.bankAccounts
                )
              }}
              className={styles.generalInformationForm}
              disabled={store.dataStore.isDocusignSent}
            >
              <BackButton
                onClick={() => {
                  store.productStore.setSelectedStore(null)
                  store.productStore.setProductAddMessage('')
                }}
              >
                {translations().back}
              </BackButton>
              <StoreInfoForm
                form={form}
                onSelectIndustry={store.onSelectIndustry}
                mccList={store.mccList}
                isMccListLoading={store.isMCCOptionsLoading}
                mccOptions={store.selectedMCCOptions}
                onSelectMcc={store.onSelectMcc}
                companyType={store.dataStore.companyType}
                bankAccounts={store.dataStore.bankAccounts}
                directDebitAccounts={
                  store.dataStore.version === PROCESS_VERSIONS.ADDITIONAL_STORE_ISSUE
                    ? store.dataStore.directDebitBankAccounts
                    : []
                }
                isDocusignSent={store.dataStore.isDocusignSent}
              />

              {store.dataStore.productVersion !== PRODUCT_VERSIONS.REDESIGNED &&
                store.productStore?.products?.length > 0 && (
                  <>
                    <ProductsTable store={store} products={store.productStore.products} />
                    <Divider />
                  </>
                )}
            </Form>

            {store.dataStore.productVersion !== PRODUCT_VERSIONS.REDESIGNED && (
              <>
                <Form
                  layout='vertical'
                  form={formProduct}
                  initialValues={{
                    posDeliveryAddress: {
                      country: store.dataStore?.application?.companyProfile?.onboardingCountryInfo?.value || UK
                    }
                  }}
                  className={styles.generalInformationForm}
                >
                  <SelectPaymentSolutionForm
                    storeForm={form}
                    store={store}
                    form={formProduct}
                    isBundlesLoading={store.isPosBundlesLoading}
                  />
                </Form>
                <Divider />

                <NextButton
                  disabled={store.productStore.products?.length === 0 || store.dataStore.isDocusignSent}
                  loading={store.dataStore.isSaveLoading}
                  onClick={() => store.productStore.saveStore(form)}
                >
                  {translations().save}
                </NextButton>
              </>
            )}
          </StartAppContainer>

          {store.dataStore.productVersion === PRODUCT_VERSIONS.REDESIGNED && (
            <StartAppContainer className={styles.mt}>
              {!store.dataStore.isDocusignSent && (
                <Form
                  layout='vertical'
                  form={formProduct}
                  initialValues={{
                    posDeliveryAddress: {
                      country: store.dataStore?.application?.companyProfile?.onboardingCountryInfo?.value || UK
                    }
                  }}
                  className={styles.generalInformationForm}
                  requiredMark={false}
                >
                  <ProductSelectionForm
                    storeForm={form}
                    store={store}
                    form={formProduct}
                    isBundlesLoading={store.isPosBundlesLoading}
                  />
                </Form>
              )}

              {store.productStore.productAddMessage !== '' && store.productStore.productAddMessage?.length > 0 && (
                <Col xs={24}>
                  <div className={styles.errMsg}>{store.productStore.productAddMessage}</div>
                </Col>
              )}

              {store.productStore.ecomProducts?.length > 0 && !store.productStore.showProductEditModal && (
                <>
                  <Collapse
                    className={classNames(styles.styledCollapse, styles.mt15)}
                    defaultActiveKey={'1'}
                    items={[
                      {
                        key: '1',
                        label: translations().ecomList,
                        children: store.productStore.ecomProducts?.map(p => (
                          <>
                            <EcomProductsList
                              product={p}
                              key={p.id}
                              onRemove={store.productStore.removeProduct}
                              setSelectedProduct={store.productStore.setSelectedProduct}
                              store={store}
                            />
                            <Divider />
                          </>
                        ))
                      }
                    ]}
                  />
                </>
              )}

              {store.productStore.posProducts?.length > 0 && !store.productStore.showProductEditModal && (
                <>
                  <Collapse
                    className={classNames(styles.styledCollapse, styles.mt15)}
                    defaultActiveKey={1}
                    items={[
                      {
                        key: 1,
                        label: translations().posList,
                        children: store.productStore.posProducts?.map(p => (
                          <>
                            <PosProductsList
                              product={p}
                              key={p.id}
                              onRemove={store.productStore.removeProduct}
                              setSelectedProduct={store.productStore.setSelectedProduct}
                              store={store}
                            />
                            <Divider />
                          </>
                        ))
                      }
                    ]}
                  />

                  <Collapse
                    className={classNames(styles.styledCollapse, styles.mt15)}
                    items={[
                      {
                        key: 1,
                        label: translations().posDeliveryAddress,
                        children: (
                          <Form
                            form={formDelivery}
                            layout='vertical'
                            initialValues={{
                              posDeliveryAddress: {
                                country:
                                  store.dataStore?.application?.companyProfile?.onboardingCountryInfo?.value || UK
                              }
                            }}
                            className={styles.generalInformationForm}
                            onValuesChange={() => store.productStore.saveDeliveryInfo(formDelivery)}
                            disabled={store.dataStore.isDocusignSent}
                          >
                            <PosDeliveryInfo
                              form={formDelivery}
                              storeForm={form}
                              companyProfile={store.dataStore.application?.companyProfile}
                              onboardingSettings={store.onboardingSettings}
                              posDeliveryAddress={store.productStore.productDeliveryAddress}
                              posDeliveryAddressType={store.productStore.posDeliveryType}
                              posDeliveryInstructions={store.productStore.productDeliveryInstructions}
                              contactPerson={store.productStore.contactPersonInfo}
                              deliveryPhoneNumber={store.productStore.productDeliveryNumber}
                              saveDeliveryInfo={store.productStore.saveDeliveryInfo}
                            />
                          </Form>
                        )
                      }
                    ]}
                  />
                </>
              )}

              <CustomButton
                className={styles.mt15}
                type='primary'
                disabled={store.productStore.products?.length === 0 || store.dataStore.isDocusignSent}
                loading={store.dataStore.isSaveLoading}
                onClick={async () => {
                  await formDelivery.validateFields()
                  store.productStore.saveStore(form)
                }}
              >
                {translations().save}
              </CustomButton>
            </StartAppContainer>
          )}
        </>
      ) : (
        <StartAppContainer>
          {store.productStore.shouldShowAcquisitionChannelForm && (
            <Form
              layout='vertical'
              form={formContact}
              className={styles.generalInformationForm}
              initialValues={store.dataStore.contactInfoData}
              disabled={store.dataStore.isDocusignSent}
            >
              <Form.Item name='acquisitionChannel' label={translations2().applicationSourceLabel}>
                <Select loading={store.contactInfoStore.isAcquisitionChannelsLoading} disabled>
                  {store.contactInfoStore.acquisitionChannels.map(option => (
                    <Select.Option key={option.code} value={option.code}>
                      {option.value}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item noStyle shouldUpdate>
                {t =>
                  store.productStore.isSalesforceOpportunityIDVisible(t.getFieldValue('acquisitionChannel')) && (
                    <Form.Item
                      name='salesforceOpportunityID'
                      label={translations2().salesforceOpportunityIDLabel}
                      rules={[
                        {
                          required: true,
                          message: translations2().salesforceOpportunityIDRequired
                        },
                        {
                          min: 15,
                          message: translations2().salesforceOpportunityIDMin
                        },
                        {
                          validator: async (rule, value) => {
                            let errMessage = ''
                            if (value) errMessage = await store.contactInfoStore.checkSalesforceOpportunityID(value)
                            if (errMessage) {
                              form.setFields([{ name: 'salesforceOpportunityID', errors: [errMessage] }])

                              throw new Error(errMessage)
                            }
                          }
                        }
                      ]}
                      validateTrigger='onChange'
                    >
                      <Input placeholder='0063M000003ukUuQAI' maxLength={18} />
                    </Form.Item>
                  )
                }
              </Form.Item>

              <Divider />
            </Form>
          )}
          <StoresList
            stores={store.productStore.stores}
            selectStore={e => {
              formProduct.resetFields()
              form.resetFields()
              store.bankAccountStore.reset()
              store.productStore.resetProducts()
              store.productStore.setSelectedStore(e)
            }}
            deleteStore={store.productStore.deleteStore}
            onSave={async () => {
              if (await store.productStore.validateContactFields(formContact))
                store.onFinishProductsInformation(
                  store.productStore.stores?.filter(st => st.removable),
                  'isSaveLoading'
                )
            }}
            isSaveLoading={store.dataStore.isSaveLoading}
            posModels={store.posModelsFull}
            isDocusignSent={store.dataStore.isDocusignSent}
          />

          {store.productStore.productAddMessage !== '' && store.productStore.productAddMessage?.length > 0 && (
            <Col xs={24}>
              <div className={styles.errMsg}>{store.productStore.productAddMessage}</div>
            </Col>
          )}
        </StartAppContainer>
      )}
    </>
  )
})
