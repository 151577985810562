import React from 'react'
import { observer } from 'mobx-react'
import { useInjection } from 'dna-react-ioc'
import { TeammatesContainer, TTeammatesStore } from '~/code/containers/Teammates'

import { PartnerTeammateDrawer } from '~/code/pages/PartnerTeammates/components'
import { PermissionMap } from '~/code/constants/PermissionMap'

export const PartnerTeammatesStoreSymbol = Symbol('PartnerTeammatesStore')

export const PartnerTeammates = observer(() => {
  const store = useInjection<TTeammatesStore>(PartnerTeammatesStoreSymbol)
  return (
    <TeammatesContainer
      store={store}
      type={'partner'}
      permissionSet={PermissionMap.partners.teammates}
      drawer={() => (
        <PartnerTeammateDrawer
          store={store}
          open={store.isUserDrawerOpen}
          onClose={() => store.setIsUserDrawerOpen(false)}
        />
      )}
    />
  )
})
