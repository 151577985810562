export enum DNAPlatformEnum {
    DASHBOARD = 'dashboard',
    MERCHANT_PORTAL = 'mp'
}

export enum NotificationTypeEnum {
    BLOCK = 'full_block',
    POPUP = 'popup',
    BANNER = 'banner'
}

export interface NotificationArgsType {
    descriptionLine1: string
    descriptionLine2: string
    descriptionLine3: string
    descriptionLine4: string
    id: string
    system: string
    title: string
    type: string
    displayType: string
    systemId: DNAPlatformEnum
    notificationTypeId: string
    notificationDisplayTypeId: NotificationTypeEnum
    notificationLevelId: string
    notificationContentAreaId: string
    actionButtonUrl: string,
    actionButtonName: string
    createdAt: string
    contentArea: string
    contentAreaId: string
    level: string
    name: string
    isActive: boolean
    isClosable: boolean
    updatedAt: string
}
