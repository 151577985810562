import { getStorageNotifications, updateStorageNotifications } from '~/code/hooks'
import storage from '~/code/services/storage'

export const syncWebSocketNotifications = (websocketNotifications: [], type: 'auth' | 'unauth', userId?: string) => {
    if (!websocketNotifications || !type) {
        return
    }

    // Determine the user key
    const email = storage.get('email')
    const storageKey = userId || email || 'unauthorized'

    // Get all notifications from local storage
    const allNotifications = getStorageNotifications()

    // Ensure the user and type structure exists
    const userNotifications = allNotifications[storageKey] || { auth: [], unauth: [] }
    const currentNotifications = userNotifications[type] || []

    // Create a set of notification IDs from the WebSocket
    const websocketNotificationIds = new Set(websocketNotifications.map((n: any) => n.id))

    // Retain all old notifications that exist in the WebSocket notifications
    const retainedNotifications = currentNotifications.filter((storedNotification) =>
        websocketNotificationIds.has(storedNotification.id)
    )

    // Add new notifications from WebSocket if they don't already exist in the retained notifications
    // Update the notifications for the given type
    userNotifications[type] = [
        ...retainedNotifications,
        ...websocketNotifications.filter(
          (wsNotification: any) =>
            !retainedNotifications.some(
              (retainedNotification) => retainedNotification.id === wsNotification.id
            )
        )
    ]
    allNotifications[storageKey] = userNotifications

    // Save back to local storage
    updateStorageNotifications(allNotifications)
}
