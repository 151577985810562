import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { ContactInfoForm, PreStartPage, StartAppContainer } from '..'
import { ContactInfoProps } from './props'
import styles from './ContactInfo.scss'

export const ContactInfo = observer(({ store }: ContactInfoProps) => {
  const { contactInfoStore } = store

  useEffect(() => {
    contactInfoStore.setIsStartTypeChoosen(false)
  }, [])

  useEffect(() => {
    store.dataStore.applicationId && contactInfoStore.setIsStartTypeChoosen(true)
  }, [store.dataStore.applicationId])

  return (
    <>
      {!store.dataStore.applicationId && (
        <div className={styles.styledCard}>
          <PreStartPage handlePreStart={contactInfoStore.handlePreStart} />
        </div>
      )}
      {contactInfoStore.isStartTypeChoosen && (
        <StartAppContainer>
          <ContactInfoForm
            initialValues={contactInfoStore.contactInfoData}
            isMoreFormFieldsVisible={contactInfoStore.isMoreFormFieldsVisible}
            isApplicationWaitingForSubmission={contactInfoStore.isApplicationWaitingForSubmission}
            isNextButtonLoading={contactInfoStore.isLoading || contactInfoStore.isCheckSalesforceOpportunityIDLoading}
            isAcquisitionChannelsLoading={contactInfoStore.isAcquisitionChannelsLoading}
            acquisitionChannels={contactInfoStore.acquisitionChannels}
            onEmailChange={contactInfoStore.onEmailChange}
            onFinish={contactInfoStore.setContactInfoData}
            onContinue={contactInfoStore.loadApplicationById}
            checkSalesforceOpportunityID={contactInfoStore.checkSalesforceOpportunityID}
            checkForExistingEmail={contactInfoStore.checkForExistingEmail}
            segmentCategories={store.segmentCategoryOptions}
            isSegmentCategoryLoading={store.segmentCategoryLoading}
            segmentSubCategories={store.segmentSubCategoryOptions}
            isSegmentSubCategoryLoading={store.segmentCategoryLoading}
            onSelectCategory={store.onSelectSegmentCategory}
            selectedCategoryID={store.selectedCategoryID}
            onSelectSubCategory={store.onSelectSegmentSubCategory}
            selectedSubCategoryID={store.selectedSubCategoryID}
            onSelectPartner={store.onPartnerSelect}
            obrCountries={store.obrCountries}
            isObrCountriesLoading={store.isObrCountriesLoading}
            onboardingSettings={store.onboardingSettings}
            companyProfile={store.dataStore.application?.companyProfile}
            setStartType={store.contactInfoStore.setIsStartTypeChoosen}
            setCountry={store.setSelectedOnboardingCountry}
            country={store.selectedOnboardingCountry}
            getOppOwnerInfo={store.getOppIdOwnerInfo}
            oppOwnerInfo={store.oppOwnerInfo}
            isOwnerInfoLoading={store.isOppOwnerInfoLoading}
            taskId={store.dataStore.applicationId}
            updateContactInfo={store.dataStore.updateContactInfoData}
            onSave={store.dataStore.saveApplication}
            isSaveLoading={store.dataStore.isSaveLoading}
            isViewMode={Boolean(store.dataStore.applicationId)}
            startType={store.dataStore.startType}
            onSelectCategoryByValue={store.onSelectSegmentCategoryByValue}
            onSelectSubCategoryByValue={store.onSelectSegmentSubCategoryByValue}
            isPartnerRequired={store.selectedSubCategoryPartnerRequired}
            isDocusignSent={store.dataStore.isDocusignSent}
          />
        </StartAppContainer>
      )}
    </>
  )
})
