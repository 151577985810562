import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { Button, Card, Col, Form, Input, Modal, Row, Select, Tabs } from 'antd'
import { NamePath } from 'antd/lib/form/interface'
import { useInjection } from 'dna-react-ioc'
import { goToRoute } from '~/code/startup/Router/utils'
import { Routes } from '~/code/startup/Router/Routes'
import { IManageNotificationTemplateStore } from '~/code/pages/Notifications'
import { NotificationDisplayTypeEnum } from '~/code/stores/NotificationManagementStore'
import {
  getPreviewComponents, formLayout
} from '~/code/pages/Notifications/ManageNotificationTemplates/components/MaintenanceSetupStep/constants'
import translations from './translations'

export const ManageNotificationTemplateStoreSymbol = Symbol('ManageNotificationTemplateStoreSymbol')

export const MaintenanceSetupStep = observer(() => {
  const store = useInjection<IManageNotificationTemplateStore>(ManageNotificationTemplateStoreSymbol)
  const [isCancelModalOpen, setCancelModal] = useState(false)
  const [isSaveDisabled, setSaveDisabled] = useState(true)
  const {labelCol} = formLayout

  const {
    isTemplateCreating,
    isOptionsDataLoading,
    isContentAreasLoading,
    setNotificationSetupForm,
    submitTemplateForm,
    notificationSetupForm,
    contentAreaOptions,
    displayTypeOptions,
    levelsOptions,
    systemOptions,
    notificationTypesOptions,
    previewTemplate,
    selectedTemplate,
    isDashboard
  } = store
  const [form] = Form.useForm()

  useEffect(() => {
    checkFormValidity()
  }, [])

  const items = getPreviewComponents(isDashboard, previewTemplate, notificationSetupForm)

  const onFormSubmit = async () => {
    await submitTemplateForm()
    goToRoute(Routes.NOTIFICATIONS_MANAGEMENT_MANAGE_TEMPLATES)
  }

  const checkFormValidity = () => {
    let allValues = {}
    const fieldsError = form.getFieldsError()
    const hasErrors = fieldsError.some(({ errors }) => errors.length > 0)
    const displayType = form.getFieldValue('notificationDisplayTypeId' as NamePath)
    const fields: NamePath[] = ['title', 'systemId', 'notificationDisplayTypeId', 'notificationContentAreaId', 'description', 'notificationLevelId'] as NamePath[]

    switch (displayType) {
      case NotificationDisplayTypeEnum.BANNER:
        allValues = form.getFieldsValue(fields)
        break
      case NotificationDisplayTypeEnum.POPUP:
        allValues = form.getFieldsValue(fields.filter((field) => field !== 'notificationLevelId'))
        break
      case NotificationDisplayTypeEnum.BLOCK:
        allValues = form.getFieldsValue(fields.filter((field) => field !== 'notificationLevelId'))
        break
      default: allValues = form.getFieldsValue(fields)
    }

    const requiredFieldsFilled = Object.values(allValues).every(value => value !== undefined && value !== '')
    const formIsValid = !hasErrors && requiredFieldsFilled

    setSaveDisabled(!formIsValid)
  }

  const onFormValuesChange = (changedValues, values) => {
    const {systemId} = values

    if (changedValues['systemId']) {
      const newValues = {...values,
        ...values,
        notificationDisplayTypeId: undefined,
        notificationLevelId: undefined,
        notificationContentAreaId: undefined,
        title: undefined,
        description: undefined,
        actionButtonUrl: undefined,
        actionButtonName: undefined
      }

      form.setFieldsValue(newValues)
      setNotificationSetupForm(newValues)
    }

    if (changedValues['notificationDisplayTypeId']) {
      let newValues = null

      switch(changedValues['notificationDisplayTypeId']) {
        case NotificationDisplayTypeEnum.BANNER:
          newValues = {...values,
            notificationLevelId: 'info',
            notificationContentAreaId: 'login',
          }
          break
        case NotificationDisplayTypeEnum.POPUP:
          newValues = {...values,
            notificationLevelId: undefined,
            notificationContentAreaId: 'auth'
          }
          break
        case NotificationDisplayTypeEnum.BLOCK:
          if (systemId === 'dashboard') {
            newValues = {...values,
              notificationLevelId: undefined,
              notificationContentAreaId: undefined
            }
          } else newValues = {...values,
            notificationLevelId: undefined,
            notificationContentAreaId: 'app'
          }
          break
        default:
          newValues = {...values,
            notificationLevelId: undefined,
            notificationContentAreaId: undefined
          }
      }
      form.setFieldsValue(newValues)
      setNotificationSetupForm(newValues)

    } else {
      setNotificationSetupForm(values)
    }

    setTimeout(checkFormValidity, 0);
  }

  return (
    <>
      <Row gutter={[8, 8]}>
        <Col xl={12} lg={24} md={24} sm={24} xs={24}>
          <Card title={translations().notificationDetails}>
            <Form
              labelCol={labelCol}
              form={form}
              onValuesChange={(changedValues, values) => onFormValuesChange(changedValues, values)}
              onFinish={onFormSubmit}
              initialValues={notificationSetupForm}
              validateTrigger={'onChange'}
            >
              <Form.Item shouldUpdate noStyle>
                {() => {
                  return selectedTemplate && selectedTemplate.id && notificationSetupForm && notificationSetupForm.notificationTypeId ? (
                    <Form.Item
                      label={translations().notificationType}
                      name='notificationTypeId'
                      rules={[{required: true, message: translations().levelRequired}]}
                    >
                      <Select disabled={true} options={notificationTypesOptions}/>
                    </Form.Item>
                  ) : null
                }}
              </Form.Item>
              <Form.Item
                label={translations().system}
                name='systemId'
                rules={[{required: true, message: translations().systemRequired}]}
              >
                <Select options={systemOptions} loading={isOptionsDataLoading}/>
              </Form.Item>
              <Form.Item
                label={translations().displayType}
                name='notificationDisplayTypeId'
                rules={[{required: true, message: translations().displayTypeRequired}]}
              >
                <Select loading={isOptionsDataLoading} options={displayTypeOptions}  disabled={!notificationSetupForm || !notificationSetupForm.systemId} />
              </Form.Item>
              <Form.Item shouldUpdate noStyle>
                {() => {
                  return form.getFieldValue('notificationDisplayTypeId' as NamePath) ===
                  'banner' ? (
                    <Form.Item
                      label={translations().level}
                      name='notificationLevelId'
                      rules={[{required: true, message: translations().levelRequired}]}
                    >
                      <Select options={levelsOptions}/>
                    </Form.Item>
                  ) : null
                }}
              </Form.Item>
              <Form.Item
                label={translations().contentArea}
                name='notificationContentAreaId'
                rules={[{required: true, message: translations().contentAreaRequired}]}
              >
                <Select
                  loading={isContentAreasLoading}
                  disabled={!notificationSetupForm || !notificationSetupForm.systemId}
                  options={contentAreaOptions}
                />
              </Form.Item>
              <Form.Item
                label={translations().templateTitle}
                name='title'
                validateTrigger={'onChange'}
                rules={[
                  {
                    required: true,
                    message: translations().templateDescriptionRequired,
                  },
                  {
                    max: 200,
                    message: translations().charactersRestrictions(200),
                  },
                  {
                    validator: (_, value) => {
                      // Skip custom validation if the field is empty, letting the `required` rule handle it
                      if (!value) {
                        return Promise.resolve();
                      }
                      if (value.trim().length === 0) {
                        return Promise.reject(translations().validTitle);
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input maxLength={35} showCount={true}/>
              </Form.Item>
              <Form.Item
                label={translations().templateDescription}
                name='description'
                validateTrigger={'onChange'}
                rules={[
                  {
                    required: true,
                    message: translations().templateDescriptionRequired,
                  },
                  {
                    max: 200,
                    message: translations().charactersRestrictions(200),
                  },
                  {
                    validator: (_, value) => {
                      // Skip custom validation if the field is empty, letting the `required` rule handle it
                      if (!value) {
                        return Promise.resolve();
                      }
                      if (value.trim().length === 0) {
                        return Promise.reject(translations().validDescription);
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input.TextArea maxLength={200} showCount={true}/>
              </Form.Item>
              <Form.Item shouldUpdate noStyle>
                {() => {
                  return form.getFieldValue('notificationDisplayTypeId' as NamePath) ===
                  'popup' ? (
                    <>
                      <Form.Item
                        label={translations().buttonUrl}
                        name='actionButtonUrl'
                        rules={[
                          {
                            pattern: new RegExp(/^(https?:\/\/)?([\w-]+(\.[\w-]+)+)([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?$/),
                            message: translations().validUrl,
                          }
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label={translations().buttonName}
                        name='actionButtonName'
                        rules={[
                          {
                            max: 20,
                            message: translations().charactersRestrictions(20),
                          },
                        ]}
                      >
                        <Input maxLength={20} showCount={true}/>
                      </Form.Item>
                    </>
                  ) : null
                }}
              </Form.Item>
              <Row gutter={12} justify={'end'}>
                <Col>
                  <Form.Item>
                    <Button
                      onClick={() => setCancelModal(true)}
                    >
                      {translations().cancel}
                    </Button>
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item>
                    <Button
                      loading={isTemplateCreating}
                      type={'primary'}
                      htmlType={'submit'}
                      disabled={isSaveDisabled}
                    >{translations().save}</Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
        <Col xl={12} lg={24} md={24} sm={24} xs={24}>
          <Card title={translations().preview}>
            <Tabs defaultActiveKey="1" items={items}/>
          </Card>
        </Col>
      </Row>

      <Modal
        width={310}
        title={translations().cancelTemplateTitle}
        open={isCancelModalOpen}
        onOk={() => goToRoute(Routes.NOTIFICATIONS_MANAGEMENT_MANAGE_TEMPLATES)}
        onCancel={() => setCancelModal(false)}
      >
        {translations().cancelTemplateContent}
      </Modal>
    </>
  )
})
