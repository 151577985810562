import React from 'react'
import styles from './Info.scss'
import { InfoPropsType } from './props'

export const Info: React.FC<InfoPropsType> = props => {
  const { title, value, bordered } = props
  return (
    <div className={styles.Info}>
      <span className={styles.styledSpan}>{title}</span>
      <p className={styles.styledP}>{value}</p>
      {bordered && <em />}
    </div>
  )
}
