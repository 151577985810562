import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { useInjection } from 'dna-react-ioc'
import { PageHeaderRaw, ReloadButton } from '~/code/components'
import {
  Button,
  Card,
  Col,
  Drawer,
  Modal,
  Pagination,
  Row,
  Space,
  Table,
  TablePaginationConfig,
  Typography
} from 'antd'
import moment from 'moment-timezone'
import { PlusOutlined } from '@ant-design/icons'
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint'
import { ManageNotificationsFilter } from '~/code/pages/Notifications/ManageNotifications/components'
import { IManageNotificationsStore } from '~/code/pages/Notifications/ManageNotifications/IManageNotificationsStore'
import { generateTablePaginationProps } from '~/code/components/table/DefaultPagination/services'
import { TemplateDetails } from '~/code/pages/Notifications/ManageNotificationTemplates/components/TemplateDetails'
import { NotificationSetupFormType } from '~/code/stores/NotificationManagementStore'
import { hasPermissions } from '~/code/services/auth'
import { PermissionMap } from '~/code/constants/PermissionMap'
import { getColumns } from './constants'
import translations from './translations'
import styles from './styles.scss'

export const ManageNotificationsStoreSymbol = Symbol('ManageNotificationsStoreSymbol')

export const ManageNotifications = observer(() => {
  const store = useInjection<IManageNotificationsStore>(ManageNotificationsStoreSymbol)
  const [deactivatingId, setDeactivatingId] = useState(null)

  const {
    isNotificationsLoading,
    isDeactivatingModalOpen,
    isDashboard,
    notifications,
    filter,
    setFilter,
    total,
    previewTemplate,
    isDrawerOpened,
    notificationSetupForm,
    selectedTemplate,
    onDrawerClose,
    goToPublishNotification,
    setDeactivatingModal,
    turnOffNotification,
    loadNotifications
  } = store

  useEffect(() => {
    loadNotifications()
  }, [])

  const screens = useBreakpoint()

  const paginationProps: TablePaginationConfig = generateTablePaginationProps(
    filter.page,
    filter.size,
    total,
    (page: number, pageSize: number) => setFilter({page, size: pageSize}, true)
  )

  const onDeactivateNotification = () => {
    turnOffNotification(deactivatingId as string)
    setDeactivatingModal(false)
  }

  const onDeactivatingActionClick = (id: string) => {
    setDeactivatingId(id)
    setDeactivatingModal(true)
  }

  const columns = getColumns(store, onDeactivatingActionClick)

  const notificationDetails = () => {
    const notification = notifications.find((notification) => notification.notificationTemplateId === selectedTemplate?.id)

    return [
      {
        title: translations().startDate,
        description: moment(notification?.startDate).format('YYYY-DD-MM HH:mm') ,
      },
      {
        title: translations().endDate,
        description: moment(notification?.endDate).format('YYYY-DD-MM HH:mm'),
      },
      {
        title: translations().status,
        description: notification?.status,
      },
      {
        title: translations().title,
        description: selectedTemplate?.title,
      },
      {
        title: translations().system,
        description: selectedTemplate?.system,
      },
      {
        title: translations().description,
        description: (<div className={styles.templateDescription}>{selectedTemplate?.descriptionLine1}</div>),
      },
      {
        title: translations().type,
        description: selectedTemplate?.type,
      },
      {
        title: translations().displayType,
        description: selectedTemplate?.displayType,
      },
      {
        title: translations().contentArea,
        description: selectedTemplate?.contentArea,
      },
      {
        title: translations().userType,
        description: notification?.userType ? translations()[notification?.userType] : undefined
      },
    ]
  }

  return (
    <>
      <Space direction="vertical" size="large" style={{ display: 'flex' }}>
        <PageHeaderRaw>
          <Space direction={'vertical'} size={'small'}>
            <Row>
              <Col>
                <Typography.Title className={styles.pageTitle} level={5}>{translations().manageNotifications}</Typography.Title>
              </Col>
            </Row>
          </Space>
        </PageHeaderRaw>

        <Card className={styles.filterCard}>
          <ManageNotificationsFilter/>
        </Card>

        <Card>
          <Space direction="vertical" size={'middle'} style={{ display: 'flex' }}>
            <Row justify={'end'}>
              <Col className={styles.refreshBtn}>
                <ReloadButton onClick={() => setFilter({...filter}, true)}/>
              </Col>
              <Col>
                <Button
                  disabled={!hasPermissions([PermissionMap.notifications.create])}
                  type={'primary'}
                  icon={<PlusOutlined />}
                  onClick={() => goToPublishNotification()}
                >
                  {translations().newNotification}
                </Button>
              </Col>
            </Row>
            <Row>
              <Col xs={24}>
                <Table
                  loading={isNotificationsLoading}
                  dataSource={notifications}
                  columns={columns}
                  pagination={false}
                  rowKey={({id}) =>id}
                  scroll={{ x: 1000, y: 1000 }}
                />
              </Col>
            </Row>
            <Row justify={'end'}>
              <Col className={styles.refreshBtn}>
                <ReloadButton onClick={() => setFilter({...filter}, true)}/>
              </Col>
              <Col>
                <Pagination className={styles.bottomPagination} {...paginationProps} disabled={false} />
              </Col>
            </Row>
          </Space>
        </Card>

        <Modal
          width={310}
          title={translations().deactivateTitle}
          open={isDeactivatingModalOpen}
          onOk={onDeactivateNotification}
          onCancel={() => setDeactivatingModal(false)}
        >
          {translations().deactivateContent}
        </Modal>

        <Drawer
          closable
          destroyOnClose
          title={translations().notificationDetails}
          placement="right"
          open={isDrawerOpened}
          onClose={onDrawerClose}
          width={screens.xs ? 360 : 620}
        >
          <TemplateDetails
            data={notificationDetails()}
            isDashboard={isDashboard}
            previewTemplate={previewTemplate}
            notificationSetupForm={notificationSetupForm as NotificationSetupFormType}
          />
        </Drawer>
      </Space>

    </>
  )
})