import { action, autorun, computed, makeObservable, observable, reaction, runInAction } from 'mobx'
import {
  IAnalyticsOverviewStore,
  MerchantsBreakdownData,
  SummaryRowDataPercentage,
  SummaryRowData
} from '~/code/pages/Acquiring/pages/Analytics/components/AnalyticsOverview'
import { IssuingBanksBreakdownData } from '~/code/pages/Acquiring/pages/Analytics/components/AnalyticsOverview/components/breakdown/IBBreakdown/models'
import { MCCBreakdownData } from '~/code/pages/Acquiring/pages/Analytics/components/AnalyticsOverview/components/breakdown/MCCBreakdown/models'
import {
  PCSBreakdownData,
  PCSDataTypeBreakdown
} from '~/code/pages/Acquiring/pages/Analytics/components/AnalyticsOverview/components/breakdown/PCSBreakdown/models'
import { YearsComparisonData } from '~/code/pages/Acquiring/pages/Analytics/components/AnalyticsOverview/components/YearsComparison/models'
import moment from 'moment-timezone'
import { AnalyticsOverviewSummaryRequest } from '~/code/models/analytics/AnalyticsOverviewSummaryRequest'
import {
  fetchAnalyticsEcomApprovalRateSummary,
  fetchAnalyticsStatusSummary,
  fetchAnalyticsYearsComparison,
  fetchEcomErrorCodes,
  fetchTopAcquisitionChannelsSummary,
  fetchTopCardSchemesSummary,
  fetchTopIssuingBanksSummary,
  fetchTopMCCSummary,
  fetchTopMerchantsSummary,
  fetchTopPaymentMethodsSummary,
  fetchTransactionsSummary
} from '~/code/services/fetchers'
import { error as errorLog, log } from 'dna-common'
import { AnalyticsYearsComparisonRequest } from '~/code/models/analytics/AnalyticsYearsComparisonRequest'
import translations from '~/code/translations/translations'
import { EntitySummary, EntitySummaryGroup, StatusSummaryGroup } from '~/code/models/analytics/AnalyticsSummaryResponse'
import React from 'react'
import numeral from 'numeral'
import { PaymentMethodsBreakdownData } from '~/code/pages/Acquiring/pages/Analytics/components/AnalyticsOverview/components/breakdown/PaymentMethodsBreakdown/models'
import {
  filterIntervalOptions,
  generateBackgroundColorsForCardTypes,
  getYearsComparisonData,
  processApprovalRates,
  sumSummaryRowData,
  getLabel
} from '~/code/stores/AnalyticsStore/services/utils'
import { IAcquiringFilterStore } from '~/code/pages/Acquiring/components/AcquiringFilter'
import { AcquiringType } from '~/code/pages/Acquiring'
import { backgroundColors } from './constants/colors-icons'
import { PaymentMethods } from '~/code/stores/AnalyticsStore/constants/PaymentMethods'
import { StatusType } from '~/code/stores/AnalyticsStore/models/StatusType'
import { IntervalType } from './models/IntervalType'
import {
  generateTransactionsLineChartData,
  TransactionsLineChartData
} from '~/code/pages/Acquiring/pages/Analytics/components/AnalyticsOverview/components/breakdown/TransactionsLineChart/models'
import { TabKey } from '~/code/pages/Acquiring/pages/Analytics/components/AnalyticsOverview/components/chart-related/SumCountCard/models'
import { isPartner } from '~/code/services/auth'
import { AcquisitionChannelsBreakdownData } from '~/code/pages/Acquiring/pages/Analytics/components/AnalyticsOverview/components/breakdown/AcquisitionChannelsBreakdown/models'
import { ErrorCodesEcomBreakdownData } from '~/code/pages/Acquiring/pages/Analytics/components/AnalyticsOverview/components/breakdown/ErrorCodesBreakdown/models'
import { GlobalConfigStore } from '../GlobalConfigStore'
import { Routes } from '~/code/startup/Router/Routes'
import { ErrorCodesEcomResType } from '~/code/models/analytics/AnalyticsEcomErrorCodes'
import { ApprovalRateData } from '~/code/models/analytics/AnalyticsApprovalRateSummary'
import storage from '~/code/services/storage'
import { cardSchemeIcons } from '~/code/services/payments/card-scheme'

export class AnalyticsOverviewStore implements IAnalyticsOverviewStore {
  constructor(filterStore: IAcquiringFilterStore, configStore: GlobalConfigStore, acquiringType: AcquiringType) {
    this.filterStore = filterStore
    this.configStore = configStore
    this.acquiringType = acquiringType
    this.route =
      acquiringType === 'dna' ? 'TRANSACTIONS_DNA_ACQUIRING_ANALYTICS' : 'TRANSACTIONS_OPTOMANY_CHECKOUT_ANALYTICS'

    makeObservable(this, {
      legendPosition: observable,
      isLoadingSummary: observable,
      isLoadingIssuingBanksAmountBreakdown: observable,
      isLoadingIssuingBanksCountBreakdown: observable,
      isLoadingMCCAmountBreakdown: observable,
      isLoadingMCCCountBreakdown: observable,
      isLoadingPCSAmountBreakdown: observable,
      isLoadingPCSCountBreakdown: observable,
      isLoadingYearsComparison: observable,
      summaryRowData: observable,
      summaryRowDataPercentage: observable,

      isLoadingApprovalRate: observable,
      approvalRateData: observable,

      isLoadingPaymentMethodsAmountBreakdown: observable,
      isLoadingPaymentMethodsCountBreakdown: observable,
      paymentMethodsBreakdownData: observable,
      isLoadingErrorCodesAmountBreakdown: observable,
      isLoadingErrorCodesCountBreakdown: observable,
      errorCodesBreakdownData: observable,

      transactionsSumStatus: observable,
      transactionsCountStatus: observable,
      transactionsCurrentTab: observable,
      _transactionsInterval: observable,
      transactionsLineChartData: observable,
      transactionsCurrentStatus: computed,
      transactionsInterval: computed,
      transactionsIntervalOptions: computed,
      isSolidGate: computed,

      isLoadingMerchantsAmountBreakdown: observable,
      isLoadingMerchantsCountBreakdown: observable,
      issuingBanksBreakdownData: observable,
      mccBreakdownData: observable,
      pcsBreakdownData: observable,

      isLoadingAcquisitionChannelsAmountBreakdown: observable,
      isLoadingAcquisitionChannelsCountBreakdown: observable,
      acquisitionChannelsBreakdownData: observable,

      yearsComparisonData: observable,
      yearsComparisonYear1: observable,
      yearsComparisonYear2: observable,
      loadStatusSummaryWrapper: action,
      loadYearsComparison: action,
      changeYearsComparisonYears: action,
      loadBreakdown: action,
      loadApprovalRateSummary: action,

      setTransactionsSumStatus: action.bound,
      setTransactionsCountStatus: action.bound,
      setTransactionsCurrentTab: action.bound,
      setTransactionsInterval: action.bound,
      loadTransactionsBreakdown: action,
      clearTransactionsLineChartData: action,
      setLegendPosition: action,
      refreshTable: action.bound,

      merchantId: computed,
      merchantName: computed,
      acquisitionChannelId: computed,
      statusSummaryPercentageChange: computed,
      currency: computed,
      currencySymbol: computed
    })
  }

  filterStore: IAcquiringFilterStore
  configStore: GlobalConfigStore
  acquiringType: AcquiringType
  route: string

  isLoadingSummary: boolean = false
  summaryRowData: SummaryRowData = null
  summaryRowDataPercentage: SummaryRowDataPercentage = null

  isLoadingApprovalRate: boolean = false
  approvalRateData: ApprovalRateData = null

  transactionsSumStatus: StatusType = 'all'
  transactionsCountStatus: StatusType = 'all'
  transactionsCurrentTab: TabKey = 'amount'
  _transactionsInterval: IntervalType = null
  transactionsLineChartData: TransactionsLineChartData = generateTransactionsLineChartData()

  isLoadingMerchantsAmountBreakdown: boolean = false
  isLoadingMerchantsCountBreakdown: boolean = false
  merchantsBreakdownData: MerchantsBreakdownData = null

  isLoadingIssuingBanksAmountBreakdown: boolean = false
  isLoadingIssuingBanksCountBreakdown: boolean = false
  issuingBanksBreakdownData: IssuingBanksBreakdownData = null

  isLoadingPaymentMethodsAmountBreakdown: boolean = false
  isLoadingPaymentMethodsCountBreakdown: boolean = false
  paymentMethodsBreakdownData: PaymentMethodsBreakdownData = null

  isLoadingErrorCodesAmountBreakdown: boolean = false
  isLoadingErrorCodesCountBreakdown: boolean = false
  errorCodesBreakdownData: ErrorCodesEcomBreakdownData = null

  isLoadingMCCAmountBreakdown: boolean = false
  isLoadingMCCCountBreakdown: boolean = false
  mccBreakdownData: MCCBreakdownData = null

  isLoadingPCSAmountBreakdown: boolean = false
  isLoadingPCSCountBreakdown: boolean = false
  pcsBreakdownData: PCSBreakdownData = null

  isLoadingAcquisitionChannelsAmountBreakdown: boolean = false
  isLoadingAcquisitionChannelsCountBreakdown: boolean = false
  acquisitionChannelsBreakdownData: AcquisitionChannelsBreakdownData = null

  isLoadingYearsComparison: boolean = false
  yearsComparisonData: YearsComparisonData = null
  yearsComparisonYear1: moment.Moment = moment().subtract(1, 'years')
  yearsComparisonYear2: moment.Moment = moment()
  shouldLoadData: boolean = true // by default the selected tab is analytics overview
  observablesHaveChanged: boolean = true // by default the selected tab is analytics overview
  legendPosition: string = 'bottom'

  public async init() {
    reaction(() => this.loadDataParams, this.loadData)

    reaction(() => this.transactionsInterval, this.loadTransactionsBreakdown.bind(this))

    autorun(
      () => {
        // if user is not on current page, do not make requests to backend on currency change
        if (window.location.pathname !== Routes[this.route]) return
        const merchant = this.merchantName
        const currency = this.currency
        if (this.yearsComparisonYear1 && this.yearsComparisonYear2 && merchant && this.shouldLoadData && currency) {
          this.loadYearsComparison({
            year1: this.yearsComparisonYear1.get('year').toString(),
            year2: this.yearsComparisonYear2.get('year').toString(),
            merchantId: this.merchantId,
            currency,
            acquisitionChannel: this.acquisitionChannelId,
            acquirer: this.isSolidGate ? 'paynetics_eu' : this.acquiringType
          })
        }
      },
      { delay: 5 }
    )

    autorun(
      () => {
        const { isMerchantListLoading, merchants } = this.filterStore.merchantSelectStore
        if (!isMerchantListLoading && merchants.length === 0) {
          this.isLoadingSummary = false
          this.isLoadingMerchantsAmountBreakdown = false
          this.isLoadingMerchantsCountBreakdown = false
          this.isLoadingIssuingBanksAmountBreakdown = false
          this.isLoadingIssuingBanksCountBreakdown = false
          this.isLoadingMCCAmountBreakdown = false
          this.isLoadingMCCCountBreakdown = false
          this.isLoadingPCSAmountBreakdown = false
          this.isLoadingPCSCountBreakdown = false
          this.isLoadingYearsComparison = false
        }
      },
      { delay: 1 }
    )

    autorun(() => {
      const {
        dateStore: { startDate, endDate }
      } = this.filterStore

      if (startDate && endDate) {
        this.setTransactionsInterval(this.transactionsIntervalOptions[1] || this.transactionsIntervalOptions[0])
      }
    })
  }

  public refreshTable() {
    this.setShouldLoadData(true)
  }

  loadData = ({ startDate, endDate, merchantName, currency, shouldLoadData, observablesHaveChanged }) => {
    // if user is not on current page, do not make requests to backend on currency change
    if (window.location.pathname !== Routes[this.route]) return

    if (startDate && endDate && merchantName && currency && shouldLoadData && observablesHaveChanged) {
      this.loadStatusSummaryWrapper()

      this.clearTransactionsLineChartData()
      this.loadTransactionsBreakdown()
      this.loadBreakdown(
        { ...this.summaryRequestParams, orderBy: 'amount' },
        'merchant',
        this.setMerchantsBreakdownData,
        this.setLoadingMerchantsAmountBreakdown,
        fetchTopMerchantsSummary
      )
      this.loadBreakdown(
        { ...this.summaryRequestParams, orderBy: 'count' },
        'merchant',
        this.setMerchantsBreakdownData,
        this.setLoadingMerchantsCountBreakdown,
        fetchTopMerchantsSummary
      )
      this.loadBreakdown(
        { ...this.summaryRequestParams, orderBy: 'amount' },
        'issuer',
        this.setIssuingBanksBreakdownData,
        this.setLoadingIssuingBanksAmountBreakdown,
        fetchTopIssuingBanksSummary,
        true
      )
      this.loadBreakdown(
        { ...this.summaryRequestParams, orderBy: 'count' },
        'issuer',
        this.setIssuingBanksBreakdownData,
        this.setLoadingIssuingBanksCountBreakdown,
        fetchTopIssuingBanksSummary,
        true
      )
      this.loadBreakdown(
        { ...this.summaryRequestParams, orderBy: 'amount' },
        'paymentMethod',
        this.setPaymentMethodsBreakdownData,
        this.setLoadingPaymentMethodsAmountBreakdown,
        fetchTopPaymentMethodsSummary,
        true
      )
      this.loadBreakdown(
        { ...this.summaryRequestParams, orderBy: 'count' },
        'paymentMethod',
        this.setPaymentMethodsBreakdownData,
        this.setLoadingPaymentMethodsCountBreakdown,
        fetchTopPaymentMethodsSummary,
        true
      )
      this.loadBreakdown(
        { ...this.summaryRequestParams, orderBy: 'amount', merchant: this.merchantId },
        'errorCode',
        this.setErrorCodesBreakdownData,
        this.setLoadingErrorCodesAmountBreakdown,
        fetchEcomErrorCodes,
        true
      )
      this.loadBreakdown(
        { ...this.summaryRequestParams, orderBy: 'count', merchant: this.merchantId },
        'errorCode',
        this.setErrorCodesBreakdownData,
        this.setLoadingErrorCodesCountBreakdown,
        fetchEcomErrorCodes,
        true
      )
      this.loadBreakdown(
        { ...this.summaryRequestParams, orderBy: 'amount' },
        'mcc',
        this.setMCCBreakdownData,
        this.setLoadingMCCAmountBreakdown,
        fetchTopMCCSummary,
        true
      )
      this.loadBreakdown(
        { ...this.summaryRequestParams, orderBy: 'count' },
        'mcc',
        this.setMCCBreakdownData,
        this.setLoadingMCCCountBreakdown,
        fetchTopMCCSummary,
        true
      )
      this.loadBreakdown(
        { ...this.summaryRequestParams, orderBy: 'amount' },
        'cardScheme',
        this.setPCSBreakdownData,
        this.setLoadingPCSAmountBreakdown,
        fetchTopCardSchemesSummary,
        true,
        cardSchemeIcons
      )
      this.loadBreakdown(
        { ...this.summaryRequestParams, orderBy: 'count' },
        'cardScheme',
        this.setPCSBreakdownData,
        this.setLoadingPCSCountBreakdown,
        fetchTopCardSchemesSummary,
        true,
        cardSchemeIcons
      )

      if (!isPartner()) {
        this.loadApprovalRateSummary()
        this.loadBreakdown(
          { ...this.summaryRequestParams, orderBy: 'amount' },
          'acquisitionChannel',
          this.setAcquisitionChannelsBreakdownData,
          this.setLoadingAcquisitionChannelsAmountBreakdown,
          fetchTopAcquisitionChannelsSummary,
          true
        )
        this.loadBreakdown(
          { ...this.summaryRequestParams, orderBy: 'count' },
          'acquisitionChannel',
          this.setAcquisitionChannelsBreakdownData,
          this.setLoadingAcquisitionChannelsCountBreakdown,
          fetchTopAcquisitionChannelsSummary,
          true
        )
      }
    } else {
      this.observablesHaveChanged = true
    }
  }

  // TODO DAS-359 acquirer
  get isSolidGate() {
    return isPartner() && storage.get('acquisition_channel') === 'solidgate'
  }

  get currency() {
    return this.configStore.currency.type
  }

  get currencySymbol() {
    return this.configStore.currency.symbol
  }

  get summaryRequestParams(): AnalyticsOverviewSummaryRequest {
    const {
      dateStore: { startDate, endDate }
    } = this.filterStore
    return {
      from: startDate.format(),
      to: endDate.format(),
      merchant: this.merchantName,
      acquirer: this.isSolidGate ? 'paynetics_eu' : this.acquiringType,
      currency: this.currency,
      top: 5,
      acquisitionChannel: this.acquisitionChannelId
    }
  }

  get prevStatusSummaryParams(): AnalyticsOverviewSummaryRequest {
    const {
      dateStore: {
        prevDates: { prevStartDate, prevEndDate }
      }
    } = this.filterStore
    return {
      from: prevStartDate.format(),
      to: prevEndDate.format(),
      merchant: this.merchantName,
      acquirer: this.isSolidGate ? 'paynetics_eu' : this.acquiringType,
      currency: this.currency,
      top: 5,
      acquisitionChannel: this.acquisitionChannelId
    }
  }

  get statusSummaryPercentageChange() {
    const totalSum = this.summaryRowDataPercentage?.current?.totalSum
    const totalCount = this.summaryRowDataPercentage?.current?.totalCount
    const prevTotalSum = this.summaryRowDataPercentage?.prev?.totalSum
    const prevTotalCount = this.summaryRowDataPercentage?.prev?.totalCount
    const amount = prevTotalSum && prevTotalSum > 0 ? ((totalSum - prevTotalSum) / prevTotalSum) * 100 : 0
    const count = prevTotalCount && prevTotalCount > 0 ? ((totalCount - prevTotalCount) / prevTotalCount) * 100 : 0
    return { amount, count }
  }

  get prevDates() {
    return this.filterStore.dateStore.prevDates
  }

  get merchantId() {
    return this.filterStore.merchantId
  }

  get merchantName() {
    return this.filterStore.merchantTradeName
  }

  get acquisitionChannelId() {
    if (isPartner() || this.filterStore.acquisitionChannelId === 'all') return undefined
    return this.filterStore.acquisitionChannelId
  }

  // TODO find an elegant solution for loading data on radio button change
  setShouldLoadData = (value: boolean) => {
    this.shouldLoadData = value
    if (value) {
      this.loadData(this.loadDataParams)
      this.observablesHaveChanged = true
    } else {
      this.observablesHaveChanged = false
    }
  }

  setTransactionsSumStatus(status: StatusType) {
    this.transactionsSumStatus = status
    this.loadTransactionsBreakdown()
  }

  setTransactionsCountStatus(status: StatusType) {
    this.transactionsCountStatus = status
    this.loadTransactionsBreakdown()
  }

  setTransactionsCurrentTab(tab: TabKey) {
    this.setLegendPosition(tab)
    this.transactionsCurrentTab = tab
    this.loadTransactionsBreakdown()
  }

  setTransactionsInterval(interval: IntervalType) {
    this._transactionsInterval = interval
  }

  get transactionsInterval(): IntervalType {
    return this._transactionsInterval
      ? this._transactionsInterval
      : this.transactionsIntervalOptions[1] || this.transactionsIntervalOptions[0]
  }

  get transactionsIntervalOptions(): IntervalType[] {
    return filterIntervalOptions(this.filterStore.dateStore.startDate, this.filterStore.dateStore.endDate)
  }

  get transactionsCurrentStatus(): StatusType {
    return this.transactionsCurrentTab === 'amount' ? this.transactionsSumStatus : this.transactionsCountStatus
  }

  setLoadingMerchantsAmountBreakdown = (value: boolean) => {
    this.isLoadingMerchantsAmountBreakdown = value
  }

  setLoadingMerchantsCountBreakdown = (value: boolean) => {
    this.isLoadingMerchantsCountBreakdown = value
  }

  setMerchantsBreakdownData = (data, fieldName) => {
    this.merchantsBreakdownData = { ...this.merchantsBreakdownData, [fieldName]: data }
  }

  setLoadingIssuingBanksAmountBreakdown = (value: boolean) => {
    this.isLoadingIssuingBanksAmountBreakdown = value
  }

  setLoadingIssuingBanksCountBreakdown = (value: boolean) => {
    this.isLoadingIssuingBanksCountBreakdown = value
  }

  setIssuingBanksBreakdownData = (data, fieldName) => {
    this.issuingBanksBreakdownData = { ...this.issuingBanksBreakdownData, [fieldName]: data }
  }

  setLoadingPaymentMethodsAmountBreakdown = (value: boolean) => {
    this.isLoadingPaymentMethodsAmountBreakdown = value
  }

  setLoadingPaymentMethodsCountBreakdown = (value: boolean) => {
    this.isLoadingPaymentMethodsCountBreakdown = value
  }

  setPaymentMethodsBreakdownData = (data, fieldName) => {
    const _data = {
      ...data,
      all: {
        ...data.all,
        labels: data.all.labels.map(label => PaymentMethods[label] || label)
      },
      successful: {
        ...data.successful,
        labels: data.successful.labels.map(label => PaymentMethods[label] || label)
      },
      failed: {
        ...data.failed,
        labels: data.failed.labels.map(label => PaymentMethods[label] || label)
      },
      other: {
        ...data.other,
        labels: data.other.labels.map(label => PaymentMethods[label] || label)
      }
    }
    this.paymentMethodsBreakdownData = { ...this.paymentMethodsBreakdownData, [fieldName]: _data }
  }

  setLoadingErrorCodesAmountBreakdown = (value: boolean) => {
    this.isLoadingErrorCodesAmountBreakdown = value
  }

  setLoadingErrorCodesCountBreakdown = (value: boolean) => {
    this.isLoadingErrorCodesCountBreakdown = value
  }

  setErrorCodesBreakdownData = (data, fieldName) => {
    this.errorCodesBreakdownData = { ...this.errorCodesBreakdownData, [fieldName]: data }
  }

  setLoadingMCCAmountBreakdown = (value: boolean) => {
    this.isLoadingMCCAmountBreakdown = value
  }

  setLoadingMCCCountBreakdown = (value: boolean) => {
    this.isLoadingMCCCountBreakdown = value
  }

  setMCCBreakdownData = (data, fieldName) => {
    this.mccBreakdownData = { ...this.mccBreakdownData, [fieldName]: data }
  }

  setLoadingPCSAmountBreakdown = (value: boolean) => {
    this.isLoadingPCSAmountBreakdown = value
  }

  setLoadingPCSCountBreakdown = (value: boolean) => {
    this.isLoadingPCSCountBreakdown = value
  }

  setPCSBreakdownData = (data: PCSDataTypeBreakdown, fieldName) => {
    const dataToSet = { ...data }
    dataToSet.all.datasets[0].backgroundColor = generateBackgroundColorsForCardTypes(dataToSet.all.labels)
    dataToSet.successful.datasets[0].backgroundColor = generateBackgroundColorsForCardTypes(dataToSet.successful.labels)
    dataToSet.failed.datasets[0].backgroundColor = generateBackgroundColorsForCardTypes(dataToSet.failed.labels)
    dataToSet.other.datasets[0].backgroundColor = generateBackgroundColorsForCardTypes(dataToSet.other.labels)
    this.pcsBreakdownData = { ...this.pcsBreakdownData, [fieldName]: data }
  }

  setLoadingAcquisitionChannelsAmountBreakdown = (value: boolean) => {
    this.isLoadingAcquisitionChannelsAmountBreakdown = value
  }

  setLoadingAcquisitionChannelsCountBreakdown = (value: boolean) => {
    this.isLoadingAcquisitionChannelsCountBreakdown = value
  }

  setAcquisitionChannelsBreakdownData = (data, fieldName) => {
    this.acquisitionChannelsBreakdownData = { ...this.acquisitionChannelsBreakdownData, [fieldName]: data }
  }

  loadStatusSummaryWrapper = async () => {
    this.isLoadingSummary = true
    await Promise.all([
      this.loadStatusSummary(this.summaryRequestParams, 'current'),
      this.loadStatusSummary(this.prevStatusSummaryParams, 'prev')
    ])
    this.isLoadingSummary = false
  }

  loadStatusSummary = async (params: AnalyticsOverviewSummaryRequest, statusSummaryType: 'current' | 'prev') => {
    const { error, result } = await fetchAnalyticsStatusSummary(params)
    if (error) {
      log('Error loading status summary:', error)
      runInAction(() => {
        this.isLoadingSummary = false
      })
      return
    }

    runInAction(() => {
      if (statusSummaryType === 'current') {
        this.processStatusSummaryRowData(result)
      } else {
        this.processPrevStatusSummaryRowData(result)
      }
    })
  }

  setLegendPosition = (tabKey: TabKey) => {
    if (tabKey === 'amount') {
      this.legendPosition = 'bottom'
    } else {
      this.legendPosition = ''
    }
  }

  loadApprovalRateSummary = async () => {
    runInAction(() => {
      this.isLoadingApprovalRate = true
    })
    const { error, result } = await fetchAnalyticsEcomApprovalRateSummary({
      ...this.summaryRequestParams,
      merchantId: this.merchantId,
      merchant: undefined,
      top: undefined
    })
    if (error) {
      log('Error loading approval rate summary:', error)
      runInAction(() => {
        this.isLoadingApprovalRate = false
      })
      return
    }

    runInAction(() => {
      this.approvalRateData = processApprovalRates(result, this.currencySymbol)
      this.isLoadingApprovalRate = false
    })
  }

  ////////// COMMON LOAD BREAKDOWN DATA ////////////
  loadBreakdown = async (
    params: AnalyticsOverviewSummaryRequest,
    uniqueFieldName,
    setData,
    setLoadingData,
    fetchFunction,
    shouldAddBackgroundColor?,
    icons?
  ) => {
    try {
      setLoadingData(true)
      const { err, result } = await fetchFunction(params)

      if (err) {
        errorLog(`Error loading ${uniqueFieldName} ${params.orderBy} summary:`, err)
        runInAction(() => {
          setLoadingData(false)
        })
        return
      }

      runInAction(() => {
        const data = (() => {
          switch (uniqueFieldName) {
            case 'errorCode':
              return this.processErrorCodesData(result, uniqueFieldName, params.orderBy, shouldAddBackgroundColor)
            case 'acquisitionChannel':
              return this.processData(
                this.modifyResult(result),
                uniqueFieldName,
                params.orderBy,
                shouldAddBackgroundColor
              )
            default:
              const processedData = this.processData(result, uniqueFieldName, params.orderBy, shouldAddBackgroundColor)  
              if (icons) {
                processedData.all.icons = processedData.all.labels.map(
                  label => icons[getLabel(label, uniqueFieldName)] || icons.unknown
                )
                processedData.successful.icons = processedData.successful.labels.map(
                  label => icons[getLabel(label, uniqueFieldName)] || icons.unknown
                )
                processedData.failed.icons = processedData.failed.labels.map(
                  label => icons[getLabel(label, uniqueFieldName)] || icons.unknown
                )
                processedData.other.icons = processedData.other.labels.map(
                  label => icons[getLabel(label, uniqueFieldName)] || icons.unknown
                )
              }
              return processedData
          }
        })()

        setData(data, params.orderBy)

        setLoadingData(false)
      })
    } catch (err) {
      errorLog(`Error loading ${uniqueFieldName} ${params.orderBy} summary`, err)
      runInAction(() => {
        setLoadingData(false)
      })
      return
    }
  }

  processData = (result: EntitySummaryGroup, uniqueFieldName, countAmount: string, shouldAddBackgroundColor?) => {
    const allTotal = result.all?.reduce((prevVal, currVal) => prevVal + currVal[countAmount], 0) || 0
    const successfulTotal = result.successful?.reduce((prevVal, currVal) => prevVal + currVal[countAmount], 0)
    const failedTotal = result.failed?.reduce((prevVal, currVal) => prevVal + currVal[countAmount], 0)
    const otherTotal = result.other?.reduce((prevVal, currVal) => prevVal + currVal[countAmount], 0)

    return {
      all: {
        total: allTotal,
        labels: result.all?.map(item => item[uniqueFieldName] || translations().unknown) || [],
        subLabels: result.all?.map(item => item[uniqueFieldName + 'Description']) || [],
        datasets: [
          {
            barThickness: 20,
            data: result.all?.map(item => item[countAmount]) || [],
            percents: result.all?.map(item => (item[countAmount] * 100) / allTotal) || [],
            backgroundColor: shouldAddBackgroundColor && backgroundColors
          }
        ],
        icons: undefined
      },
      successful: {
        total: successfulTotal,
        labels: result.successful?.map(item => item[uniqueFieldName] || translations().unknown),
        subLabels: result.successful?.map(item => item[uniqueFieldName + 'Description']),
        datasets: [
          {
            barThickness: 20,
            data: result.successful?.map(item => item[countAmount]),
            percents: result.successful?.map(item => (item[countAmount] * 100) / successfulTotal),
            backgroundColor: shouldAddBackgroundColor && backgroundColors
          }
        ],
        icons: undefined
      },
      failed: {
        total: failedTotal,
        labels: result.failed?.map(item => item[uniqueFieldName] || translations().unknown),
        subLabels: result.failed?.map(item => item[uniqueFieldName + 'Description']),
        datasets: [
          {
            barThickness: 20,
            data: result.failed?.map(item => item[countAmount]),
            percents: result.failed?.map(item => (item[countAmount] * 100) / failedTotal),
            backgroundColor: shouldAddBackgroundColor && backgroundColors
          }
        ],
        icons: undefined
      },
      other: {
        total: otherTotal,
        labels: result.other?.map(item => item[uniqueFieldName] || translations().unknown),
        subLabels: result.other?.map(item => item[uniqueFieldName + 'Description']),
        datasets: [
          {
            barThickness: 20,
            data: result.other?.map(item => item[countAmount]),
            percents: result.other?.map(item => (item[countAmount] * 100) / otherTotal),
            backgroundColor: shouldAddBackgroundColor && backgroundColors
          }
        ],
        icons: undefined
      }
    }
  }

  processErrorCodesData = (
    result: ErrorCodesEcomResType,
    uniqueFieldName,
    countAmount: string,
    shouldAddBackgroundColor?
  ) => {
    const responseCodesTotal =
      result.responseCodes?.reduce((prevVal, currVal) => prevVal + currVal[countAmount], 0) || 0
    const cscErrorsTotal = result.cscErrors?.reduce((prevVal, currVal) => prevVal + currVal[countAmount], 0)
    const threeDsecureErrorsTotal = result.threeDsecureErrors?.reduce(
      (prevVal, currVal) => prevVal + currVal[countAmount],
      0
    )

    return {
      responseCodes: {
        total: responseCodesTotal,
        labels: result.responseCodes?.map(item => item.value || translations().unknown) || [],
        subLabels: result.responseCodes?.map(item => item.description) || [],
        datasets: [
          {
            barThickness: 20,
            data: result.responseCodes?.map(item => item[countAmount]) || [],
            percents: result.responseCodes?.map(item => (item[countAmount] * 100) / responseCodesTotal) || [],
            backgroundColor: shouldAddBackgroundColor && backgroundColors
          }
        ],
        icons: undefined
      },
      cscErrors: {
        total: cscErrorsTotal,
        labels: result.cscErrors?.map(item => item.value || translations().unknown),
        subLabels: result.cscErrors?.map(item => item.description),
        datasets: [
          {
            barThickness: 20,
            data: result.cscErrors?.map(item => item[countAmount]),
            percents: result.cscErrors?.map(item => (item[countAmount] * 100) / cscErrorsTotal),
            backgroundColor: shouldAddBackgroundColor && backgroundColors
          }
        ],
        icons: undefined
      },
      threeDsecureErrors: {
        total: threeDsecureErrorsTotal,
        labels: result.threeDsecureErrors?.map(item => item.value || translations().unknown),
        subLabels: result.threeDsecureErrors?.map(item => item.description),
        datasets: [
          {
            barThickness: 20,
            data: result.threeDsecureErrors?.map(item => item[countAmount]),
            percents: result.threeDsecureErrors?.map(item => (item[countAmount] * 100) / threeDsecureErrorsTotal),
            backgroundColor: shouldAddBackgroundColor && backgroundColors
          }
        ],
        icons: undefined
      }
    }
  }

  modifyResult(result: EntitySummaryGroup) {
    return {
      all: result.all.map(item => this.processItem(item)),
      successful: result.successful.map(item => this.processItem(item)),
      failed: result.failed.map(item => this.processItem(item)),
      other: result.other.map(item => this.processItem(item))
    }
  }

  processItem(item: EntitySummary) {
    const acquisitionChannelId = item['acquisitionChannel']
    const acquisitionChannel = this.filterStore.acquisitionChannels.find(a => a.id === acquisitionChannelId)
    if (acquisitionChannel) {
      return {
        ...item,
        acquisitionChannel: acquisitionChannel.name
      }
    }

    return item
  }

  loadYearsComparison = async (params: AnalyticsYearsComparisonRequest) => {
    if (!this.merchantName) return

    this.isLoadingYearsComparison = true
    const { error, result } = await fetchAnalyticsYearsComparison('ecom', params)

    if (error) {
      log('Error:', error)
      runInAction(() => {
        this.isLoadingYearsComparison = false
      })
    }

    runInAction(() => {
      const _year1 = this.yearsComparisonYear1.get('year')
      const _year2 = this.yearsComparisonYear2.get('year')

      this.yearsComparisonData = getYearsComparisonData(_year1, _year2, [result])
      this.isLoadingYearsComparison = false
    })
  }

  changeYearsComparisonYears = (year1, year2) => {
    this.yearsComparisonYear1 = year1
    this.yearsComparisonYear2 = year2
  }

  private processStatusSummaryRowData = async (statusSummary: StatusSummaryGroup) => {
    const successfulData = sumSummaryRowData(
      statusSummary.authorized,
      statusSummary.cancelled,
      statusSummary.charged,
      statusSummary.credited,
      statusSummary.refunded,
      statusSummary.tokenized,
      statusSummary.verified
    )

    const failedData = sumSummaryRowData(statusSummary.failed, statusSummary.rejected)

    const otherData = sumSummaryRowData(
      statusSummary.created,
      statusSummary.new,
      statusSummary.paymentLinkCancelled,
      statusSummary.paymentLinkViewed,
      statusSummary.processing,
      statusSummary.threeD,
      statusSummary.abandoned
    )

    // as we use only GBP the current implementation uses only GBP
    const successfulCurrencyData = successfulData.find(item => item.currency === this.currency) || {
      amount: 0,
      count: 0,
      currency: this.currency
    }
    const failedCurrencyData = failedData.find(item => item.currency === this.currency) || {
      amount: 0,
      count: 0,
      currency: this.currency
    }
    const otherCurrencyData = otherData.find(item => item.currency === this.currency) || {
      amount: 0,
      count: 0,
      currency: this.currency
    }

    const totalSum = successfulCurrencyData.amount + failedCurrencyData.amount + otherCurrencyData.amount
    const totalCount = successfulCurrencyData.count + failedCurrencyData.count + otherCurrencyData.count
    const numberOfDays = this.filterStore.dateStore.endDate.diff(this.filterStore.dateStore.startDate, 'days') + 1

    this.summaryRowDataPercentage = { ...this.summaryRowDataPercentage, current: { totalSum, totalCount } }

    this.summaryRowData = {
      sum: {
        total: `${this.currencySymbol}${numeral(totalSum).format('0,0.00')}`,
        successful: `${this.currencySymbol}${numeral(successfulCurrencyData.amount).format('0,0.00')}`,
        successfulPercent: `${numeral((successfulCurrencyData.amount * 100) / totalSum).format('0,0.00')}%`,
        failed: `${this.currencySymbol}${numeral(failedCurrencyData.amount).format('0,0.00')}`,
        failedPercent: `${numeral((failedCurrencyData.amount * 100) / totalSum).format('0,0.00')}%`,
        other: `${this.currencySymbol}${numeral(otherCurrencyData.amount).format('0,0.00')}`,
        otherPercent: `${numeral((otherCurrencyData.amount * 100) / totalSum).format('0,0.00')}%`,
        average: {
          successful: `${this.currencySymbol}${numeral(
            (successfulCurrencyData.amount / numberOfDays).toFixed(2)
          ).format('0,0.00')}`,
          failed: `${this.currencySymbol}${numeral((failedCurrencyData.amount / numberOfDays).toFixed(2)).format(
            '0,0.00'
          )}`,
          other: `${this.currencySymbol}${numeral((otherCurrencyData.amount / numberOfDays).toFixed()).format(
            '0,0.00'
          )}`
        }
      },
      count: {
        total: `${numeral(totalCount).format('0,0')}`,
        successful: `${numeral(successfulCurrencyData.count).format('0,0')}`,
        successfulPercent: `${numeral((successfulCurrencyData.count * 100) / totalCount).format('0,0.00')}%`,
        failed: `${numeral(failedCurrencyData.count).format('0,0')}`,
        failedPercent: `${numeral((failedCurrencyData.count * 100) / totalCount).format('0,0.00')}%`,
        other: `${numeral(otherCurrencyData.count).format('0,0')}`,
        otherPercent: `${numeral((otherCurrencyData.count * 100) / totalCount).format('0,0.00')}%`,
        average: {
          successful: `${numeral((successfulCurrencyData.count / numberOfDays).toFixed(2)).format('0,0.00')}`,
          failed: `${numeral((failedCurrencyData.count / numberOfDays).toFixed(2)).format('0,0.00')}`,
          other: `${numeral((otherCurrencyData.count / numberOfDays).toFixed(2)).format('0,0.00')}`
        }
      }
    }
  }

  private processPrevStatusSummaryRowData = async (statusSummary: StatusSummaryGroup) => {
    const successfulData = sumSummaryRowData(
      statusSummary.authorized,
      statusSummary.cancelled,
      statusSummary.charged,
      statusSummary.credited,
      statusSummary.refunded,
      statusSummary.tokenized,
      statusSummary.verified
    )

    const failedData = sumSummaryRowData(statusSummary.failed, statusSummary.rejected)

    const otherData = sumSummaryRowData(
      statusSummary.created,
      statusSummary.new,
      statusSummary.paymentLinkCancelled,
      statusSummary.paymentLinkViewed,
      statusSummary.processing,
      statusSummary.threeD,
      statusSummary.abandoned
    )

    // as we use only GBP the current implementation uses only GBP
    const successfulCurrencyData = successfulData.find(item => item.currency === this.currency) || {
      amount: 0,
      count: 0,
      currency: this.currency
    }
    const failedCurrencyData = failedData.find(item => item.currency === this.currency) || {
      amount: 0,
      count: 0,
      currency: this.currency
    }
    const otherCurrencyData = otherData.find(item => item.currency === this.currency) || {
      amount: 0,
      count: 0,
      currency: this.currency
    }

    const totalSum = successfulCurrencyData.amount + failedCurrencyData.amount + otherCurrencyData.amount
    const totalCount = successfulCurrencyData.count + failedCurrencyData.count + otherCurrencyData.count

    this.summaryRowDataPercentage = { ...this.summaryRowDataPercentage, prev: { totalSum, totalCount } }
  }

  async loadTransactionsBreakdown() {
    if (!this.merchantName) return

    const summaryReqParams = this.summaryRequestParams
    delete summaryReqParams.top
    const currentTab = this.transactionsCurrentTab
    const currentStatus = this.transactionsCurrentStatus
    const currentInterval = this.transactionsInterval

    try {
      const hasLoaded: boolean = this.transactionsLineChartData[currentTab][currentStatus][currentInterval]['hasLoaded']
      if (hasLoaded) {
        return
      }

      runInAction(() => {
        this.transactionsLineChartData[currentTab]['isLoading'] = true
      })
      const { error, result } = await fetchTransactionsSummary({
        ...summaryReqParams,
        status: this.transactionsCurrentStatus,
        interval: this.transactionsInterval
      })

      if (error) {
        errorLog('Error loading transactions summary:', error)
        return
      }

      runInAction(() => {
        this.transactionsLineChartData['amount'][currentStatus][currentInterval] = this.processDataTransactions(
          result,
          'amount'
        )
        this.transactionsLineChartData['count'][currentStatus][currentInterval] = this.processDataTransactions(
          result,
          'count'
        )
        this.transactionsLineChartData['amount'][currentStatus][currentInterval]['hasLoaded'] = true
        this.transactionsLineChartData['count'][currentStatus][currentInterval]['hasLoaded'] = true
      })
    } catch (error) {
      errorLog('Error loading transactions summary:', error)
    } finally {
      runInAction(() => {
        this.transactionsLineChartData[currentTab]['isLoading'] = false
      })
    }
  }

  processDataTransactions = (result: EntitySummary[], amountCount: TabKey) => {
    if (amountCount === 'amount') {
      return {
        labels: result.map(res => res.date),
        datasets: [
          {
            data: result.map(res => res[amountCount]),
            fill: false,
            borderColor: 'rgba(24, 143, 255, 0.7)',
            label: translations().total,
            hidden: false
          },
          {
            data: result.map(res => {
              if (res['count']) {
                return res[amountCount] / res['count']
              } else {
                return res['count']
              }
            }),
            fill: false,
            borderColor: 'rgba(24, 143, 255, 0.35)',
            label: translations().average,
            hidden: false
          }
        ]
      }
    } else {
      return {
        labels: result.map(res => res.date),
        datasets: [
          {
            data: result.map(res => res[amountCount]),
            fill: false,
            borderColor: 'rgba(24, 143, 255, 0.7)'
          }
        ]
      }
    }
  }

  clearTransactionsLineChartData() {
    this.transactionsLineChartData = generateTransactionsLineChartData()
  }

  private get loadDataParams() {
    const {
      dateStore: { startDate, endDate }
    } = this.filterStore
    const { merchantName, currency, shouldLoadData, observablesHaveChanged, acquisitionChannelId } = this
    return { startDate, endDate, merchantName, currency, shouldLoadData, observablesHaveChanged, acquisitionChannelId }
  }
}
