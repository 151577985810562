import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
    web: string
    mobile: string
    notificationDetails: string
    templateName: string
    system: string
    displayType: string
    level: string
    contentType: string
    templateTitle: string
    templateDescription: string
    buttonUrl: string
    buttonName: string
    back: string
    save: string
    preview: string
    displayTypeRequired: string
    systemRequired: string
    templateNameRequired: string
    levelRequired: string
    contentArea: string
    contentAreaRequired: string
    templateTitleRequired: string
    templateDescriptionRequired: string
    type: string
    cancel: string
    notificationType: string
    cancelTemplateTitle: string
    cancelTemplateContent: string
    charactersRestrictions: (value: number) => string
    validUrl: string
    validDescription: string
    validTitle: string
}>()

