import translations from './translations'

translations.add('en', {
  cancel: 'Cancel',
  submit: 'Save',
  formFields: {
    email: 'Email',
    firstName: 'First name',
    lastName: 'Last name'
  },
  formErrors: {
    emailRequired: 'email is required',
    emailInvalid: 'email is invalid',
    firstNameRequired: 'first name is required',
    lastNameRequired: 'last name is required'
  },
  permissionSelects: {
    admin: 'Invite as Admin',
    restricted: 'Invite as Restricted Access'
  },
  permissionSelectsText: {
    admin: 'Admins will have full access including managing teammates and new features.',
    restricted: `Customize your teammate's access.`
  },
  subtitles: {
    userAccess: 'User access'
  }
})
