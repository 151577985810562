import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Form, Input, Select } from 'antd'
import { PermissionsTable, SpecificPermissions } from '~/code/pages/PartnerAccess/components'
import { PartnerAccessAddEditFormProps } from './props'

import translations from './translations'

export const PartnerAccessAddEditForm = observer(({ form, store }: PartnerAccessAddEditFormProps) => {
  const {
    partners,
    allPermissions,
    partnerPermissions,
    partnerFormInitialValues,
    partnerSpecificPermissions,
    financialInstitutionOptions,
    isSpecificPermissionsVisible,
    onPartnerFormSubmit
  } = store

  useEffect(() => {
    form?.setFieldsValue({
      permissions: partnerPermissions
    })
  }, [partnerPermissions])

  useEffect(() => {
    form?.setFieldsValue({
      specificPermissions: partnerSpecificPermissions
    })
  }, [partnerSpecificPermissions])

  return (
    <Form
      form={form}
      initialValues={partnerFormInitialValues}
      requiredMark={false}
      labelAlign='left'
      labelCol={{ span: 8 }}
      onFinish={({ permissions, specificPermissions, ...v }) =>
        onPartnerFormSubmit({ ...v, permissions: [...permissions, ...specificPermissions] })
      }
    >
      <Form.Item
        label={translations().formFields.partnerName}
        name='partnerName'
        rules={[{ required: true, message: translations().formErrors.partnerNameRequired }]}
      >
        <Input />
      </Form.Item>

      <Form.Item label={translations().formFields.parentPartner} name='parentCompany'>
        <Select options={partners} allowClear showSearch />
      </Form.Item>

      <Form.Item
        label={translations().formFields.financialInstitution}
        name='financialInstitution'
        rules={[{ required: true, message: translations().formErrors.financialInstitutionRequired }]}
      >
        <Select options={financialInstitutionOptions} />
      </Form.Item>

      <Form.Item name='permissions'>
        <PermissionsTable
          permissions={allPermissions}
          initialPermissions={partnerPermissions}
          onPermissionsChange={permissions => {
            form?.setFieldsValue({
              permissions
            })
          }}
        />
      </Form.Item>

      {isSpecificPermissionsVisible && (
        <Form.Item name='specificPermissions'>
          <SpecificPermissions
            permissions={partnerSpecificPermissions}
            initialPermissions={partnerPermissions}
            onPermissionsChange={specificPermissions => {
              form?.setFieldsValue({
                specificPermissions
              })
            }}
          />
        </Form.Item>
      )}
    </Form>
  )
})
