import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import { Checkbox, Divider, Form, Radio, Modal, Typography, Space, Row, Col } from 'antd'
import { BackButton, CustomButton } from '~/code/components'
import { MerchantProductType } from '~/code/models'
import { PricingPropsType } from './props'
import { getScrollBehaviorOptions } from '../../services'
import { BlendedTable, FeesFormMSA, ICPlusTable } from './components'
import translations from './translations'
import styles from './styles.scss'

export const Pricing = observer((props: PricingPropsType) => {
  const { store } = props
  const { dataStore, tarrifTypes, same, setSame, selectedPricingProduct } = store

  const [formBlended] = Form.useForm()
  const [formICP] = Form.useForm()
  const [formFees] = Form.useForm()

  useEffect(() => {
    store.loadTariffs()
  }, [])

  useEffect(() => {
    store.setTarrifTypes(
      selectedPricingProduct,
      store.dataStore.tariffs.find(t => t.type === selectedPricingProduct)?.tariffs?.tariffType
    )
  }, [selectedPricingProduct])

  const warning = () => {
    Modal.warning({
      title: translations().warningTitle,
      content: (
        <>
          <Typography.Text>{translations().ensureMSA}</Typography.Text>
          <br />
          <Typography.Text>{translations().wrongFee}</Typography.Text>
          <br />
          <Typography.Text>{translations().doubleCheck}</Typography.Text>
        </>
      ),
      width: 600
    })
  }

  return (
    <>
      <BackButton onClick={() => store.setSelectedPricingProduct('')}>{translations().back}</BackButton>
      <div className={styles.prodHeader}>
        <div>
          <b>{translations().prodName(store.selectedPricingProduct)}</b>
        </div>
        <div>
          <p>{translations().selectFeeType}</p>
        </div>
      </div>

      {translations().note}

      <div className={styles.wrapper}>
        {store.showSameOption && (
          <Checkbox
            disabled={store.dataStore.isDocusignSent}
            checked={same}
            onChange={e => {
              setSame(e.target.checked)
            }}
          >
            {translations().sameAsPos}
          </Checkbox>
        )}

        <Divider />

        <Radio.Group
          value={tarrifTypes[selectedPricingProduct]}
          onChange={e => store.setTarrifTypes(selectedPricingProduct, e.target.value)}
          className={styles.radioGroup}
          disabled={store.dataStore.isDocusignSent}
        >
          <Space direction='vertical'>
            <Radio value='blended'>{translations().blended}</Radio>
            <Radio value='ic+'>{translations().icPlus}</Radio>
          </Space>
        </Radio.Group>

        <Form.Provider>
          <>
            {tarrifTypes[selectedPricingProduct] === 'blended' && (
              <BlendedTable
                store={store}
                form={formBlended}
                initialValues={dataStore.tariffs?.find(t => t.type === selectedPricingProduct)?.tariffs}
                type={selectedPricingProduct}
              />
            )}

            {tarrifTypes[selectedPricingProduct] === 'ic+' && (
              <>
                <Divider className={styles.styledDivider} />
                <ICPlusTable
                  store={store}
                  form={formICP}
                  initialValues={dataStore.tariffs?.find(t => t.type === selectedPricingProduct)?.tariffs}
                  type={selectedPricingProduct}
                />
              </>
            )}

            {tarrifTypes[selectedPricingProduct] && (
              <>
                <Divider className={styles.styledDivider} />
                <FeesFormMSA
                  form={formFees}
                  initialValues={dataStore.tariffs?.find(t => t.type === selectedPricingProduct)?.tariffs}
                  product={selectedPricingProduct as MerchantProductType}
                  store={store}
                  tarriffType={tarrifTypes[selectedPricingProduct]}
                />
              </>
            )}

            <Divider />
            {!store.dataStore.isDocusignSent && (
              <Row gutter={10}>
                <Col>
                  {tarrifTypes[selectedPricingProduct] && (
                    <>
                      <CustomButton
                        type='primary'
                        loading={dataStore.isNextLoading}
                        onClick={async () => {
                          const form = tarrifTypes[selectedPricingProduct] === 'blended' ? formBlended : formICP

                          Promise.all([form.validateFields(), formFees.validateFields()])
                            .then(() => {
                              if (store.showWarning()) {
                                warning()
                                return
                              }
                              store.submit(
                                tarrifTypes[selectedPricingProduct],
                                formBlended,
                                formICP,
                                formFees,
                                selectedPricingProduct,
                                false
                              )
                            })
                            .catch(er => {
                              form.scrollToField(er.errorFields[0].name, getScrollBehaviorOptions())
                              formBlended.scrollToField(er.errorFields[0].name, getScrollBehaviorOptions())
                            })
                        }}
                      >
                        {translations().save}
                      </CustomButton>
                    </>
                  )}
                </Col>
                <Col>
                  {tarrifTypes[selectedPricingProduct] && (
                    <>
                      <CustomButton
                        loading={dataStore.isNextLoading}
                        disabled={store.isTariffsLoading}
                        onClick={() => {
                          store.submit(
                            tarrifTypes[selectedPricingProduct],
                            formBlended,
                            formICP,
                            formFees,
                            selectedPricingProduct,
                            true
                          )
                        }}
                      >
                        {translations().saveForLater}
                      </CustomButton>
                    </>
                  )}
                </Col>
                <Col>
                  <CustomButton onClick={() => store.setSelectedPricingProduct('')}>
                    {translations().cancel}
                  </CustomButton>
                </Col>
              </Row>
            )}
          </>
        </Form.Provider>
      </div>
    </>
  )
})
