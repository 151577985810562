import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { CategoryType, ItemModel } from '~/code/models/handbooks'
import {
  AcquisitionChannelModel,
  AcquisitionChannelType,
  BpmStatusModel,
  BpmUserGroupModel,
  BpmUserModel,
  PosModelsResponseType
} from '~/code/models'
import { AcquiringType } from '../pages/Acquiring/models'
import {
  fetchAcquisitionChannels,
  fetchAcquisitionChannelsV2,
  fetchApprovalTypes,
  fetchBpmStatuses,
  fetchBpmUserGroups,
  fetchBpmUsers,
  fetchCategories,
  fetchMccList,
  fetchPosModelsV2,
  fetchProductTypes,
  fetchSubCategories,
  fetchTerminalSubscriptionPrice
} from '~/code/services/fetchers'
import {
  COUNTRIES,
  PAYMENT_METHODS,
  STATUSES,
  THREE_DS_VERSIONS,
  TRANSACTION_CHANNELS,
  PAYER_AUTHS,
  PROCESSING_TYPES,
  POS_TRANSACTION_CHANNELS
} from '~/code/constants/dictionaries'
import translations from '~/code/translations'
import { ApiResponse } from 'back-connector'
import { message } from 'antd'
import { ListMCCModel } from '../models/handbooks/ListMCCModel'
import { fetchOnboardingCountriesInfo } from '../pages/StartProcess/services'
import { OnboardingCountriesResponseType } from '../pages/StartProcess/models/onboarding/OnboardingCountriesResponseType'

const nonUK = { value: 'non-uk', label: translations().nonUK }

export class HandbooksStore {
  isAcquisitionChannelsLoading: boolean = false
  isStartApplicationAcquisitionChannelsLoading: boolean = false
  isBpmStatusesLoading: boolean = false
  isBpmUsersLoading: boolean = false
  isBpmUserGroupsLoading: boolean = false
  isPosModelsLoading: boolean = false
  isApprovalTypesLoading: boolean = false
  isProductTypesLoading: boolean = false
  isMccListLoading: boolean = false
  isSegmentCategoriesLoading: boolean = false
  isSegmentSubCategoriesLoading: boolean = false
  isStartProcessAcquisitionChannelsLoading: boolean = false
  isOnboardingCountriesLoading: boolean = false

  acquisitionChannels: AcquisitionChannelModel[] = []
  startApplicationAcquisitionChannels: AcquisitionChannelModel[] = []
  bpmStatuses: BpmStatusModel[] = []
  bpmUserGroups: BpmUserGroupModel[] = []
  bpmUsers: BpmUserModel[] = []
  posModels: PosModelsResponseType[] = []
  approvalTypes: ItemModel[] = []
  productTypes: ItemModel[] = []
  mccList: ListMCCModel[] = []
  segmentCategories: CategoryType[] = []
  segmentSubCategories: CategoryType[] = []
  startProcessAcquisitionChannels: AcquisitionChannelType[] = []
  onboardingCountries: OnboardingCountriesResponseType[] = []

  countries: ItemModel[] = [nonUK, ...COUNTRIES.map(({ name, alpha3 }) => ({ value: alpha3, label: name }))]
  ipCountries: ItemModel[] = [nonUK, ...COUNTRIES.map(({ name }) => ({ value: name, label: name }))]
  // approvalTypes: ItemModel[] = [{value: null, label: translations().all}, ...APPROVAL_TYPES.map((name) => ({ value: name, label: name }))]
  statuses: string[] = STATUSES.sort()
  paymentMethods = PAYMENT_METHODS.sort((a, b) => a.label.localeCompare(b.label))
  threeDSVersions: ItemModel[] = THREE_DS_VERSIONS
  payerAuths: ItemModel[] = PAYER_AUTHS.map(payerAuth => ({
    value: payerAuth,
    label: payerAuth
  }))
  processingTypes: ItemModel[] = PROCESSING_TYPES.map(p => ({
    value: p,
    label: p
  }))
  posTransactionChannels: ItemModel[] = POS_TRANSACTION_CHANNELS
  subscriptionPrices: PosModelsResponseType[] = []
  isSubscriptionPricesLoading: boolean = false

  constructor() {
    makeObservable(this, {
      isAcquisitionChannelsLoading: observable,
      isStartApplicationAcquisitionChannelsLoading: observable,
      isBpmStatusesLoading: observable,
      isBpmUserGroupsLoading: observable,
      isBpmUsersLoading: observable,
      isPosModelsLoading: observable,
      isApprovalTypesLoading: observable,
      isProductTypesLoading: observable,
      isMccListLoading: observable,
      onboardingCountries: observable,
      subscriptionPrices: observable,
      isSubscriptionPricesLoading: observable,

      acquisitionChannels: observable,
      startApplicationAcquisitionChannels: observable,
      bpmStatuses: observable,
      bpmUserGroups: observable,
      bpmUsers: observable,
      posModels: observable,
      approvalTypes: observable,
      productTypes: observable,
      mccList: observable,
      segmentCategories: observable,
      segmentSubCategories: observable,
      startProcessAcquisitionChannels: observable,

      acquisitionChannelsWithAll: computed,
      startApplicationAcquisitionChannelsWithAll: computed,
      startProcessAcquisitionChannelsWithAll: computed,
      businessCategoriesWithAll: computed,
      businessSubCategoriesWithAll: computed,

      loadAcquisitionChannels: action.bound,
      loadStartApplicationAcquisitionChannels: action.bound,
      loadBpmStatuses: action.bound,
      loadBpmUserGroups: action.bound,
      loadBpmUsers: action.bound,
      loadMccList: action.bound,
      loadBusinessCategories: action.bound,
      loadBusinessSubCategories: action.bound,
      sortedSubCategoriesByCategory: action.bound,
      sortedAcqChannelsBySubCategory: action.bound,
      loadOnboardingCountries: action.bound
    })
  }

  get acquisitionChannelsWithAll() {
    return [
      {
        name: null,
        description: translations().all
      },
      ...this.acquisitionChannels
    ]
  }

  get startProcessAcquisitionChannelsWithAll() {
    return [
      {
        code: null,
        value: translations().all
      },
      ...this.startProcessAcquisitionChannels
    ]
  }

  get startApplicationAcquisitionChannelsWithAll() {
    return [
      {
        name: null,
        description: translations().all
      },
      ...this.startApplicationAcquisitionChannels
    ]
  }

  get statusList() {
    return this.statuses.map(s => ({ value: s, label: s })) as ItemModel[]
  }

  getTransactionChannels(acquiringType: AcquiringType) {
    return TRANSACTION_CHANNELS.sort((a, b) => a.label.localeCompare(b.label))
  }

  protected async loadHandbook<T>(
    fetchHandbook: () => Promise<ApiResponse<T[]>>,
    handbookName: string,
    handbookLoaderName: string
  ): Promise<T[]> {
    if (this[handbookName]?.length > 0) {
      return this[handbookName]
    }

    runInAction(() => (this[handbookLoaderName] = true))

    const { error, result } = await fetchHandbook()

    if (!error) {
      runInAction(() => (this[handbookName] = result))
    } else {
      message.error(error.message || translations().errors.loadData)
    }

    runInAction(() => (this[handbookLoaderName] = false))

    return this[handbookName]
  }

  loadPosModels() {
    return this.loadHandbook(
      async () => {
        const { result, error } = await fetchPosModelsV2()
        const items = result && !error ? result : []
        return { result: items, error }
      },
      'posModels',
      'isPosModelsLoading'
    )
  }

  loadSubscriptionPrices() {
    return this.loadHandbook(
      async () => {
        const { result, error } = await fetchTerminalSubscriptionPrice()
        const items = result && !error ? result : []
        return { result: items, error }
      },
      'subscriptionPrices',
      'isSubscriptionPricesLoading'
    )
  }

  async loadStartApplicationAcquisitionChannels() {
    return this.loadHandbook(
      fetchAcquisitionChannelsV2,
      'startProcessAcquisitionChannels',
      'isStartProcessAcquisitionChannelsLoading'
    )
  }

  loadAcquisitionChannels() {
    return this.loadHandbook(fetchAcquisitionChannels, 'acquisitionChannels', 'isAcquisitionChannelsLoading')
  }

  async loadApprovalTypes() {
    if (this.approvalTypes && this.approvalTypes.length > 0) {
      return this.approvalTypes
    }

    runInAction(() => {
      this.isApprovalTypesLoading = true
    })

    const { error, result } = await fetchApprovalTypes()

    const approvalTypesResponse = result.map(type => ({
      value: type.code,
      label: type.name
    }))

    runInAction(() => {
      if (error) {
        return null
      }

      this.approvalTypes = [
        {
          value: null,
          label: translations().all
        },
        ...approvalTypesResponse
      ]

      this.isApprovalTypesLoading = false
    })
    return this.approvalTypes
  }

  async loadProductTypes() {
    if (this.productTypes && this.productTypes.length > 0) {
      return this.productTypes
    }

    runInAction(() => {
      this.isProductTypesLoading = true
    })

    const { error, result } = await fetchProductTypes()

    const productTypesResponse = result.map(type => ({
      value: type.code,
      label: type.name
    }))

    runInAction(() => {
      if (error) {
        return null
      }

      this.productTypes = [
        {
          value: null,
          label: translations().all
        },
        ...productTypesResponse
      ]

      this.isProductTypesLoading = false
    })
    return this.productTypes
  }

  async loadBpmStatuses() {
    if (this.bpmStatuses && this.bpmStatuses.length > 0) {
      return this.bpmStatuses
    }

    this.isBpmStatusesLoading = true
    const { error, result } = await fetchBpmStatuses()

    runInAction(() => {
      if (error) {
        return null
      }

      this.bpmStatuses = [
        {
          name: null,
          description: translations().all,
          processStatuses: []
        },
        ...result
      ]
      this.isBpmStatusesLoading = false
    })

    return this.bpmStatuses
  }

  async loadBpmUserGroups() {
    if (this.bpmUserGroups && this.bpmUserGroups.length > 0) {
      return this.bpmUserGroups
    }

    this.isBpmUserGroupsLoading = true
    const { error, result } = await fetchBpmUserGroups()
    const sortedResult =
      result && result.sort((a, b) => a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase()))

    runInAction(() => {
      if (error) {
        return null
      }
      this.bpmUserGroups = [
        {
          id: null,
          name: translations().all
        },
        ...sortedResult
      ]
      this.isBpmUserGroupsLoading = false
    })

    return this.bpmUserGroups
  }

  async loadBpmUsers() {
    if (this.bpmUsers && this.bpmUsers.length > 0) {
      return this.bpmUsers
    }

    this.isBpmUsersLoading = true
    const { error, result } = await fetchBpmUsers()
    const sortedResult =
      result && result.sort((a, b) => a.fullName.toLocaleLowerCase().localeCompare(b.fullName.toLocaleLowerCase()))

    const uniqueUsers = sortedResult.reduce((acc, current) => {
      if (acc.some(user => user.login === current.login)) return acc
      return [...acc, current]
    }, [] as BpmUserModel[])

    runInAction(() => {
      if (error) {
        return null
      }
      this.bpmUsers = [
        {
          login: null,
          email: '',
          fullName: translations().all,
          group: null
        },
        ...uniqueUsers
      ]
      this.isBpmUsersLoading = false
    })

    return this.bpmUsers
  }

  loadMccList() {
    return this.loadHandbook(
      async () => {
        const { result, error } = await fetchMccList()
        const items =
          result && !error
            ? result.map(item => ({
                value: item.code,
                label: item.name,
                mccOptions: item.mcc.map(itm => ({
                  value: itm.code,
                  label: `${itm.code}: ${itm.name}`
                }))
              }))
            : []
        return { result: items, error }
      },
      'mccList',
      'isMccListLoading'
    )
  }

  loadBusinessCategories() {
    return this.loadHandbook(
      async () => {
        const { result, error } = await fetchCategories()
        const items = result && !error ? result : []
        return { result: items, error }
      },
      'segmentCategories',
      'isSegmentCategoriesLoading'
    )
  }

  loadBusinessSubCategories() {
    return this.loadHandbook(
      async () => {
        const { result, error } = await fetchSubCategories()
        const items = result && !error ? result : []
        return { result: items, error }
      },
      'segmentSubCategories',
      'isSegmentSubCategoriesLoading'
    )
  }

  get businessCategoriesWithAll() {
    return [
      {
        code: null,
        value: translations().all
      },
      ...this.segmentCategories
    ]
  }

  get businessSubCategoriesWithAll() {
    return [
      {
        code: null,
        value: translations().all
      },
      ...this.segmentSubCategories
    ]
  }

  sortedSubCategoriesByCategory(category: string) {
    const categoryId = this.segmentCategories.find(s => s.code === category)?.id

    const filteredSubCategories =
      categoryId && this.segmentSubCategories.filter(c => c.segmentCategory === categoryId.toString())

    return [
      {
        code: null,
        value: translations().all
      },
      ...filteredSubCategories
    ]
  }

  sortedAcqChannelsBySubCategory(subCategory: string) {
    const subCategoryId = this.segmentSubCategories.find(s => s.code === subCategory)?.id

    const filteredAcqChannels =
      subCategoryId &&
      this.startProcessAcquisitionChannels.filter(c => c.segmentSubCategory === subCategoryId.toString())

    return [
      {
        code: null,
        value: translations().all
      },
      ...filteredAcqChannels
    ]
  }

  loadOnboardingCountries() {
    return this.loadHandbook(
      async () => {
        const { result, error } = await fetchOnboardingCountriesInfo()
        const items = result && !error ? result : []
        return { result: items, error }
      },
      'onboardingCountries',
      'isOnboardingCountriesLoading'
    )
  }
}
