import { action, computed, makeObservable, observable } from 'mobx'
import { StoreItem, ProductItem, CompanyProfile, LoadingPropertyType, ContactPerson } from '../models'
import translations from '../components/ProductsInformation/translations'
import { StartProcessStore } from '../StartProcessStore'
import { FormInstance } from 'antd'
import { checkSalesforceOpportunityID } from '../services'
import { PosBundlesResponseType } from '~/code/models'
import { StoreAddressType } from '../models/product/StoreAddressType'
import { processingMethodsData } from '../components/FinancialInfo/constants/data'
import { advancePaymentsData } from '../components/FinancialInfo/constants/data'
import { PROCESS_VERSIONS } from '../services/constants'
import { isEmpty } from 'dna-common'
import { clearMobx } from '~/code/services'

export class ProductStore {
  public products: ProductItem[] = []
  public showStoresList: boolean = false
  public productAddMessage: string = ''
  public mccValue: string = ''
  selectedStore: StoreItem = null
  productDeliveryAddress: StoreAddressType = {}
  productDeliveryInstructions: string = ''
  productDeliveryNumber: string = ''
  posDeliveryType: string = ''
  subscriptionServicePeriod: string = ''
  freeSubscriptionPeriod: string = ''
  financialStore: StoreItem = null
  isPaytek: boolean = null
  showPopup: boolean = false
  showProductEditModal: boolean = false
  selectedProduct: ProductItem = null
  needRefresh: boolean = false
  contactPersonInfo: ContactPerson = {}

  constructor(private parentStore: StartProcessStore) {
    makeObservable(this, {
      products: observable,
      showStoresList: observable,
      productAddMessage: observable,
      mccValue: observable,
      selectedStore: observable,
      productDeliveryAddress: observable,
      productDeliveryInstructions: observable,
      productDeliveryNumber: observable,
      posDeliveryType: observable,
      subscriptionServicePeriod: observable,
      freeSubscriptionPeriod: observable,
      financialStore: observable,
      isPaytek: observable,
      showPopup: observable,
      showProductEditModal: observable,
      selectedProduct: observable,
      needRefresh: observable,
      contactPersonInfo: observable,

      shouldShowAcquisitionChannelForm: computed,
      contactInfoData: computed,
      reset: action,
      removeProduct: action.bound,
      setProductAddMessage: action,
      resetProducts: action,
      setMccValue: action,
      setSelectedStore: action.bound,
      addProduct: action,
      addProductV2: action,
      saveStore: action,
      isSalesforceOpportunityIDVisible: action,
      validateContactFields: action,
      deleteStore: action.bound,
      setFinancialStore: action.bound,
      updateStoreFinancialInformation: action.bound,
      onLoadData: action.bound,
      mapDataToFinancialInfo: action.bound,
      onSubmitStore: action.bound,
      handleChargeTypeChange: action.bound,
      setShowPopup: action.bound,
      showChargeType: action.bound,
      setShowProductEditModal: action.bound,
      setSelectedProduct: action.bound,
      onProductDeSelect: action.bound,
      saveDeliveryInfo: action.bound,
      saveEditingProduct: action.bound,
      setNeedRefresh: action.bound,

      ecomProducts: computed,
      posProducts: computed
    })
  }

  setNeedRefresh(val: boolean) {
    this.needRefresh = val
  }

  onProductDeSelect() {
    this.selectedProduct = null
    this.showProductEditModal = false
  }

  setSelectedProduct(product: ProductItem) {
    this.selectedProduct = product
    this.showProductEditModal = true
  }

  setShowProductEditModal(val: boolean) {
    this.showProductEditModal = val
  }

  saveEditingProduct(product: ProductItem, id: number) {
    const index = this.products.findIndex(o => o.id === id)
    const oldValue = this.products[index]
    this.products.splice(index, 1)

    product.freeSubscriptionPeriod =
      product && product.freeSubscriptionPeriod !== undefined && product?.freeSubscriptionPeriod != null
        ? String(product.freeSubscriptionPeriod)
        : ''

    if (product && product.feeSubscriptionPerMonth != null) {
      product.feeSubscriptionPerMonth = Number(product.feeSubscriptionPerMonth)
    }

    if (!isEmpty(product.storeWebSite)) {
      product.website = product.storeWebSite
    }

    this.products.push({ ...product, type: oldValue.type, id: oldValue.id, removable: true })
    this.setNeedRefresh(true)
  }

  setShowPopup(val: boolean) {
    this.showPopup = val
  }

  async onLoadData(tForm: FormInstance) {
    await this.parentStore.onSelectIndustry(this.selectedStore?.industry)
    tForm.setFieldsValue({ mcc: this.selectedStore?.mcc })
  }

  get shouldShowAcquisitionChannelForm() {
    return this.dataStore.version === PROCESS_VERSIONS.ADDITIONAL_STORE_ISSUE
  }

  isSalesforceOpportunityIDVisible(acquisitionChannel: string) {
    let acqChannel = acquisitionChannel
    if (acquisitionChannel?.includes('_sales')) {
      acqChannel = acquisitionChannel.replace('_sales', '')
    }
    const channelInfo = this.parentStore.acquisitionChannels.find(a => a.code === acqChannel)

    return channelInfo ? channelInfo.salesforceOpportunityRequired : true
  }

  setSelectedStore(store: StoreItem) {
    this.selectedStore = store
    this.products = store?.products || []
    this.posDeliveryType = store?.deliveryType
    this.productDeliveryAddress = store?.fullDeliveryAddress
    this.contactPersonInfo = store?.contactPerson
    this.productDeliveryNumber = store?.deliveryPhoneNumber
  }

  async validateContactFields(form: FormInstance) {
    await form.validateFields()
    if (
      form &&
      this.dataStore.version === PROCESS_VERSIONS.ADDITIONAL_STORE_ISSUE &&
      this.isSalesforceOpportunityIDVisible(form.getFieldValue('acquisitionChannel'))
    ) {
      const { error } = await checkSalesforceOpportunityID(form.getFieldValue('salesforceOpportunityID'))
      if (error) {
        form.setFields([{ name: 'salesforceOpportunityID', errors: [error.message] }])
        return false
      } else {
        this.dataStore.updateContactInfoData(form.getFieldsValue())
      }
    }

    return true
  }

  get ecomProducts() {
    return this.products?.filter(p => p.type === 'ecom')
  }

  get posProducts() {
    return this.products?.filter(p => p.type === 'pos')
  }

  get dataStore() {
    return this.parentStore.dataStore
  }

  get contactInfoData() {
    return this.dataStore.contactInfoData
  }

  get stores() {
    return this.dataStore.stores
  }

  showChargeType(tForm: FormInstance) {
    return (
      tForm.getFieldValue('terminalType') === 'subscription' ||
      tForm.getFieldValue('terminalType') === 'terminalPurchase'
    )
  }

  setMccValue(val: string) {
    this.mccValue = val
  }

  public setProductAddMessage(message: string) {
    this.productAddMessage = message
  }

  getMaxId(array: { id?: number }[]) {
    if (array.length === 0) {
      return array.length + 1
    } else {
      return Math.max(...array.map(item => item.id)) + 1
    }
  }

  async addProduct(form: FormInstance, posBundles: PosBundlesResponseType[]) {
    const product = form.getFieldsValue()
    const check = product.posBundles?.every(item => {
      posBundles.find(p => p.code === item).acquirerRequiredTids !== 1
    })

    if (check) {
      this.setProductAddMessage(translations().acquirerCheckFailed)
      return
    }

    const productExist = this.products.find((p: ProductItem) => {
      if (!product.posModel) {
        return p.name === product.paymentSolutionView
      } else {
        return p.model === product.posModel && p.name === product.paymentSolutionView
      }
    })

    if (productExist) {
      this.setProductAddMessage(translations().productAlreadyExists)
      return
    } else {
      this.setProductAddMessage('')
    }

    if (product.posDeliveryAddress) {
      this.productDeliveryAddress = product.posDeliveryAddress
      this.productDeliveryInstructions = product.posDeliveryInstructions
      this.productDeliveryNumber = product.posDeliveryNumber
      this.posDeliveryType = product.posDeliveryAddressType
      this.subscriptionServicePeriod = product.subscriptionServicePeriod
      this.freeSubscriptionPeriod = String(product.freeSubscriptionPeriod)
    }

    const newProduct: ProductItem = {
      id: this.getMaxId(this.products),
      name: product.paymentSolutionView,
      count: product?.posCount || 1,
      model: product?.posModel || product?.model || '',
      website: product.storeWebSite,
      removable: true,
      posBundles: product.posBundles,
      subscriptionServicePeriod: product.subscriptionServicePeriod,
      freeSubscriptionPeriod: String(product.freeSubscriptionPeriod),
      terminalType: product.terminalType,
      chargeType: product.chargeType,
      zashDigital: product.zashDigital,
      zashEpos: product.zashEpos
    }

    this.products.push(newProduct)
    form.resetFields()
  }

  async addProductV2(form: FormInstance, posBundles: PosBundlesResponseType[], productType: string) {
    const product = form.getFieldsValue()
    const check = product.posBundles?.every(item => {
      posBundles.find(p => p.code === item).acquirerRequiredTids !== 1
    })

    if (check) {
      this.setProductAddMessage(translations().acquirerCheckFailed)
      return
    }

    if (product.posDeliveryAddress) {
      this.productDeliveryAddress = product.posDeliveryAddress
      this.productDeliveryInstructions = product.posDeliveryInstructions
      this.productDeliveryNumber = product.posDeliveryNumber
      this.posDeliveryType = product.posDeliveryAddressType
      this.subscriptionServicePeriod = product.subscriptionServicePeriod
      this.freeSubscriptionPeriod =
        (product.freeSubscriptionPeriod !== null &&
          product.freeSubscriptionPeriod !== undefined &&
          String(product.freeSubscriptionPeriod)) ||
        ''
      this.contactPersonInfo = {
        nameElements: product.nameElements,
        emailAddress: product.emailAddress,
        telephoneNumber: product.telephoneNumber
      }
    }

    const newProduct: ProductItem = {
      id: this.getMaxId(this.products),
      type: productType,
      removable: true,
      aliPay: product.aliPay,
      cards: product.cards,
      openBanking: product.openBanking
    }

    if (newProduct.type === 'ecom') {
      newProduct.website = product.storeWebSite
      newProduct.zashDigital = product.zashDigital
      newProduct.payByLink = product.payByLink
      newProduct.virtualTerminal = product.virtualTerminal
      newProduct.websitePayments = product.websitePayments
      newProduct.storeWebSite = product.storeWebSite
      newProduct.amex = product.amex
      newProduct.unionPay = product.unionPay
      newProduct.discovery = product.discovery
      newProduct.openBanking = product.openBanking
      newProduct.payByBank = product.payByBank
      newProduct.subscriptionServicePeriod = product.subscriptionServicePeriod
    }

    if (newProduct.type === 'pos') {
      newProduct.count = product?.count || 1
      newProduct.model = product?.model || ''
      newProduct.posBundles = product.posBundles
      newProduct.subscriptionServicePeriod = product?.subscriptionServicePeriod
      newProduct.freeSubscriptionPeriod =
        (product.freeSubscriptionPeriod !== null &&
          product.freeSubscriptionPeriod !== undefined &&
          String(product.freeSubscriptionPeriod)) ||
        ''
      newProduct.terminalType = product.terminalType
      newProduct.chargeType = product.chargeType
      newProduct.zashEpos = product.zashEpos
      newProduct.moto = product.moto
      newProduct.terminalCost = product.terminalCost
      newProduct.feeSubscriptionPerMonth =
        product.feeSubscriptionPerMonth !== null ? Number(product.feeSubscriptionPerMonth) : null
    }

    this.products.push(newProduct)
    form.resetFields()
    this.parentStore.setAliPayEnabled(false)
  }

  public async saveDeliveryInfo(form: FormInstance) {
    const values = form.getFieldsValue()
    this.productDeliveryAddress = values.posDeliveryAddress
    this.productDeliveryInstructions = values.posDeliveryInstructions
    this.productDeliveryNumber = values.posDeliveryNumber
    this.posDeliveryType = values.posDeliveryAddressType
    this.contactPersonInfo = {
      nameElements: values.nameElements,
      emailAddress: values.emailAddress,
      telephoneNumber: values.telephoneNumber
    }
  }

  public async saveStore(form: FormInstance) {
    await form.validateFields()
    if (this.products?.filter(st => st.removable)?.length === 0) {
      this.setProductAddMessage(translations().addProductMessage)
      return
    }
    const values = form.getFieldsValue()
    if (this.selectedStore.id) {
      this.dataStore.setStores(this.stores.filter(st => st.id !== this.selectedStore.id))
    } else {
      if (this.stores.find(store => store.storeName === values.storeName)) {
        this.setProductAddMessage(translations().storeExists)
        return
      }
    }

    const settlementAcc = this.dataStore.bankAccounts?.find(t => t.bankAccountNumber === values.settlementAccount)
    const directDebitAcc = this.dataStore.bankAccounts?.find(t => t.bankAccountNumber === values.directDebitAccount)

    const includesPos = this.products.find((prod: ProductItem) => prod.type === 'pos')

    const item: StoreItem = {
      ...this.selectedStore,
      id: this.selectedStore.id || this.getMaxId(this.stores?.filter(st => st.removable)),
      storeName: values.storeName,
      systemDescriptor: values.systemDescriptor,
      storeAddress: this.removeSpacesInObject(values.storeAddress),
      systemStoreAddress: this.removeSpacesInObject(values.systemStoreAddress),
      products: [],
      mcc: values.mcc,
      mccDescription: this.mccValue || this.parentStore.getMccDescriptionByValue(values.mcc),
      industry: values.industry,
      natureOfBusiness: values.natureOfBusiness,
      bankInfo: {
        ...settlementAcc,
        bankAccountNumber: settlementAcc?.bankAccountNumber?.replace(/\s/g, ''),
        sortCode: settlementAcc?.sortCode?.replace(/\s/g, '')
      },
      directDebitBankInfo: {
        ...directDebitAcc,
        bankAccountNumber: directDebitAcc?.bankAccountNumber?.replace(/\s/g, ''),
        sortCode: directDebitAcc?.sortCode?.replace(/\s/g, '')
      },

      removable: true,
      deliveryType: values.posDeliveryAddressType
    }

    if (includesPos) {
      item.fullDeliveryAddress = this.productDeliveryAddress
      item.deliveryInstructions = this.productDeliveryInstructions
      item.deliveryPhoneNumber = this.productDeliveryNumber
      item.deliveryType = this.posDeliveryType
      item.contactPerson = this.contactPersonInfo
    }
    item.products = this.products
    this.stores.push(item)

    if (
      this.dataStore.application?.companyProfile?.companyType === 'sole-trader' ||
      this.dataStore.application?.companyProfile?.companyType === 'partnership'
    ) {
      const minID = Math.min(...this.stores.map(item => item.id))
      if (
        (this.selectedStore.id && this.selectedStore.id === minID) ||
        isEmpty(this.dataStore.application?.companyProfile?.companyAddress?.postalCode)
      ) {
        this.dataStore.updateCompanyProfile({
          companyAddress: {
            addressLine1: values.systemStoreAddress?.addressLine1,
            addressLine2: values.systemStoreAddress?.addressLine2,
            postalCode: values.systemStoreAddress?.postalCode,
            townOrCity: values.systemStoreAddress?.townOrCity,
            country: values.systemStoreAddress?.country,
            region: values.systemStoreAddress?.region,
            county: values.systemStoreAddress?.county
          }
        })
      }
    }

    if (!this.dataStore.application?.companyProfile.bankAccountNumber) {
      this.dataStore.updateCompanyProfile({
        bankAccountType: item.bankInfo?.bankAccountType,
        bankName: item.bankInfo?.bankName,
        accountName: item.bankInfo?.accountName,
        bankAccountNumber: item.bankInfo?.bankAccountNumber,
        sortCode: item.bankInfo?.sortCode,
        merchantCategoryCode: item?.mcc,
        merchantCategoryCodeDescription: item?.mccDescription,
        directDebitAccount: item?.directDebitBankInfo
      } as CompanyProfile)
    }

    this.setSelectedStore(null)
    this.setProductAddMessage('')

    this.dataStore.setTariffs(this.dataStore.tariffs?.filter(t => this.dataStore.productTypes?.includes(t.type)))
    this.dataStore.tariffs?.forEach(t => {
      t.completed = false
    })

    await this.dataStore.saveOnProducts(this.stores, 'isSaveLoading')
  }

  deleteStore(store: StoreItem) {
    const index = this.stores.findIndex(o => o.id === store.id)
    this.stores.splice(index, 1)
  }

  removeProduct(id: number) {
    const newProds = this.products.filter(p => p.id !== id)
    this.products = newProds
  }

  resetProducts() {
    this.products = []
  }

  public reset() {
    this.products = []
    this.dataStore.stores = []
    this.selectedStore = null
    this.selectedProduct = null
  }

  removeSpacesInObject(obj: any) {
    Object.keys(obj).forEach(k => (obj[k] = obj[k]?.trim()))
    return obj
  }

  setFinancialStore(store: StoreItem) {
    this.financialStore = store
  }

  updateStoreFinancialInformation(values: any, st: StoreItem) {
    const dt = this.mapDataToFinancialInfo(values)

    const index = this.stores.findIndex(o => o.id === st.id)
    this.stores[index] = { ...st, ...dt }
  }

  mapDataToFinancialInfo(values: any): StoreItem {
    let procMethods = []
    let advPayments = []
    if (values?.processingMethods) {
      procMethods = processingMethodsData.map(item => ({
        name: item.key,
        description: item.description,
        percent: Number(values.processingMethods[item.key].percent || 0)
      }))
    }

    if (values?.advancePayments) {
      advPayments = advancePaymentsData.map(item => ({
        name: item.key,
        description: item.description,
        percent: Number(values.advancePayments[item.key].percent || 0),
        days: Number(values.advancePayments[item.key].days || 0)
      }))
    }

    return { ...values, processingMethods: procMethods, advancePayments: advPayments }
  }

  async onSubmitStore(tForm: FormInstance, validate: boolean, loadingProperty: LoadingPropertyType) {
    validate && (await tForm.validateFields())
    await this.updateStoreFinancialInformation(tForm.getFieldsValue(), this.financialStore)
    await this.dataStore.saveOnProducts(this.dataStore.stores, loadingProperty)
    this.setFinancialStore(null)
  }

  handleChargeTypeChange(chargeType: string, tForm: FormInstance) {
    if (chargeType === 'byPaytek') {
      this.isPaytek = true
      tForm.setFieldsValue({ freeSubscriptionPeriod: 0 })
    } else {
      if (chargeType === 'byOptomany') {
        this.setShowPopup(true)
      }
      this.isPaytek = false
      tForm.setFieldsValue({ freeSubscriptionPeriod: null })
    }
  }
}
