import React, { useEffect } from 'react'
import { Col, Form, Input, Row, Select } from 'antd'
import { Rule } from 'antd/lib/form'
import { useForm } from 'antd/lib/form/Form'
import { observer } from 'mobx-react'
import { FormSearch, MultiSelect } from '~/code/components'
import { TransactionsStatementFilterProps } from './props'
import { TransactionStatementFilterModel } from '~/code/stores/TransactionStatementStore/models'
import { removeSpaces } from '~/code/pages/Acquiring/services/utils'
import { ecomCardSchemes } from '~/code/services/payments/card-scheme'
import translations from './translations'

export const TransactionsStatementFilter: React.FC<TransactionsStatementFilterProps> = observer((props) => {
  const { className, store, handbooksStore } = props
  const [ form ] = useForm()

  const rules: Rule[] = [{ type: 'string', min: 3, message: translations().validationMinLength }]
  const onEnterKey = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      form.validateFields()
      store.onFilterBlur()
    }
  }

  useEffect(() => {
    form.setFieldsValue(store.filter)
  }, [])

  const filter = store.filter as TransactionStatementFilterModel

  const setFilterValue = (field, value, shouldLoaded) => {
    form.setFieldsValue({[field]: removeSpaces(value)})
    store.setFilter({ [field]: form.getFieldValue(field) }, shouldLoaded)
  }

  return <FormSearch
    className={className}
    form={form}
    onReset={() => {
      store.resetFilter()
      form.resetFields()
    }}
    items={[
      {
        key: 'status',
        value: filter.status,
        colProps: { xs: 24, md: 12, xl: 8, xxl: 6 },
        collapsedColProps: { xs: 24, sm: 15, md: 6, xl: 5 },
        component: <Form.Item label={translations().status}>
          <MultiSelect
              isDefaultAll={true}
              value={filter.status}
              options={handbooksStore.statusList}
              onChange={(v) => store.setFilter({ status: v }, false)}
              onBlur={store.onFilterBlur}
          />
        </Form.Item>
      },
      {
        key: 'transactionChannel',
        value: filter.transactionChannel,
        colProps: { xs: 24, md: 12, xl: 8, xxl: 6 },
        collapsedColProps: { xs: 0, md: 11, xl: 8 },
        component: <Form.Item label={translations().transactionChannel}>
          <MultiSelect
              isDefaultAll={true}
              value={filter.transactionChannel}
              onChange={(v) => store.setFilter({ transactionChannel: v }, false)}
              onBlur={store.onFilterBlur}
              options={store.transactionChannels}
          />
        </Form.Item>
      },
      {
        key: 'paymentMethod',
        value: filter.paymentMethod,
        colProps: { xs: 24, md: 12, xl: 8, xxl: 6 },
        collapsedColProps: { xs: 0, xl: 6 },
        component: <Form.Item label={translations().paymentMethod}>
          <MultiSelect
              isDefaultAll={true}
              value={filter.paymentMethod}
              onChange={(v) => store.setFilter({ paymentMethod: v }, false)}
              onBlur={store.onFilterBlur}
              options={handbooksStore.paymentMethods}
          />
        </Form.Item>
      },
      {
        key: 'issuerCountry',
        value: filter.issuerCountry,
        colProps: { xs: 24, md: 12, xl: 8, xxl: 6 },
        component: <Form.Item label={translations().issuerCountry}>
          <MultiSelect
              isDefaultAll={true}
              isSearchable={true}
              value={filter.issuerCountry}
              onChange={(v) => store.setFilter({ issuerCountry: v }, false)}
              onBlur={store.onFilterBlur}
              extraActions={[{ text: translations().nonUK, key: 'non-uk', onClick: () => store.setFilter({ issuerCountry: ['non-uk'] }, false) }]}
              options={handbooksStore.countries}
          />
        </Form.Item>
      },
      {
        key: 'ipCountry',
        value: filter.ipCountry,
        colProps: { xs: 24, md: 12, xl: 8, xxl: 6 },
        component: <Form.Item label={translations().ipCountry}>
          <MultiSelect
              isDefaultAll={true}
              isSearchable={true}
              value={filter.ipCountry}
              onChange={(v) => store.setFilter({ ipCountry: v }, false)}
              onBlur={store.onFilterBlur}
              extraActions={[{ text: translations().nonUK, key: 'non-uk', onClick: () => store.setFilter({ ipCountry: ['non-uk'] }, false) }]}
              options={handbooksStore.ipCountries}
          />
        </Form.Item>
      },
      {
        key: 'payerIp',
        value: filter.payerIp,
        colProps: { xs: 24, md: 12, xl: 8, xxl: 6 },
        component: <Form.Item
          name='payerIp'
          label={translations().payerIp}
          rules={rules}
        >
          <Input
            value={filter.payerIp}
            onChange={(e) => {setFilterValue('payerIp', e.target.value, false)}}
            onBlur={store.onFilterBlur}
            onKeyDown={onEnterKey}
          />
        </Form.Item>
      },
      {
        key: 'payerEmail',
        value: filter.payerEmail,
        colProps: { xs: 24, md: 12, xl: 8, xxl: 6 },
        component: <Form.Item
          name='payerEmail'
          label={translations().payerEmail}
          rules={rules}
        >
          <Input
            value={filter.payerEmail}
            onChange={(e) => {setFilterValue('payerEmail', e.target.value?.trim(), false)}}
            onBlur={store.onFilterBlur}
            onKeyDown={onEnterKey}
          />
        </Form.Item>
      },
      {
        key: 'cardMask',
        value: filter.cardMask,
        colProps: { xs: 24, md: 12, xl: 8, xxl: 6 },
        component: <Form.Item
          name='cardMask'
          label={translations().cardMask}
          rules={rules}
        >
          <Input
            value={filter.cardMask}
            onChange={(e) => {setFilterValue('cardMask', e.target.value?.trim(), false)}}
            onBlur={store.onFilterBlur}
            onKeyDown={onEnterKey}
          />
        </Form.Item>
      },
      {
        key: 'terminal',
        value: filter.terminal,
        colProps: { xs: 24, md: 12, xl: 8, xxl: 6 },
        component: <Form.Item
          name='terminal'
          label={translations().terminal}
          rules={rules}
        >
          <Input
            value={filter.terminal}
            onChange={(e) => {setFilterValue('terminal', e.target.value?.trim(), false)}}
            onBlur={store.onFilterBlur}
            onKeyDown={onEnterKey}
          />
        </Form.Item>
      },
      {
        key: 'reference',
        value: filter.reference,
        colProps: { xs: 24, md: 12, xl: 8, xxl: 6 },
        component: <Form.Item
          name='reference'
          label={translations().reference}
          rules={rules}
        >
          <Input
            value={filter.reference}
            onChange={(e) => {setFilterValue('reference', e.target.value?.trim(), false)}}
            onBlur={store.onFilterBlur}
            onKeyDown={onEnterKey}
          />
        </Form.Item>
      },
      {
        key: 'mid',
        value: store.filter.mid,
        colProps: { xs: 24, md: 12, xl: 8, xxl: 6 },
        component: <Form.Item
          name='mid'
          label={translations().mid}
          rules={rules}
        >
          <Input
            value={store.filter.mid}
            onChange={(e) => {setFilterValue('mid', e.target.value?.trim(), false)}}
            onBlur={store.onFilterBlur}
            onKeyDown={onEnterKey}
          />
        </Form.Item>
      },
      {
        key: 'merchantReference',
        value: filter.merchantReference,
        colProps: { xs: 24, md: 12, xl: 8, xxl: 6 },
        component: <Form.Item
          name='merchantReference'
          label={translations().merchantReference}
          rules={rules}
        >
          <Input
            value={filter.merchantReference}
            onChange={(e) => {setFilterValue('merchantReference', e.target.value?.trim(), false)}}
            onBlur={store.onFilterBlur}
            onKeyDown={onEnterKey}
          />
        </Form.Item>
      },
      {
        key: 'threeDSVersion',
        value: filter.threeDSVersion,
        colProps: { xs: 24, md: 12, xl: 8, xxl: 6 },
        component: <Form.Item label={translations().threeDSVersion}>
          <Select
            onChange={val => store.setFilter({ threeDSVersion: val?.toString() }, false)}
            onBlur={store.onFilterBlur}
            defaultValue={null}
          >
            {handbooksStore.threeDSVersions.map((val, idx) => (
              <Select.Option key={val.value || idx} value={val.value}>{val.label}</Select.Option>
            ))}
          </Select>
        </Form.Item>
      },
      {
        key: 'payerAuthenticationResult',
        value: filter.payerAuthenticationResult,
        colProps: { xs: 24, md: 12, xl: 8, xxl: 6 },
        component: <Form.Item label={translations().payerAuthenticationResult}>
          <MultiSelect
              isDefaultAll={true}
              isSearchable={true}
              value={filter.payerAuthenticationResult}
              onChange={(v) => store.setFilter({ payerAuthenticationResult: v }, false)}
              onBlur={store.onFilterBlur}
              options={handbooksStore.payerAuths}
          />
        </Form.Item>
      },
      {
        key: 'transactionId',
        value: filter.transactionId,
        colProps: { xs: 24, md: 12, xl: 8, xxl: 6 },
        component: <Form.Item
          name='transactionId'
          label={translations().transactionId}
          rules={rules}
        >
          <Input
            value={filter.transactionId}
            onChange={(e) => {setFilterValue('transactionId', e.target.value?.trim(), false)}}
            onBlur={store.onFilterBlur}
            onKeyDown={onEnterKey}
          />
        </Form.Item>
      },
      {
        key: 'processingType',
        value: filter.processingType,
        colProps: { xs: 24, md: 12, xl: 8, xxl: 6 },
        component: <Form.Item label={translations().processingType}>
          <MultiSelect
              isDefaultAll={true}
              isSearchable={true}
              value={filter.processingType}
              onChange={(v) => store.setFilter({ processingType: v }, false)}
              onBlur={store.onFilterBlur}
              options={handbooksStore.processingTypes}
          />
        </Form.Item>
      },
      {
        key: 'cardScheme',
        value: filter.cardScheme,
        colProps: { xs: 24, md: 12, xl: 8, xxl: 6 },
        component: <Form.Item label={translations().cardScheme}>
          <MultiSelect
              isDefaultAll={true}
              isSearchable={true}
              value={filter.cardScheme}
              onChange={(v) => store.setFilter({ cardScheme: v }, false)}
              onBlur={store.onFilterBlur}
              options={ecomCardSchemes}
          />
        </Form.Item>
      },
      {
        key: 'signifydDecision',
        value: filter.signifydDecision,
        colProps: { xs: 24, md: 12, xl: 8, xxl: 6 },
        component: <Form.Item label={translations().signifydDecision}>
          <MultiSelect
              emptyText={translations().any}
              isSearchable={true}
              value={filter.signifydDecision}
              onChange={(v) => store.setFilter({ signifydDecision: v }, false)}
              onBlur={store.onFilterBlur}
              options={handbooksStore.signifydDecisions}
          />
        </Form.Item>
      },
      {
        key: 'amount',
        value: {amountFrom: filter.amountFrom, amountTo: filter.amountTo},
        colProps: { xs: 24, md: 12, xl: 8, xxl: 6 },
        component: <Form.Item label={translations().amount}>
          <Input.Group>
            <Row gutter={6}>
              <Col xs={12} sm={12} md={12}>
                <Input
                    type={'number'}
                    placeholder={translations().amountFrom}
                    value={filter.amountFrom}
                    onChange={(e) => store.setFilter({amountFrom: e.target.value}, false)}
                    onBlur={store.onFilterBlur}
                />
              </Col>
              <Col xs={12} sm={12} md={12}>
                <Input
                    type={'number'}
                    placeholder={translations().amountTo}
                    value={filter.amountTo}
                    onChange={(e) => store.setFilter({amountTo: e.target.value}, false)}
                    onBlur={store.onFilterBlur}
                />
              </Col>
            </Row>
          </Input.Group>
        </Form.Item>
      }
    ]}
  />
})
