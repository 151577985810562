import {
  NotificationBehaviourEnum,
  NotificationDisplayTypeEnum, NotificationSetupFormType,
  TemplateType
} from '~/code/stores/NotificationManagementStore'
import { SelectItem } from '~/code/models'
import { message } from 'antd'

export const formatTemplateForm = (form: TemplateType) => {
  if (form) {
    const {
      descriptionLine1,
      systemId,
      notificationDisplayTypeId,
      notificationContentAreaId,
      notificationLevelId,
      name,
      title,
      settings,
      notificationTypeId
    } = form

    return {
      description: descriptionLine1,
      systemId,
      notificationDisplayTypeId,
      notificationContentAreaId,
      notificationLevelId,
      name,
      title,
      notificationTypeId,
      actionButtonUrl: settings.actionButtonUrl,
      actionButtonName: settings.actionButtonName
    }
  } else return null
}

export const getPreviewTemplateOption = (notificationSetupForm: NotificationSetupFormType) => {
  if (notificationSetupForm) {
    const {systemId, notificationDisplayTypeId, notificationContentAreaId} = notificationSetupForm
    let isDashboard = systemId && systemId === 'dashboard'

    switch(notificationDisplayTypeId) {
      case NotificationDisplayTypeEnum.BANNER:
        if (notificationContentAreaId === 'login') {
          return isDashboard
            ? NotificationBehaviourEnum.DASHBOARD_LOGIN_BANNER
            : NotificationBehaviourEnum.MP_LOGIN_BANNER
        } else return isDashboard
          ? NotificationBehaviourEnum.DASHBOARD_SECTION_BANNER
          : NotificationBehaviourEnum.MP_SECTION_BANNER
      case NotificationDisplayTypeEnum.POPUP:
        return isDashboard ? NotificationBehaviourEnum.DASHBOARD_POPUP : NotificationBehaviourEnum.MP_POPUP
      case NotificationDisplayTypeEnum.BLOCK:
        if (notificationContentAreaId === 'app') {
          return isDashboard ? NotificationBehaviourEnum.DASHBOARD_BLOCK : NotificationBehaviourEnum.MP_BLOCK
        }
        return isDashboard ? NotificationBehaviourEnum.DASHBOARD_SECTION_BLOCK : NotificationBehaviourEnum.MP_SECTION_BLOCK
      default: return null
    }
  } else return null
}

export const computeContentAreas = (notificationDisplayTypeId: NotificationDisplayTypeEnum, sections: SelectItem[]) => {
  switch (notificationDisplayTypeId) {
    case NotificationDisplayTypeEnum.BANNER:
      return sections.filter((section) => (section.value !== 'app' && section.value !== 'auth'))
    case NotificationDisplayTypeEnum.POPUP:
      return sections.filter((section) => (section.value === 'auth'))
    case NotificationDisplayTypeEnum.BLOCK:
      return sections.filter((section) => (section.value !== 'login' && section.value !== 'auth'))
    default: return sections
  }
}

const computeIsClosable = (notificationSetupForm: NotificationSetupFormType) => {
  const {notificationDisplayTypeId, notificationContentAreaId} = notificationSetupForm

  switch(notificationDisplayTypeId) {
    case NotificationDisplayTypeEnum.BANNER:
      return notificationContentAreaId !== 'auth' && notificationContentAreaId !== 'login'
    case NotificationDisplayTypeEnum.POPUP:
      return true
    default: return false
  }
}

export const formatSubmitData = (notificationSetupForm: NotificationSetupFormType) => {
  const {
    description,
    actionButtonName,
    actionButtonUrl,
    name,
    title,
    ...rest
  } = notificationSetupForm

  const isClosable = computeIsClosable(notificationSetupForm)

  return {
    ...rest,
    descriptionLine1: description.trim(),
    title: title.trim(),
    notificationTypeId: 'maintenance_incident',
    isClosable,
    settings: {
      actionButtonName: actionButtonName && actionButtonName.length ? actionButtonName.trim() : undefined,
      actionButtonUrl: actionButtonUrl && actionButtonUrl.length ? actionButtonUrl.trim() : undefined
    }
  }
}

export const handleFulfilled = <T>(result: PromiseSettledResult<T>) => {
  if (result.status === 'fulfilled' && result.value) {
    const { value } = result
    const { error, result: data } = value as any

    if (error) {
      message.error(error.message)
      return []
    }
    return data || []
  }
  return []
}