import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { observer } from 'mobx-react'
import { v4 as uuid } from 'uuid'
import { useInjection } from 'dna-react-ioc'
import { LatLngExpression } from 'leaflet'
import { Card, Typography } from 'antd'
import { MapContainer, TileLayer, Marker } from 'react-leaflet'
import { RealTimeTransactionMapWrapper } from './components/RealTimeTransactionMapWrapper'
import { RealTimeTransactionMapPanel } from './components/RealTimeTransactionMapPanel'
import { RealTimeTransactionSourceType } from '~/code/stores/RealTimeTransactionStore/models'
import { RealTimeTransactionMapStoreInterface } from './RealTimeTransactionMapStoreInterface'
import { MAP_CENTER, MAP_DEFAULT_ZOOM, MAP_MARKER_LIFE_TIME } from './constants'
import { getIcon } from './helpers'

import translations from './translations'

import './animations.css'
import 'leaflet/dist/leaflet.css'
import styles from './styles.scss'

type TransactionMarkerType = {
  position: LatLngExpression
  key: string
  id: string
  source: RealTimeTransactionSourceType
}

export const RealTimeTransactionMapStoreSymbol = Symbol('RealTimeTransactionMapStoreSymbol')

export const RealTimeTransactionMap = observer(({ className }) => {
  const [transactions, setTransactions] = useState<TransactionMarkerType[]>([])

  const { data } = useInjection<RealTimeTransactionMapStoreInterface>(RealTimeTransactionMapStoreSymbol)

  useEffect(() => {
    if (!data?.latitude || !data?.longitude) return

    setTransactions(prevTransactions => [
      ...prevTransactions,
      ...[
        {
          key: uuid(),
          id: data.terminalId,
          source: data.source,
          position: [data.latitude, data.longitude]
        } as TransactionMarkerType
      ]
    ])

    setTimeout(
      () => setTransactions(prevTransactions => prevTransactions.filter(t => t.id !== data.terminalId)),
      MAP_MARKER_LIFE_TIME
    )
  }, [data])

  return (
    <Card bordered={false} className={classNames(className)}>
      <Typography.Title level={5}>{translations().title}</Typography.Title>
      <div className={styles.mapHolder}>
        <MapContainer center={MAP_CENTER} zoom={MAP_DEFAULT_ZOOM} style={{ height: '80vh', width: '100%' }}>
          <TileLayer url='https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png' />

          {transactions.map(({ key, source, position }) => (
            <Marker position={position} icon={getIcon(source)} key={key}></Marker>
          ))}

          <RealTimeTransactionMapWrapper />
        </MapContainer>

        <RealTimeTransactionMapPanel />
      </div>
    </Card>
  )
})
