import { injectable, inject } from 'inversify'
import { BaseWebSocketStore } from '../BaseWebSocketStore'
import {
  RealTimeTransactionDataStoreSymbol,
  RealTimeTransactionDataStoreInterface
} from '~/code/stores/RealTimeTransactionStore/RealTimeTransactionDataStore'
import { PROD_URL, TEST_URL } from './constants'

export const RealTimeTPSWebsocketStoreSymbol = Symbol('RealTimeTPSWebsocketStore')

@injectable()
export class RealTimeTPSWebsocketStore extends BaseWebSocketStore {
  public url = __IS_PROD__ ? PROD_URL : TEST_URL

  constructor(
    @inject(RealTimeTransactionDataStoreSymbol)
    private realTimeTransactionDataStore: RealTimeTransactionDataStoreInterface
  ) {
    super()
  }

  public handleData(data): void {
    this.realTimeTransactionDataStore.updateTPSData(data)
  }
}
