import { makeObservable, observable, autorun, action, computed } from 'mobx'
import moment from 'moment-timezone'
import { injectable } from 'inversify'
import { message } from 'antd'
import { PosTransactionModel } from '~/code/models/PosTransactionModel'
import { GroupableItem, PeriodType, TableData, TableFetchParams } from '~/code/models'
import { isPartner } from '~/code/services/auth'
import { fetchPosAmexTransactions } from '~/code/services/fetchers'
import { clearObject, createCheckColumnDate } from '~/code/services/utils'
import { TableFilterStore } from '~/code/stores/TableFilterStore'
import { IAcquiringFilterStore } from '~/code/pages/Acquiring/components/AcquiringFilter'
import {
  columns,
  columnsOnlyForFullAccess,
  getTransactionDateColumn,
  PosTransactionColumnType,
  TRANSACTION_DATE_COLUMN_KEY,
  TRANSACTION_DATE_COLUMN_WIDTH
} from '~/code/pages/Acquiring/pages/MonitoringPosAmex/components/MonitoringPosAmexTransactions/columns'
import {
  getAcquiringStorageData,
  setAcquiringStorageData
} from '../../pages/Acquiring/components/AcquiringFilter/services'
import { AcquiringType } from '~/code/pages/Acquiring'
import { CaptureMethod, CardScheme, Status } from './models/FilterModels'
import { MonitoringPosTransactionFilterModel, MonitoringPosTransactionFilterScheme } from './models'
import { ifValueIsAllReturnUndefined, ifValueIsEmptyReturnUndefined } from './services'
import { ITransactionStatementStore } from '~/code/pages/Acquiring/pages/Statement/models'
import { GlobalConfigStore } from '../GlobalConfigStore'
import { Routes } from '~/code/startup/Router/Routes'
import { HandbooksStoreInjectable } from '../HandbooksStoreInjectable'
import translations from './translations'

@injectable()
export class MonitoringPosAmexTransactionStore implements ITransactionStatementStore {
  constructor(
    public acquiringFilterStore: IAcquiringFilterStore,
    public handbooksStore: HandbooksStoreInjectable,
    public configStore: GlobalConfigStore,
    public acquiringType: AcquiringType,
    public isV2: boolean = false
  ) {
    this.tableStore = new TableFilterStore(this.fetchData)

    const columnsWidthMap = getAcquiringStorageData(this.acquiringType, 'pos', 'statement', 'columns')
    columns.forEach(c => (this.columnsWidthMap[c.key as string] = columnsWidthMap[c.key as string] || c.width))
    this.columnsWidthMap[TRANSACTION_DATE_COLUMN_KEY] = this.columnsWidthMap[TRANSACTION_DATE_COLUMN_KEY] =
      columnsWidthMap[TRANSACTION_DATE_COLUMN_KEY] || TRANSACTION_DATE_COLUMN_WIDTH

    makeObservable(this, {
      isExportModalOpen: observable,
      totalAmount: observable,
      filter: observable,
      columnsWidthMap: observable,
      columns: computed,
      isExportButtonDisable: computed,
      exportsColumns: computed,
      merchantId: computed,
      acquisitionChannelId: computed,
      currency: computed,
      currencySymbol: computed,
      setFilter: action,
      request: computed,
      openExportModal: action.bound
    })

    autorun(
      () => {
        const {
          dateStore: { startDate, endDate },
          merchantTradeName,
          storeAndTerminalFilterStore: { terminal, loadingStatus, mid }
        } = this.acquiringFilterStore
        // if user is not on current page, do not make requests to backend on currency change
        if (!this.isPosMonitoringPage()) return
        if (
          startDate &&
          endDate &&
          merchantTradeName &&
          terminal &&
          mid &&
          loadingStatus !== 'loading' &&
          this.currency
        ) {
          this.loadData(1)
        }
      },
      { delay: 5 }
    )
  }
  isExportModalOpen = false
  dateColumn = {}
  totalAmount: number = 0
  tableStore: TableFilterStore<GroupableItem<PosTransactionModel>>
  filter = new MonitoringPosTransactionFilterModel()
  prevFilterJSON = ''
  columnsWidthMap: Record<string, number> = {}

  get currency() {
    return this.configStore.currency.type
  }

  get currencySymbol() {
    return this.configStore.currency.symbol
  }

  get hasFullAccess() {
    return this.acquiringType === 'dna' && !isPartner()
  }

  get filteredColumns() {
    return columns.filter(c => columnsOnlyForFullAccess.indexOf(c.key as string) < 0 || this.hasFullAccess)
  }

  get transactionDateColumn() {
    return getTransactionDateColumn(this.filteredColumns.length + 1)
  }

  get columns() {
    return [this.transactionDateColumn, ...this.filteredColumns].map((c, i) => ({
      ...c,
      width: this.columnsWidthMap[c.key as string],
      onHeaderCell: (column: PosTransactionColumnType) =>
        ({
          width: column.width,
          onResize: this.handleResize(column.key?.toString())
        } as any)
    }))
  }

  get exportsColumns() {
    return this.columns.reduce((acc, curr) => {
      return {
        ...acc,
        [curr.key as number]: { value: curr.key, label: curr.title }
      }
    }, {})
  }

  get merchantId() {
    return this.acquiringFilterStore.merchantId
  }

  get acquisitionChannelId() {
    if (isPartner() || this.acquiringFilterStore.acquisitionChannelId === 'all') return undefined
    return this.acquiringFilterStore.acquisitionChannelId
  }

  get isExportButtonDisable() {
    return !this.tableStore.data.length
  }

  get request() {
    const {
      dateStore: { startDate, endDate },
      storeAndTerminalFilterStore: { requestForServer }
    } = this.acquiringFilterStore
    const {
      status,
      transactionId,
      captureMethod,
      cardScheme,
      terminalId,
      cardMask,
      transactionChannel,
      amountFrom,
      amountTo
    } = this.filter

    return {
      from: startDate.format(),
      to: endDate.format(),
      size: this.tableStore.pageSize,
      page: this.tableStore.currentPage,
      currency: this.currency,
      status: ifValueIsAllReturnUndefined(status) as Status,
      captureMethod: ifValueIsAllReturnUndefined(captureMethod) as CaptureMethod,
      cardScheme: ifValueIsAllReturnUndefined(cardScheme) as CardScheme,
      transactionId: ifValueIsEmptyReturnUndefined(transactionId),
      cardMask: ifValueIsEmptyReturnUndefined(cardMask),
      merchantId: ifValueIsEmptyReturnUndefined(this.merchantId),
      acquisitionChannel: this.acquisitionChannelId,
      transactionChannel: transactionChannel.length > 0 ? transactionChannel : undefined,
      amountFrom: amountFrom ? parseFloat(amountFrom) : undefined,
      amountTo: amountTo ? parseFloat(amountTo) : undefined,
      ...requestForServer
    }
  }

  openExportModal(value: boolean) {
    this.isExportModalOpen = value
  }

  handleResize = (key: string) =>
    action((e: any, { size: { width } }) => {
      this.columnsWidthMap[key] = width
      setTimeout(() => setAcquiringStorageData(this.acquiringType, 'pos', 'statement', 'columns', this.columnsWidthMap))
    })

  setFilter(filter: Partial<MonitoringPosTransactionFilterModel>, shouldLoad: boolean = true) {
    this.filter = {
      ...this.filter,
      ...filter
    }
    if (shouldLoad) {
      this.loadData(1)
    }
  }

  setMid(value) {
    this.acquiringFilterStore.storeAndTerminalFilterStore.setMid(value)
    this.setFilter({ mid: value }, true)
  }

  resetFilter() {
    this.filter = new MonitoringPosTransactionFilterModel()
    this.acquiringFilterStore.setPeriod(PeriodType.Day)
  }

  onFilterBlur = () => {
    const isValid = MonitoringPosTransactionFilterScheme.isValidSync({ ...this.filter })
    const isChanged = this.prevFilterJSON !== JSON.stringify(this.filter)
    if (isValid && isChanged) {
      this.loadData(1)
    }
  }

  loadData(pageNumber?: number) {
    this.tableStore.loadData({ pageNumber })
  }

  isPosMonitoringPage() {
    return [
      Routes.TRANSACTIONS_DNA_ACQUIRING_MONITORING_POS,

      Routes.TRANSACTIONS_DNA_ACQUIRING_MONITORING_POS_AMEX
    ].includes(window.location.pathname)
  }

  get transactionChannels() {
    return this.handbooksStore.posTransactionChannels
  }

  checkColumnDate = createCheckColumnDate(this.dateColumn)

  fetchData = async (params: TableFetchParams): Promise<TableData<GroupableItem<PosTransactionModel>, number>> => {
    this.prevFilterJSON = JSON.stringify(this.filter)
    const data: GroupableItem<PosTransactionModel>[] = []
    let totalCount = 0

    try {
      const { error, result } = await fetchPosAmexTransactions({ ...this.request })

      clearObject(this.dateColumn)

      if (error) throw new Error(error.message)

      if (!result?.data) {
        this.totalAmount = 0
        return {
          data: [],
          total: 0
        }
      }

      totalCount = result.totalCount
      this.totalAmount = result.totalAmount
      result.data.forEach(item => {
        const date = moment(item.transactionDate).format('DD.MM.YYYY')
        if (this.checkColumnDate(date)) {
          data.push({ groupTitle: date })
        }
        data.push(item)
      })
    } catch (error) {
      message.error(error.message || translations().error, 10)

      this.totalAmount = 0
      return {
        data: [],
        total: 0
      }
    }

    return {
      data,
      total: totalCount
    }
  }
}
