export const SENT_STATUS = 'SENT'
export const SUCCESS_STATUS = 'success'
export enum PRODUCT_VERSIONS {
  REDESIGNED = '2'
}
export enum PROCESS_VERSIONS {
  ONBOARDING = '2.1',
  ADDITIONAL_STORE_ISSUE = '2.2'
}

export const ECOSPEND_STATUSES = {
  SENT: 'SENT',
  VERIFIED: 'VERIFIED',
  MATCH: 'MATCH',
  NOMATCH: 'NOMATCH',
  CANCELED: 'CANCELEDBYLINK'
}

export const paymentTypeCodeMap = {
  dailyNetSettlements: 'DN',
  monthlyNetSettlements: 'Gross',
  grossSettlementsByMid: 'DD',
  grossSettlementsByUberId: 'DD'
}

export const settlementNameMap = {
  dailyNetSettlements: 'Daily Net',
  monthlyNetSettlements: 'Monthly Net',
  grossSettlementsByMid: 'Gross Settlement per MID',
  grossSettlementsByUberId: 'Gross Settlement per UBERID'
}

export const merchantCharge = (name: string) => {
  switch (name) {
    case 'dailyNetSettlements':
      return ''
    case 'monthlyNetSettlements':
      return ''
    case 'grossSettlementsByMid':
      return 'MID'
    case 'grossSettlementsByUberId':
      return 'UBER_ID'
    default:
      return ''
  }
}

export const merchantAttributesMap = {
  settlementPayment: 129,
  setlementType: 130,
  settlementPaymentCode: 58
}
export const DNA_DOMAINS = ['dnapayments.com', 'dnapaymentsgroup.com', 'optomany.com', '123send.com']
