import React, { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Form, Drawer, Grid, Button } from 'antd'
import { hasPermissions } from '~/code/services/auth'
import { PermissionMap } from '~/code/constants/PermissionMap'
import { PartnerTeammateAddEditForm } from '~/code/pages/PartnerTeammates/components/PartnerTeammateAddEditForm'
import { PartnerTeammatesDrawerProps } from './props'

import translations from './translations'
import styles from './styles.scss'

const { useBreakpoint } = Grid

export const PartnerTeammateDrawer = observer((props: PartnerTeammatesDrawerProps) => {
  const { open, onClose, store, ...rest } = props
  const [form] = Form.useForm()
  const { xs } = useBreakpoint()
  const width = xs ? window.innerWidth : 512
  const { isLoading } = store
  const isEdit = !!store.selectedUser

  useEffect(() => form.resetFields(), [open])

  const onCloseDrawer = () => {
    onClose()
  }

  const renderFooter = () => (
    <div className={styles.footer}>
      <Button onClick={onCloseDrawer}>{translations().cancel}</Button>
      <Button
        htmlType='button'
        type='primary'
        loading={isLoading}
        disabled={!hasPermissions([PermissionMap.partners.teammates.create, PermissionMap.partners.teammates.update])}
        onClick={() => form.submit()}
      >
        {translations().submit}
      </Button>
    </div>
  )

  return (
    <Drawer
      open={open}
      title={isEdit ? translations().edit : translations().add}
      width={width}
      footer={renderFooter()}
      onClose={onCloseDrawer}
      destroyOnClose
      {...rest}
    >
      <PartnerTeammateAddEditForm form={form} store={store} onCancel={onCloseDrawer} />
    </Drawer>
  )
})
