import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
    avgDurationTime: string
    avgReviewTime: string
    target: string
    performance: string
    min: string
    applications: string
    hour: string
    hours: string
    hourAbbr: string
    hoursAbbr: string
    uwCapacity: string
    uwReviews: string
    appsPerChannel: string
    numOfAppsPerChannel: string
    uniqueAppsFunnel: string
    currentApps: string
}>()

