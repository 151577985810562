import React from 'react'
import { List, Row, Col } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import sortBy from 'lodash/sortBy'
import { isCorporate } from '~/code/pages/StartProcess/services'
import { ListItem } from '../ListItem'
import translations from './translations'
import { ListPageProps } from './props'
import styles from './ListPage.scss'
import { BelowActionsWithSave, FormTitle } from '../shared'
import { CustomButton } from '~/code/components'

export const ListPage: React.FC<ListPageProps> = props => {
  const { title, text, list, onAdd, isSaveLoading, onSave, addTitle, sorting = true, addDisabled, saveDisabled } = props

  return (
    <>
      <FormTitle>{title}</FormTitle>
      <p className={styles.text}>{text}</p>
      <Row className={styles.styledRow}>
        <Col xs={24}>
          <List
            locale={{ emptyText: translations().empty }}
            className={styles.listStyle}
            split={false}
            itemLayout='horizontal'
            dataSource={
              sorting
                ? sortBy(list, item => {
                    if (isCorporate(item)) {
                      return item.corporateName?.toLocaleLowerCase()
                    }
                    return item.name?.toLocaleLowerCase()
                  })
                : list
            }
            renderItem={item => (
              <>
                <ListItem {...item} />
              </>
            )}
          />
          {onAdd && (
            <div className={styles.addButtonContainer}>
              <CustomButton disabled={addDisabled} icon={<PlusOutlined />} onClick={onAdd}>
                {addTitle || translations().add}
              </CustomButton>
            </div>
          )}
          {onSave && <BelowActionsWithSave disabled={saveDisabled} large isLoading={isSaveLoading} onSave={onSave} />}
        </Col>
      </Row>
    </>
  )
}
