import translations from './translations'

translations.add('en', {
    status: 'Status',
    all: 'All',
    statuses: {
        authorised: 'Authorised',
        charged: 'Charged',
        declined: 'Declined',
        refunded: 'Refunded',
        cancelled: 'Cancelled',
        chargeback: 'Chargeback',
        'chargeback-reversal': 'Chargeback Reversal',
        'second-chargeback-lost': 'Second Chargeback - Lost'
    },
    transactionId: 'Transaction ID',
    captureMethod: 'Capture Method',
    captureMethods: {
        pos: 'POS',
        posContactless: 'POS Contactless',
        stored: 'Stored',
        moto: 'MOTO',
        recurring: 'Recurring',
        posOpenbanking: 'Open Banking',
        posAlipay: 'Alipay'
    },
    cardScheme: 'Card Scheme',
    transactionChannel: 'Transaction Channel',
    paymentMethod: 'Payment Method',
    issuerCountry: 'Issuer Country',
    ipCountry: 'IP Country',
    nonUK: 'Non-UK',
    payerIp: 'Payer IP',
    payerEmail: 'Payer Email',
    cardMask: 'Card Mask',
    mid: 'MID',
    terminalId: 'Terminal',
    reference: 'RRN',
    validationMinLength: (min) => `Enter at least ${ min } characters`,
    amountFrom: 'From',
    amountTo: 'To',
    amount: 'Amount'
})
