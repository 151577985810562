import moment from 'moment'
import { message } from 'antd'
import { inject, injectable } from 'inversify'
import { action, autorun, makeObservable, observable } from 'mobx'
import { GroupableItem, PeriodType, TableData, TableFetchParams } from '~/code/models'
import { ISettlementsPaymentsStore } from '~/code/pages/settlements/SettlementsPayments/ISettlementsPaymentsStore'
import { PaymentStatuses } from '~/code/pages/settlements/SettlementsPayments/constants/PaymentStatuses'
import { fetchSettlementsPayments } from '~/code/stores/SettlementsPaymentsStore/services/fetchers'
import { IRangePickerContainerStore } from '~/code/pages/settlements/SettlementsPayments/components/RangePickerContainer/IRangePickerContainerStore'
import {
  MerchantSelectStoreSettlementsPaymentsSymbol,
  SettlementsPaymentsRangePickerStoreSymbol
} from '~/code/pages/settlements/SettlementsPayments'
import { SettlementsPaymentsTableContainerStore } from '~/code/stores/SettlementsPaymentsStore/SettlementsPaymentsTableContainerStore'
import { SettlementsPaymentsTableContainerStoreSymbol } from '~/code/pages/settlements/SettlementsPayments/components/SettlementsPaymentsTableContainer/SettlementsPaymentsTableContainer'
import { IMerchantSelectStore } from '~/code/components/MerchantSelect/models'

// @ts-ignore
@injectable()
export class SettlementsPaymentsStore implements ISettlementsPaymentsStore {
  constructor(
    @inject(SettlementsPaymentsRangePickerStoreSymbol) rangePickerContainerStore: IRangePickerContainerStore,
    @inject(SettlementsPaymentsTableContainerStoreSymbol) tableContainerStore: SettlementsPaymentsTableContainerStore,
    @inject(MerchantSelectStoreSettlementsPaymentsSymbol) merchantSelectStore: IMerchantSelectStore
  ) {
    this.rangePickerContainerStore = rangePickerContainerStore
    this.tableContainerStore = tableContainerStore
    this.tableContainerStore.fetchData = this.loadData
    this.rangePickerContainerStore.setPeriod(PeriodType.Day)
    this.merchantSelectStore = merchantSelectStore

    this.configureMobXProps()

    // responsible for fetching data
    autorun(() => {
      const { startDate, endDate } = this.rangePickerContainerStore
      const selectedStatus = this.selectedPaymentStatus
      const { merchant } = this.merchantSelectStore // when acquisition channel changes, merchant also changes

      if (startDate && endDate && selectedStatus && merchant) {
        this.tableContainerStore.loadData({ pageNumber: 1 })
      }
    })
  }

  // helper stores
  private rangePickerContainerStore: IRangePickerContainerStore
  private tableContainerStore: SettlementsPaymentsTableContainerStore
  merchantSelectStore: IMerchantSelectStore

  // filter related properties
  selectedPaymentStatus: PaymentStatuses = PaymentStatuses.all

  handlePaymentStatusChange = (status: PaymentStatuses) => {
    this.selectedPaymentStatus = status
    this.tableContainerStore.selectedStatus = status
  }

  get merchantId() {
    return this.merchantSelectStore.merchant?.id
  }

  loadData = async (params: TableFetchParams): Promise<TableData<GroupableItem<any>, { totalCount; totalAmount }>> => {
    const { error, result } = await fetchSettlementsPayments({
      merchantId: this.merchantId === 'all' ? undefined : this.merchantId,
      acquisitionChannel:
        this.merchantSelectStore.acquisitionChannel?.id === 'all'
          ? undefined
          : this.merchantSelectStore.acquisitionChannel?.id,
      from: this.rangePickerContainerStore.startDate.format('YYYY-MM-DD'),
      to: this.rangePickerContainerStore.endDate.format('YYYY-MM-DD'),
      page: params.currentPage,
      size: params.pageSize,
      status: this.selectedPaymentStatus,
      orderBy: params.orderBy,
      orderType: params.orderType
    })

    if (error || !result?.data) {
      message.error(error.message)

      return {
        error: error || new Error('Failed to fetch data'),
        data: [],
        total: { totalCount: 0, totalAmount: 0 }
      }
    }

    const data: GroupableItem<any>[] = []
    result.data.forEach(item => {
      data.push({
        ...item,
        postingDate: moment(item.postingDate).format('DD.MM.YYYY'),
        merchantName: item.merchantName?.replace(/\'/g, '’')
      })
    })

    return {
      data,
      total: { totalCount: result.totalCount, totalAmount: result.totalAmount }
    }
  }

  private configureMobXProps = () => {
    makeObservable(this, {
      // observables
      selectedPaymentStatus: observable,

      // actions
      handlePaymentStatusChange: action
    })
  }
}
