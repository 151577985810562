import React from 'react'
import { observer } from 'mobx-react'
import { Card, Col, Row } from 'antd'
import { BpmAnalyticsStore } from '../../BpmAnalyticsStore'
import translations from './translations'
import styles from './BpmAnalyticsHeaderV2.scss'
import { Info } from '../Info'

export const BpmAnalyticsHeaderV2 = observer((props: { store: BpmAnalyticsStore }) => {
  const { store } = props

  return (
    <Card bordered={false} className={styles.BpmAnalyticsHeader} loading={store.isTopDataLoading}>
      <Row>
        <Col lg={4} sm={12} xs={24}>
          <Info title={translations().totalApp} value={store.topDataResult?.totalApplicationSubmitted} bordered />
        </Col>
        <Col lg={5} sm={12} xs={24}>
          <Info title={translations().approved} value={store.topDataResult?.totalApplicationApproved} bordered />
        </Col>
        <Col lg={5} sm={12} xs={24}>
          <Info title={translations().avrIssueTime} value={store.topDataResult?.avrMidIssueTimeInWorkMinute} bordered />
        </Col>
        <Col lg={5} sm={12} xs={24}>
          <Info
            title={translations().fastestIssueTime}
            value={store.topDataResult?.fastestMidIssueTimeInWorkMinute}
            bordered
          />
        </Col>
        <Col lg={5} sm={12} xs={24}>
          <Info title={translations().avrReviewTime} value={store.topDataResult?.avrReviewTimeInWorkMinute} bordered />
        </Col>
      </Row>
    </Card>
  )
})


