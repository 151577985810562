import { ReactNode } from 'react'
import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
  title: string
  description: string
  companyBlockTitle: string
  companyNameLabel: string
  companyNameRequired: string
  sameAsCompanyName: string
  companyNumberLabel: string
  companyNumberRequired: string
  dateOfCreationLabel: string
  dateOfCreationRequired: string
  dateOfCreationInvalid: string
  natureOfBusinessLabel: string
  natureOfBusinessRequired: string
  natureOfBusinessPlaceholder: string
  natureOfBusinessDetailsLabel: string
  natureOfBusinessDetailsRequired: string
  natureOfBusinessDetailsPlaceholder: string
  companyWebsiteLabel: string
  companyWebsiteRequired: string
  companyWebsiteInvalid: string
  employeesNumberLabel: string
  employeesNumberRequired: string
  emailAddressLabel: string
  emailAddressRequired: string
  emailFormatIncorrect: string
  telephoneNumberLabel: string
  telephoneNumberRequired: string
  telephoneNumberWrongFormat: string
  companyAddressTitle: string
  companyAddress: {
    cityLabel: string
    cityRequired: string
    cityInvalid: string
    countryLabel: string
    countryRequired: string
    regionLabel: string
    postalCodeLabel: string
    postalCodeRequired: string
    postalCodeInvalid: string
    addressLine1Label: string
    addressLine1Required: string
    addressLine2Label: string
    countryTooltip: string
  }
  tradingAddressTitle: string
  sameAsCompanyAddress: string
  tradingAddress: {
    cityLabel: string
    cityRequired: string
    cityInvalid: string
    countryLabel: string
    countryRequired: string
    regionLabel: string
    postalCodeLabel: string
    postalCodeRequired: string
    postalCodeInvalid: string
    addressLine1Label: string
    addressLine1Required: string
    addressLine2Label: string
    countryTooltip: string
  }
  employeesNumberOptions: {
    upTo15: string
    between15And50: string
    between50And100: string
    between100And300: string
    over300: string
  }
  new: string
  renewal: string
  newToDNA: string
  relationshipTypeRequired: string
  comments: string
  isDirectDebit: string
  yes: string
  no: string
  commentsPlaceholder: string
  attention: string
  informText1: string
  informText2: string
  informText3: string
  emailPlaceholder: string
  merchantBankAccount: string
  confirmEdit: string
  confirmationText: ReactNode
  countryOfInitialReg: string
  required: string
  companyTradeName: string
  tradeNameNote: string
  invalidDomain: string
  datePlaceholder: string
  websitePlaceholder: string
  invalidWebsiteDomain: string
}>()
