import { useEffect, useState } from 'react'
import storage from '~/code/services/storage'

// Constants
const NOTIFICATIONS_STORAGE_KEY = 'notifications'
const DEFAULT_NOTIFICATIONS = { auth: [], unauth: [] }

// Helper function to safely get notifications from storage
export const getStorageNotifications = () => {
    try {
        const storedNotifications = storage.get(NOTIFICATIONS_STORAGE_KEY)
        return storedNotifications ? JSON.parse(storedNotifications) : {}
    } catch (e) {
        console.error('Error parsing notifications from storage:', e)
        return {}
    }
}

// Helper function to update notifications in storage
export const updateStorageNotifications = (allNotifications) => {
    storage.set(NOTIFICATIONS_STORAGE_KEY, JSON.stringify(allNotifications))
}

// Custom Hook
export const useStorageNotification = (notification, type: 'auth' | 'unauth', userId?) => {
    const { id, notificationContentAreaId } = notification || {}
    const storageKey = userId || 'unauthorized'

    const [notifications, setNotifications] = useState(() => {
        const allNotifications = getStorageNotifications()
        return allNotifications[storageKey]?.[type] || []
    })

    const [isClosed, setIsClosed] = useState(true)

    // Effect to initialize or update notifications
    useEffect(() => {
        if (!id || !type) return

        const allNotifications = getStorageNotifications()
        const userNotifications = allNotifications[storageKey] || { ...DEFAULT_NOTIFICATIONS }
        const currentNotifications = userNotifications[type] || []

        const existingNotification = currentNotifications.find((item) => item.id === id)

        if (existingNotification) {
            setIsClosed(existingNotification.isClosed)
        } else {
            const newNotification = { id, notificationContentAreaId, isClosed: false }
            const updatedNotifications = [...currentNotifications, newNotification]

            userNotifications[type] = updatedNotifications
            allNotifications[storageKey] = userNotifications

            setNotifications(updatedNotifications)
            setIsClosed(newNotification.isClosed)
            updateStorageNotifications(allNotifications)
        }
    }, [id, type, storageKey, notificationContentAreaId])

    // Handler to close a notification
    const onCloseNotification = (notificationId) => {
        if (!notificationId || !type) return

        const allNotifications = getStorageNotifications()
        const userNotifications = allNotifications[storageKey] || { ...DEFAULT_NOTIFICATIONS }
        const currentNotifications = userNotifications[type] || []

        const updatedNotifications = currentNotifications.map((item) =>
          item.id === notificationId ? { ...item, isClosed: true } : item
        )

        userNotifications[type] = updatedNotifications
        allNotifications[storageKey] = userNotifications

        setNotifications(updatedNotifications)
        setIsClosed(true)
        updateStorageNotifications(allNotifications)
    }

    return { notifications, isClosed, onCloseNotification }
}