import CryptoJS from 'crypto-js'
import { CRYPTO_KEY } from '~/code/constants/Configurations'
import { PermissionMap } from '~/code/constants/PermissionMap'
import { AppStore } from '~/code/stores/AppStore'
import { MenuDataItemType } from '~/code/models'
import storage from '~/code/services/storage'
import { Routes } from '~/code/startup/Router/Routes'

export const isDeveloperMode = () => AppStore.authStore.hasPermissions([PermissionMap.debug])

export const isPartner = () => storage.get('user_type') === 'partner'

export const getAcquisitionChannel = () => {
  const res = storage.get('acquisition_channel')
  if (!res || res === 'null' || res === 'undefined') {
    return getAcquisitionChannelHardCode()
  }
  return res
}

export const getEmailDomain = (email: string) => (email && email.toString().split('@')[1]) || ''

export const getAcquisitionChannelHardCode = (email = storage.get('email')) => {
  const domain = getEmailDomain(email)
  switch (domain) {
    case 'vestamerchantservices.com':
    case 'vestasoftwaregroup.com':
      return 'vesta_software'
    case 'datman.je':
    case 'foodhub.com':
    case 'omni-pay.com':
      return 'foodhub'
    case 'plexy.com':
      return 'plexypay'
    case 'anpriot.com':
      return 'anpriot'
    case 'itslolly.com':
      return 'itslolly'
    case 'impactleisuregroup.com':
      return 'zenithepos'
    case 'hypefin.com':
    case 'hypefin.onmicrosoft.com':
    case 'pozitive.energy':
    case 'pozitivepayments.com':
      return 'pozitive'
    case 'simplypayme.com':
      return 'smarttradeapp'
    case 'btinternet.com':
      return 'aufait_systems'
    case 'parkholidays.com':
      return 'park_holidays'
    case 'crbcunninghams.co.uk':
      return 'CRB_CUNNINGHAMS'
    case 'mikomms.co.uk':
      return 'mikomms'
    case 'hoge100.co.uk':
      return 'hoge100'
    case 'swoopos.com':
      return 'swoopos'
    case 'ordara.co.uk':
      return 'ordara_ltd'
    case 'eposcompany.co.uk':
      return 'epos_company'
    case 'payyltd.com':
      return 'payy_ltd'
    case 'cheaperpay.me':
      return 'cheaper_pay'
    case 'langleybusinesssystems.co.uk':
      return 'langley_business_systems'
    case 'vennersys.co.uk':
      return 'vennersys'
    case 'dbe.uk.com':
      return 'direct_business_equipment'
    case 'affairsgroup.co.uk':
      return 'affairs_group'
    case 'saveyourbusinessmoney.co.uk':
      return 'save_your_business_money'
    case 'paymentpros.co.uk':
      return 'payment_pros'
    default:
      return undefined
  }
}

export const generatePermissions = (permissions: string[]) => {
  const hasBoth = permissions.filter((p) => [
    PermissionMap.transactions.dna_ecom_analytics.read,
    PermissionMap.transactions.dna_pos_analytics.read
  ].includes(p)).length === 2

  if (hasBoth) {
    permissions.push(PermissionMap.transactions.dna_total_analytics.read)
  }

  return permissions
}

export const hasPermissions = (permissions: string[]) => AppStore.authStore.hasPermissions(permissions)

export const savePermissions = (permissions: string[]) => {
  const encrypted = CryptoJS.AES.encrypt(JSON.stringify(permissions), CRYPTO_KEY).toString()
  storage.set('permissions', encrypted)
}

export const getPermissions = () => {
  const encrypted = storage.get('permissions')
  if (!encrypted) return null

  const bytes = CryptoJS.AES.decrypt(encrypted, CRYPTO_KEY)
  const decrypted = bytes.toString(CryptoJS.enc.Utf8)
  return JSON.parse(decrypted)
}

export const filterMenuItems = (
  menuItems: MenuDataItemType[],
  hasPermissionsFunc: (permissions: string[]) => boolean
): MenuDataItemType[] =>
  menuItems.reduce((visibleItems: MenuDataItemType[], m) => {
    if (m.children) {
      const children = filterMenuItems(m.children, hasPermissionsFunc)
      if (children.length === 0) return visibleItems
      return [...visibleItems, { ...m, children }]
    }

    if (hasPermissionsFunc(m.permissions)) {
      return [...visibleItems, m]
    }
    return visibleItems
  }, [])

export const getHomePageRoute = () => {
  for (const r of prioritizedPossibleHomeRoutes) {
    if (hasPermissions(r.permissions)) {
      return r.route
    }
  }
  return null
}

const prioritizedPossibleHomeRoutes = [
  {
    route: Routes.DASHBOARD,
    permissions: [PermissionMap.transactions.dna_ecom_realtime.read, PermissionMap.transactions.dna_pos_realtime.read]
  },
  {
    route: Routes.TRANSACTIONS_DNA_ACQUIRING_ANALYTICS,
    permissions: [PermissionMap.transactions.dna_ecom_analytics.read]
  },
  {
    route: Routes.TRANSACTIONS_DNA_ACQUIRING_STATEMENT,
    permissions: [PermissionMap.transactions.dna_ecom_statement.read]
  },
  {
    route: Routes.TRANSACTIONS_DNA_ACQUIRING_ANALYTICS_POS,
    permissions: [PermissionMap.transactions.dna_pos_analytics.read]
  },
  {
    route: Routes.TRANSACTIONS_DNA_ACQUIRING_MONITORING_POS,
    permissions: [PermissionMap.transactions.dna_pos_statement.v2.read]
  },
  {
    route: Routes.TRANSACTIONS_DNA_ACQUIRING_MONITORING_POS_AMEX,
    permissions: [PermissionMap.transactions.dna_pos_amex_statement.read]
  },
  {
    route: Routes.TRANSACTIONS_OPTOMANY_CHECKOUT_ANALYTICS,
    permissions: [PermissionMap.transactions.optomany_ecom_analytics.read]
  },
  {
    route: Routes.TRANSACTIONS_OPTOMANY_CHECKOUT_STATEMENT,
    permissions: [PermissionMap.transactions.optomany_ecom_statement.read]
  },
  {
    route: Routes.APPLICATIONS_START_APPLICATION,
    permissions: [PermissionMap.onboarding.processes.create]
  },
  {
    route: Routes.APPLICATIONS_START_PROCESS,
    permissions: [PermissionMap.onboarding.processes.create, PermissionMap.onboarding.processesV2.create]
  },
  {
    route: Routes.BPM_REPORTS_ONBOARDING_ANALYTICS,
    permissions: [PermissionMap.onboarding.analytics.read]
  },
  {
    route: Routes.BPM_REPORTS_ONBOARDING_ANALYTICS_V2,
    permissions: [PermissionMap.onboarding.analytics.read]
  },
  {
    route: Routes.BPM_REPORTS_ONBOARDING_PROCESSES,
    permissions: [PermissionMap.onboarding.processes.read]
  },
  {
    route: Routes.BPM_PROCESSES_SUBMITTED_REQUESTS,
    permissions: [PermissionMap.merchants.processes.read]
  },
  {
    route: Routes.BPM_PROCESSES_MOTO_VT,
    permissions: [PermissionMap.merchants.processes.read]
  },
  {
    route: Routes.SETTLEMENTS,
    permissions: [PermissionMap.settlements.read]
  },
  {
    route: Routes.MERCHANTS,
    permissions: [PermissionMap.merchants.read]
  },
  {
    route: Routes.TRANSACTION_CHANNELS,
    permissions: [PermissionMap.transactions.channels.read]
  }
]
