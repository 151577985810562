import {injectable} from 'inversify'
import {NotificationsClient} from 'dna-notification-client'
import { action, makeObservable, observable, runInAction } from 'mobx'
import { ACCESS_TOKEN_KEY, PLATFORM, SECTION_ROUTES } from './constants'
import {INotificationStore} from './INotificationStore'
import {NotificationArgsType} from './types'
import storage from '~/code/services/storage'
import { syncWebSocketNotifications } from '~/code/stores/notification/services/syncWebSocketNotifications'


@injectable()
export class AuthNotificationStore implements INotificationStore {
    notificationClient: NotificationsClient | null = null
    sections = {}
    isAppBlocked = false
    accessToken: string = storage.get(ACCESS_TOKEN_KEY)

    constructor() {
        makeObservable(this, {
            sections: observable,
            isAppBlocked: observable,
            notificationClient: observable,
            eventHandler: action.bound
        })
        this.startOnLogin()
    }

    public eventHandler(args: NotificationArgsType[]) {
        const newSections = {}

        if (!args.length) {
            runInAction(() => {
                this.sections = {}
                this.isAppBlocked = false
                syncWebSocketNotifications([], 'auth')
            })
            return
        }

        args.forEach((notification) => {
            const {systemId, notificationContentAreaId, descriptionLine1} = notification
            if (systemId === PLATFORM) {
                newSections[notificationContentAreaId] = {
                    description: descriptionLine1,
                    route: SECTION_ROUTES[notificationContentAreaId],
                    ...notification
                }
            }
        })

        syncWebSocketNotifications(args as [], 'auth')
        runInAction(() => this.sections = newSections)
    }

    startOnLogin = () => {
        if (this.accessToken) {
            if (this.notificationClient) this.notificationClient.connectionClose()
            this.notificationClient = new NotificationsClient(__IS_PROD__
              ? __AUTH_NOTIFICATION_PROD_URL__
              : __AUTH_NOTIFICATION_TEST_URL__,
              {Authorization: `Bearer ${this.accessToken}`, systemId: 'dashboard'})
            this.notificationClient.subscribe(this)
        }
    }

    refreshOnTokenChange = (result) => {
        if (result?.access_token) {
            this.notificationClient.connectionClose()
            this.notificationClient = new NotificationsClient(__IS_PROD__
              ? __AUTH_NOTIFICATION_PROD_URL__
              : __AUTH_NOTIFICATION_TEST_URL__,
              {Authorization: `Bearer ${result?.access_token}` , systemId: 'dashboard'} )
            this.notificationClient.subscribe(this)
        }
    }

    destroy() {
        this.notificationClient.connectionClose()
    }
}
