import { notification } from 'antd'
import { injectable } from 'inversify'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { ApiResponse } from 'back-connector'
import { DataLoadingStatus, TableFetchParams } from '~/code/models'
import { TeammateRequestType, TeammateTabType } from './models'
import {
  TTeammatesStore,
  TeammateType,
  TeammateViewType,
  TeammateFilterType,
  RoleItemModel
} from '~/code/containers/Teammates'
import { TeammateStatus, AuthMethods } from './constants'
import { SelectItem } from '~/code/models'
import { UserType } from '~/code/models/auth'
import { isPartner } from '~/code/services/auth'
import { TableFilterStore } from '../TableFilterStore'
import { PAGE_SIZE_10 } from '~/code/constants/Configurations'
import { TeammatesFilterFields } from '~/code/stores/TeammatesStore/models/TeammatesFilterFields'
import { getSpecificPermission } from '~/code/pages/PartnerAccess/components/PermissionsTable/services'
import {
  deleteTeammate,
  fetchRolesWithPermissions,
  fetchTeammates,
  inviteTeammate,
  resendTeammate,
  updateTeammate,
  updateTeammateTwoFASettings
} from './services'

import rootTranslations from '~/code/translations'
import translations from './translations'

@injectable()
export class TeammatesStore implements TTeammatesStore {
  _isLoading = false
  _roles: RoleItemModel[] = []
  _rolesLoadingState: DataLoadingStatus = 'idle'

  filterValues: Partial<TeammateFilterType> = {
    role: null,
    status: null,
    authMethod: null
  }
  activeTab: TeammateTabType = 'all'
  selectedUser = null
  isSecurityDrawerOpen: boolean = false
  isUserDrawerOpen: boolean = false
  usersStore: TableFilterStore<TeammateViewType>
  usersInvitedStore: TableFilterStore<TeammateViewType>
  filterFields: TeammatesFilterFields[] = []
  type: UserType = null

  constructor(filterFields: TeammatesFilterFields[], type: UserType) {
    makeObservable(this, {
      selectedUser: observable,
      filterValues: observable,
      activeTab: observable,
      isSecurityDrawerOpen: observable,
      isUserDrawerOpen: observable,

      _isLoading: observable,
      _roles: observable,
      _rolesLoadingState: observable,

      isLoading: computed,
      roles: computed,
      roleTitleMap: computed,
      userPermissions: computed,
      userSpecificPermissions: computed,

      setActiveTab: action.bound,
      setSelectedUser: action.bound,
      setFilterValues: action.bound,
      setIsSecurityDrawerOpen: action.bound,
      setIsUserDrawerOpen: action.bound
    })

    this.usersStore = new TableFilterStore(this.fetchUsers.bind(this))
    this.usersInvitedStore = new TableFilterStore(this.fetchUsersInvited.bind(this))

    this.usersStore.setPageSize(1, PAGE_SIZE_10)
    this.usersInvitedStore.setPageSize(1, PAGE_SIZE_10)

    this.type = type
    this.filterFields = filterFields
  }

  get userPermissions(): string[] {
    return this.selectedUser?.permissions
  }

  get userSpecificPermissions(): string[] {
    return getSpecificPermission(this.selectedUser?.permissions || [])
  }

  get isLoading() {
    return (
      this._isLoading ||
      this.usersStore.loadingStatus === 'loading' ||
      this.usersInvitedStore.loadingStatus === 'loading' ||
      this._rolesLoadingState === 'loading'
    )
  }

  get roles() {
    if (this._rolesLoadingState === 'idle') {
      this.fetchRoles()
    }
    return this._roles || []
  }

  get statuses(): SelectItem[] {
    return [
      { label: translations().all, value: null },
      ...Object.keys(TeammateStatus)
        .filter(status => status !== 'invited')
        .map(s => ({
          label: translations().statuses[s],
          value: TeammateStatus[s]
        }))
    ]
  }

  get authMethods(): SelectItem[] {
    return [
      { label: translations().all, value: null },
      ...Object.keys(AuthMethods).map(m => ({ label: translations().authMethods[m], value: AuthMethods[m] }))
    ]
  }

  get roleTitleMap() {
    return this.roles.reduce((prev, r) => ({ ...prev, [r.value]: r }), {})
  }

  get isInvited() {
    return this.activeTab === 'invited'
  }

  setActiveTab(tab: TeammateTabType) {
    this.activeTab = tab
  }

  setSelectedUser(user: TeammateType) {
    this.selectedUser = user
  }

  setFilterValues(filterValues: Partial<TeammateFilterType>) {
    this.filterValues = filterValues
    this.usersStore.loadData({ pageNumber: 1 })
  }

  setIsSecurityDrawerOpen(isOpen: boolean) {
    this.isSecurityDrawerOpen = isOpen
  }

  setIsUserDrawerOpen(isOpen: boolean) {
    this.isUserDrawerOpen = isOpen
  }

  onSecurityDrawerClose() {
    this.setIsSecurityDrawerOpen(false)
    this.setSelectedUser(null)
  }

  onUserDrawerClose() {
    this.setIsUserDrawerOpen(false)
    this.setSelectedUser(null)
  }

  inviteOrUpdateUser = async (user: TeammateType & { twoFAEnabled: boolean }): Promise<void> => {
    const { id, email, firstName, lastName, roles, twoFAEnabled } = user
    const isEditMode = Boolean(id)
    const twoFAStatus = twoFAEnabled ? 'ENFORCED' : 'DISABLED'

    await this._processRequest(
      rootTranslations().errors.saveData,
      isEditMode ? translations().messages.update : translations().messages.invite,
      () => {
        if (isEditMode) {
          return Promise.all([updateTeammate(id, { firstName, lastName, roles })])
        }
        return inviteTeammate({ email, firstName, lastName, roles, twoFAStatus })
      },
      async () => {
        if (!isEditMode) {
          await this.usersInvitedStore.loadData({ pageNumber: 1 })
        } else {
          if (this.isInvited) {
            await this.usersInvitedStore.loadData()
          } else {
            await this.usersStore.loadData()
          }
        }
      }
    )
  }

  inviteOrUpdatePartnerTeammate = async (user: TeammateType & { twoFAEnabled: boolean }): Promise<void> => {
    const { id, email, firstName, lastName, roles, permissions, twoFAEnabled } = user
    const isEditMode = Boolean(id)
    const twoFAStatus = twoFAEnabled ? 'ENFORCED' : 'DISABLED'

    await this._processRequest(
      rootTranslations().errors.saveData,
      isEditMode ? translations().messages.update : translations().messages.invite,
      () => {
        if (isEditMode) {
          return Promise.all([updateTeammate(id, { firstName, lastName, roles, permissions })])
        }
        return inviteTeammate({ email, firstName, lastName, roles, twoFAStatus, permissions })
      },
      async () => {
        if (!isEditMode) {
          await this.usersInvitedStore.loadData({ pageNumber: 1 })
        } else {
          if (this.isInvited) {
            await this.usersInvitedStore.loadData()
          } else {
            await this.usersStore.loadData()
          }
        }
      }
    )
  }

  async updateUserSecurity(user: TeammateType & { twoFAEnabled: boolean }) {
    const { id, reason, twoFAEnabled } = user
    const isEditMode = Boolean(id)
    const twoFAStatus = twoFAEnabled ? 'ENFORCED' : 'DISABLED'

    await this._processRequest(
      rootTranslations().errors.saveData,
      isEditMode ? translations().messages.update : translations().messages.invite,
      () => {
        return updateTeammateTwoFASettings(id, { twoFAStatus, reason })
      },
      async () => {
        await this.usersStore.loadData()

        this.onSecurityDrawerClose()
      }
    )
  }

  async resend(id: string) {
    await this._processRequest(translations().errors.resend, translations().messages.resend, () => resendTeammate(id))
  }

  async deleteUser(user: TeammateType) {
    await this._processRequest(
      translations().errors.delete,
      translations().messages.delete,
      () => deleteTeammate(user.id),
      async () => {
        if (this.isInvited) {
          await this.usersInvitedStore.loadData({ pageNumber: 1 })
        } else {
          await this.usersStore.loadData({ pageNumber: 1 })
        }
      }
    )
  }

  private async _processRequest<T>(
    errorMessage: string,
    successMessage: string,
    requests: () => Promise<ApiResponse<T> | ApiResponse<T>[]>,
    func?: () => Promise<void>
  ) {
    const normalizedRequests = Array.isArray(requests) ? requests : [requests]

    runInAction(() => (this._isLoading = true))

    const onError = (error: any) => {
      notification.error({ message: error?.message || errorMessage })
    }

    try {
      const responses = await Promise.all(normalizedRequests.map(request => request()))

      const hasError = responses.some(response =>
        Array.isArray(response)
          ? response.some(item => item.error || item.status !== 200)
          : response.error || response.status !== 200
      )

      if (hasError) {
        onError(errorMessage)
      } else {
        func && (await func())
        notification.success({ message: successMessage })
      }
    } catch (err) {
      onError(err)
    } finally {
      runInAction(() => (this._isLoading = false))
    }
  }

  private async fetchRoles() {
    if (this._roles?.length || this._rolesLoadingState === 'loading') {
      return this._roles
    }

    runInAction(() => (this._rolesLoadingState = 'loading'))
    const { result, error } = await fetchRolesWithPermissions()

    if (error) {
      runInAction(() => {
        this._rolesLoadingState = 'failed'
        this._roles = []
      })
    } else {
      runInAction(() => {
        this._rolesLoadingState = 'finished'
        this._roles = result.map(r => ({
          value: r.role,
          label: r.name,
          permissions: r.permissions
        }))
      })
    }

    return result
  }

  private fetchUsers(params: TableFetchParams) {
    const { currentPage, pageSize } = params

    return this.fetchTeammates({
      ...this.filterValues,
      page: currentPage,
      active: 'true',
      size: pageSize
    })
  }

  private fetchUsersInvited(params: TableFetchParams) {
    const { currentPage, pageSize } = params

    return this.fetchTeammates({
      page: currentPage,
      active: 'false',
      size: pageSize
    })
  }

  private async fetchTeammates(params: TeammateRequestType) {
    await this.fetchRoles()

    const { error, status, result } = await fetchTeammates({
      ...params,
      userType: isPartner() ? 'partner' : 'employee'
    })

    if (error) {
      throw new Error(error.message)
    }

    const { teamMates, totalCount } = result
    const users: TeammateViewType[] = teamMates.map(({ login, roles, ...rest }) => ({
      ...rest,
      email: login,
      roles,
      roleTitlesStr: roles?.map(r => this.roleTitleMap[r]?.label || r).join(', ') || ''
    }))

    return {
      data: users,
      total: totalCount
    }
  }

  onInviteTeammateClick = () => {
    this.setSelectedUser(null)
    this.isUserDrawerOpen = true
  }
}
