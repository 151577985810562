import React from 'react'
import { PopupNotificationProps } from './props'
import {Modal} from 'antd'
import translations from './translations'
import styles from './styles.scss'
import { toJS } from 'mobx'

export const PopupNotification: React.FC<PopupNotificationProps> = ({ notification, onCloseNotification }) => {
    const { id, title, description, settings } = notification || {}

    console.log('popup', toJS(notification))

    const handleClose = () => {
        onCloseNotification(id)
    }

    const handleOk = () => {
        if(settings.actionButtonUrl) {
            window.open(settings.actionButtonUrl)
        }
        onCloseNotification(id)
    }

    const validateOkBtn = () => {
        const { actionButtonName } = settings;

        return String(actionButtonName || '').trim().length > 0
    }

    return (
        <>
            <Modal
                open={true}
                title={title}
                okText={validateOkBtn() ? settings.actionButtonName : translations().ok }
                cancelText={settings.cancelButtonName ? settings.cancelButtonName : translations().close }
                onOk={handleOk}
                onCancel={handleClose}
            >
                <div className={styles.description}>{description}</div>
            </Modal>
        </>
    )
}
