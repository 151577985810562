import translations from './translations'
import React from 'react'

translations.add('en', {
  title: 'Contact info',
  firstName: 'First name',
  surname: 'Surname',
  firstNameRequired: 'first name is required',
  surnameRequired: 'surname is required',
  emailLabel: 'Email address',
  emailRequired: 'email is required',
  emailFormatIncorrect: 'invalid email',
  telephoneNumberLabel: 'Phone number',
  telephoneNumberRequired: 'phone number is required',
  telephoneNumberWrongFormat: 'invalid phone number',
  acquisitionChannel: 'Acquisition channel',
  acquisitionChannelRequired: 'acquisition channel is required',
  referrer: 'Sales ID',
  referrerRequired: 'sales ID is required',
  referrerPlaceholder: 'Sales ID',
  firstNameSubLabel: 'This information will be used as the Primary Contact.',
  emailSubLabel: 'This email will receive Merchant Portal access details.',
  applicationSourceLabel: 'Partner',
  applicationSourceRequired: 'Partner is required',
  applicationAlreadyStarted: 'Application with this email has already started.',
  continue: 'Continue',
  salesforceOpportunityIDLabel: 'Salesforce opportunity ID',
  salesforceOpportunityIDRequired: 'salesforce opportunity ID is required',
  salesforceOpportunityIDMin: 'salesforce opportunity ID must be at least 15 characters',
  sellerEmail: 'Sale owner (Salesforce Opportunity Owner)',
  fastTrack: 'FastTrack',
  msaSigned: 'MSA signed',
  applicantInfo: 'Applicant Info',
  firstNamePlaceholder: 'John',
  surnamePlaceholder: 'Doe',
  emailAlreadyExists: (
    <>
      <p>There is already an application with this email as the primary company's email.</p>
      <div>What to do:</div>
      <div>
        1. If you're trying to add an additional product to an existing/onboarded merchant, please follow the "Add
        additional product to an existing merchant" process
      </div>
      <div>
        2. If you're trying to submit an application for the merchant whose onboarding application is not yet approved,
        this application has to be terminated first.
      </div>
    </>
  ),

  segmentCategory: 'Business channel',
  requiredField: 'Required field',
  segmentSubCategory: 'Businesss sub-channel',
  obrCountry: 'Country',
  applicationDetails: 'Application details',
  start: 'Start application',
  cancel: 'Cancel',
  opportunityIdOwnerName: 'Opportunity ID Owner name',
  oppIdNote:
    'Once the Salesforce Opportunity ID is pasted in, Opportunity Owner`s name and email will be pulled out automatically from Salesforce',
  save: 'Save',
  errorWithEmail: 'There is already an application with this email as the primary company`s email.',
  whatToDo: 'What to do:',
  whatToDo1:
    '1. If you`re trying to add an additional product to an existing/onboarded merchant, please follow the "Add additional product to an existing merchant" process',
  whatToDo2:
    '2. If you`re trying to submit an application for the merchant whose onboarding application is not yet approved, this application has to be terminated first. ',
  refereeUberId: 'Referee Uber ID'
})
