import React, { useEffect, useRef, useState } from 'react'
import { Col, Row, Tooltip } from 'antd'
import Chart from 'chart.js'
import classNames from 'classnames'
import numeral from 'numeral'
import styles from './PieChart.scss'

export const PieChart = (props) => {
    const { data, currencySymbol, valueTitle, valueNumberFormat, renderTooltipLabel } = props
    const chartRef = useRef(null)

    useEffect(() => {
        createChart()
    }, [])

    const [legend, setLegend] = useState(null)

    const _renderLegendTitle = (index) => {
        if (data.icons) {
            return <div className={styles.legendIcon}>{ data.icons[index] && data.icons[index]() } </div>
        }
        return  <p className={styles.legendTitle}>{ data.labels[index] || 'Unknown' }</p>
    }

    const createChart = () => {
        const ctx = chartRef.current.getContext('2d')
        const options = {
            responsive: true,
            maintainAspectRatio: false,
            cutoutPercentage: 75,
            legend: {
                position: 'right',
                display: false
            },
            legendCallback: (_chart) => {
                const _legend = (
                    <ul className={styles.legend}>
                        {_chart?.data?.datasets[0]?.data.map((item, i) => (
                            <li key={_chart.data.labels[i]}>
                                <Row gutter={[8, 0]} align='middle'>
                                    <Col xs={2}>
                                            <span
                                                className={styles.dot}
                                                style={{ backgroundColor: _chart.data.datasets[0].backgroundColor[i] }}
                                            />
                                    </Col>
                                    <Col xs={9}>
                                        {_renderLegendTitle(i)}
                                        {/*{ _chart.data.subLabels && <p className={styles.legendSubTitle}>{_chart.data.subLabels[i] || ''}</p>}*/}
                                    </Col>
                                    <Col xs={5}>
                                            <span className={styles.percent}>
                                                {`${((data.datasets[0].percents && data.datasets[0].percents[i]) || 0).toFixed(2)}%`}
                                            </span>
                                    </Col>
                                    <Col xs={8}>
                                        <span className={styles.value}>{ `${currencySymbol || ''}${numeral(item).format(valueNumberFormat || '0.00a')}` }</span>
                                    </Col>
                                    <Col offset={2} xs={22}>{ _chart.data.subLabels && <Tooltip title={_chart.data.subLabels[i] || ''}><div className={styles.legendSubTitle}>{_chart.data.subLabels[i] || ''}</div></Tooltip>}</Col>
                                </Row>
                            </li>
                        ))}
                    </ul>
                )

                return _legend
            },
            tooltips: {
                callbacks: {
                    label: ({datasetIndex, index}, {datasets, labels}) => { // {datasetIndex, index, label, value}, _data
                        return renderTooltipLabel(labels[index], datasets[datasetIndex].data[index])
                    }
                }
            }
        }

        if (!renderTooltipLabel) {
            delete options.tooltips
        }

        const chart = new Chart(ctx, {
            type: 'doughnut',
            data,
            options
        })
        setLegend(chart.generateLegend())
    }

    return <div className={classNames(styles.PieChart, styles.hasLegend)}>
        <Row gutter={[16, 16]}>
            <Col xs={24} md={12}>
                <div className={styles.chart}>
                    <canvas
                        id={`pie-${styles.PieChart}`}
                        ref={chartRef}
                    />
                    <div className={styles.total}>
                        {<h4>{ valueTitle }</h4>}
                        {data?.total >= 0 && <p>{`${currencySymbol || ''}${numeral(data?.total).format(valueNumberFormat || '0.00a')}`}</p>}
                    </div>
                </div>
            </Col>
            <Col xs={24} md={12} className={styles.legendWrapper}>
                <div id={`pie-${styles.PieChart}-legend`} style={{width: '100%'}}>
                    { legend }
                </div>
            </Col>
        </Row>
    </div>
}
