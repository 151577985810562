import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
    status: string
    all: string
    statuses: {
        authorised: string
        charged: string
        declined: string
        refunded: string
        cancelled: string
    }
    transactionId: string
    captureMethod: string
    captureMethods: {
        pos: string,
        posContactless: string,
        stored: string
        moto: string
        recurring: string
        posOpenbanking: string
    }
    cardScheme: string
    transactionChannel: string
    paymentMethod: string
    issuerCountry: string
    ipCountry: string
    nonUK: string
    payerIp: string
    payerEmail: string
    cardMask: string
    mid: string
    terminalId: string
    reference: string
    validationMinLength: (min) => string
    validationMaxLength: (max) => string
    amountFrom: string
    amountTo: string
    amount: string
}>()
