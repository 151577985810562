import { Col, Row, TableProps } from 'antd'
import { TemplateType } from '~/code/stores/NotificationManagementStore'
import React from 'react'
import { IPublishNotificationsStore } from '~/code/pages/Notifications'
import { MerchantModel } from '~/code/models/handbooks'
import translations from './translations'
import { TeammateViewType } from '~/code/containers/Teammates'

export const ITEMS = [
  {
    key: 'selectTemplate',
    title: translations().selectTemplate
  },
  {
    key: 'selectRecipient',
    title: translations().selectRecipient
  },
]

export const getColumns = (store: IPublishNotificationsStore): TableProps<TemplateType>['columns'] => {
  const { selectTemplate, viewTemplate } = store

  return [
    {
      title: translations().title,
      dataIndex: 'title',
      key: 'title'
    },
    {
      title: translations().system,
      dataIndex: 'system',
      key: 'system'
    },
    {
      title: translations().displayType,
      dataIndex: 'displayType',
      key: 'displayType',
    },
    {
      title: translations().type,
      dataIndex: 'type',
      key: 'type'
    },
    {
      title: translations().actions,
      dataIndex: 'actions',
      width: 135,
      key: 'actions',
      align: 'center',
      fixed: 'right',
      render: (value, record) => {
        return (
          <Row gutter={24} justify={'space-around'}>
            <Col>
              <a
                onClick={e => {
                  e.preventDefault()
                  viewTemplate(record.id)
                }}
                href=''
              >
                {translations().view}
              </a>
            </Col>
            <Col>
              <a
                onClick={e => {
                  e.preventDefault()
                  selectTemplate(record.id)
                }}
                href=''
              >
                {translations().select}
              </a>
            </Col>
          </Row>
        )
      }
    }
  ]
}

export const getMerchantsColumns = (store: IPublishNotificationsStore): TableProps<MerchantModel>['columns'] => {
  const { setSelectedMerchantsIds, setStep } = store

  return [
    {
      title: translations().companyName,
      dataIndex: 'companyName',
      key: 'companyName'
    },
    {
      title: translations().descriptor,
      dataIndex: 'descriptor',
      key: 'descriptor'
    },
    {
      title: translations().actions,
      dataIndex: 'actions',
      width: 150,
      key: 'actions',
      align: 'center',
      render: (value, record) => {
        const { id } = record
        return (
          <Row gutter={24} justify={'space-around'}>
            <Col>
              <a
                onClick={e => {
                  e.preventDefault()
                  setSelectedMerchantsIds([id])
                  setStep(2)
                }}
                href=''
              >
                {translations().publish}
              </a>
            </Col>
          </Row>
        )
      }
    }
  ]
}

export const getTeamMatesColumns = (store: IPublishNotificationsStore): TableProps<TeammateViewType>['columns'] => {
  const { setSelectedUsersIds, setStep } = store

  return [
    {
      title: translations().email,
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: translations().firstName,
      dataIndex: 'firstName',
      key: 'firstName'
    },
    {
      title: translations().lastName,
      dataIndex: 'lastName',
      key: 'lastName'
    },
    {
      title: translations().actions,
      dataIndex: 'actions',
      width: 150,
      key: 'actions',
      align: 'center',
      render: (value, record) => {
        const { id } = record
        return (
          <Row gutter={24} justify={'space-around'}>
            <Col>
              <a
                onClick={e => {
                  e.preventDefault()
                  setSelectedUsersIds([id])
                  setStep(2)
                }}
                href=''
              >
                {translations().publish}
              </a>
            </Col>
          </Row>
        )
      }
    }
  ]
}
