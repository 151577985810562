import { TranslationBuilder } from '~/code/components/Translation'

export default TranslationBuilder.create<{
    title: string
    shortTitle: string
    merchant: string
    transactionDate : string
    amount: string
    currency: string
    status: string
    returnCode: string
    message: string
    transactionId: string
    mid: string
    terminalId: string
    operation: string
    transactionType: string
    transactionChannel: string
    transactionDetails: string
    transactionCountry: string
    transactionCity: string
    cardScheme: string
    cardMask: string
    cardType: string
    issuerRegion: string
    isCorporateCard: string
    issuer: string
    captureMethod: string
    transactionMcc: string
    european: string
    nonEuropean: string
    corporate: string
    personal: string
    pos: string
    'pos-contactless': string
    'pos-openbanking': string
    moto: string
    recurring: string
    stored: string
    export: string
}>()
