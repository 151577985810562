import qs from 'qs'
import page from 'page'
import { Buffer } from 'buffer'
import { observable, runInAction } from 'mobx'
import { error } from 'dna-common'
import { Routes } from '~/code/startup/Router/Routes'
import { isPartner } from '~/code/services/auth'
import { IRouterStore } from './IRouterStore'
import * as Sentry from '@sentry/react'
import storage from '~/code/services/storage'

export const context = observable({
  pathname: ''
})

page((ctx, next) => {
  runInAction(() => {
    context.pathname = ctx.pathname
    setSentryTagAndUser(context.pathname)
  })
  next()
})

function setSentryTagAndUser(pathname) {
  if (!__IS_PROD__ && window.location.hostname !== 'localhost') {
    const email = storage.get('email')
    console.log('setting tag for sentry location: ', pathname)
    console.log('Setting user as: ', email)
    Sentry.setUser({ email })
    if (
        pathname.startsWith('/bpm') ||
        pathname.startsWith('/applications') ||
        pathname.startsWith('/merchants') ||
        pathname.startsWith('/partner-commissions')
    ) {
      Sentry.getCurrentScope().setTag('team', 'bpm')
      console.log('Tag set: BPM')
    } else {
      Sentry.getCurrentScope().setTag('team', 'transactions')
      console.log('Tag set: Transactions')
    }
  }
}

// initialize routes
export function Router(store: IRouterStore) {
  let path: string
  page(Routes.WILDCARD, (ctx, next) => {
    path = ctx.path
    ctx.query = qs.parse(ctx.querystring)
    store.currentRoute = ctx.pathname
    next()
  })

  page(Routes.MAIN, () => store.showHomePage())
  page(Routes.DASHBOARD, () => store.showDashboardPage())

  page(Routes.TRANSACTIONS_DNA_ACQUIRING_ANALYTICS, () => store.showDNATransactionsAnalyticsPage())
  page(Routes.TRANSACTIONS_DNA_ACQUIRING_TOTAL_ANALYTICS, () => store.showDNATransactionsAnalyticsTotalPage())
  page(Routes.TRANSACTIONS_DNA_ACQUIRING_ANALYTICS_POS, () => store.showDNATransactionsAnalyticsPosPage())
  page(Routes.TRANSACTIONS_DNA_ACQUIRING_STATEMENT, () => store.showDNATransactionsStatementPage())
  page(Routes.TRANSACTIONS_DNA_ACQUIRING_MONITORING_POS, () => store.showDNATransactionsMonitoringPosPage())
  page(Routes.TRANSACTIONS_DNA_ACQUIRING_MONITORING_POS_TEST, () => store.showDNATransactionsMonitoringPosTestPage())
  page(Routes.TRANSACTIONS_DNA_ACQUIRING_MONITORING_POS_AMEX, () => store.showDNATransactionsMonitoringPosAmexPage())
  page(Routes.LOGIN, ctx => store.showLoginPage(ctx.query))
  page(Routes.RESET_PASSWORD, ctx => {
    let email = null
    try {
      email = ctx?.query?.t ? Buffer.from(ctx.query?.t, 'base64').toString() : null
    } catch (err) {
      const errorMessage = `Failed to get or parse email from query params: ${err?.message}`
      error(err)
      page.redirect(`${Routes.RESET_PASSWORD_FAILURE}?m=${errorMessage}`)
      return
    }
    store.showResetPassword(email)
  })
  page(Routes.RESET_PASSWORD_FAILURE, ctx => store.showResetPasswordFailure(ctx?.query?.m))
  page(Routes.CONFIRM_EMAIL, () => store.showConfirmEmail())
  page(Routes.SET_PASSWORD, () => store.showSetNewPassword())
  page(Routes.TRANSACTIONS_OPTOMANY_CHECKOUT_ANALYTICS, () => store.showOptomanyCheckoutV3AnalyticsPage())
  page(Routes.TRANSACTIONS_OPTOMANY_CHECKOUT_STATEMENT, () => store.showOptomanyCheckoutV3StatementPage())
  page(Routes.BPM_REPORTS_ONBOARDING, Routes.BPM_REPORTS_ONBOARDING_ANALYTICS_V2)
  page(Routes.BPM_REPORTS_ONBOARDING_PROCESSES, () => store.showOnboardingProcesses())
  page(Routes.BPM_REPORTS_ONBOARDING_PROCESSES_ADDITIONAL_STORE, () => store.showAdditionalStoreProcesses())
  page(Routes.BPM_REPORTS_ONBOARDING_PROCESS, ctx => store.showOnboardingProcess(ctx.params.id, ctx.params.type))
  page(Routes.BPM_REPORTS_ONBOARDING_ANALYTICS, () => store.showOnboardingAnalytics())
  page(Routes.BPM_REPORTS_ONBOARDING_ANALYTICS_V2, () => store.showOnboardingAnalyticsV2())
  page(Routes.BPM_PROCESSES, Routes.BPM_PROCESSES_SUBMITTED_REQUESTS, Routes.BPM_PROCESSES_MOTO_VT)
  page(Routes.BPM_PROCESSES_SUBMITTED_REQUESTS, () => store.showRiskLimitsSubmittedRequests())
  page(Routes.BPM_PROCESSES_MOTO_VT, () => store.showMotoVtRequests())
  page(Routes.BPM_PROCESSES_CHANGE_BANK_DETAILS_DOSSIER_V2, () => store.showChangeBankDetailsDossierV2())
  page(Routes.BPM_PROCESSES_CLOSURE_OF_MERCHANT, () => store.showClosureOfMerchant())
  page(Routes.BPM_PROCESSES_CLOSURE_OF_TERMINAL, () => store.showClosureOfTerminal())
  page(Routes.BPM_CHANGE_OF_SETTLEMENT_PERIOD, () => store.showChangeOfSettlementPeriod())
  page(Routes.BPM_CHANGE_OF_SETTLEMENT_KIND, () => store.showChangeOfSettlementKind())
  page(Routes.BPM_CHANGE_OF_SETTLEMENT_STATUS, () => store.showChangeOfSettlementStatus())
  page(Routes.BPM_PROCESSES_TERMINAL_ISSUE, () => store.showTerminalIssue())
  page(Routes.BPM_PROCESSES_ADJUSTMENT_REQUESTS, () => store.showAdjustmentRequests())
  page(Routes.BPM_PROCESSES_EDIT_TARIFFS, () => store.showEditTariffsRequests())
  page(Routes.APPLICATIONS_START_NEW_APPLICATION, () => store.showStartApplicationPage(undefined))
  page(Routes.APPLICATIONS_START_APPLICATION, ({ params: { id } }) => store.showStartApplicationPage(id))
  page(Routes.APPLICATIONS_SAVED_APPLICATION, () => store.showSavedApplicationsPage())
  page(Routes.BPM_REPORTS_STATISTICS, () => store.showStatisticsPage())
  page(Routes.SETTLEMENTS, Routes.SETTLEMENTS_STATEMENT)
  page(Routes.SETTLEMENTS_STATEMENT, () => store.showSettlementsStatementPage())
  page(Routes.SETTLEMENTS_STATEMENT_TEST, () => store.showSettlementsStatementTestPage())
  page(Routes.SETTLEMENTS_PAYMENTS, () => store.showSettlementsPaymentsPage())
  page(Routes.CHARGEBACKS, () => store.showChargebacks())
  page(Routes.TRANSACTION_CHANNELS, Routes.TRANSACTION_CHANNELS_GLOBAL_SETTINGS)
  page(Routes.TRANSACTION_CHANNELS_GLOBAL_SETTINGS, () => store.showTransactionChannelsGlobalSettingsPage())
  page(Routes.TRANSACTION_CHANNELS_MERCHANT_SETTINGS, () => store.showTransactionChannelsMerchantSettingsPage())
  page(Routes.NOTIFICATIONS_MANAGEMENT_MANAGE_TEMPLATES, () => store.showManageNotificationTemplatesPage())
  page(Routes.NOTIFICATIONS_MANAGEMENT_MANAGE_TEMPLATES_CREATE, () => store.showManageNotificationTemplatesCreatePage())
  page(Routes.NOTIFICATIONS_MANAGEMENT_MANAGE_TEMPLATES_EDIT, ({ params: { id } }) => store.showManageNotificationTemplatesEditPage(id))
  page(Routes.NOTIFICATIONS_MANAGEMENT_MANAGE_NOTIFICATIONS, () => store.showManageNotificationPage())
  page(Routes.NOTIFICATIONS_MANAGEMENT_PUBLISH_NOTIFICATIONS, () => store.showPublishNotificationPage())
  page(Routes.APPLICATIONS_START_NEW_PROCESS, () => store.showStartProcessPage(undefined))
  page(Routes.APPLICATIONS_START_PROCESS, ({ params: { id } }) => store.showStartProcessPage(id))
  page(Routes.APPLICATIONS_START_PROCESS_ADDITIONAL, ({ params: { id, uberID } }) =>
    store.showAdditionalStoreProcessPage(id, uberID)
  )
  page(Routes.PARTNER_COMMISSIONS, () => store.showPartnerCommissions())

  page(Routes.PROFILE, () => store.showProfilePage())
  page(Routes.RECOVERY_CODE, () => store.showRecoveryCodePage())
  page(Routes.TWO_FA, () => store.showTwoFAPage())
  page(Routes.TWO_FA_ENFORCED, () => store.showTwoFAEnforcedPage())
  page(Routes.TWO_FA_CONFIRM, () => store.showTwoFAConfirmPage())
  page(Routes.MERCHANTS, () => store.showMerchantsPage())
  page(Routes.INVOICES, () => store.showInvoicesPage())
  page(Routes.NETSUITE_INVOICES, () => store.showNetSuiteInvoicesPage())
  page(Routes.PORTAL_ACCESS, () => store.showPortalAccessPage())
  page(Routes.PARTNER_ACCESS, () => store.showPartnerAccessPage())
  page(Routes.TEAMMATES, () => isPartner() ? store.showPartnerTeammatesPage() : store.showTeammatesPage())
  page(Routes.EXPORTS, () => store.showExportsPage())
  page(Routes.WILDCARD, () => store.showNotFoundPage())

  // initialize the router
  page()
}
