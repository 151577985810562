import { Button, Col, Form, Row } from 'antd'
import React, { useState } from 'react'
import styles from './EcomProductsList.scss'
import translations from './translations'
import { EcomProductsListPropsType } from './props'
import { DeleteRedOutlined, EditOutlinedBlue } from '~/assets/icons'
import { EcomFieldsForm } from '../EcomFieldsForm'
import { CustomButton } from '~/code/components'

export const EcomProductsList: React.FC<EcomProductsListPropsType> = props => {
  const { product, onRemove, store } = props

  const [form] = Form.useForm()
  const [editEnabled, setEditEnabled] = useState(false)

  return (
    <>
      <Row gutter={24}>
        <Col span={12}>
          <b>{translations().configureEcom}</b>
        </Col>
        <Col className={styles.rightAlign} span={12}>
          <Button
            disabled={store.dataStore.isDocusignSent}
            type='text'
            icon={<DeleteRedOutlined />}
            onClick={() => onRemove(product.id)}
          />
          <Button
            disabled={store.dataStore.isDocusignSent}
            type='text'
            icon={<EditOutlinedBlue />}
            onClick={() => setEditEnabled(true)}
          />
        </Col>
      </Row>
      <Form form={form} disabled={!editEnabled} layout='vertical' initialValues={product}>
        <EcomFieldsForm form={form} editMode website={store.dataStore.application?.companyProfile?.companyWebsite} />
        {editEnabled && (
          <CustomButton
            className={styles.mt15}
            type='primary'
            onClick={async () => {
              await form.validateFields()
              await store.productStore.saveEditingProduct(form.getFieldsValue(), product.id)
              setEditEnabled(false)
            }}
          >
            {translations().save}
          </CustomButton>
        )}
      </Form>
    </>
  )
}
