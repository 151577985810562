import React, { useState, useEffect, useMemo } from 'react'
import { Table, Checkbox, Typography } from 'antd'
import { CheckboxChangeEvent } from 'antd/es/checkbox'
import { isPartner } from '~/code/services/auth'
import {
  validActions,
  extractPermissionBase,
  filterValidPermissions,
  getInitialPermissionsState,
  removeDuplicateBases
} from './services'
import { PermissionTableProps } from './props'

import translations from './translations'
import styles from './styles.scss'

export const PermissionsTable: React.FC<PermissionTableProps> = ({
  permissions,
  initialPermissions,
  onPermissionsChange
}) => {
  const [selectedPermissions, setSelectedPermissions] = useState<{ [key: string]: string[] }>({})

  useEffect(() => {
    setSelectedPermissions(getInitialPermissionsState(initialPermissions))
  }, [initialPermissions])

  useEffect(() => {
    onPermissionsChange(
      Object.entries(selectedPermissions || {}).flatMap(([base, actions]) => actions.map(action => `${base}.${action}`))
    )
  }, [selectedPermissions, onPermissionsChange])

  const handleCheckboxChange = (permissionBase: string, action: string) => (e: CheckboxChangeEvent) => {
    setSelectedPermissions(prev => {
      const updatedPermissions = { ...prev }
      if (!updatedPermissions[permissionBase]) {
        updatedPermissions[permissionBase] = []
      }
      if (e.target.checked) {
        updatedPermissions[permissionBase].push(action)
      } else {
        updatedPermissions[permissionBase] = updatedPermissions[permissionBase].filter(a => a !== action)
        if (!updatedPermissions[permissionBase].length) {
          delete updatedPermissions[permissionBase]
        }
      }
      return updatedPermissions
    })
  }

  const columns = useMemo(
    () => [
      {
        dataIndex: 'permissionBase',
        key: 'permissionBase',

        render: (_: string, { permissionBase }: { permissionBase: string }) =>
          translations().permissions(isPartner())[permissionBase] || permissionBase
      },
      ...validActions.map(action => ({
        title: translations().actionTitle[action] || action,
        dataIndex: action,
        key: action,
        render: (_, { permissionBase }: { permissionBase: string }) => (
          <div className={styles.checkboxCell}>
            <Checkbox
              checked={selectedPermissions && selectedPermissions[permissionBase]?.includes(action)}
              onChange={handleCheckboxChange(permissionBase, action)}
              disabled={!isActionAvailable(permissionBase, action)}
            />
          </div>
        )
      }))
    ],
    [selectedPermissions]
  )

  const dataSource = useMemo(
    () =>
      removeDuplicateBases(filterValidPermissions(permissions)).map(permission => ({
        key: permission,
        permissionBase: extractPermissionBase(permission)
      })),
    [permissions]
  )

  const isActionAvailable = (base: string, action: string) => permissions.includes(`${base}.${action}`)

  return (
    <>
      <Typography.Title level={5}>{translations().title}</Typography.Title>
      <Table dataSource={dataSource} columns={columns} pagination={false} className={styles.root} />
    </>
  )
}
