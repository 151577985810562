import React from 'react'
import { TabType } from '~/code/models'
import { StyledTab } from '../../StyledTab'
import translations from '../translations'
import { ContactInfo } from '../../ContactInfo'
import { StartProcessStore } from '../../../StartProcessStore'
import { StartAppContainer } from '../../StartAppContainer'
import { BankAccountInfo } from '../../BankAccountInfo'
import { BankAccounts } from '../../BankAccounts'
import { IndividualDetails } from '../../IndividualDetails/IndividualDetails'
import { isEmpty } from 'dna-common'
import { ProductsInformation } from '../../ProductsInformation'
import { FinancialInfo } from '../../FinancialInfo'
import { FinancialInfoList } from '../../FinancialInfoList'
import { Pricing } from '../../Pricing'
import { PricingProductsList } from '../../PricingProductsList'
import { DocuSignForm } from '../../DocuSignForm'
import { SupportingDocuments } from '../../SupportingDocuments'
import { StartAppResult } from '../../StartAppResult'
import { BackButton } from '~/code/components'
import { SettlementsList } from '../../SettlementsList'
import { SettlementsForm } from '../../SettlementsForm'

export const generateMainFormTabItems = (store: StartProcessStore): TabType[] => {
  const tabItems: TabType[] = [
    {
      key: 'start',
      label: (
        <StyledTab
          title={translations().start}
          isCompleted={store.dataStore.isCompanyProfileValid}
          active={store.activeTabKey === 'start'}
          isDocusignSigned={store.dataStore.isDocusignSent}
        />
      ),
      children: store.activeTabKey === 'start' && <ContactInfo store={store} />,
      disabled: store.completeApplicationStore.isAdditionalStoreProcess
    }
  ]

  if (!store.completeApplicationStore.isAdditionalStoreProcess) {
    tabItems.push({
      key: 'individualDetails',
      label: (
        <StyledTab
          title={translations().individialDetails}
          isCompleted={store.dataStore.individualDetailsValid}
          disabled={!store.dataStore.applicationId || store.dataStore.isDocusignSent}
          active={store.activeTabKey === 'individualDetails'}
          isDocusignSigned={store.dataStore.isDocusignSent}
        />
      ),
      children: store.activeTabKey === 'individualDetails' && (
        <StartAppContainer>
          <IndividualDetails store={store} />
        </StartAppContainer>
      ),
      disabled: !store.dataStore.applicationId
    })
  }

  tabItems.push(
    {
      key: 'bankInfo',
      label: (
        <StyledTab
          title={translations().bankDetailsVerif}
          isCompleted={store.dataStore.isBankAccountsValid}
          disabled={store.dataStore.isBankAccountsSectionDisabled}
          active={store.activeTabKey === 'bankInfo'}
          isDocusignSigned={store.dataStore.isDocusignSent}
        />
      ),
      children: store.activeTabKey === 'bankInfo' && (
        <StartAppContainer>
          {store.bankAccountStore.selectedBankAccount ? (
            <>
              <BackButton onClick={() => store.bankAccountStore.selectBankAccount(null)}>
                {translations().back}
              </BackButton>
              <BankAccountInfo
                store={store.bankAccountStore}
                companyType={store.dataStore.application.companyProfile.companyType}
                onCancel={store.bankAccountStore.selectBankAccount}
                settings={store.onboardingSettings}
                disabled={store.dataStore.isDocusignSent}
              />
            </>
          ) : (
            <BankAccounts
              accounts={store.dataStore.bankAccounts}
              selectAccount={store.bankAccountStore.selectBankAccount}
              deleteAccount={store.dataStore.deleteBankAccount}
              onSave={() => store.dataStore.saveApplication('isSaveLoading')}
              settings={store.onboardingSettings}
              isDocusignSent={store.dataStore.isDocusignSent}
            />
          )}
        </StartAppContainer>
      ),
      disabled: store.dataStore.isBankAccountsSectionDisabled
    },
    {
      key: 'stores',
      label: (
        <StyledTab
          title={translations().storesProducts}
          isCompleted={store.dataStore.isStoresMainInfoValid}
          disabled={isEmpty(store.dataStore.bankAccounts) || store.dataStore.isDocusignSent}
          active={store.activeTabKey === 'stores'}
          isDocusignSigned={store.dataStore.isDocusignSent}
        />
      ),
      children: store.activeTabKey === 'stores' && <ProductsInformation store={store} />,
      disabled: isEmpty(store.dataStore.bankAccounts)
    },
    {
      key: 'financialInfo',
      label: (
        <StyledTab
          title={translations().financialInfo}
          isCompleted={store.dataStore.isStoresMainInfoValid && store.dataStore.isFinancialInfoValid}
          disabled={!store.dataStore.isStoresMainInfoValid || store.dataStore.isDocusignSent}
          active={store.activeTabKey === 'financialInfo'}
          isDocusignSigned={store.dataStore.isDocusignSent}
        />
      ),
      children: store.activeTabKey === 'financialInfo' && (
        <StartAppContainer>
          {store.productStore.financialStore ? (
            <>
              <BackButton onClick={() => store.productStore.setFinancialStore(null)}>{translations().back}</BackButton>
              <FinancialInfo
                onSubmit={store.completeApplicationStore.submitFinancialInfo}
                isNextLoading={store.completeApplicationStore.isNextLoading}
                isSaveLoading={store.completeApplicationStore.isSaveLoading}
                onSave={store.completeApplicationStore.saveOnGeneralInformation}
                financialStore={store.productStore.financialStore}
              />
            </>
          ) : (
            <FinancialInfoList
              stores={store.dataStore.stores}
              selectStore={store.productStore.setFinancialStore}
              onSave={() => store.dataStore.saveApplication('isSaveLoading')}
              isSaveLoading={store.dataStore.isSaveLoading}
              isDocusignSent={store.dataStore.isDocusignSent}
            />
          )}
        </StartAppContainer>
      ),
      disabled: !store.dataStore.isStoresMainInfoValid
    }
  )

  tabItems.push({
    key: 'settlements',
    label: (
      <StyledTab
        title={translations().settlements}
        isCompleted={store.dataStore.isSettlementsInfoValid()}
        disabled={store.dataStore.removableStores?.length === 0 || store.dataStore.isDocusignSent}
        active={store.activeTabKey === 'settlements'}
        isDocusignSigned={store.dataStore.isDocusignSent}
      />
    ),
    children: store.activeTabKey === 'settlements' && (
      <StartAppContainer>
        {store.pricingStore.showSettlementTerms ? (
          <>
            <BackButton onClick={() => store.pricingStore.setShowSettlementTerms(false)}>
              {translations().back}
            </BackButton>
            <SettlementsForm
              initialValues={store.dataStore.application.companyProfile?.settlementInfo}
              store={store.pricingStore}
            />
          </>
        ) : (
          <SettlementsList
            selectSettlements={store.pricingStore.setShowSettlementTerms}
            settlements={store.dataStore.application.companyProfile?.settlementInfo}
            onSave={() => store.dataStore.saveApplication('isSaveLoading')}
            isSaveLoading={store.dataStore.isSaveLoading}
            isValid={store.dataStore.isSettlementsValid}
            isDocusignSent={store.dataStore.isDocusignSent}
          />
        )}
      </StartAppContainer>
    ),
    disabled: store.dataStore.removableStores?.length === 0
  })

  if (store.onboardingSettings?.needPricing) {
    tabItems.push({
      key: 'pricing',
      label: (
        <StyledTab
          title={translations().pricing}
          isCompleted={store.dataStore.isPricingValid}
          disabled={store.dataStore.removableStores?.length === 0 || store.dataStore.isDocusignSent}
          active={store.activeTabKey === 'pricing'}
          isDocusignSigned={store.dataStore.isDocusignSent}
        />
      ),
      children: store.activeTabKey === 'pricing' && (
        <StartAppContainer>
          {store.pricingStore.selectedPricingProduct ? (
            <Pricing store={store.pricingStore} />
          ) : (
            <PricingProductsList
              selectProduct={store.pricingStore.setSelectedPricingProduct}
              products={store.pricingStore.productTypes}
              onSave={() => store.dataStore.saveApplication('isSaveLoading')}
              isSaveLoading={store.dataStore.isSaveLoading}
              isValid={store.dataStore.isPricingPlanValid}
              isDocusignSent={store.dataStore.isDocusignSent}
            />
          )}
        </StartAppContainer>
      ),
      disabled: store.dataStore.removableStores?.length === 0
    })
  }

  if (store.supportingDocumentsStore.showDocusign) {
    tabItems.push({
      key: 'contract',
      label: (
        <StyledTab
          title={translations().contract}
          isCompleted={store.dataStore.isDocusignCompleted}
          subTitle={
            store.dataStore.isApplicationValidWithoutDocuments &&
            store.dataStore.agreementOfficer &&
            !store.dataStore.isManualMSA
              ? translations().docusignStatus(store.dataStore.docuSignStatus)
              : store.dataStore.isManualMSA
              ? translations().manualMSA
              : ''
          }
          disabled={!store.dataStore.isApplicationValidWithoutDocuments}
          active={store.activeTabKey === 'contract'}
        />
      ),
      children: store.activeTabKey === 'contract' && (
        <>
          <StartAppContainer>
            <DocuSignForm store={store.supportingDocumentsStore} />
          </StartAppContainer>
        </>
      ),
      disabled: !store.dataStore.isApplicationValidWithoutDocuments
    })
  }

  tabItems.push(
    {
      key: 'supportingDocuments',
      label: (
        <StyledTab
          title={translations().title}
          isCompleted={
            store.supportingDocumentsStore.isDocsSectionCompleted &&
            store.dataStore.isApplicationValidWithoutDocuments &&
            store.dataStore.applicationId &&
            store.dataStore.uploadedDocumentsValid
          }
          disabled={!store.dataStore.isApplicationValidWithoutDocuments}
          active={store.activeTabKey === 'supportingDocuments'}
        />
      ),
      children: store.activeTabKey === 'supportingDocuments' && (
        <SupportingDocuments store={store.supportingDocumentsStore} />
      ),
      disabled: !store.dataStore.isApplicationValidWithoutDocuments
    },
    {
      key: 'result',
      label: (
        <StyledTab
          title={translations().submit}
          isCompleted={false}
          subTitle={translations().submit}
          disabled={store.isDocusignDisabled}
          active={store.activeTabKey === 'result'}
        />
      ),
      children: store.activeTabKey === 'result' && (
        <StartAppContainer>
          <StartAppResult
            store={store.completeApplicationStore}
            onBack={() => {
              store.openCompleteApplicationPage()
              store.completeApplicationStore.clearResultPage()
            }}
            onBackToStart={() => {
              store.reset()
            }}
            setActiveTab={store.setActiveTabKey}
          />
        </StartAppContainer>
      ),
      disabled: store.isDocusignDisabled
    }
  )
  return tabItems
}
