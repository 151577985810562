import React from 'react'
import translations from './translations'

translations.add('en', {
  title: 'General Information',
  description: 'We need to learn more about your business before you can start using our service',
  companyBlockTitle: 'Company',
  companyNameLabel: 'Legal name',
  companyNameRequired: 'legal name is required',
  sameAsCompanyName: 'same as company name',
  companyNumberLabel: 'Company number',
  companyNumberRequired: 'company number is required',
  dateOfCreationLabel: 'Trading from',
  dateOfCreationRequired: 'trading from date is required',
  dateOfCreationInvalid: 'invalid trading from date',
  natureOfBusinessLabel: 'Industry',
  natureOfBusinessRequired: 'industry is required',
  natureOfBusinessPlaceholder: 'Enter the industry, e.g.: retail, food',
  natureOfBusinessDetailsLabel: 'Product/service description',
  natureOfBusinessDetailsRequired: 'product/service is required',
  natureOfBusinessDetailsPlaceholder: 'Tell us what products or services you provide',
  companyWebsiteLabel: 'Company website',
  companyWebsiteRequired: 'company website is required',
  companyWebsiteInvalid: 'company website is invalid',
  employeesNumberLabel: 'Number of employees',
  employeesNumberRequired: 'number of employees is required',
  emailAddressLabel: 'Company email address',
  emailAddressRequired: 'company email address is required',
  emailFormatIncorrect: 'invalid email',
  telephoneNumberLabel: 'Company phone number',
  telephoneNumberRequired: 'company phone number is required',
  telephoneNumberWrongFormat: 'invalid company phone number',
  companyAddressTitle: 'Company registered address',
  companyAddress: {
    cityLabel: 'Town/City',
    cityRequired: 'town/city is required',
    cityInvalid: 'only alphanumeric characters are allowed',
    countryLabel: 'Country',
    countryRequired: 'country is required',
    regionLabel: 'County',
    postalCodeLabel: 'Postcode',
    postalCodeRequired: 'postcode is required',
    postalCodeInvalid: 'invalid postcode',
    addressLine1Label: 'Address line 1',
    addressLine1Required: 'address line 1 is required',
    addressLine2Label: 'Address line 2',
    countryTooltip: 'We are currently only able to work with UK registered businesses'
  },
  tradingAddressTitle: 'Trading address',
  sameAsCompanyAddress: 'same as company address',
  tradingAddress: {
    cityLabel: 'Town/City',
    cityRequired: 'town/city is required',
    cityInvalid: 'only alphanumeric characters are allowed',
    countryLabel: 'Country',
    countryRequired: 'country is required',
    regionLabel: 'County',
    postalCodeLabel: 'Postcode',
    postalCodeRequired: 'postcode is required',
    postalCodeInvalid: 'invalid postcode',
    addressLine1Label: 'Address line 1',
    addressLine1Required: 'address line 1 is required',
    addressLine2Label: 'Address line 2',
    countryTooltip: 'We are currently only able to work with UK registered businesses'
  },
  employeesNumberOptions: {
    upTo15: 'Up to 15',
    between15And50: '15-50',
    between50And100: '50-100',
    between100And300: '100-300',
    over300: 'Over 300'
  },
  new: 'New',
  renewal: 'Renewal',
  newToDNA: 'New to DNA Group?',
  relationshipTypeRequired: 'relationship type is required',
  comments: 'Application comments',
  isDirectDebit: 'Is Direct Debit?',
  yes: 'Yes',
  no: 'No',
  commentsPlaceholder: 'Fill this out if you wish to provide additional details for the underwriting team',
  attention: '! ATTENTION',
  informText1: 'This email will be registered to access DNA Merchant Portal.',
  informText2: 'It is vital to input the exact email the Merchant will use to log in to the Merchant Portal.',
  informText3: 'Confirm this email with the Merchant and paste it carefully to avoid typos.',
  emailPlaceholder: 'me@company.co.uk',
  merchantBankAccount: 'Merchant Bank Account - For Charges (Money Out)',
  confirmEdit: 'Confirm edit',
  confirmationText: (
    <>
      <p>
        Before proceeding with editing this field, please be aware that any changes made here will lead to the removal
        of all bank account information associated with this process.
      </p>
      <p>Are you certain you wish to proceed with editing this field?</p>
    </>
  ),
  countryOfInitialReg: 'Country of initial registration of the company',
  required: 'Required',
  companyTradeName: 'Company Trade Name',
  tradeNameNote: 'Please ensure correct Trade Name for automated verification and checks.',
  invalidDomain: 'Domains: @dnapayments.com;@dnapaymentsgroup.com;@optomany.com;@123send.com forbidden, use another email',
  datePlaceholder: 'yyyy / mm / dd',
  websitePlaceholder: 'https://www.example.com',
  invalidWebsiteDomain: 'Domains: dnapayments.com;dnapaymentsgroup.com;optomany.com;123send.com forbidden, use another domain'
})
